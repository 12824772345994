import { assert } from "@leon-hub/guards";
class ServiceLocator {
  constructor(keys) {
    this.keys = keys;
    this.services = /* @__PURE__ */ new Map();
  }
  reset() {
    this.services.clear();
  }
  mockProvider(key, options) {
    if (process.env.NODE_ENV !== "test") return () => void 0;
    const { services } = this;
    const prevOptions = services.get(key);
    services.set(key, options);
    return () => {
      if (prevOptions) {
        services.set(key, prevOptions);
      } else {
        services.delete(key);
      }
    };
  }
  registerProvider(key, options) {
    if (process.env.NODE_ENV !== "test" && this.services.has(key)) {
      const errMsg = `Provider for key "${key.key}" is already registered`;
      if (process.env.NODE_ENV === "development") {
        throw new Error(errMsg);
      } else {
        console.error(errMsg);
        return;
      }
    }
    this.services.set(key, options);
  }
  getProvider(key) {
    const options = this.services.get(key);
    if (options) {
      return () => {
        const value = options.value();
        if (options.required && !value) {
          throw new Error(`Invalid provided value for key ${key.key}: "${value}"`);
        }
        return value;
      };
    }
    throw new Error(`Service not found: ${key.key}`);
  }
  getNonConfiguredKeys() {
    const loadedKeys = [...this.services.keys()];
    return this.keys.filter((key) => !loadedKeys.includes(key));
  }
  isConfigured() {
    return !this.getNonConfiguredKeys().length;
  }
}
function assertConfiguration(serviceLocator) {
  assert(serviceLocator.isConfigured(), `Expected service locator keys to be configured: ${serviceLocator.getNonConfiguredKeys().join(", ")}`);
}
const createToString = (key) => () => key;
function createServiceLocatorKey(key) {
  return {
    withType() {
      return {
        key,
        toString: createToString(key)
      };
    }
  };
}
export {
  ServiceLocator,
  assertConfiguration,
  createServiceLocatorKey
};
