import { LogLevel, AbstractError } from "@leon-hub/errors";
import { LogLevel as LogLevel2 } from "@leon-hub/errors";
import bindAll from "lodash/bindAll.js";
import noop from "lodash/noop.js";
import { getLocationHref } from "@leon-hub/service-locator-env";
import { isObject, isString, isOptionalString } from "@leon-hub/guards";
function isLogData(log) {
  return isObject(log) && isString(log.message) && isString(log.stacktrace) && isOptionalString(log.payload);
}
function consoleLog(level, initMessage, trace, payload) {
  let message = initMessage;
  if (trace) {
    message += `
[Stacktrace]: ${trace}`;
  }
  const consoleInput = [
    message
  ];
  if (payload) {
    consoleInput.push(payload);
  }
  switch (level) {
    case LogLevel.INFO:
      console.info(...consoleInput);
      break;
    case LogLevel.WARN:
      console.warn(...consoleInput);
      break;
    case LogLevel.DEBUG:
      console.debug(...consoleInput);
      break;
    case LogLevel.ERROR:
      console.error(...consoleInput);
      break;
  }
}
function consoleLogWithTime(level, prefix, message) {
  const timestamp = `${(/* @__PURE__ */ new Date()).toLocaleTimeString("en-GB")}.${String((/* @__PURE__ */ new Date()).getMilliseconds()).padStart(3, "0")}`;
  consoleLog(level, `[${timestamp}] ${prefix} ${message}`);
}
class NameSpacedLogger {
  constructor(logger2, namespace) {
    this.logger = logger2;
    this.namespace = namespace;
  }
  info(input, payload, error) {
    this.logger.sendLog(LogLevel.INFO, input, payload, error, this.namespace);
  }
  debug(input, payload, error) {
    this.logger.sendLog(LogLevel.DEBUG, input, payload, error, this.namespace);
  }
  warn(input, payload, error) {
    this.logger.sendLog(LogLevel.WARN, input, payload, error, this.namespace);
  }
  error(input, payload, error) {
    this.logger.sendLog(LogLevel.ERROR, input, payload, error, this.namespace);
  }
  createNamespace(namespace) {
    return new NameSpacedLogger(this.logger, namespace ? [...this.namespace, namespace] : [...this.namespace]);
  }
}
class Logger {
  constructor(options) {
    this.loaded = false;
    this.options = {
      appName: "",
      appVersion: "",
      remoteSaveActionLogLevel: LogLevel.DEBUG
    };
    bindAll(this);
    if (options) {
      this.init(options);
    }
  }
  init(options) {
    this.options = options;
    this.loaded = true;
  }
  setRemoteSaveAction(remoteSaveAction) {
    this.options.remoteSaveAction = remoteSaveAction;
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
  sendLog(level, input, payload, error, namespace) {
    var _a, _b;
    if (process.env.NODE_ENV === "test") {
      return;
    }
    if (!this.loaded) {
      console.warn("LOGGING CALLED WITH DEFAULT PARAMETERS! To setup logger please call logger.init() action!");
    }
    let message = "";
    let trace = "";
    let payloadForRemoteLog = "";
    let payloadForLocalLog = payload;
    const namespacePrefix = namespace && namespace.length > 0 ? namespace.map((item) => `[${item}]`).join("") + " " : "";
    if (typeof input === "string") {
      message = input;
      if (payload) {
        if (payload instanceof Error) {
          trace = payload.stack || "";
          if (payload instanceof AbstractError) {
            payloadForRemoteLog = JSON.stringify(payload);
          } else {
            message += `
${payload}`;
          }
        } else {
          payloadForRemoteLog = JSON.stringify(payload);
          payloadForLocalLog = payload;
        }
      }
      if (error) {
        trace = error.stack || "";
        message += `
${error}`;
      }
    } else if (input instanceof AbstractError) {
      const {
        stack,
        message: errorMessage,
        ...sendToLog
      } = input.toJSON();
      message = errorMessage;
      trace = input.stack ?? "";
      payloadForRemoteLog = JSON.stringify(sendToLog);
      payloadForLocalLog = payloadForLocalLog || input.payload;
    } else if (input instanceof Error) {
      message = `${input}`;
      trace = input.stack || "";
      payloadForRemoteLog = JSON.stringify(payload || input);
    } else if (isLogData(input)) {
      message = input.message;
      trace = input.stacktrace;
      payloadForRemoteLog = input.payload || "";
      payloadForLocalLog = payloadForLocalLog || input.payload;
    }
    message = `${namespacePrefix}${message}`;
    if (this.options.remoteSaveAction !== void 0 && Logger.isLoggableLevel(level, this.options.remoteSaveActionLogLevel)) {
      const url = getLocationHref();
      const remoteInput = {
        appName: this.options.appName,
        appVersion: this.options.appVersion,
        level,
        line: 0,
        column: 0,
        error: message,
        file: "",
        url,
        ts: 0,
        stacktrace: trace || "",
        payload: payloadForRemoteLog || ""
      };
      this.options.remoteSaveAction(remoteInput).catch(noop);
    }
    if (!this.options.remoteSaveAction || ((_b = (_a = this.options).isConsoleOutputAllowed) == null ? void 0 : _b.call(_a))) {
      consoleLog(level, message, trace, payloadForLocalLog);
    }
  }
  static isLoggableLevel(level1, level2) {
    if (!level2) {
      return true;
    }
    switch (level2) {
      case LogLevel.DEBUG:
        return true;
      case LogLevel.INFO:
        return level1 === LogLevel.INFO || level1 === LogLevel.WARN || level1 === LogLevel.ERROR;
      case LogLevel.WARN:
        return level1 === LogLevel.WARN || level1 === LogLevel.ERROR;
      case LogLevel.ERROR:
        return level1 === LogLevel.ERROR;
      default:
        return false;
    }
  }
  info(input, payload, error) {
    this.sendLog(LogLevel.INFO, input, payload, error);
  }
  debug(input, payload, error) {
    this.sendLog(LogLevel.DEBUG, input, payload, error);
  }
  warn(input, payload, error) {
    this.sendLog(LogLevel.WARN, input, payload, error);
  }
  error(input, payload, error) {
    this.sendLog(LogLevel.ERROR, input, payload, error);
  }
  createNamespace(namespace) {
    return new NameSpacedLogger(this, namespace ? [namespace] : []);
  }
}
const logger = /* @__PURE__ */ new Logger();
export {
  LogLevel2 as LogLevel,
  consoleLogWithTime,
  Logger as default,
  logger
};
