import { CustomError } from "ts-custom-error";
import { getBootstrapTranslations } from "@leon-hub/bootstrap-translations";
import { isError } from "@leon-hub/guards";
let cache;
function isCauseSupported() {
  if (cache === void 0) {
    try {
      const testError = new Error(void 0, { cause: new Error() });
      cache = testError.cause !== void 0;
    } catch {
      cache = false;
    }
  }
  return cache;
}
function getErrorMessage(exception, message) {
  if (typeof exception === "undefined") return message;
  if (typeof exception === "object") {
    const exceptionName = Object.prototype.toString.call(exception);
    const serializedException = JSON.stringify(exception);
    return `${message}: ${exceptionName} ${serializedException}`;
  }
  return `${message}: ${String(exception)}`;
}
const defaultMessage = "Unexpected state";
function normalizeError(exception, message = defaultMessage) {
  var _a;
  if (isError(exception)) {
    return exception;
  }
  const errorFallback = new Error(getErrorMessage(exception, message));
  (_a = Error.captureStackTrace) == null ? void 0 : _a.call(Error, errorFallback, normalizeError);
  return errorFallback;
}
class AbstractError extends CustomError {
  constructor({
    log,
    ...options
  }) {
    var _a, _b;
    const rawCause = options.cause ?? options.originalError;
    const cause = rawCause === void 0 ? void 0 : normalizeError(rawCause);
    const causeSupported = isCauseSupported();
    super(options.message ?? (cause == null ? void 0 : cause.message) ?? getBootstrapTranslations().WEB2_TECHNICAL_ERROR, causeSupported ? { cause } : void 0);
    this.logLevel = "ERROR";
    this.metaData = {};
    this.cause = this.cause ?? cause;
    if (!causeSupported) {
      if (cause) {
        this.cause = cause;
        const stack = (_a = this.cause) == null ? void 0 : _a.stack;
        if (!this.stack) {
          this.stack = stack;
        } else if (stack) {
          this.stack = `${this.stack}
Caused by: ${stack}`;
        }
      }
    } else {
      (_b = Error.captureStackTrace) == null ? void 0 : _b.call(Error, this, (cause == null ? void 0 : cause.constructor) ?? new.target);
    }
    this.logLevel = options.logLevel ?? this.logLevel;
    this.code = options.code;
    this.silent = options.silent ?? false;
    this.payload = options.payload;
    this.interactive = !!options.interactive;
    if (log) {
      this.addLogMetaData("log", log);
    }
  }
  isInteractive() {
    return this.interactive;
  }
  addLogMetaData(key, value) {
    this.metaData[key] = value;
  }
  toJSON() {
    return this.getSerializable();
  }
  toString() {
    const stack = this.stack ? `
${this.stack}` : "";
    return `${this.name}: ${this.message}${stack}`;
  }
  getSerializable() {
    return {
      name: this.name,
      message: this.message,
      code: this.code.toString(),
      metaData: this.metaData,
      stack: this.stack,
      silent: this.silent,
      payload: this.payload,
      interactive: this.interactive
    };
  }
  clone() {
    return Object.create(Object.getPrototypeOf(this), Object.getOwnPropertyDescriptors(this));
  }
}
class AbstractErrorCode {
  constructor(code) {
    this.code = code;
  }
  is(Constructor) {
    return this instanceof Constructor;
  }
  equals(code) {
    return this.code === code.toString();
  }
  toString() {
    return this.code;
  }
}
var LogLevel = /* @__PURE__ */ ((LogLevel2) => {
  LogLevel2["ERROR"] = "ERROR";
  LogLevel2["WARN"] = "WARN";
  LogLevel2["INFO"] = "INFO";
  LogLevel2["DEBUG"] = "DEBUG";
  return LogLevel2;
})(LogLevel || {});
export {
  AbstractError,
  AbstractErrorCode,
  LogLevel,
  normalizeError
};
