import { getLocationHref, getLocationOrigin, getQueryParams } from "@leon-hub/service-locator-env";
import { isString, assert, isNumber, isBoolean } from "@leon-hub/guards";
var QueryParameterGroupName = /* @__PURE__ */ ((QueryParameterGroupName2) => {
  QueryParameterGroupName2["APP"] = "app";
  QueryParameterGroupName2["AFFILIATE"] = "affiliate";
  QueryParameterGroupName2["MARKETING"] = "marketing";
  QueryParameterGroupName2["BONUS"] = "bonus";
  QueryParameterGroupName2["TESTING"] = "testing";
  return QueryParameterGroupName2;
})(QueryParameterGroupName || {});
var QueryParameterName = /* @__PURE__ */ ((QueryParameterName2) => {
  QueryParameterName2["UTM_SOURCE"] = "utm_source";
  QueryParameterName2["UTM_MEDIUM"] = "utm_medium";
  QueryParameterName2["UTM_CAMPAIGN"] = "utm_campaign";
  QueryParameterName2["UTM_TERM"] = "utm_term";
  QueryParameterName2["UTM_CONTENT"] = "utm_content";
  QueryParameterName2["RETENTION_ID"] = "retentionId";
  QueryParameterName2["APP_AD"] = "ca";
  QueryParameterName2["QTAG"] = "qtag";
  QueryParameterName2["REFTAG"] = "reftag";
  QueryParameterName2["PRERENDER"] = "pr";
  QueryParameterName2["PRERENDER_HOST"] = "prhost";
  QueryParameterName2["PRERENDER_IP"] = "prip";
  QueryParameterName2["BONUS_CODE"] = "bcode";
  QueryParameterName2["TEST_CASE"] = "test-case";
  QueryParameterName2["VUE_DEVTOOLS"] = "vue-devtools";
  QueryParameterName2["VUE_PERFORMANCE"] = "vue-performance";
  QueryParameterName2["VUE_SILENT"] = "vue-silent";
  QueryParameterName2["WM"] = "wm";
  QueryParameterName2["SUB_ID"] = "subid";
  QueryParameterName2["SUB_ID2"] = "subid2";
  QueryParameterName2["SUB_ID3"] = "subid3";
  QueryParameterName2["SUB_ID4"] = "subid4";
  QueryParameterName2["SUB_ID5"] = "subid5";
  QueryParameterName2["CLICK_ID"] = "click_id";
  QueryParameterName2["WM_ZONE"] = "wmzone";
  QueryParameterName2["BONUS_TAG"] = "btag";
  QueryParameterName2["IA_BONUS_TAG"] = "iaBtag";
  QueryParameterName2["LOCALE"] = "locale";
  QueryParameterName2["IA_LOCALE"] = "iaLocale";
  QueryParameterName2["PLATFORM"] = "platform";
  QueryParameterName2["FROM_PUSH"] = "is-push";
  QueryParameterName2["BANNER_ID"] = "bid";
  return QueryParameterName2;
})(QueryParameterName || {});
function toString(value, defaultValue) {
  return isString(value) ? value : defaultValue;
}
function toNumber(value, defaultValue) {
  const numberValue = Number(value);
  if (Number.isFinite(numberValue) && String(numberValue) === value) {
    return numberValue;
  }
  return defaultValue;
}
function toBoolean(value, defaultValue) {
  if (value === "true") {
    return true;
  }
  if (value === "false") {
    return false;
  }
  return defaultValue;
}
function getQueryParameter(searchParameters, key, type, defaultValue) {
  const value = searchParameters.get(key);
  if (isString(value)) {
    if (type === "string") {
      assert(isString(defaultValue));
      return toString(value, defaultValue);
    }
    if (type === "number") {
      assert(isNumber(defaultValue));
      return toNumber(value, defaultValue);
    }
    if (type === "boolean") {
      assert(isBoolean(defaultValue));
      return toBoolean(value, defaultValue);
    }
  }
  return defaultValue;
}
function getAffiliateParameters() {
  const affiliateParameters = [
    QueryParameterName.WM,
    QueryParameterName.SUB_ID,
    QueryParameterName.SUB_ID2,
    QueryParameterName.SUB_ID3,
    QueryParameterName.SUB_ID4,
    QueryParameterName.SUB_ID5,
    QueryParameterName.CLICK_ID,
    QueryParameterName.WM_ZONE,
    QueryParameterName.BONUS_TAG,
    QueryParameterName.IA_BONUS_TAG,
    QueryParameterName.LOCALE,
    QueryParameterName.IA_LOCALE
  ];
  const parameters = [];
  for (const parameter of affiliateParameters) {
    parameters.push({
      key: parameter,
      type: "string",
      value: "",
      present: false,
      group: QueryParameterGroupName.AFFILIATE,
      apiHeaderName: `X-${parameter.replace(/_/g, "-").toUpperCase()}`
    });
  }
  return parameters;
}
function getBonusParameters() {
  return [{
    key: QueryParameterName.BONUS_CODE,
    type: "string",
    value: "",
    present: false,
    group: QueryParameterGroupName.BONUS
  }];
}
function getBanerIdParameters() {
  return [{
    key: QueryParameterName.BANNER_ID,
    type: "string",
    value: "",
    present: false,
    group: QueryParameterGroupName.BONUS
  }];
}
function getDefaultParameters() {
  return [
    {
      key: QueryParameterName.VUE_DEVTOOLS,
      type: "boolean",
      value: !process.env.VUE_APP_RENDERING_SSR && process.env.NODE_ENV === "development",
      present: false,
      group: QueryParameterGroupName.APP
    },
    {
      key: QueryParameterName.VUE_PERFORMANCE,
      type: "boolean",
      value: !process.env.VUE_APP_RENDERING_SSR && process.env.NODE_ENV === "development",
      present: false,
      group: QueryParameterGroupName.APP
    },
    {
      key: QueryParameterName.VUE_SILENT,
      type: "boolean",
      value: process.env.NODE_ENV === "production",
      present: false,
      group: QueryParameterGroupName.APP
    }
  ];
}
function getMarketingParameters() {
  const marketingParameters = [
    QueryParameterName.UTM_SOURCE,
    QueryParameterName.UTM_MEDIUM,
    QueryParameterName.UTM_CAMPAIGN,
    QueryParameterName.UTM_TERM,
    QueryParameterName.UTM_CONTENT
  ];
  const parameters = [];
  for (const parameter of marketingParameters) {
    parameters.push({
      key: parameter,
      type: "string",
      value: "",
      present: false,
      group: QueryParameterGroupName.MARKETING,
      apiHeaderName: `X-${parameter.replace(/_/g, "-").toUpperCase()}`
    }, {
      key: QueryParameterName.RETENTION_ID,
      apiHeaderName: "X-RETENTION-ID",
      type: "string",
      value: "",
      present: false,
      useOnce: true,
      group: QueryParameterGroupName.MARKETING
    }, {
      key: QueryParameterName.APP_AD,
      type: "string",
      value: "",
      present: false,
      group: QueryParameterGroupName.MARKETING
    }, {
      key: QueryParameterName.QTAG,
      apiHeaderName: "X-QTAG",
      type: "string",
      value: "",
      present: false,
      group: QueryParameterGroupName.MARKETING
    }, {
      key: QueryParameterName.REFTAG,
      apiHeaderName: "X-REFTAG",
      type: "string",
      value: "",
      present: false,
      group: QueryParameterGroupName.MARKETING
    });
  }
  return parameters;
}
function getTestingParameters() {
  return [{
    key: QueryParameterName.TEST_CASE,
    type: "string",
    value: "",
    present: false,
    group: QueryParameterGroupName.TESTING
  }, {
    key: QueryParameterName.PRERENDER,
    type: "number",
    value: 0,
    present: false,
    group: QueryParameterGroupName.TESTING
  }, {
    key: QueryParameterName.PRERENDER_HOST,
    type: "string",
    value: "",
    present: false,
    group: QueryParameterGroupName.TESTING
  }, {
    key: QueryParameterName.PRERENDER_IP,
    type: "string",
    value: "",
    present: false,
    group: QueryParameterGroupName.TESTING
  }];
}
function isValidParameterItem(item, input) {
  let addItem = true;
  if (input) {
    if (input.group && (Array.isArray(input.group) && !input.group.includes(item.group) || !Array.isArray(input.group) && input.group !== item.group)) {
      addItem = false;
    }
    if (!input.showNotPresent && !item.present) {
      addItem = false;
    }
  } else if (!item.present) {
    addItem = false;
  }
  return addItem;
}
function getQueryManager(initUrl) {
  let url = initUrl || getLocationHref();
  if (process.env.VUE_APP_PLATFORM_CORDOVA && !initUrl) {
    const { origin, pathname, hash } = window.location;
    const searchQuery = hash.split("?")[1];
    if (searchQuery) {
      url = `${origin}${pathname}?${searchQuery}`;
    }
  }
  if (url.startsWith("/")) {
    url = `${getLocationOrigin()}${url}`;
  }
  const parameters = [];
  const searchParameters = new URL(url).searchParams;
  function applyUrlParameters() {
    for (const parameter of Object.values(parameters)) {
      Object.assign(parameter, { present: searchParameters.has(parameter.key) });
    }
  }
  parameters.push(
    ...getDefaultParameters(),
    ...getAffiliateParameters(),
    ...getBonusParameters(),
    ...getMarketingParameters(),
    ...getTestingParameters(),
    ...getBanerIdParameters()
  );
  applyUrlParameters();
  function getParameters(input) {
    return parameters.reduce((accumulator, item) => {
      let value = getQueryParameter(
        searchParameters,
        item.key,
        item.type,
        item.value
      );
      if (typeof value === "boolean" && !value && !(input == null ? void 0 : input.booleanAsString)) {
        value = "";
      }
      if (isValidParameterItem(item, input)) {
        return {
          ...accumulator,
          [item.key]: String(value)
        };
      }
      return accumulator;
    }, {});
  }
  function getHeaderParameters(input) {
    return parameters.reduce((accumulator, item) => {
      let value = getQueryParameter(
        searchParameters,
        item.key,
        item.type,
        item.value
      );
      if (typeof value === "boolean" && !value && !(input == null ? void 0 : input.booleanAsString)) {
        value = "";
      }
      if (isValidParameterItem(item, input)) {
        if (item.useOnce) {
          item.present = false;
        }
        const key = item.apiHeaderName ? item.apiHeaderName : item.key.toUpperCase();
        return {
          ...accumulator,
          [key]: encodeURIComponent(String(value))
        };
      }
      return accumulator;
    }, {});
  }
  function getParameter(name, showNotPresent = false) {
    return getParameters({
      showNotPresent
    })[name] || "";
  }
  function getRootHeaderParameters() {
    return getHeaderParameters({
      group: [QueryParameterGroupName.AFFILIATE, QueryParameterGroupName.MARKETING]
    });
  }
  return {
    getHeaderParameters,
    getParameters,
    getParameter,
    getRootHeaderParameters
  };
}
const getPrerenderIp = () => getQueryParams()[QueryParameterName.PRERENDER_IP] || void 0;
function stripPrerenderParametersFromUrl(url) {
  for (const key of [
    QueryParameterName.PRERENDER,
    QueryParameterName.PRERENDER_HOST,
    QueryParameterName.PRERENDER_IP
  ]) {
    url.searchParams.delete(key);
  }
  return url;
}
export {
  QueryParameterGroupName,
  QueryParameterName,
  getPrerenderIp,
  getQueryManager,
  getQueryParameter,
  stripPrerenderParametersFromUrl
};
