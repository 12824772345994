function useApiMethod(client, method, middleware) {
  const stop = client.addMiddleware(method, middleware);
  return {
    stop
  };
}
function print(value) {
  return value;
}
var AccessDeniedRemoteApiExceptionCode = /* @__PURE__ */ ((AccessDeniedRemoteApiExceptionCode2) => {
  AccessDeniedRemoteApiExceptionCode2["ACCESS_DENIED"] = "ACCESS_DENIED";
  return AccessDeniedRemoteApiExceptionCode2;
})(AccessDeniedRemoteApiExceptionCode || {});
const accessDeniedRemoteApiExceptionCodeAccessDenied = "ACCESS_DENIED";
var AccountHistoryBetType = /* @__PURE__ */ ((AccountHistoryBetType2) => {
  AccountHistoryBetType2["SINGLE"] = "SINGLE";
  AccountHistoryBetType2["EXPRESS"] = "EXPRESS";
  AccountHistoryBetType2["SYSTEM"] = "SYSTEM";
  return AccountHistoryBetType2;
})(AccountHistoryBetType || {});
const accountHistoryBetTypeSingle = "SINGLE";
const accountHistoryBetTypeExpress = "EXPRESS";
const accountHistoryBetTypeSystem = "SYSTEM";
var AccountHistoryBonusCursorStatus = /* @__PURE__ */ ((AccountHistoryBonusCursorStatus2) => {
  AccountHistoryBonusCursorStatus2["CREATED"] = "CREATED";
  AccountHistoryBonusCursorStatus2["ACTIVATED"] = "ACTIVATED";
  AccountHistoryBonusCursorStatus2["RESULTED"] = "RESULTED";
  return AccountHistoryBonusCursorStatus2;
})(AccountHistoryBonusCursorStatus || {});
const accountHistoryBonusCursorStatusCreated = "CREATED";
const accountHistoryBonusCursorStatusActivated = "ACTIVATED";
const accountHistoryBonusCursorStatusResulted = "RESULTED";
var AccountHistoryCloseReason = /* @__PURE__ */ ((AccountHistoryCloseReason2) => {
  AccountHistoryCloseReason2["SUCCESSFUL"] = "SUCCESSFUL";
  AccountHistoryCloseReason2["NO_BALANCE"] = "NO_BALANCE";
  AccountHistoryCloseReason2["REFUSED"] = "REFUSED";
  AccountHistoryCloseReason2["EXPIRED"] = "EXPIRED";
  AccountHistoryCloseReason2["FREESPIN_LOST"] = "FREESPIN_LOST";
  AccountHistoryCloseReason2["ERROR"] = "ERROR";
  return AccountHistoryCloseReason2;
})(AccountHistoryCloseReason || {});
const accountHistoryCloseReasonSuccessful = "SUCCESSFUL";
const accountHistoryCloseReasonNoBalance = "NO_BALANCE";
const accountHistoryCloseReasonRefused = "REFUSED";
const accountHistoryCloseReasonExpired = "EXPIRED";
const accountHistoryCloseReasonFreespinLost = "FREESPIN_LOST";
const accountHistoryCloseReasonError = "ERROR";
const accountHistoryDataGroupEgs = "EGS";
const accountHistoryDataGroupMarketing = "MARKETING";
const accountHistoryDataGroupPayment = "PAYMENT";
const accountHistoryDataGroupSportsbook = "SPORTSBOOK";
const accountHistoryDataGroupVsport = "VSPORT";
const accountHistoryDataGroupFastBet = "FAST_BET";
const accountHistoryFeatureEgs = "EGS";
const accountHistoryFeatureSport = "SPORT";
const accountHistoryFeatureVsport = "VSPORT";
const accountHistoryFeatureFastBet = "FAST_BET";
const accountHistoryFeatureLeonshop = "LEONSHOP";
var AccountHistorySource = /* @__PURE__ */ ((AccountHistorySource2) => {
  AccountHistorySource2["EGS"] = "EGS";
  AccountHistorySource2["STAFF"] = "STAFF";
  AccountHistorySource2["CUSTOMER"] = "CUSTOMER";
  AccountHistorySource2["SYSTEM"] = "SYSTEM";
  AccountHistorySource2["EXTERNAL_SYSTEM"] = "EXTERNAL_SYSTEM";
  return AccountHistorySource2;
})(AccountHistorySource || {});
const accountHistorySourceEgs = "EGS";
const accountHistorySourceStaff = "STAFF";
const accountHistorySourceCustomer = "CUSTOMER";
const accountHistorySourceSystem = "SYSTEM";
const accountHistorySourceExternalSystem = "EXTERNAL_SYSTEM";
var AccountHistoryStatus = /* @__PURE__ */ ((AccountHistoryStatus2) => {
  AccountHistoryStatus2["UNKNOWN"] = "UNKNOWN";
  AccountHistoryStatus2["BET_PENDING"] = "BET_PENDING";
  AccountHistoryStatus2["BET_WAITING"] = "BET_WAITING";
  AccountHistoryStatus2["BET_CALCULATED"] = "BET_CALCULATED";
  AccountHistoryStatus2["BET_CALCULATED_CHECKED"] = "BET_CALCULATED_CHECKED";
  AccountHistoryStatus2["BET_CALCULATED_CASHOUT"] = "BET_CALCULATED_CASHOUT";
  AccountHistoryStatus2["MARKETING_CREATED"] = "MARKETING_CREATED";
  AccountHistoryStatus2["MARKETING_PENDING_ACTIVATION"] = "MARKETING_PENDING_ACTIVATION";
  AccountHistoryStatus2["MARKETING_ACTIVE"] = "MARKETING_ACTIVE";
  AccountHistoryStatus2["MARKETING_CLOSED"] = "MARKETING_CLOSED";
  AccountHistoryStatus2["PAY_PROCESSING"] = "PAY_PROCESSING";
  AccountHistoryStatus2["PAY_EXECUTED"] = "PAY_EXECUTED";
  AccountHistoryStatus2["PAY_CANCELLED"] = "PAY_CANCELLED";
  AccountHistoryStatus2["PAY_STARTED"] = "PAY_STARTED";
  AccountHistoryStatus2["PAY_BAD_EXECUTED"] = "PAY_BAD_EXECUTED";
  AccountHistoryStatus2["PAY_WAITING"] = "PAY_WAITING";
  AccountHistoryStatus2["PAY_ROLLBACK"] = "PAY_ROLLBACK";
  AccountHistoryStatus2["MGC_LOST"] = "MGC_LOST";
  AccountHistoryStatus2["MGC_WON"] = "MGC_WON";
  AccountHistoryStatus2["MGC_REFUND"] = "MGC_REFUND";
  AccountHistoryStatus2["MGC_WON_2"] = "MGC_WON_2";
  AccountHistoryStatus2["EXG_PERFORMED"] = "EXG_PERFORMED";
  AccountHistoryStatus2["PAY_REGULATOR_CANCELLED"] = "PAY_REGULATOR_CANCELLED";
  AccountHistoryStatus2["PAY_REGULATOR_ROLLBACK"] = "PAY_REGULATOR_ROLLBACK";
  AccountHistoryStatus2["LEONSHOP_ORDER_CREATED"] = "LEONSHOP_ORDER_CREATED";
  AccountHistoryStatus2["LEONSHOP_ORDER_IN_PROGRESS"] = "LEONSHOP_ORDER_IN_PROGRESS";
  AccountHistoryStatus2["LEONSHOP_ORDER_DONE"] = "LEONSHOP_ORDER_DONE";
  AccountHistoryStatus2["LEONSHOP_ORDER_CANCELLED"] = "LEONSHOP_ORDER_CANCELLED";
  return AccountHistoryStatus2;
})(AccountHistoryStatus || {});
const accountHistoryStatusUnknown = "UNKNOWN";
const accountHistoryStatusBetPending = "BET_PENDING";
const accountHistoryStatusBetWaiting = "BET_WAITING";
const accountHistoryStatusBetCalculated = "BET_CALCULATED";
const accountHistoryStatusBetCalculatedChecked = "BET_CALCULATED_CHECKED";
const accountHistoryStatusBetCalculatedCashout = "BET_CALCULATED_CASHOUT";
const accountHistoryStatusMarketingCreated = "MARKETING_CREATED";
const accountHistoryStatusMarketingPendingActivation = "MARKETING_PENDING_ACTIVATION";
const accountHistoryStatusMarketingActive = "MARKETING_ACTIVE";
const accountHistoryStatusMarketingClosed = "MARKETING_CLOSED";
const accountHistoryStatusPayProcessing = "PAY_PROCESSING";
const accountHistoryStatusPayExecuted = "PAY_EXECUTED";
const accountHistoryStatusPayCancelled = "PAY_CANCELLED";
const accountHistoryStatusPayStarted = "PAY_STARTED";
const accountHistoryStatusPayBadExecuted = "PAY_BAD_EXECUTED";
const accountHistoryStatusPayWaiting = "PAY_WAITING";
const accountHistoryStatusPayRollback = "PAY_ROLLBACK";
const accountHistoryStatusMgcLost = "MGC_LOST";
const accountHistoryStatusMgcWon = "MGC_WON";
const accountHistoryStatusMgcRefund = "MGC_REFUND";
const accountHistoryStatusMgcWon2 = "MGC_WON_2";
const accountHistoryStatusExgPerformed = "EXG_PERFORMED";
const accountHistoryStatusPayRegulatorCancelled = "PAY_REGULATOR_CANCELLED";
const accountHistoryStatusPayRegulatorRollback = "PAY_REGULATOR_ROLLBACK";
const accountHistoryStatusLeonshopOrderCreated = "LEONSHOP_ORDER_CREATED";
const accountHistoryStatusLeonshopOrderInProgress = "LEONSHOP_ORDER_IN_PROGRESS";
const accountHistoryStatusLeonshopOrderDone = "LEONSHOP_ORDER_DONE";
const accountHistoryStatusLeonshopOrderCancelled = "LEONSHOP_ORDER_CANCELLED";
var AccountHistoryType = /* @__PURE__ */ ((AccountHistoryType2) => {
  AccountHistoryType2["ENCASH"] = "ENCASH";
  AccountHistoryType2["LEONSHOP"] = "LEONSHOP";
  AccountHistoryType2["CORRECTION"] = "CORRECTION";
  AccountHistoryType2["RESET"] = "RESET";
  AccountHistoryType2["LOYALTY_PROGRAM"] = "LOYALTY_PROGRAM";
  AccountHistoryType2["CREDIT"] = "CREDIT";
  AccountHistoryType2["DEBIT"] = "DEBIT";
  return AccountHistoryType2;
})(AccountHistoryType || {});
const accountHistoryTypeEncash = "ENCASH";
const accountHistoryTypeLeonshop = "LEONSHOP";
const accountHistoryTypeCorrection = "CORRECTION";
const accountHistoryTypeReset = "RESET";
const accountHistoryTypeLoyaltyProgram = "LOYALTY_PROGRAM";
const accountHistoryTypeCredit = "CREDIT";
const accountHistoryTypeDebit = "DEBIT";
const accountTypeMain = "MAIN";
const accountTypeCasino = "CASINO";
const accountTypeBetting = "BETTING";
const accountTypeLocked = "LOCKED";
const achievementRewardStatusNotAvailable = "NOT_AVAILABLE";
const achievementRewardStatusAvailable = "AVAILABLE";
const achievementRewardStatusRequested = "REQUESTED";
const achievementRewardStatusReceived = "RECEIVED";
const achievementRewardStatusRejected = "REJECTED";
const achievementRewardStatusExpired = "EXPIRED";
const achievementRewardTypeFreespin = "FREESPIN";
const achievementRewardTypeBonus = "BONUS";
const achievementRewardTypeAvatar = "AVATAR";
var AffiliatePartnerStatus = /* @__PURE__ */ ((AffiliatePartnerStatus2) => {
  AffiliatePartnerStatus2["YES"] = "YES";
  AffiliatePartnerStatus2["NO"] = "NO";
  AffiliatePartnerStatus2["CLOSED"] = "CLOSED";
  AffiliatePartnerStatus2["DISABLED"] = "DISABLED";
  return AffiliatePartnerStatus2;
})(AffiliatePartnerStatus || {});
const affiliatePartnerStatusYes = "YES";
const affiliatePartnerStatusNo = "NO";
const affiliatePartnerStatusClosed = "CLOSED";
const affiliatePartnerStatusDisabled = "DISABLED";
var AllAccountHistoryOutcome = /* @__PURE__ */ ((AllAccountHistoryOutcome2) => {
  AllAccountHistoryOutcome2["WIN"] = "WIN";
  AllAccountHistoryOutcome2["WIN_HALF"] = "WIN_HALF";
  AllAccountHistoryOutcome2["LOSE_HALF"] = "LOSE_HALF";
  AllAccountHistoryOutcome2["LOSE"] = "LOSE";
  AllAccountHistoryOutcome2["RETURN"] = "RETURN";
  AllAccountHistoryOutcome2["UNDEFINED"] = "UNDEFINED";
  return AllAccountHistoryOutcome2;
})(AllAccountHistoryOutcome || {});
const allAccountHistoryOutcomeWin = "WIN";
const allAccountHistoryOutcomeWinHalf = "WIN_HALF";
const allAccountHistoryOutcomeLoseHalf = "LOSE_HALF";
const allAccountHistoryOutcomeLose = "LOSE";
const allAccountHistoryOutcomeReturn = "RETURN";
const allAccountHistoryOutcomeUndefined = "UNDEFINED";
var AllTransactionHistoryTransactionType = /* @__PURE__ */ ((AllTransactionHistoryTransactionType2) => {
  AllTransactionHistoryTransactionType2["BET"] = "BET";
  AllTransactionHistoryTransactionType2["WIN"] = "WIN";
  AllTransactionHistoryTransactionType2["LOSE"] = "LOSE";
  AllTransactionHistoryTransactionType2["ROLLBACK_WIN"] = "ROLLBACK_WIN";
  AllTransactionHistoryTransactionType2["ROLLBACK_BET"] = "ROLLBACK_BET";
  return AllTransactionHistoryTransactionType2;
})(AllTransactionHistoryTransactionType || {});
const allTransactionHistoryTransactionTypeBet = "BET";
const allTransactionHistoryTransactionTypeWin = "WIN";
const allTransactionHistoryTransactionTypeLose = "LOSE";
const allTransactionHistoryTransactionTypeRollbackWin = "ROLLBACK_WIN";
const allTransactionHistoryTransactionTypeRollbackBet = "ROLLBACK_BET";
var AppflowUpdateMethod = /* @__PURE__ */ ((AppflowUpdateMethod2) => {
  AppflowUpdateMethod2["NONE"] = "NONE";
  AppflowUpdateMethod2["AUTO"] = "AUTO";
  AppflowUpdateMethod2["BACKGROUND"] = "BACKGROUND";
  return AppflowUpdateMethod2;
})(AppflowUpdateMethod || {});
const appflowUpdateMethodNone = "NONE";
const appflowUpdateMethodAuto = "AUTO";
const appflowUpdateMethodBackground = "BACKGROUND";
var AvailableBonusType = /* @__PURE__ */ ((AvailableBonusType2) => {
  AvailableBonusType2["BONUS_AMOUNT"] = "BONUS_AMOUNT";
  AvailableBonusType2["MAX_BONUS_AMOUNT"] = "MAX_BONUS_AMOUNT";
  AvailableBonusType2["FREEBET"] = "FREEBET";
  AvailableBonusType2["FREESPIN"] = "FREESPIN";
  return AvailableBonusType2;
})(AvailableBonusType || {});
const availableBonusTypeBonusAmount = "BONUS_AMOUNT";
const availableBonusTypeMaxBonusAmount = "MAX_BONUS_AMOUNT";
const availableBonusTypeFreebet = "FREEBET";
const availableBonusTypeFreespin = "FREESPIN";
const avatarStatusActive = "ACTIVE";
const avatarStatusAvailable = "AVAILABLE";
const avatarStatusLocked = "LOCKED";
var BannerAnimation = /* @__PURE__ */ ((BannerAnimation2) => {
  BannerAnimation2["MOVE_HORIZONTALLY"] = "MOVE_HORIZONTALLY";
  return BannerAnimation2;
})(BannerAnimation || {});
const bannerAnimationMoveHorizontally = "MOVE_HORIZONTALLY";
var BannerType = /* @__PURE__ */ ((BannerType2) => {
  BannerType2["PROMO"] = "PROMO";
  BannerType2["SPORT"] = "SPORT";
  BannerType2["JACKPOT"] = "JACKPOT";
  return BannerType2;
})(BannerType || {});
const bannerTypePromo = "PROMO";
const bannerTypeSport = "SPORT";
const bannerTypeJackpot = "JACKPOT";
const bannerVersionV1 = "V1";
const bannerVersionV2 = "V2";
const bannerVersionV3 = "V3";
var BetLine = /* @__PURE__ */ ((BetLine2) => {
  BetLine2["PREMATCH"] = "PREMATCH";
  BetLine2["LIVE"] = "LIVE";
  BetLine2["VSPORTS"] = "VSPORTS";
  BetLine2["OUTRIGHT"] = "OUTRIGHT";
  return BetLine2;
})(BetLine || {});
const betLinePrematch = "PREMATCH";
const betLineLive = "LIVE";
const betLineVsports = "VSPORTS";
const betLineOutright = "OUTRIGHT";
var BetType = /* @__PURE__ */ ((BetType2) => {
  BetType2["SINGLE"] = "SINGLE";
  BetType2["EXPRESS"] = "EXPRESS";
  BetType2["SYSTEM"] = "SYSTEM";
  return BetType2;
})(BetType || {});
const betTypeSingle = "SINGLE";
const betTypeExpress = "EXPRESS";
const betTypeSystem = "SYSTEM";
const bonusAccountHistoryDataCategoryEgs = "EGS";
const bonusAccountHistoryDataCategorySport = "SPORT";
const bonusAccountHistoryDataCategoryBonusPoints = "BONUS_POINTS";
const bonusAccountHistoryDataTypeEgsProviderReward = "EGS_PROVIDER_REWARD";
const bonusAccountHistoryDataTypeEgsBonusToMainWalletActivated = "EGS_BONUS_TO_MAIN_WALLET_ACTIVATED";
const bonusAccountHistoryDataTypeEgsBonusTransferredToBonusWallet = "EGS_BONUS_TRANSFERRED_TO_BONUS_WALLET";
const bonusAccountHistoryDataTypeEgsBonusFailed = "EGS_BONUS_FAILED";
const bonusAccountHistoryDataTypeEgsFreespin = "EGS_FREESPIN";
const bonusAccountHistoryDataTypeEgsFreespinResultTransferredToBonusWallet = "EGS_FREESPIN_RESULT_TRANSFERRED_TO_BONUS_WALLET";
const bonusAccountHistoryDataTypeEgsBonusResultTransferredToMainAccount = "EGS_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT";
const bonusAccountHistoryDataTypeSportBonusTransferredToBonusWallet = "SPORT_BONUS_TRANSFERRED_TO_BONUS_WALLET";
const bonusAccountHistoryDataTypeSportBonusFailed = "SPORT_BONUS_FAILED";
const bonusAccountHistoryDataTypeSportBonusResultTransferredToMainAccount = "SPORT_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT";
const bonusAccountHistoryDataTypeSportFreebet = "SPORT_FREEBET";
const bonusAccountHistoryDataTypeSportFreebetResultTransferredToBonusWallet = "SPORT_FREEBET_RESULT_TRANSFERRED_TO_BONUS_WALLET";
const bonusAccountHistoryDataTypeLoyaltyBonusWithdrawal = "LOYALTY_BONUS_WITHDRAWAL";
const bonusAccountHistoryDataTypeLoyaltyBonusCorrection = "LOYALTY_BONUS_CORRECTION";
const bonusAccountHistoryDataTypeLoyaltyBonusDeposit = "LOYALTY_BONUS_DEPOSIT";
const bonusAccountHistoryStatusActivated = "ACTIVATED";
const bonusAccountHistoryStatusCompleted = "COMPLETED";
var BonusCampaignType = /* @__PURE__ */ ((BonusCampaignType2) => {
  BonusCampaignType2["SPORT"] = "SPORT";
  BonusCampaignType2["EGS"] = "EGS";
  return BonusCampaignType2;
})(BonusCampaignType || {});
const bonusCampaignTypeSport = "SPORT";
const bonusCampaignTypeEgs = "EGS";
var BonusCategory = /* @__PURE__ */ ((BonusCategory2) => {
  BonusCategory2["CBC"] = "CBC";
  BonusCategory2["SBC"] = "SBC";
  BonusCategory2["FIRST_DEPOSIT"] = "FIRST_DEPOSIT";
  BonusCategory2["ACCUMULATIVE"] = "ACCUMULATIVE";
  BonusCategory2["UNKNOWN"] = "UNKNOWN";
  return BonusCategory2;
})(BonusCategory || {});
const bonusCategoryCbc = "CBC";
const bonusCategorySbc = "SBC";
const bonusCategoryFirstDeposit = "FIRST_DEPOSIT";
const bonusCategoryAccumulative = "ACCUMULATIVE";
const bonusCategoryUnknown = "UNKNOWN";
var BonusCodeType = /* @__PURE__ */ ((BonusCodeType2) => {
  BonusCodeType2["CAMPAIGN"] = "CAMPAIGN";
  BonusCodeType2["DEPOSIT"] = "DEPOSIT";
  BonusCodeType2["REGISTRATION"] = "REGISTRATION";
  return BonusCodeType2;
})(BonusCodeType || {});
const bonusCodeTypeCampaign = "CAMPAIGN";
const bonusCodeTypeDeposit = "DEPOSIT";
const bonusCodeTypeRegistration = "REGISTRATION";
var BonusesDataType = /* @__PURE__ */ ((BonusesDataType2) => {
  BonusesDataType2["LOYALTY_BONUS"] = "LOYALTY_BONUS";
  BonusesDataType2["EGS_PROVIDER_REWARD"] = "EGS_PROVIDER_REWARD";
  BonusesDataType2["EGS_BONUS_TO_MAIN_WALLET_ACTIVATED"] = "EGS_BONUS_TO_MAIN_WALLET_ACTIVATED";
  BonusesDataType2["EGS_BONUS_TRANSFERRED_TO_BONUS_WALLET"] = "EGS_BONUS_TRANSFERRED_TO_BONUS_WALLET";
  BonusesDataType2["EGS_BONUS_FAILED"] = "EGS_BONUS_FAILED";
  BonusesDataType2["EGS_FREESPIN"] = "EGS_FREESPIN";
  BonusesDataType2["EGS_FREESPIN_RESULT_TRANSFERRED_TO_BONUS_WALLET"] = "EGS_FREESPIN_RESULT_TRANSFERRED_TO_BONUS_WALLET";
  BonusesDataType2["EGS_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT"] = "EGS_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT";
  BonusesDataType2["SPORT_BONUS_TRANSFERRED_TO_BONUS_WALLET"] = "SPORT_BONUS_TRANSFERRED_TO_BONUS_WALLET";
  BonusesDataType2["SPORT_BONUS_FAILED"] = "SPORT_BONUS_FAILED";
  BonusesDataType2["SPORT_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT"] = "SPORT_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT";
  BonusesDataType2["SPORT_FREEBET"] = "SPORT_FREEBET";
  return BonusesDataType2;
})(BonusesDataType || {});
const bonusesDataTypeLoyaltyBonus = "LOYALTY_BONUS";
const bonusesDataTypeEgsProviderReward = "EGS_PROVIDER_REWARD";
const bonusesDataTypeEgsBonusToMainWalletActivated = "EGS_BONUS_TO_MAIN_WALLET_ACTIVATED";
const bonusesDataTypeEgsBonusTransferredToBonusWallet = "EGS_BONUS_TRANSFERRED_TO_BONUS_WALLET";
const bonusesDataTypeEgsBonusFailed = "EGS_BONUS_FAILED";
const bonusesDataTypeEgsFreespin = "EGS_FREESPIN";
const bonusesDataTypeEgsFreespinResultTransferredToBonusWallet = "EGS_FREESPIN_RESULT_TRANSFERRED_TO_BONUS_WALLET";
const bonusesDataTypeEgsBonusResultTransferredToMainAccount = "EGS_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT";
const bonusesDataTypeSportBonusTransferredToBonusWallet = "SPORT_BONUS_TRANSFERRED_TO_BONUS_WALLET";
const bonusesDataTypeSportBonusFailed = "SPORT_BONUS_FAILED";
const bonusesDataTypeSportBonusResultTransferredToMainAccount = "SPORT_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT";
const bonusesDataTypeSportFreebet = "SPORT_FREEBET";
var BonusGameRewardType = /* @__PURE__ */ ((BonusGameRewardType2) => {
  BonusGameRewardType2["FREEBET"] = "FREEBET";
  BonusGameRewardType2["FREESPIN"] = "FREESPIN";
  BonusGameRewardType2["BONUS"] = "BONUS";
  BonusGameRewardType2["MULTIPLIER"] = "MULTIPLIER";
  BonusGameRewardType2["SUPER_PRIZE"] = "SUPER_PRIZE";
  BonusGameRewardType2["EMPTY"] = "EMPTY";
  return BonusGameRewardType2;
})(BonusGameRewardType || {});
const bonusGameRewardTypeFreebet = "FREEBET";
const bonusGameRewardTypeFreespin = "FREESPIN";
const bonusGameRewardTypeBonus = "BONUS";
const bonusGameRewardTypeMultiplier = "MULTIPLIER";
const bonusGameRewardTypeSuperPrize = "SUPER_PRIZE";
const bonusGameRewardTypeEmpty = "EMPTY";
var BonusState = /* @__PURE__ */ ((BonusState2) => {
  BonusState2["ACTIVE"] = "ACTIVE";
  BonusState2["SUCCESSFUL"] = "SUCCESSFUL";
  BonusState2["SUCCESSFUL_WITH_ACTIVE_BONUS"] = "SUCCESSFUL_WITH_ACTIVE_BONUS";
  BonusState2["CANCELLED"] = "CANCELLED";
  BonusState2["EMPTY"] = "EMPTY";
  return BonusState2;
})(BonusState || {});
const bonusStateActive = "ACTIVE";
const bonusStateSuccessful = "SUCCESSFUL";
const bonusStateSuccessfulWithActiveBonus = "SUCCESSFUL_WITH_ACTIVE_BONUS";
const bonusStateCancelled = "CANCELLED";
const bonusStateEmpty = "EMPTY";
var BonusStep = /* @__PURE__ */ ((BonusStep2) => {
  BonusStep2["REQUIREMENTS"] = "REQUIREMENTS";
  BonusStep2["WAGER"] = "WAGER";
  BonusStep2["FREEBET"] = "FREEBET";
  BonusStep2["FREESPIN"] = "FREESPIN";
  BonusStep2["TRANSFER"] = "TRANSFER";
  return BonusStep2;
})(BonusStep || {});
const bonusStepRequirements = "REQUIREMENTS";
const bonusStepWager = "WAGER";
const bonusStepFreebet = "FREEBET";
const bonusStepFreespin = "FREESPIN";
const bonusStepTransfer = "TRANSFER";
const buttonActionClaimCashback = "CLAIM_CASHBACK";
const buttonActionClose = "CLOSE";
const cAHEgsGameTypeSlots = "SLOTS";
const cAHEgsGameTypeLive = "LIVE";
const cAHEgsGameTypeBetgames = "BETGAMES";
const cAHEgsGameTypeFastgames = "FASTGAMES";
const cAHEgsGameTypeAll = "ALL";
var CampaignState = /* @__PURE__ */ ((CampaignState2) => {
  CampaignState2["ACTIVE"] = "ACTIVE";
  CampaignState2["AVAILABLE"] = "AVAILABLE";
  CampaignState2["CLOSED"] = "CLOSED";
  return CampaignState2;
})(CampaignState || {});
const campaignStateActive = "ACTIVE";
const campaignStateAvailable = "AVAILABLE";
const campaignStateClosed = "CLOSED";
var CaptchaMode = /* @__PURE__ */ ((CaptchaMode2) => {
  CaptchaMode2["ENFORCED"] = "ENFORCED";
  CaptchaMode2["ON"] = "ON";
  CaptchaMode2["OFF"] = "OFF";
  return CaptchaMode2;
})(CaptchaMode || {});
const captchaModeEnforced = "ENFORCED";
const captchaModeOn = "ON";
const captchaModeOff = "OFF";
var CaptchaStatus = /* @__PURE__ */ ((CaptchaStatus2) => {
  CaptchaStatus2["ENFORCED"] = "ENFORCED";
  CaptchaStatus2["ON"] = "ON";
  CaptchaStatus2["OFF"] = "OFF";
  return CaptchaStatus2;
})(CaptchaStatus || {});
const captchaStatusEnforced = "ENFORCED";
const captchaStatusOn = "ON";
const captchaStatusOff = "OFF";
var CaptchaType = /* @__PURE__ */ ((CaptchaType2) => {
  CaptchaType2["DISTORTED_NUMBERS"] = "DISTORTED_NUMBERS";
  CaptchaType2["RECAPTCHA"] = "RECAPTCHA";
  CaptchaType2["INVISIBLE_RECAPTCHA"] = "INVISIBLE_RECAPTCHA";
  CaptchaType2["ANDROID_RECAPTCHA"] = "ANDROID_RECAPTCHA";
  CaptchaType2["RECAPTCHA_V3"] = "RECAPTCHA_V3";
  return CaptchaType2;
})(CaptchaType || {});
const captchaTypeDistortedNumbers = "DISTORTED_NUMBERS";
const captchaTypeRecaptcha = "RECAPTCHA";
const captchaTypeInvisibleRecaptcha = "INVISIBLE_RECAPTCHA";
const captchaTypeAndroidRecaptcha = "ANDROID_RECAPTCHA";
const captchaTypeRecaptchaV3 = "RECAPTCHA_V3";
var CashbackConfirmationState = /* @__PURE__ */ ((CashbackConfirmationState2) => {
  CashbackConfirmationState2["CONFIRMED"] = "CONFIRMED";
  CashbackConfirmationState2["DECLINED"] = "DECLINED";
  CashbackConfirmationState2["NOT_CONFIRMED"] = "NOT_CONFIRMED";
  CashbackConfirmationState2["REFUSED"] = "REFUSED";
  return CashbackConfirmationState2;
})(CashbackConfirmationState || {});
const cashbackConfirmationStateConfirmed = "CONFIRMED";
const cashbackConfirmationStateDeclined = "DECLINED";
const cashbackConfirmationStateNotConfirmed = "NOT_CONFIRMED";
const cashbackConfirmationStateRefused = "REFUSED";
var CashOutReason = /* @__PURE__ */ ((CashOutReason2) => {
  CashOutReason2["DONE"] = "DONE";
  CashOutReason2["WAITING"] = "WAITING";
  CashOutReason2["VALIDATION_ERROR"] = "VALIDATION_ERROR";
  CashOutReason2["BET_ALREADY_CALCULATED"] = "BET_ALREADY_CALCULATED";
  CashOutReason2["AMOUNT_CHANGED"] = "AMOUNT_CHANGED";
  CashOutReason2["DISABLED"] = "DISABLED";
  CashOutReason2["BET_MISSING"] = "BET_MISSING";
  CashOutReason2["CUSTOMER_NOT_IDENTIFIED"] = "CUSTOMER_NOT_IDENTIFIED";
  CashOutReason2["SESSION_ERROR"] = "SESSION_ERROR";
  return CashOutReason2;
})(CashOutReason || {});
const cashOutReasonDone = "DONE";
const cashOutReasonWaiting = "WAITING";
const cashOutReasonValidationError = "VALIDATION_ERROR";
const cashOutReasonBetAlreadyCalculated = "BET_ALREADY_CALCULATED";
const cashOutReasonAmountChanged = "AMOUNT_CHANGED";
const cashOutReasonDisabled = "DISABLED";
const cashOutReasonBetMissing = "BET_MISSING";
const cashOutReasonCustomerNotIdentified = "CUSTOMER_NOT_IDENTIFIED";
const cashOutReasonSessionError = "SESSION_ERROR";
var CashOutStatus = /* @__PURE__ */ ((CashOutStatus2) => {
  CashOutStatus2["OK"] = "OK";
  CashOutStatus2["ERROR"] = "ERROR";
  return CashOutStatus2;
})(CashOutStatus || {});
const cashOutStatusOk = "OK";
const cashOutStatusError = "ERROR";
var CategoryType = /* @__PURE__ */ ((CategoryType2) => {
  CategoryType2["ALL"] = "ALL";
  CategoryType2["ARCHIVED"] = "ARCHIVED";
  CategoryType2["CUSTOM"] = "CUSTOM";
  CategoryType2["CUSTOMER"] = "CUSTOMER";
  CategoryType2["BONUS_WALLET"] = "BONUS_WALLET";
  CategoryType2["CUSTOMER_ARCHIVED"] = "CUSTOMER_ARCHIVED";
  CategoryType2["COMPLEX"] = "COMPLEX";
  return CategoryType2;
})(CategoryType || {});
const categoryTypeAll = "ALL";
const categoryTypeArchived = "ARCHIVED";
const categoryTypeCustom = "CUSTOM";
const categoryTypeCustomer = "CUSTOMER";
const categoryTypeBonusWallet = "BONUS_WALLET";
const categoryTypeCustomerArchived = "CUSTOMER_ARCHIVED";
const categoryTypeComplex = "COMPLEX";
var ContentPlace = /* @__PURE__ */ ((ContentPlace2) => {
  ContentPlace2["BETLINE_EVENT"] = "BETLINE_EVENT";
  ContentPlace2["BETLINE_LEAGUE"] = "BETLINE_LEAGUE";
  ContentPlace2["BETLINE_REGION"] = "BETLINE_REGION";
  ContentPlace2["BETLINE_SPORT"] = "BETLINE_SPORT";
  ContentPlace2["VIRTUAL_SPORT"] = "VIRTUAL_SPORT";
  ContentPlace2["EGS_LOBBY"] = "EGS_LOBBY";
  ContentPlace2["EGS_GAME"] = "EGS_GAME";
  ContentPlace2["PROMOACTION"] = "PROMOACTION";
  ContentPlace2["UNKNOWN"] = "UNKNOWN";
  return ContentPlace2;
})(ContentPlace || {});
const contentPlaceBetlineEvent = "BETLINE_EVENT";
const contentPlaceBetlineLeague = "BETLINE_LEAGUE";
const contentPlaceBetlineRegion = "BETLINE_REGION";
const contentPlaceBetlineSport = "BETLINE_SPORT";
const contentPlaceVirtualSport = "VIRTUAL_SPORT";
const contentPlaceEgsLobby = "EGS_LOBBY";
const contentPlaceEgsGame = "EGS_GAME";
const contentPlacePromoaction = "PROMOACTION";
const contentPlaceUnknown = "UNKNOWN";
var CouponType = /* @__PURE__ */ ((CouponType2) => {
  CouponType2["BONUS_PACK"] = "BONUS_PACK";
  CouponType2["SPORT_BET"] = "SPORT_BET";
  return CouponType2;
})(CouponType || {});
const couponTypeBonusPack = "BONUS_PACK";
const couponTypeSportBet = "SPORT_BET";
var CustomerBetType = /* @__PURE__ */ ((CustomerBetType2) => {
  CustomerBetType2["UNDEFINED"] = "UNDEFINED";
  CustomerBetType2["SPORT"] = "SPORT";
  CustomerBetType2["EGS"] = "EGS";
  return CustomerBetType2;
})(CustomerBetType || {});
const customerBetTypeUndefined = "UNDEFINED";
const customerBetTypeSport = "SPORT";
const customerBetTypeEgs = "EGS";
var CustomerBonusState = /* @__PURE__ */ ((CustomerBonusState2) => {
  CustomerBonusState2["AVAILABLE"] = "AVAILABLE";
  CustomerBonusState2["ACTIVE"] = "ACTIVE";
  return CustomerBonusState2;
})(CustomerBonusState || {});
const customerBonusStateAvailable = "AVAILABLE";
const customerBonusStateActive = "ACTIVE";
var CustomerConfig = /* @__PURE__ */ ((CustomerConfig2) => {
  CustomerConfig2["HIDE_COMMISSION_HINT"] = "HIDE_COMMISSION_HINT";
  CustomerConfig2["HIDE_ON_BOARDING_PAGE"] = "HIDE_ON_BOARDING_PAGE";
  CustomerConfig2["HIDE_BALANCE"] = "HIDE_BALANCE";
  CustomerConfig2["HIDE_NICKNAME_PROMPT"] = "HIDE_NICKNAME_PROMPT";
  CustomerConfig2["HIDE_EGS_LOYALTY_ON_BOARDING_PAGE"] = "HIDE_EGS_LOYALTY_ON_BOARDING_PAGE";
  CustomerConfig2["HIDE_ON_BOARDING_ON_LEON_SHOP_PAGE"] = "HIDE_ON_BOARDING_ON_LEON_SHOP_PAGE";
  CustomerConfig2["CREATE_PIN_CODE_MODAL_COUNTER"] = "CREATE_PIN_CODE_MODAL_COUNTER";
  CustomerConfig2["IS_PIN_CODE_REQUESTED_ON_LOGIN"] = "IS_PIN_CODE_REQUESTED_ON_LOGIN";
  CustomerConfig2["BONUS_CHOOSER_SHOWN_BEFORE_DEPOSITS"] = "BONUS_CHOOSER_SHOWN_BEFORE_DEPOSITS";
  CustomerConfig2["HIDE_NO_SELECTED_BONUS_MODAL"] = "HIDE_NO_SELECTED_BONUS_MODAL";
  CustomerConfig2["HIDE_LIMIT_MODAL_ON_GAME_PAGE"] = "HIDE_LIMIT_MODAL_ON_GAME_PAGE";
  CustomerConfig2["IS_VIDEO_VERIFICATION_ONBOARDING_SHOWN"] = "IS_VIDEO_VERIFICATION_ONBOARDING_SHOWN";
  CustomerConfig2["IS_FORTUNE_WHEEL_WINDOW_SHOWN"] = "IS_FORTUNE_WHEEL_WINDOW_SHOWN";
  CustomerConfig2["IS_ASO_MAIN_ONBOARDING_SHOWN"] = "IS_ASO_MAIN_ONBOARDING_SHOWN";
  CustomerConfig2["IS_RESPONSIBLE_GAMBLING_ONBOARDING_SHOWN"] = "IS_RESPONSIBLE_GAMBLING_ONBOARDING_SHOWN";
  CustomerConfig2["IS_ODDS_TYPE_CLASSIC"] = "IS_ODDS_TYPE_CLASSIC";
  CustomerConfig2["IS_ODDS_TYPE_AMERICAN"] = "IS_ODDS_TYPE_AMERICAN";
  CustomerConfig2["IS_REFERRAL_PROGRAM_ONBOARDING_SHOWN"] = "IS_REFERRAL_PROGRAM_ONBOARDING_SHOWN";
  CustomerConfig2["IS_REFERRAL_PROGRAM_NOTIFICATION_CLICKED"] = "IS_REFERRAL_PROGRAM_NOTIFICATION_CLICKED";
  CustomerConfig2["IS_EKYC_POPUP_ON_FIRST_LOGIN_SHOWN"] = "IS_EKYC_POPUP_ON_FIRST_LOGIN_SHOWN";
  CustomerConfig2["IS_PRIVATE_PROFILE"] = "IS_PRIVATE_PROFILE";
  CustomerConfig2["IS_HIDDEN_NICKNAME"] = "IS_HIDDEN_NICKNAME";
  CustomerConfig2["IS_ACHIEVEMENTS_MAIN_ONBOARDING_SHOWN"] = "IS_ACHIEVEMENTS_MAIN_ONBOARDING_SHOWN";
  CustomerConfig2["IS_LOYALTY_PROGRAM_SHOWN"] = "IS_LOYALTY_PROGRAM_SHOWN";
  return CustomerConfig2;
})(CustomerConfig || {});
const customerConfigHideCommissionHint = "HIDE_COMMISSION_HINT";
const customerConfigHideOnBoardingPage = "HIDE_ON_BOARDING_PAGE";
const customerConfigHideBalance = "HIDE_BALANCE";
const customerConfigHideNicknamePrompt = "HIDE_NICKNAME_PROMPT";
const customerConfigHideEgsLoyaltyOnBoardingPage = "HIDE_EGS_LOYALTY_ON_BOARDING_PAGE";
const customerConfigHideOnBoardingOnLeonShopPage = "HIDE_ON_BOARDING_ON_LEON_SHOP_PAGE";
const customerConfigCreatePinCodeModalCounter = "CREATE_PIN_CODE_MODAL_COUNTER";
const customerConfigIsPinCodeRequestedOnLogin = "IS_PIN_CODE_REQUESTED_ON_LOGIN";
const customerConfigBonusChooserShownBeforeDeposits = "BONUS_CHOOSER_SHOWN_BEFORE_DEPOSITS";
const customerConfigHideNoSelectedBonusModal = "HIDE_NO_SELECTED_BONUS_MODAL";
const customerConfigHideLimitModalOnGamePage = "HIDE_LIMIT_MODAL_ON_GAME_PAGE";
const customerConfigIsVideoVerificationOnboardingShown = "IS_VIDEO_VERIFICATION_ONBOARDING_SHOWN";
const customerConfigIsFortuneWheelWindowShown = "IS_FORTUNE_WHEEL_WINDOW_SHOWN";
const customerConfigIsAsoMainOnboardingShown = "IS_ASO_MAIN_ONBOARDING_SHOWN";
const customerConfigIsResponsibleGamblingOnboardingShown = "IS_RESPONSIBLE_GAMBLING_ONBOARDING_SHOWN";
const customerConfigIsOddsTypeClassic = "IS_ODDS_TYPE_CLASSIC";
const customerConfigIsOddsTypeAmerican = "IS_ODDS_TYPE_AMERICAN";
const customerConfigIsReferralProgramOnboardingShown = "IS_REFERRAL_PROGRAM_ONBOARDING_SHOWN";
const customerConfigIsReferralProgramNotificationClicked = "IS_REFERRAL_PROGRAM_NOTIFICATION_CLICKED";
const customerConfigIsEkycPopupOnFirstLoginShown = "IS_EKYC_POPUP_ON_FIRST_LOGIN_SHOWN";
const customerConfigIsPrivateProfile = "IS_PRIVATE_PROFILE";
const customerConfigIsHiddenNickname = "IS_HIDDEN_NICKNAME";
const customerConfigIsAchievementsMainOnboardingShown = "IS_ACHIEVEMENTS_MAIN_ONBOARDING_SHOWN";
const customerConfigIsLoyaltyProgramShown = "IS_LOYALTY_PROGRAM_SHOWN";
var CustomerFieldType = /* @__PURE__ */ ((CustomerFieldType2) => {
  CustomerFieldType2["FIRST_NAME"] = "FIRST_NAME";
  CustomerFieldType2["LAST_NAME"] = "LAST_NAME";
  CustomerFieldType2["MIDDLE_NAME"] = "MIDDLE_NAME";
  CustomerFieldType2["CITY"] = "CITY";
  CustomerFieldType2["EMAIL"] = "EMAIL";
  CustomerFieldType2["OLD_PASSWORD"] = "OLD_PASSWORD";
  CustomerFieldType2["PASSWORD"] = "PASSWORD";
  CustomerFieldType2["BONUS_CODE"] = "BONUS_CODE";
  CustomerFieldType2["ADDRESS"] = "ADDRESS";
  CustomerFieldType2["DOCUMENT_TYPE_SELECT"] = "DOCUMENT_TYPE_SELECT";
  CustomerFieldType2["DOCUMENT_COUNTRY_SELECT"] = "DOCUMENT_COUNTRY_SELECT";
  CustomerFieldType2["COUNTRY_SELECT"] = "COUNTRY_SELECT";
  CustomerFieldType2["CURRENCY_SELECT"] = "CURRENCY_SELECT";
  CustomerFieldType2["TERMS_CHECKBOX"] = "TERMS_CHECKBOX";
  CustomerFieldType2["BIRTH_DATE"] = "BIRTH_DATE";
  CustomerFieldType2["DOCUMENT_ISSUE_DATE"] = "DOCUMENT_ISSUE_DATE";
  CustomerFieldType2["PHONE_INPUT"] = "PHONE_INPUT";
  CustomerFieldType2["REGISTRATION_CODE"] = "REGISTRATION_CODE";
  CustomerFieldType2["PERSONAL_NUMBER"] = "PERSONAL_NUMBER";
  CustomerFieldType2["DOCUMENT_NUMBER"] = "DOCUMENT_NUMBER";
  CustomerFieldType2["DOCUMENT_ISSUE_AGENCY"] = "DOCUMENT_ISSUE_AGENCY";
  CustomerFieldType2["CPF"] = "CPF";
  CustomerFieldType2["DAILY_DEPOSIT_LIMIT"] = "DAILY_DEPOSIT_LIMIT";
  CustomerFieldType2["PROMO_CODE"] = "PROMO_CODE";
  CustomerFieldType2["OPTIONAL_INSTANT"] = "OPTIONAL_INSTANT";
  CustomerFieldType2["NICKNAME"] = "NICKNAME";
  CustomerFieldType2["SUBSCRIBE"] = "SUBSCRIBE";
  CustomerFieldType2["ACCEPTED_OFFER"] = "ACCEPTED_OFFER";
  CustomerFieldType2["ACCEPTED_PDA"] = "ACCEPTED_PDA";
  return CustomerFieldType2;
})(CustomerFieldType || {});
const customerFieldTypeFirstName = "FIRST_NAME";
const customerFieldTypeLastName = "LAST_NAME";
const customerFieldTypeMiddleName = "MIDDLE_NAME";
const customerFieldTypeCity = "CITY";
const customerFieldTypeEmail = "EMAIL";
const customerFieldTypeOldPassword = "OLD_PASSWORD";
const customerFieldTypePassword = "PASSWORD";
const customerFieldTypeBonusCode = "BONUS_CODE";
const customerFieldTypeAddress = "ADDRESS";
const customerFieldTypeDocumentTypeSelect = "DOCUMENT_TYPE_SELECT";
const customerFieldTypeDocumentCountrySelect = "DOCUMENT_COUNTRY_SELECT";
const customerFieldTypeCountrySelect = "COUNTRY_SELECT";
const customerFieldTypeCurrencySelect = "CURRENCY_SELECT";
const customerFieldTypeTermsCheckbox = "TERMS_CHECKBOX";
const customerFieldTypeBirthDate = "BIRTH_DATE";
const customerFieldTypeDocumentIssueDate = "DOCUMENT_ISSUE_DATE";
const customerFieldTypePhoneInput = "PHONE_INPUT";
const customerFieldTypeRegistrationCode = "REGISTRATION_CODE";
const customerFieldTypePersonalNumber = "PERSONAL_NUMBER";
const customerFieldTypeDocumentNumber = "DOCUMENT_NUMBER";
const customerFieldTypeDocumentIssueAgency = "DOCUMENT_ISSUE_AGENCY";
const customerFieldTypeCpf = "CPF";
const customerFieldTypeDailyDepositLimit = "DAILY_DEPOSIT_LIMIT";
const customerFieldTypePromoCode = "PROMO_CODE";
const customerFieldTypeOptionalInstant = "OPTIONAL_INSTANT";
const customerFieldTypeNickname = "NICKNAME";
const customerFieldTypeSubscribe = "SUBSCRIBE";
const customerFieldTypeAcceptedOffer = "ACCEPTED_OFFER";
const customerFieldTypeAcceptedPda = "ACCEPTED_PDA";
var CustomerHistoryFilterType = /* @__PURE__ */ ((CustomerHistoryFilterType2) => {
  CustomerHistoryFilterType2["HOUR"] = "HOUR";
  CustomerHistoryFilterType2["DAY"] = "DAY";
  CustomerHistoryFilterType2["MONTH"] = "MONTH";
  return CustomerHistoryFilterType2;
})(CustomerHistoryFilterType || {});
const customerHistoryFilterTypeHour = "HOUR";
const customerHistoryFilterTypeDay = "DAY";
const customerHistoryFilterTypeMonth = "MONTH";
var CustomerIdentificationStage = /* @__PURE__ */ ((CustomerIdentificationStage2) => {
  CustomerIdentificationStage2["PHONE_NOT_VERIFIED"] = "PHONE_NOT_VERIFIED";
  CustomerIdentificationStage2["FORM_NOT_INITIALIZED"] = "FORM_NOT_INITIALIZED";
  CustomerIdentificationStage2["FORM_NOT_SUBMITTED"] = "FORM_NOT_SUBMITTED";
  CustomerIdentificationStage2["FORM_IS_SUBMITTED"] = "FORM_IS_SUBMITTED";
  CustomerIdentificationStage2["ALREADY_IDENTIFIED"] = "ALREADY_IDENTIFIED";
  CustomerIdentificationStage2["EXISTING_IDENT_BINDING_PENDING"] = "EXISTING_IDENT_BINDING_PENDING";
  return CustomerIdentificationStage2;
})(CustomerIdentificationStage || {});
const customerIdentificationStagePhoneNotVerified = "PHONE_NOT_VERIFIED";
const customerIdentificationStageFormNotInitialized = "FORM_NOT_INITIALIZED";
const customerIdentificationStageFormNotSubmitted = "FORM_NOT_SUBMITTED";
const customerIdentificationStageFormIsSubmitted = "FORM_IS_SUBMITTED";
const customerIdentificationStageAlreadyIdentified = "ALREADY_IDENTIFIED";
const customerIdentificationStageExistingIdentBindingPending = "EXISTING_IDENT_BINDING_PENDING";
var CustomerIdentificationStatus = /* @__PURE__ */ ((CustomerIdentificationStatus2) => {
  CustomerIdentificationStatus2["NEW"] = "NEW";
  CustomerIdentificationStatus2["DOCUMENTS_REQUESTED"] = "DOCUMENTS_REQUESTED";
  CustomerIdentificationStatus2["WAITING_CALL"] = "WAITING_CALL";
  CustomerIdentificationStatus2["SUSPENDED"] = "SUSPENDED";
  CustomerIdentificationStatus2["REJECTED_BY_SUPPORT"] = "REJECTED_BY_SUPPORT";
  CustomerIdentificationStatus2["APPROVED_BY_SUPPORT"] = "APPROVED_BY_SUPPORT";
  CustomerIdentificationStatus2["REJECTED_BY_TSUPIS"] = "REJECTED_BY_TSUPIS";
  CustomerIdentificationStatus2["APPROVED_BY_TSUPIS"] = "APPROVED_BY_TSUPIS";
  CustomerIdentificationStatus2["CANNOT_GAMBLE"] = "CANNOT_GAMBLE";
  CustomerIdentificationStatus2["CAN_GAMBLE"] = "CAN_GAMBLE";
  CustomerIdentificationStatus2["COMPLETE"] = "COMPLETE";
  return CustomerIdentificationStatus2;
})(CustomerIdentificationStatus || {});
const customerIdentificationStatusNew = "NEW";
const customerIdentificationStatusDocumentsRequested = "DOCUMENTS_REQUESTED";
const customerIdentificationStatusWaitingCall = "WAITING_CALL";
const customerIdentificationStatusSuspended = "SUSPENDED";
const customerIdentificationStatusRejectedBySupport = "REJECTED_BY_SUPPORT";
const customerIdentificationStatusApprovedBySupport = "APPROVED_BY_SUPPORT";
const customerIdentificationStatusRejectedByTsupis = "REJECTED_BY_TSUPIS";
const customerIdentificationStatusApprovedByTsupis = "APPROVED_BY_TSUPIS";
const customerIdentificationStatusCannotGamble = "CANNOT_GAMBLE";
const customerIdentificationStatusCanGamble = "CAN_GAMBLE";
const customerIdentificationStatusComplete = "COMPLETE";
const customerLevelBenefitValueTypePercent = "PERCENT";
const customerLevelBenefitValueTypeBoolean = "BOOLEAN";
const customerLevelRewardStateNotAvailable = "NOT_AVAILABLE";
const customerLevelRewardStateAvailable = "AVAILABLE";
const customerLevelRewardStateReceived = "RECEIVED";
const customerLevelRewardTypeFreebet = "FREEBET";
const customerLevelRewardTypeCashback = "CASHBACK";
var CustomerLimitsExceptionCode = /* @__PURE__ */ ((CustomerLimitsExceptionCode2) => {
  CustomerLimitsExceptionCode2["CUSTOMER_LIMITS_ERROR"] = "CUSTOMER_LIMITS_ERROR";
  return CustomerLimitsExceptionCode2;
})(CustomerLimitsExceptionCode || {});
const customerLimitsExceptionCodeCustomerLimitsError = "CUSTOMER_LIMITS_ERROR";
var CustomerLimitsRange = /* @__PURE__ */ ((CustomerLimitsRange2) => {
  CustomerLimitsRange2["DAILY"] = "DAILY";
  CustomerLimitsRange2["WEEKLY"] = "WEEKLY";
  CustomerLimitsRange2["MONTHLY"] = "MONTHLY";
  return CustomerLimitsRange2;
})(CustomerLimitsRange || {});
const customerLimitsRangeDaily = "DAILY";
const customerLimitsRangeWeekly = "WEEKLY";
const customerLimitsRangeMonthly = "MONTHLY";
var CustomerRegistrationType = /* @__PURE__ */ ((CustomerRegistrationType2) => {
  CustomerRegistrationType2["FULL"] = "FULL";
  CustomerRegistrationType2["SIMPLE_EMAIL"] = "SIMPLE_EMAIL";
  CustomerRegistrationType2["SIMPLE_SOCIAL"] = "SIMPLE_SOCIAL";
  CustomerRegistrationType2["SIMPLE_PHONE"] = "SIMPLE_PHONE";
  CustomerRegistrationType2["SIMPLE_REG_API"] = "SIMPLE_REG_API";
  CustomerRegistrationType2["SIMPLE_REG_API_V2"] = "SIMPLE_REG_API_V2";
  CustomerRegistrationType2["FULL_SMS_CONFIRMATION"] = "FULL_SMS_CONFIRMATION";
  CustomerRegistrationType2["SIMPLE_NO_PHONE_CHECK"] = "SIMPLE_NO_PHONE_CHECK";
  CustomerRegistrationType2["FULL_NO_PHONE_CHECK"] = "FULL_NO_PHONE_CHECK";
  CustomerRegistrationType2["SLOTT_PHONE"] = "SLOTT_PHONE";
  CustomerRegistrationType2["SLOTT_NO_PHONE_CHECK"] = "SLOTT_NO_PHONE_CHECK";
  CustomerRegistrationType2["UNKNOWN"] = "UNKNOWN";
  return CustomerRegistrationType2;
})(CustomerRegistrationType || {});
const customerRegistrationTypeFull = "FULL";
const customerRegistrationTypeSimpleEmail = "SIMPLE_EMAIL";
const customerRegistrationTypeSimpleSocial = "SIMPLE_SOCIAL";
const customerRegistrationTypeSimplePhone = "SIMPLE_PHONE";
const customerRegistrationTypeSimpleRegApi = "SIMPLE_REG_API";
const customerRegistrationTypeSimpleRegApiV2 = "SIMPLE_REG_API_V2";
const customerRegistrationTypeFullSmsConfirmation = "FULL_SMS_CONFIRMATION";
const customerRegistrationTypeSimpleNoPhoneCheck = "SIMPLE_NO_PHONE_CHECK";
const customerRegistrationTypeFullNoPhoneCheck = "FULL_NO_PHONE_CHECK";
const customerRegistrationTypeSlottPhone = "SLOTT_PHONE";
const customerRegistrationTypeSlottNoPhoneCheck = "SLOTT_NO_PHONE_CHECK";
const customerRegistrationTypeUnknown = "UNKNOWN";
var CustomerVerificationStatus = /* @__PURE__ */ ((CustomerVerificationStatus2) => {
  CustomerVerificationStatus2["NONE"] = "NONE";
  CustomerVerificationStatus2["STARTED"] = "STARTED";
  CustomerVerificationStatus2["IN_PROCESS"] = "IN_PROCESS";
  CustomerVerificationStatus2["RETRY"] = "RETRY";
  CustomerVerificationStatus2["SUCCESS"] = "SUCCESS";
  CustomerVerificationStatus2["FAILED"] = "FAILED";
  return CustomerVerificationStatus2;
})(CustomerVerificationStatus || {});
const customerVerificationStatusNone = "NONE";
const customerVerificationStatusStarted = "STARTED";
const customerVerificationStatusInProcess = "IN_PROCESS";
const customerVerificationStatusRetry = "RETRY";
const customerVerificationStatusSuccess = "SUCCESS";
const customerVerificationStatusFailed = "FAILED";
var CustomerVerificationType = /* @__PURE__ */ ((CustomerVerificationType2) => {
  CustomerVerificationType2["INTERNAL"] = "INTERNAL";
  CustomerVerificationType2["CUSTOMER_VERIFICATION_SERVICE"] = "CUSTOMER_VERIFICATION_SERVICE";
  CustomerVerificationType2["VIDEO_VERIFICATION_SERVICE"] = "VIDEO_VERIFICATION_SERVICE";
  return CustomerVerificationType2;
})(CustomerVerificationType || {});
const customerVerificationTypeInternal = "INTERNAL";
const customerVerificationTypeCustomerVerificationService = "CUSTOMER_VERIFICATION_SERVICE";
const customerVerificationTypeVideoVerificationService = "VIDEO_VERIFICATION_SERVICE";
var DeleteCardExceptionCode = /* @__PURE__ */ ((DeleteCardExceptionCode2) => {
  DeleteCardExceptionCode2["DELETE_CARD"] = "DELETE_CARD";
  return DeleteCardExceptionCode2;
})(DeleteCardExceptionCode || {});
const deleteCardExceptionCodeDeleteCard = "DELETE_CARD";
var DepositOperationType = /* @__PURE__ */ ((DepositOperationType2) => {
  DepositOperationType2["DEPOSIT"] = "DEPOSIT";
  DepositOperationType2["DEPOSIT_CANCEL"] = "DEPOSIT_CANCEL";
  return DepositOperationType2;
})(DepositOperationType || {});
const depositOperationTypeDeposit = "DEPOSIT";
const depositOperationTypeDepositCancel = "DEPOSIT_CANCEL";
const depositStatusUnavailable = "UNAVAILABLE";
const depositStatusNeedsDeposit = "NEEDS_DEPOSIT";
const depositStatusDepositPending = "DEPOSIT_PENDING";
const depositStatusDepositSuccess = "DEPOSIT_SUCCESS";
var DisplayType = /* @__PURE__ */ ((DisplayType2) => {
  DisplayType2["PUSH"] = "PUSH";
  DisplayType2["SILENT"] = "SILENT";
  DisplayType2["SNACKBAR"] = "SNACKBAR";
  return DisplayType2;
})(DisplayType || {});
const displayTypePush = "PUSH";
const displayTypeSilent = "SILENT";
const displayTypeSnackbar = "SNACKBAR";
var DocumentType = /* @__PURE__ */ ((DocumentType2) => {
  DocumentType2["PASSPORT"] = "PASSPORT";
  DocumentType2["RESIDENCE_PERMIT"] = "RESIDENCE_PERMIT";
  DocumentType2["REFUGEE_CERTIFICATE"] = "REFUGEE_CERTIFICATE";
  DocumentType2["FOREIGNERS_PASSPORT"] = "FOREIGNERS_PASSPORT";
  return DocumentType2;
})(DocumentType || {});
const documentTypePassport = "PASSPORT";
const documentTypeResidencePermit = "RESIDENCE_PERMIT";
const documentTypeRefugeeCertificate = "REFUGEE_CERTIFICATE";
const documentTypeForeignersPassport = "FOREIGNERS_PASSPORT";
var EgsAvailabilityStatus = /* @__PURE__ */ ((EgsAvailabilityStatus2) => {
  EgsAvailabilityStatus2["AVAILABLE"] = "AVAILABLE";
  EgsAvailabilityStatus2["BET_BEHIND"] = "BET_BEHIND";
  EgsAvailabilityStatus2["NOT_AVAILABLE"] = "NOT_AVAILABLE";
  return EgsAvailabilityStatus2;
})(EgsAvailabilityStatus || {});
const egsAvailabilityStatusAvailable = "AVAILABLE";
const egsAvailabilityStatusBetBehind = "BET_BEHIND";
const egsAvailabilityStatusNotAvailable = "NOT_AVAILABLE";
var EgsBlockType = /* @__PURE__ */ ((EgsBlockType2) => {
  EgsBlockType2["GEO"] = "GEO";
  EgsBlockType2["UNKNOWN"] = "UNKNOWN";
  return EgsBlockType2;
})(EgsBlockType || {});
const egsBlockTypeGeo = "GEO";
const egsBlockTypeUnknown = "UNKNOWN";
var EgsGameType = /* @__PURE__ */ ((EgsGameType2) => {
  EgsGameType2["SLOTS"] = "SLOTS";
  EgsGameType2["LIVE"] = "LIVE";
  EgsGameType2["JACKPOTS"] = "JACKPOTS";
  EgsGameType2["FASTGAMES"] = "FASTGAMES";
  EgsGameType2["ALL"] = "ALL";
  return EgsGameType2;
})(EgsGameType || {});
const egsGameTypeSlots = "SLOTS";
const egsGameTypeLive = "LIVE";
const egsGameTypeJackpots = "JACKPOTS";
const egsGameTypeFastgames = "FASTGAMES";
const egsGameTypeAll = "ALL";
var EgsTopWinnersFilter = /* @__PURE__ */ ((EgsTopWinnersFilter2) => {
  EgsTopWinnersFilter2["DAY"] = "DAY";
  EgsTopWinnersFilter2["WEEK"] = "WEEK";
  EgsTopWinnersFilter2["MONTH"] = "MONTH";
  EgsTopWinnersFilter2["ALL_TIME"] = "ALL_TIME";
  return EgsTopWinnersFilter2;
})(EgsTopWinnersFilter || {});
const egsTopWinnersFilterDay = "DAY";
const egsTopWinnersFilterWeek = "WEEK";
const egsTopWinnersFilterMonth = "MONTH";
const egsTopWinnersFilterAllTime = "ALL_TIME";
var EgsTopWinnersGameType = /* @__PURE__ */ ((EgsTopWinnersGameType2) => {
  EgsTopWinnersGameType2["SLOTS"] = "SLOTS";
  EgsTopWinnersGameType2["LIVE"] = "LIVE";
  EgsTopWinnersGameType2["BETGAMES"] = "BETGAMES";
  EgsTopWinnersGameType2["SPORTGAMES"] = "SPORTGAMES";
  EgsTopWinnersGameType2["ALL"] = "ALL";
  return EgsTopWinnersGameType2;
})(EgsTopWinnersGameType || {});
const egsTopWinnersGameTypeSlots = "SLOTS";
const egsTopWinnersGameTypeLive = "LIVE";
const egsTopWinnersGameTypeBetgames = "BETGAMES";
const egsTopWinnersGameTypeSportgames = "SPORTGAMES";
const egsTopWinnersGameTypeAll = "ALL";
var ErrorHandlerType = /* @__PURE__ */ ((ErrorHandlerType2) => {
  ErrorHandlerType2["SENTRY"] = "SENTRY";
  return ErrorHandlerType2;
})(ErrorHandlerType || {});
const errorHandlerTypeSentry = "SENTRY";
var EsiaIdentificationType = /* @__PURE__ */ ((EsiaIdentificationType2) => {
  EsiaIdentificationType2["NATIVE"] = "NATIVE";
  EsiaIdentificationType2["ETSUPIS"] = "ETSUPIS";
  return EsiaIdentificationType2;
})(EsiaIdentificationType || {});
const esiaIdentificationTypeNative = "NATIVE";
const esiaIdentificationTypeEtsupis = "ETSUPIS";
var EtsupisApiType = /* @__PURE__ */ ((EtsupisApiType2) => {
  EtsupisApiType2["BAPI"] = "BAPI";
  EtsupisApiType2["IAPI"] = "IAPI";
  return EtsupisApiType2;
})(EtsupisApiType || {});
const etsupisApiTypeBapi = "BAPI";
const etsupisApiTypeIapi = "IAPI";
var EventTimerMode = /* @__PURE__ */ ((EventTimerMode2) => {
  EventTimerMode2["STATIC"] = "STATIC";
  EventTimerMode2["LIVE"] = "LIVE";
  return EventTimerMode2;
})(EventTimerMode || {});
const eventTimerModeStatic = "STATIC";
const eventTimerModeLive = "LIVE";
var FeatureVisibility = /* @__PURE__ */ ((FeatureVisibility2) => {
  FeatureVisibility2["ANONYMOUS"] = "ANONYMOUS";
  FeatureVisibility2["REGISTERED"] = "REGISTERED";
  FeatureVisibility2["VIP"] = "VIP";
  FeatureVisibility2["BOTH"] = "BOTH";
  return FeatureVisibility2;
})(FeatureVisibility || {});
const featureVisibilityAnonymous = "ANONYMOUS";
const featureVisibilityRegistered = "REGISTERED";
const featureVisibilityVip = "VIP";
const featureVisibilityBoth = "BOTH";
var FeedbackChannel = /* @__PURE__ */ ((FeedbackChannel2) => {
  FeedbackChannel2["DEFAULT"] = "DEFAULT";
  FeedbackChannel2["SELF_EXCLUSION"] = "SELF_EXCLUSION";
  FeedbackChannel2["PROMOACTION"] = "PROMOACTION";
  return FeedbackChannel2;
})(FeedbackChannel || {});
const feedbackChannelDefault = "DEFAULT";
const feedbackChannelSelfExclusion = "SELF_EXCLUSION";
const feedbackChannelPromoaction = "PROMOACTION";
var FormType = /* @__PURE__ */ ((FormType2) => {
  FormType2["STRING"] = "STRING";
  FormType2["BOOLEAN"] = "BOOLEAN";
  FormType2["OBJECT"] = "OBJECT";
  return FormType2;
})(FormType || {});
const formTypeString = "STRING";
const formTypeBoolean = "BOOLEAN";
const formTypeObject = "OBJECT";
var FrontNotificationType = /* @__PURE__ */ ((FrontNotificationType2) => {
  FrontNotificationType2["CBC_BONUS_ACTIVATED"] = "CBC_BONUS_ACTIVATED";
  FrontNotificationType2["ALCR_NOTIFICATION"] = "ALCR_NOTIFICATION";
  FrontNotificationType2["VIDEO_VERIFICATION"] = "VIDEO_VERIFICATION";
  FrontNotificationType2["ON_SITE"] = "ON_SITE";
  FrontNotificationType2["REFPRG"] = "REFPRG";
  FrontNotificationType2["PLAY_TIME_ENDED"] = "PLAY_TIME_ENDED";
  FrontNotificationType2["UNKNOWN"] = "UNKNOWN";
  FrontNotificationType2["BONUS_GAME_REWARD"] = "BONUS_GAME_REWARD";
  FrontNotificationType2["BONUS_GAME_LAST_ROUND_FEEDBACK"] = "BONUS_GAME_LAST_ROUND_FEEDBACK";
  FrontNotificationType2["BONUS_EXPIRED_CASHBACK"] = "BONUS_EXPIRED_CASHBACK";
  FrontNotificationType2["BONUS_CASHBACK"] = "BONUS_CASHBACK";
  FrontNotificationType2["SUSPENDED_CASHBACK"] = "SUSPENDED_CASHBACK";
  FrontNotificationType2["RESUMED_CASHBACK"] = "RESUMED_CASHBACK";
  FrontNotificationType2["CRYPTO_DEPOSIT_STATUS"] = "CRYPTO_DEPOSIT_STATUS";
  FrontNotificationType2["BS_DEPOSIT"] = "BS_DEPOSIT";
  FrontNotificationType2["FOMO_BONUS_CREATED"] = "FOMO_BONUS_CREATED";
  return FrontNotificationType2;
})(FrontNotificationType || {});
const frontNotificationTypeCbcBonusActivated = "CBC_BONUS_ACTIVATED";
const frontNotificationTypeAlcrNotification = "ALCR_NOTIFICATION";
const frontNotificationTypeVideoVerification = "VIDEO_VERIFICATION";
const frontNotificationTypeOnSite = "ON_SITE";
const frontNotificationTypeRefprg = "REFPRG";
const frontNotificationTypePlayTimeEnded = "PLAY_TIME_ENDED";
const frontNotificationTypeUnknown = "UNKNOWN";
const frontNotificationTypeBonusGameReward = "BONUS_GAME_REWARD";
const frontNotificationTypeBonusGameLastRoundFeedback = "BONUS_GAME_LAST_ROUND_FEEDBACK";
const frontNotificationTypeBonusExpiredCashback = "BONUS_EXPIRED_CASHBACK";
const frontNotificationTypeBonusCashback = "BONUS_CASHBACK";
const frontNotificationTypeSuspendedCashback = "SUSPENDED_CASHBACK";
const frontNotificationTypeResumedCashback = "RESUMED_CASHBACK";
const frontNotificationTypeCryptoDepositStatus = "CRYPTO_DEPOSIT_STATUS";
const frontNotificationTypeBsDeposit = "BS_DEPOSIT";
const frontNotificationTypeFomoBonusCreated = "FOMO_BONUS_CREATED";
var G2SVRequiredExceptionCode = /* @__PURE__ */ ((G2SVRequiredExceptionCode2) => {
  G2SVRequiredExceptionCode2["G2SV_REQUIRED"] = "G2SV_REQUIRED";
  return G2SVRequiredExceptionCode2;
})(G2SVRequiredExceptionCode || {});
const g2SVRequiredExceptionCodeG2svRequired = "G2SV_REQUIRED";
var GambleStatus = /* @__PURE__ */ ((GambleStatus2) => {
  GambleStatus2["NONE"] = "NONE";
  GambleStatus2["APPROVED_BY_REGULATOR"] = "APPROVED_BY_REGULATOR";
  GambleStatus2["APPROVED_BY_STAFF"] = "APPROVED_BY_STAFF";
  GambleStatus2["TEMPORARY_BLOCKED"] = "TEMPORARY_BLOCKED";
  GambleStatus2["DECLINED_BY_REGULATOR"] = "DECLINED_BY_REGULATOR";
  GambleStatus2["CAN_GAMBLE"] = "CAN_GAMBLE";
  return GambleStatus2;
})(GambleStatus || {});
const gambleStatusNone = "NONE";
const gambleStatusApprovedByRegulator = "APPROVED_BY_REGULATOR";
const gambleStatusApprovedByStaff = "APPROVED_BY_STAFF";
const gambleStatusTemporaryBlocked = "TEMPORARY_BLOCKED";
const gambleStatusDeclinedByRegulator = "DECLINED_BY_REGULATOR";
const gambleStatusCanGamble = "CAN_GAMBLE";
var GameOpenMethod = /* @__PURE__ */ ((GameOpenMethod2) => {
  GameOpenMethod2["WINDOW"] = "WINDOW";
  GameOpenMethod2["IFRAME"] = "IFRAME";
  return GameOpenMethod2;
})(GameOpenMethod || {});
const gameOpenMethodWindow = "WINDOW";
const gameOpenMethodIframe = "IFRAME";
var GameUnavailableExceptionCode = /* @__PURE__ */ ((GameUnavailableExceptionCode2) => {
  GameUnavailableExceptionCode2["GAME_UNAVAILABLE"] = "GAME_UNAVAILABLE";
  return GameUnavailableExceptionCode2;
})(GameUnavailableExceptionCode || {});
const gameUnavailableExceptionCodeGameUnavailable = "GAME_UNAVAILABLE";
var HelpButtonMode = /* @__PURE__ */ ((HelpButtonMode2) => {
  HelpButtonMode2["HELP"] = "HELP";
  HelpButtonMode2["EXT_CHAT"] = "EXT_CHAT";
  return HelpButtonMode2;
})(HelpButtonMode || {});
const helpButtonModeHelp = "HELP";
const helpButtonModeExtChat = "EXT_CHAT";
var HelpChatType = /* @__PURE__ */ ((HelpChatType2) => {
  HelpChatType2["NONE"] = "NONE";
  HelpChatType2["LIVECHAT"] = "LIVECHAT";
  HelpChatType2["INTERCOM"] = "INTERCOM";
  return HelpChatType2;
})(HelpChatType || {});
const helpChatTypeNone = "NONE";
const helpChatTypeLivechat = "LIVECHAT";
const helpChatTypeIntercom = "INTERCOM";
var HistoryFilter = /* @__PURE__ */ ((HistoryFilter2) => {
  HistoryFilter2["ALL"] = "ALL";
  HistoryFilter2["ALL_BETS"] = "ALL_BETS";
  HistoryFilter2["ALL_PAYMENTS"] = "ALL_PAYMENTS";
  HistoryFilter2["ALL_DEPOSITS"] = "ALL_DEPOSITS";
  HistoryFilter2["ALL_WITHDRAWALS"] = "ALL_WITHDRAWALS";
  HistoryFilter2["ALL_EGS"] = "ALL_EGS";
  HistoryFilter2["ALL_SBC"] = "ALL_SBC";
  HistoryFilter2["ALL_BONUS"] = "ALL_BONUS";
  HistoryFilter2["ALL_CBC"] = "ALL_CBC";
  HistoryFilter2["LEONSHOP"] = "LEONSHOP";
  HistoryFilter2["PENDING"] = "PENDING";
  HistoryFilter2["PENDING_BETS"] = "PENDING_BETS";
  HistoryFilter2["PENDING_EGS"] = "PENDING_EGS";
  HistoryFilter2["PENDING_WITHDRAWALS"] = "PENDING_WITHDRAWALS";
  HistoryFilter2["COMPLETED"] = "COMPLETED";
  HistoryFilter2["COMPLETED_BETS"] = "COMPLETED_BETS";
  HistoryFilter2["COMPLETED_EGS"] = "COMPLETED_EGS";
  HistoryFilter2["COMPLETED_PAYMENTS"] = "COMPLETED_PAYMENTS";
  HistoryFilter2["COMPLETED_DEPOSITS"] = "COMPLETED_DEPOSITS";
  HistoryFilter2["COMPLETED_WITHDRAWALS"] = "COMPLETED_WITHDRAWALS";
  return HistoryFilter2;
})(HistoryFilter || {});
const historyFilterAll = "ALL";
const historyFilterAllBets = "ALL_BETS";
const historyFilterAllPayments = "ALL_PAYMENTS";
const historyFilterAllDeposits = "ALL_DEPOSITS";
const historyFilterAllWithdrawals = "ALL_WITHDRAWALS";
const historyFilterAllEgs = "ALL_EGS";
const historyFilterAllSbc = "ALL_SBC";
const historyFilterAllBonus = "ALL_BONUS";
const historyFilterAllCbc = "ALL_CBC";
const historyFilterLeonshop = "LEONSHOP";
const historyFilterPending = "PENDING";
const historyFilterPendingBets = "PENDING_BETS";
const historyFilterPendingEgs = "PENDING_EGS";
const historyFilterPendingWithdrawals = "PENDING_WITHDRAWALS";
const historyFilterCompleted = "COMPLETED";
const historyFilterCompletedBets = "COMPLETED_BETS";
const historyFilterCompletedEgs = "COMPLETED_EGS";
const historyFilterCompletedPayments = "COMPLETED_PAYMENTS";
const historyFilterCompletedDeposits = "COMPLETED_DEPOSITS";
const historyFilterCompletedWithdrawals = "COMPLETED_WITHDRAWALS";
var HomeAway = /* @__PURE__ */ ((HomeAway2) => {
  HomeAway2["HOME"] = "HOME";
  HomeAway2["AWAY"] = "AWAY";
  return HomeAway2;
})(HomeAway || {});
const homeAwayHome = "HOME";
const homeAwayAway = "AWAY";
var HomePageType = /* @__PURE__ */ ((HomePageType2) => {
  HomePageType2["SPORTS"] = "SPORTS";
  HomePageType2["SLOTS"] = "SLOTS";
  HomePageType2["LIVE_GAMES"] = "LIVE_GAMES";
  HomePageType2["CUSTOM"] = "CUSTOM";
  return HomePageType2;
})(HomePageType || {});
const homePageTypeSports = "SPORTS";
const homePageTypeSlots = "SLOTS";
const homePageTypeLiveGames = "LIVE_GAMES";
const homePageTypeCustom = "CUSTOM";
var HttpMethod = /* @__PURE__ */ ((HttpMethod2) => {
  HttpMethod2["GET"] = "GET";
  HttpMethod2["HEAD"] = "HEAD";
  HttpMethod2["POST"] = "POST";
  HttpMethod2["PUT"] = "PUT";
  HttpMethod2["PATCH"] = "PATCH";
  HttpMethod2["DELETE"] = "DELETE";
  HttpMethod2["OPTIONS"] = "OPTIONS";
  HttpMethod2["TRACE"] = "TRACE";
  return HttpMethod2;
})(HttpMethod || {});
const httpMethodGet = "GET";
const httpMethodHead = "HEAD";
const httpMethodPost = "POST";
const httpMethodPut = "PUT";
const httpMethodPatch = "PATCH";
const httpMethodDelete = "DELETE";
const httpMethodOptions = "OPTIONS";
const httpMethodTrace = "TRACE";
var IdentificationDocumentType = /* @__PURE__ */ ((IdentificationDocumentType2) => {
  IdentificationDocumentType2["PASSPORT"] = "PASSPORT";
  IdentificationDocumentType2["RESIDENCE"] = "RESIDENCE";
  IdentificationDocumentType2["THIRD_PAGE"] = "THIRD_PAGE";
  IdentificationDocumentType2["FACEPASS"] = "FACEPASS";
  IdentificationDocumentType2["VIDEO"] = "VIDEO";
  return IdentificationDocumentType2;
})(IdentificationDocumentType || {});
const identificationDocumentTypePassport = "PASSPORT";
const identificationDocumentTypeResidence = "RESIDENCE";
const identificationDocumentTypeThirdPage = "THIRD_PAGE";
const identificationDocumentTypeFacepass = "FACEPASS";
const identificationDocumentTypeVideo = "VIDEO";
var IdentificationLevel = /* @__PURE__ */ ((IdentificationLevel2) => {
  IdentificationLevel2["NONE"] = "NONE";
  IdentificationLevel2["SIMPLE"] = "SIMPLE";
  IdentificationLevel2["FULL"] = "FULL";
  return IdentificationLevel2;
})(IdentificationLevel || {});
const identificationLevelNone = "NONE";
const identificationLevelSimple = "SIMPLE";
const identificationLevelFull = "FULL";
var IdentificationProvider = /* @__PURE__ */ ((IdentificationProvider2) => {
  IdentificationProvider2["ESIA_SIMPLE"] = "ESIA_SIMPLE";
  IdentificationProvider2["TINKOFF"] = "TINKOFF";
  IdentificationProvider2["SBER"] = "SBER";
  return IdentificationProvider2;
})(IdentificationProvider || {});
const identificationProviderEsiaSimple = "ESIA_SIMPLE";
const identificationProviderTinkoff = "TINKOFF";
const identificationProviderSber = "SBER";
var IgnoredErrorType = /* @__PURE__ */ ((IgnoredErrorType2) => {
  IgnoredErrorType2["TEXT"] = "TEXT";
  IgnoredErrorType2["REGEXP"] = "REGEXP";
  return IgnoredErrorType2;
})(IgnoredErrorType || {});
const ignoredErrorTypeText = "TEXT";
const ignoredErrorTypeRegexp = "REGEXP";
var IllegalMutationsExceptionCode = /* @__PURE__ */ ((IllegalMutationsExceptionCode2) => {
  IllegalMutationsExceptionCode2["INVALID_MUTATIONS_NUMBER"] = "INVALID_MUTATIONS_NUMBER";
  return IllegalMutationsExceptionCode2;
})(IllegalMutationsExceptionCode || {});
const illegalMutationsExceptionCodeInvalidMutationsNumber = "INVALID_MUTATIONS_NUMBER";
var IncorrectCustomerType = /* @__PURE__ */ ((IncorrectCustomerType2) => {
  IncorrectCustomerType2["NEW"] = "NEW";
  IncorrectCustomerType2["SUSPEND"] = "SUSPEND";
  IncorrectCustomerType2["LOCKED"] = "LOCKED";
  return IncorrectCustomerType2;
})(IncorrectCustomerType || {});
const incorrectCustomerTypeNew = "NEW";
const incorrectCustomerTypeSuspend = "SUSPEND";
const incorrectCustomerTypeLocked = "LOCKED";
var InlineChatAnimation = /* @__PURE__ */ ((InlineChatAnimation2) => {
  InlineChatAnimation2["SPEECH_BUBBLE"] = "SPEECH_BUBBLE";
  InlineChatAnimation2["ICON_SHAKE"] = "ICON_SHAKE";
  InlineChatAnimation2["ICON"] = "ICON";
  return InlineChatAnimation2;
})(InlineChatAnimation || {});
const inlineChatAnimationSpeechBubble = "SPEECH_BUBBLE";
const inlineChatAnimationIconShake = "ICON_SHAKE";
const inlineChatAnimationIcon = "ICON";
var InlineChatHideCondition = /* @__PURE__ */ ((InlineChatHideCondition2) => {
  InlineChatHideCondition2["SECONDS_3"] = "SECONDS_3";
  InlineChatHideCondition2["SECONDS_5"] = "SECONDS_5";
  InlineChatHideCondition2["SECONDS_10"] = "SECONDS_10";
  InlineChatHideCondition2["ON_OUTSIDE_CLICK"] = "ON_OUTSIDE_CLICK";
  return InlineChatHideCondition2;
})(InlineChatHideCondition || {});
const inlineChatHideConditionSeconds3 = "SECONDS_3";
const inlineChatHideConditionSeconds5 = "SECONDS_5";
const inlineChatHideConditionSeconds10 = "SECONDS_10";
const inlineChatHideConditionOnOutsideClick = "ON_OUTSIDE_CLICK";
var InvalidBatchGraphQLRequestBodyIdExceptionCode = /* @__PURE__ */ ((InvalidBatchGraphQLRequestBodyIdExceptionCode2) => {
  InvalidBatchGraphQLRequestBodyIdExceptionCode2["INVALID_BATCH_ID_ERROR"] = "INVALID_BATCH_ID_ERROR";
  return InvalidBatchGraphQLRequestBodyIdExceptionCode2;
})(InvalidBatchGraphQLRequestBodyIdExceptionCode || {});
const invalidBatchGraphQLRequestBodyIdExceptionCodeInvalidBatchIdError = "INVALID_BATCH_ID_ERROR";
var InvalidBusinessHeaderValueExceptionCode = /* @__PURE__ */ ((InvalidBusinessHeaderValueExceptionCode2) => {
  InvalidBusinessHeaderValueExceptionCode2["INVALID_HEADER_VALUE"] = "INVALID_HEADER_VALUE";
  return InvalidBusinessHeaderValueExceptionCode2;
})(InvalidBusinessHeaderValueExceptionCode || {});
const invalidBusinessHeaderValueExceptionCodeInvalidHeaderValue = "INVALID_HEADER_VALUE";
var InvalidCodeExceptionCode = /* @__PURE__ */ ((InvalidCodeExceptionCode2) => {
  InvalidCodeExceptionCode2["INVALID_CODE"] = "INVALID_CODE";
  return InvalidCodeExceptionCode2;
})(InvalidCodeExceptionCode || {});
const invalidCodeExceptionCodeInvalidCode = "INVALID_CODE";
var InvalidGraphqlRequestsCountExceptionCode = /* @__PURE__ */ ((InvalidGraphqlRequestsCountExceptionCode2) => {
  InvalidGraphqlRequestsCountExceptionCode2["INVALID_GRAPHQL_REQUESTS_NUMBER"] = "INVALID_GRAPHQL_REQUESTS_NUMBER";
  return InvalidGraphqlRequestsCountExceptionCode2;
})(InvalidGraphqlRequestsCountExceptionCode || {});
const invalidGraphqlRequestsCountExceptionCodeInvalidGraphqlRequestsNumber = "INVALID_GRAPHQL_REQUESTS_NUMBER";
const invalidI18nKeysHashExceptionCodeInvalidI18nKeysHash = "INVALID_I18N_KEYS_HASH";
var InvalidPhoneExceptionCode = /* @__PURE__ */ ((InvalidPhoneExceptionCode2) => {
  InvalidPhoneExceptionCode2["INVALID_PHONE"] = "INVALID_PHONE";
  return InvalidPhoneExceptionCode2;
})(InvalidPhoneExceptionCode || {});
const invalidPhoneExceptionCodeInvalidPhone = "INVALID_PHONE";
var InvalidRequestsCountExceptionCode = /* @__PURE__ */ ((InvalidRequestsCountExceptionCode2) => {
  InvalidRequestsCountExceptionCode2["INVALID_REQUESTS_NUMBER"] = "INVALID_REQUESTS_NUMBER";
  return InvalidRequestsCountExceptionCode2;
})(InvalidRequestsCountExceptionCode || {});
const invalidRequestsCountExceptionCodeInvalidRequestsNumber = "INVALID_REQUESTS_NUMBER";
var InvalidTsExceptionCode = /* @__PURE__ */ ((InvalidTsExceptionCode2) => {
  InvalidTsExceptionCode2["INVALID_TS"] = "INVALID_TS";
  return InvalidTsExceptionCode2;
})(InvalidTsExceptionCode || {});
const invalidTsExceptionCodeInvalidTs = "INVALID_TS";
var InvalidUpdatedFromExceptionCode = /* @__PURE__ */ ((InvalidUpdatedFromExceptionCode2) => {
  InvalidUpdatedFromExceptionCode2["INVALID_UPDATED_FROM"] = "INVALID_UPDATED_FROM";
  return InvalidUpdatedFromExceptionCode2;
})(InvalidUpdatedFromExceptionCode || {});
const invalidUpdatedFromExceptionCodeInvalidUpdatedFrom = "INVALID_UPDATED_FROM";
var JurisdictionNotSupportedActionExceptionCode = /* @__PURE__ */ ((JurisdictionNotSupportedActionExceptionCode2) => {
  JurisdictionNotSupportedActionExceptionCode2["JURISDICTION_NOT_SUPPORTED_ACTION"] = "JURISDICTION_NOT_SUPPORTED_ACTION";
  return JurisdictionNotSupportedActionExceptionCode2;
})(JurisdictionNotSupportedActionExceptionCode || {});
const jurisdictionNotSupportedActionExceptionCodeJurisdictionNotSupportedAction = "JURISDICTION_NOT_SUPPORTED_ACTION";
const levelSettingsProgressStatusNotAvailable = "NOT_AVAILABLE";
const levelSettingsProgressStatusInProgress = "IN_PROGRESS";
const levelSettingsProgressStatusReceived = "RECEIVED";
var LobbyGameType = /* @__PURE__ */ ((LobbyGameType2) => {
  LobbyGameType2["SLOTS"] = "SLOTS";
  LobbyGameType2["LIVE"] = "LIVE";
  LobbyGameType2["BETGAMES"] = "BETGAMES";
  LobbyGameType2["FASTGAMES"] = "FASTGAMES";
  LobbyGameType2["ALL"] = "ALL";
  return LobbyGameType2;
})(LobbyGameType || {});
const lobbyGameTypeSlots = "SLOTS";
const lobbyGameTypeLive = "LIVE";
const lobbyGameTypeBetgames = "BETGAMES";
const lobbyGameTypeFastgames = "FASTGAMES";
const lobbyGameTypeAll = "ALL";
var LobbyGridType = /* @__PURE__ */ ((LobbyGridType2) => {
  LobbyGridType2["DEFAULT"] = "DEFAULT";
  LobbyGridType2["SMALL"] = "SMALL";
  LobbyGridType2["LARGE"] = "LARGE";
  return LobbyGridType2;
})(LobbyGridType || {});
const lobbyGridTypeDefault = "DEFAULT";
const lobbyGridTypeSmall = "SMALL";
const lobbyGridTypeLarge = "LARGE";
var LobbyItemType = /* @__PURE__ */ ((LobbyItemType2) => {
  LobbyItemType2["GAMES_CATEGORY"] = "GAMES_CATEGORY";
  LobbyItemType2["PROMOTIONS"] = "PROMOTIONS";
  LobbyItemType2["GROUPS"] = "GROUPS";
  LobbyItemType2["JACKPOTS"] = "JACKPOTS";
  LobbyItemType2["TOP_WINNERS"] = "TOP_WINNERS";
  LobbyItemType2["SPORTBET_TOP_WINNERS"] = "SPORTBET_TOP_WINNERS";
  LobbyItemType2["ALL_TOP_WINNERS"] = "ALL_TOP_WINNERS";
  LobbyItemType2["BANNERS"] = "BANNERS";
  LobbyItemType2["SPORTLINE_TOP_MATCHES"] = "SPORTLINE_TOP_MATCHES";
  return LobbyItemType2;
})(LobbyItemType || {});
const lobbyItemTypeGamesCategory = "GAMES_CATEGORY";
const lobbyItemTypePromotions = "PROMOTIONS";
const lobbyItemTypeGroups = "GROUPS";
const lobbyItemTypeJackpots = "JACKPOTS";
const lobbyItemTypeTopWinners = "TOP_WINNERS";
const lobbyItemTypeSportbetTopWinners = "SPORTBET_TOP_WINNERS";
const lobbyItemTypeAllTopWinners = "ALL_TOP_WINNERS";
const lobbyItemTypeBanners = "BANNERS";
const lobbyItemTypeSportlineTopMatches = "SPORTLINE_TOP_MATCHES";
var LobbyItemWidget = /* @__PURE__ */ ((LobbyItemWidget2) => {
  LobbyItemWidget2["SQUARE_LIST"] = "SQUARE_LIST";
  LobbyItemWidget2["LIST"] = "LIST";
  LobbyItemWidget2["SWIPE_LIST"] = "SWIPE_LIST";
  LobbyItemWidget2["JACKPOT_LIST"] = "JACKPOT_LIST";
  LobbyItemWidget2["JACKPOT_SWIPE_LIST"] = "JACKPOT_SWIPE_LIST";
  LobbyItemWidget2["SQUARE_SWIPE_LIST"] = "SQUARE_SWIPE_LIST";
  LobbyItemWidget2["SQUARE_ROWS_LIST"] = "SQUARE_ROWS_LIST";
  LobbyItemWidget2["BIG_SMALL_ROWS_LIST"] = "BIG_SMALL_ROWS_LIST";
  return LobbyItemWidget2;
})(LobbyItemWidget || {});
const lobbyItemWidgetSquareList = "SQUARE_LIST";
const lobbyItemWidgetList = "LIST";
const lobbyItemWidgetSwipeList = "SWIPE_LIST";
const lobbyItemWidgetJackpotList = "JACKPOT_LIST";
const lobbyItemWidgetJackpotSwipeList = "JACKPOT_SWIPE_LIST";
const lobbyItemWidgetSquareSwipeList = "SQUARE_SWIPE_LIST";
const lobbyItemWidgetSquareRowsList = "SQUARE_ROWS_LIST";
const lobbyItemWidgetBigSmallRowsList = "BIG_SMALL_ROWS_LIST";
var LobbyTitleType = /* @__PURE__ */ ((LobbyTitleType2) => {
  LobbyTitleType2["DEFAULT"] = "DEFAULT";
  LobbyTitleType2["SMALL"] = "SMALL";
  LobbyTitleType2["HIDDEN"] = "HIDDEN";
  return LobbyTitleType2;
})(LobbyTitleType || {});
const lobbyTitleTypeDefault = "DEFAULT";
const lobbyTitleTypeSmall = "SMALL";
const lobbyTitleTypeHidden = "HIDDEN";
var LobbyType = /* @__PURE__ */ ((LobbyType2) => {
  LobbyType2["SLOTS"] = "SLOTS";
  LobbyType2["LIVE_CASINO"] = "LIVE_CASINO";
  LobbyType2["SEARCH"] = "SEARCH";
  LobbyType2["SLOTS_GAME_PAGE"] = "SLOTS_GAME_PAGE";
  LobbyType2["LIVE_GAME_PAGE"] = "LIVE_GAME_PAGE";
  LobbyType2["LOYALTY_PROGRAM"] = "LOYALTY_PROGRAM";
  LobbyType2["FASTGAMES"] = "FASTGAMES";
  LobbyType2["HOME"] = "HOME";
  LobbyType2["ALL"] = "ALL";
  return LobbyType2;
})(LobbyType || {});
const lobbyTypeSlots = "SLOTS";
const lobbyTypeLiveCasino = "LIVE_CASINO";
const lobbyTypeSearch = "SEARCH";
const lobbyTypeSlotsGamePage = "SLOTS_GAME_PAGE";
const lobbyTypeLiveGamePage = "LIVE_GAME_PAGE";
const lobbyTypeLoyaltyProgram = "LOYALTY_PROGRAM";
const lobbyTypeFastgames = "FASTGAMES";
const lobbyTypeHome = "HOME";
const lobbyTypeAll = "ALL";
var LoginType = /* @__PURE__ */ ((LoginType2) => {
  LoginType2["EMAIL"] = "EMAIL";
  LoginType2["PHONE"] = "PHONE";
  LoginType2["ACCOUNT"] = "ACCOUNT";
  return LoginType2;
})(LoginType || {});
const loginTypeEmail = "EMAIL";
const loginTypePhone = "PHONE";
const loginTypeAccount = "ACCOUNT";
var LogLevel = /* @__PURE__ */ ((LogLevel2) => {
  LogLevel2["ERROR"] = "ERROR";
  LogLevel2["WARN"] = "WARN";
  LogLevel2["INFO"] = "INFO";
  LogLevel2["DEBUG"] = "DEBUG";
  return LogLevel2;
})(LogLevel || {});
const logLevelError = "ERROR";
const logLevelWarn = "WARN";
const logLevelInfo = "INFO";
const logLevelDebug = "DEBUG";
const manualCustomerDataVersionV5 = "V5";
const manualCustomerDataVersionV4 = "V4";
var ManualStatus = /* @__PURE__ */ ((ManualStatus2) => {
  ManualStatus2["INIT"] = "INIT";
  ManualStatus2["UPDATED"] = "UPDATED";
  ManualStatus2["API_FOUND"] = "API_FOUND";
  ManualStatus2["ERROR"] = "ERROR";
  ManualStatus2["DRAFT"] = "DRAFT";
  ManualStatus2["DRAFT_INIT"] = "DRAFT_INIT";
  ManualStatus2["SUCCESS"] = "SUCCESS";
  return ManualStatus2;
})(ManualStatus || {});
const manualStatusInit = "INIT";
const manualStatusUpdated = "UPDATED";
const manualStatusApiFound = "API_FOUND";
const manualStatusError = "ERROR";
const manualStatusDraft = "DRAFT";
const manualStatusDraftInit = "DRAFT_INIT";
const manualStatusSuccess = "SUCCESS";
var MarketStatus = /* @__PURE__ */ ((MarketStatus2) => {
  MarketStatus2["CLOSED"] = "CLOSED";
  MarketStatus2["MISSING"] = "MISSING";
  MarketStatus2["PRICE_UP"] = "PRICE_UP";
  MarketStatus2["PRICE_DOWN"] = "PRICE_DOWN";
  MarketStatus2["SUSPENDED"] = "SUSPENDED";
  MarketStatus2["LIMIT"] = "LIMIT";
  MarketStatus2["DUPLICATE"] = "DUPLICATE";
  return MarketStatus2;
})(MarketStatus || {});
const marketStatusClosed = "CLOSED";
const marketStatusMissing = "MISSING";
const marketStatusPriceUp = "PRICE_UP";
const marketStatusPriceDown = "PRICE_DOWN";
const marketStatusSuspended = "SUSPENDED";
const marketStatusLimit = "LIMIT";
const marketStatusDuplicate = "DUPLICATE";
const marketTypeTagTotal = "TOTAL";
const marketTypeTagHandicap = "HANDICAP";
const marketTypeTagRegular = "REGULAR";
var MatchPhase = /* @__PURE__ */ ((MatchPhase2) => {
  MatchPhase2["PRE_GAME"] = "PRE_GAME";
  MatchPhase2["IN_PLAY"] = "IN_PLAY";
  MatchPhase2["GAME_ABANDONED"] = "GAME_ABANDONED";
  MatchPhase2["BREAK_IN_PLAY"] = "BREAK_IN_PLAY";
  MatchPhase2["OVERTIME"] = "OVERTIME";
  MatchPhase2["POST_GAME"] = "POST_GAME";
  return MatchPhase2;
})(MatchPhase || {});
const matchPhasePreGame = "PRE_GAME";
const matchPhaseInPlay = "IN_PLAY";
const matchPhaseGameAbandoned = "GAME_ABANDONED";
const matchPhaseBreakInPlay = "BREAK_IN_PLAY";
const matchPhaseOvertime = "OVERTIME";
const matchPhasePostGame = "POST_GAME";
var MatchStreamAvailabilityType = /* @__PURE__ */ ((MatchStreamAvailabilityType2) => {
  MatchStreamAvailabilityType2["ALL"] = "ALL";
  MatchStreamAvailabilityType2["NORMAL"] = "NORMAL";
  MatchStreamAvailabilityType2["VIP"] = "VIP";
  MatchStreamAvailabilityType2["UNKNOWN"] = "UNKNOWN";
  return MatchStreamAvailabilityType2;
})(MatchStreamAvailabilityType || {});
const matchStreamAvailabilityTypeAll = "ALL";
const matchStreamAvailabilityTypeNormal = "NORMAL";
const matchStreamAvailabilityTypeVip = "VIP";
const matchStreamAvailabilityTypeUnknown = "UNKNOWN";
var MetricsToolType = /* @__PURE__ */ ((MetricsToolType2) => {
  MetricsToolType2["YANDEX"] = "YANDEX";
  MetricsToolType2["INCOME_ACCESS"] = "INCOME_ACCESS";
  MetricsToolType2["GOOGLE_ANALYTICS"] = "GOOGLE_ANALYTICS";
  return MetricsToolType2;
})(MetricsToolType || {});
const metricsToolTypeYandex = "YANDEX";
const metricsToolTypeIncomeAccess = "INCOME_ACCESS";
const metricsToolTypeGoogleAnalytics = "GOOGLE_ANALYTICS";
var NavigationSection = /* @__PURE__ */ ((NavigationSection2) => {
  NavigationSection2["HEADER_NAVIGATION"] = "HEADER_NAVIGATION";
  NavigationSection2["FOOTER_NAVIGATION"] = "FOOTER_NAVIGATION";
  NavigationSection2["SIDE_NAVIGATION"] = "SIDE_NAVIGATION";
  NavigationSection2["PROMOACTIONS_NAVIGATION"] = "PROMOACTIONS_NAVIGATION";
  return NavigationSection2;
})(NavigationSection || {});
const navigationSectionHeaderNavigation = "HEADER_NAVIGATION";
const navigationSectionFooterNavigation = "FOOTER_NAVIGATION";
const navigationSectionSideNavigation = "SIDE_NAVIGATION";
const navigationSectionPromoactionsNavigation = "PROMOACTIONS_NAVIGATION";
var NoCashbackExceptionCode = /* @__PURE__ */ ((NoCashbackExceptionCode2) => {
  NoCashbackExceptionCode2["NO_CAMPAIGN"] = "NO_CAMPAIGN";
  return NoCashbackExceptionCode2;
})(NoCashbackExceptionCode || {});
const noCashbackExceptionCodeNoCampaign = "NO_CAMPAIGN";
var NoRegisterStrategyExceptionCode = /* @__PURE__ */ ((NoRegisterStrategyExceptionCode2) => {
  NoRegisterStrategyExceptionCode2["NO_REGISTRATION_STRATEGY_FOUND"] = "NO_REGISTRATION_STRATEGY_FOUND";
  return NoRegisterStrategyExceptionCode2;
})(NoRegisterStrategyExceptionCode || {});
const noRegisterStrategyExceptionCodeNoRegistrationStrategyFound = "NO_REGISTRATION_STRATEGY_FOUND";
var NoSchemaExceptionCode = /* @__PURE__ */ ((NoSchemaExceptionCode2) => {
  NoSchemaExceptionCode2["NO_SCHEMA_FOUND"] = "NO_SCHEMA_FOUND";
  return NoSchemaExceptionCode2;
})(NoSchemaExceptionCode || {});
const noSchemaExceptionCodeNoSchemaFound = "NO_SCHEMA_FOUND";
var NoSuchContentExceptionCode = /* @__PURE__ */ ((NoSuchContentExceptionCode2) => {
  NoSuchContentExceptionCode2["NO_CONTENT"] = "NO_CONTENT";
  return NoSuchContentExceptionCode2;
})(NoSuchContentExceptionCode || {});
const noSuchContentExceptionCodeNoContent = "NO_CONTENT";
var NoSuchHeaderExceptionCode = /* @__PURE__ */ ((NoSuchHeaderExceptionCode2) => {
  NoSuchHeaderExceptionCode2["NO_SUCH_HEADER"] = "NO_SUCH_HEADER";
  return NoSuchHeaderExceptionCode2;
})(NoSuchHeaderExceptionCode || {});
const noSuchHeaderExceptionCodeNoSuchHeader = "NO_SUCH_HEADER";
var NoSuchTranslationExceptionCode = /* @__PURE__ */ ((NoSuchTranslationExceptionCode2) => {
  NoSuchTranslationExceptionCode2["NO_TRANSLATION"] = "NO_TRANSLATION";
  return NoSuchTranslationExceptionCode2;
})(NoSuchTranslationExceptionCode || {});
const noSuchTranslationExceptionCodeNoTranslation = "NO_TRANSLATION";
var NoValidationSchemaFoundExceptionCode = /* @__PURE__ */ ((NoValidationSchemaFoundExceptionCode2) => {
  NoValidationSchemaFoundExceptionCode2["NO_VALIDATION_SCHEMA"] = "NO_VALIDATION_SCHEMA";
  return NoValidationSchemaFoundExceptionCode2;
})(NoValidationSchemaFoundExceptionCode || {});
const noValidationSchemaFoundExceptionCodeNoValidationSchema = "NO_VALIDATION_SCHEMA";
var OpenIn = /* @__PURE__ */ ((OpenIn2) => {
  OpenIn2["SAME_WINDOW"] = "SAME_WINDOW";
  OpenIn2["NEW_WINDOW"] = "NEW_WINDOW";
  OpenIn2["IFRAME"] = "IFRAME";
  return OpenIn2;
})(OpenIn || {});
const openInSameWindow = "SAME_WINDOW";
const openInNewWindow = "NEW_WINDOW";
const openInIframe = "IFRAME";
var OperationStatus = /* @__PURE__ */ ((OperationStatus2) => {
  OperationStatus2["OK"] = "OK";
  OperationStatus2["FAIL"] = "FAIL";
  return OperationStatus2;
})(OperationStatus || {});
const operationStatusOk = "OK";
const operationStatusFail = "FAIL";
const otpCodePlaceWithdrawal = "WITHDRAWAL";
const otpCodeRequiredExceptionCodeOtpCodeVerificationNeeded = "OTP_CODE_VERIFICATION_NEEDED";
var OutcomeState = /* @__PURE__ */ ((OutcomeState2) => {
  OutcomeState2["UNDEFINED"] = "UNDEFINED";
  OutcomeState2["WIN"] = "WIN";
  OutcomeState2["LOSE"] = "LOSE";
  OutcomeState2["RETURN"] = "RETURN";
  OutcomeState2["WIN_HALF"] = "WIN_HALF";
  OutcomeState2["LOSE_HALF"] = "LOSE_HALF";
  OutcomeState2["PRE_GAME"] = "PRE_GAME";
  OutcomeState2["IN_PLAY"] = "IN_PLAY";
  return OutcomeState2;
})(OutcomeState || {});
const outcomeStateUndefined = "UNDEFINED";
const outcomeStateWin = "WIN";
const outcomeStateLose = "LOSE";
const outcomeStateReturn = "RETURN";
const outcomeStateWinHalf = "WIN_HALF";
const outcomeStateLoseHalf = "LOSE_HALF";
const outcomeStatePreGame = "PRE_GAME";
const outcomeStateInPlay = "IN_PLAY";
var PackListState = /* @__PURE__ */ ((PackListState2) => {
  PackListState2["UPCOMING"] = "UPCOMING";
  PackListState2["ACTIVE"] = "ACTIVE";
  PackListState2["CLOSED"] = "CLOSED";
  return PackListState2;
})(PackListState || {});
const packListStateUpcoming = "UPCOMING";
const packListStateActive = "ACTIVE";
const packListStateClosed = "CLOSED";
var PackReward = /* @__PURE__ */ ((PackReward2) => {
  PackReward2["EMPTY"] = "EMPTY";
  PackReward2["COLLECTIBLE_CARD"] = "COLLECTIBLE_CARD";
  PackReward2["FREEBET"] = "FREEBET";
  PackReward2["FREESPIN"] = "FREESPIN";
  PackReward2["BONUS"] = "BONUS";
  return PackReward2;
})(PackReward || {});
const packRewardEmpty = "EMPTY";
const packRewardCollectibleCard = "COLLECTIBLE_CARD";
const packRewardFreebet = "FREEBET";
const packRewardFreespin = "FREESPIN";
const packRewardBonus = "BONUS";
var PackState = /* @__PURE__ */ ((PackState2) => {
  PackState2["NOT_AVAILABLE"] = "NOT_AVAILABLE";
  PackState2["AVAILABLE_FOR_CALCULATION"] = "AVAILABLE_FOR_CALCULATION";
  PackState2["CALCULATED"] = "CALCULATED";
  return PackState2;
})(PackState || {});
const packStateNotAvailable = "NOT_AVAILABLE";
const packStateAvailableForCalculation = "AVAILABLE_FOR_CALCULATION";
const packStateCalculated = "CALCULATED";
var ParticipationType = /* @__PURE__ */ ((ParticipationType2) => {
  ParticipationType2["NONE"] = "NONE";
  ParticipationType2["PROMO"] = "PROMO";
  ParticipationType2["LEON_BONUS"] = "LEON_BONUS";
  return ParticipationType2;
})(ParticipationType || {});
const participationTypeNone = "NONE";
const participationTypePromo = "PROMO";
const participationTypeLeonBonus = "LEON_BONUS";
var PathType = /* @__PURE__ */ ((PathType2) => {
  PathType2["INTERNAL_PATH"] = "INTERNAL_PATH";
  PathType2["EXTERNAL_PATH"] = "EXTERNAL_PATH";
  PathType2["MARKETING_LEONSHOP"] = "MARKETING_LEONSHOP";
  return PathType2;
})(PathType || {});
const pathTypeInternalPath = "INTERNAL_PATH";
const pathTypeExternalPath = "EXTERNAL_PATH";
const pathTypeMarketingLeonshop = "MARKETING_LEONSHOP";
var PaymentExceptionCode = /* @__PURE__ */ ((PaymentExceptionCode2) => {
  PaymentExceptionCode2["PAYMENT_ERROR"] = "PAYMENT_ERROR";
  return PaymentExceptionCode2;
})(PaymentExceptionCode || {});
const paymentExceptionCodePaymentError = "PAYMENT_ERROR";
const paymentsAccountHistoryDataCategoryDeposit = "DEPOSIT";
const paymentsAccountHistoryDataCategoryWithdrawal = "WITHDRAWAL";
const paymentsAccountHistoryDataCategoryDepositBonus = "DEPOSIT_BONUS";
const paymentsAccountHistoryDataCategoryCorrection = "CORRECTION";
const paymentsAccountHistoryDataTypesDeposit = "DEPOSIT";
const paymentsAccountHistoryDataTypesWithdrawal = "WITHDRAWAL";
const paymentsAccountHistoryDataTypesEgsProviderReward = "EGS_PROVIDER_REWARD";
const paymentsAccountHistoryDataTypesManualBsDepositBonus = "MANUAL_BS_DEPOSIT_BONUS";
const paymentsAccountHistoryDataTypesLeaderboardCashReward = "LEADERBOARD_CASH_REWARD";
const paymentsAccountHistoryDataTypesMarketingEgsBonusDeposit = "MARKETING_EGS_BONUS_DEPOSIT";
const paymentsAccountHistoryDataTypesLoyaltyBonusEncash = "LOYALTY_BONUS_ENCASH";
const paymentsAccountHistoryDataTypesMarketingSportBonusDeposit = "MARKETING_SPORT_BONUS_DEPOSIT";
const paymentsAccountHistoryDataTypesCorrectionFromAccount = "CORRECTION_FROM_ACCOUNT";
const paymentsAccountHistoryDataTypesCorrectionToAccount = "CORRECTION_TO_ACCOUNT";
const paymentsAccountHistoryDataTypesInfluencerDeposit = "INFLUENCER_DEPOSIT";
const paymentsAccountHistoryDataTypesInfluencerWithdrawal = "INFLUENCER_WITHDRAWAL";
const paymentsAccountHistoryStatusPending = "PENDING";
const paymentsAccountHistoryStatusExecuted = "EXECUTED";
const paymentsAccountHistoryStatusCancelled = "CANCELLED";
var PaymentType = /* @__PURE__ */ ((PaymentType2) => {
  PaymentType2["DEPOSIT"] = "DEPOSIT";
  PaymentType2["WITHDRAWAL"] = "WITHDRAWAL";
  return PaymentType2;
})(PaymentType || {});
const paymentTypeDeposit = "DEPOSIT";
const paymentTypeWithdrawal = "WITHDRAWAL";
var PersonalDomainType = /* @__PURE__ */ ((PersonalDomainType2) => {
  PersonalDomainType2["CONDITIONAL"] = "CONDITIONAL";
  PersonalDomainType2["PERSONAL"] = "PERSONAL";
  return PersonalDomainType2;
})(PersonalDomainType || {});
const personalDomainTypeConditional = "CONDITIONAL";
const personalDomainTypePersonal = "PERSONAL";
var PhoneCodeMethod = /* @__PURE__ */ ((PhoneCodeMethod2) => {
  PhoneCodeMethod2["SMS"] = "SMS";
  PhoneCodeMethod2["CALL_PASSWORD_CODE"] = "CALL_PASSWORD_CODE";
  PhoneCodeMethod2["EXTERNAL_SMS"] = "EXTERNAL_SMS";
  return PhoneCodeMethod2;
})(PhoneCodeMethod || {});
const phoneCodeMethodSms = "SMS";
const phoneCodeMethodCallPasswordCode = "CALL_PASSWORD_CODE";
const phoneCodeMethodExternalSms = "EXTERNAL_SMS";
var PhoneIdentificationRequired = /* @__PURE__ */ ((PhoneIdentificationRequired2) => {
  PhoneIdentificationRequired2["UNKNOWN"] = "UNKNOWN";
  PhoneIdentificationRequired2["NO"] = "NO";
  PhoneIdentificationRequired2["YES"] = "YES";
  return PhoneIdentificationRequired2;
})(PhoneIdentificationRequired || {});
const phoneIdentificationRequiredUnknown = "UNKNOWN";
const phoneIdentificationRequiredNo = "NO";
const phoneIdentificationRequiredYes = "YES";
var PinCodePlace = /* @__PURE__ */ ((PinCodePlace2) => {
  PinCodePlace2["WITHDRAWAL"] = "WITHDRAWAL";
  PinCodePlace2["SELF_EXCLUSION"] = "SELF_EXCLUSION";
  PinCodePlace2["LOGIN"] = "LOGIN";
  PinCodePlace2["PIN_CODE_AT_LOGIN_SETTING"] = "PIN_CODE_AT_LOGIN_SETTING";
  PinCodePlace2["PLAY_TIME_CONTROL"] = "PLAY_TIME_CONTROL";
  PinCodePlace2["UNKNOWN"] = "UNKNOWN";
  return PinCodePlace2;
})(PinCodePlace || {});
const pinCodePlaceWithdrawal = "WITHDRAWAL";
const pinCodePlaceSelfExclusion = "SELF_EXCLUSION";
const pinCodePlaceLogin = "LOGIN";
const pinCodePlacePinCodeAtLoginSetting = "PIN_CODE_AT_LOGIN_SETTING";
const pinCodePlacePlayTimeControl = "PLAY_TIME_CONTROL";
const pinCodePlaceUnknown = "UNKNOWN";
var PinCodeRequiredExceptionCode = /* @__PURE__ */ ((PinCodeRequiredExceptionCode2) => {
  PinCodeRequiredExceptionCode2["PIN_CODE_VERIFICATION_NEEDED"] = "PIN_CODE_VERIFICATION_NEEDED";
  return PinCodeRequiredExceptionCode2;
})(PinCodeRequiredExceptionCode || {});
const pinCodeRequiredExceptionCodePinCodeVerificationNeeded = "PIN_CODE_VERIFICATION_NEEDED";
var PinCodeState = /* @__PURE__ */ ((PinCodeState2) => {
  PinCodeState2["INITIAL"] = "INITIAL";
  PinCodeState2["SET"] = "SET";
  PinCodeState2["RESET"] = "RESET";
  PinCodeState2["BANNED"] = "BANNED";
  PinCodeState2["UNKNOWN"] = "UNKNOWN";
  return PinCodeState2;
})(PinCodeState || {});
const pinCodeStateInitial = "INITIAL";
const pinCodeStateSet = "SET";
const pinCodeStateReset = "RESET";
const pinCodeStateBanned = "BANNED";
const pinCodeStateUnknown = "UNKNOWN";
var PreviousRequestHasNotExpiredExceptionCode = /* @__PURE__ */ ((PreviousRequestHasNotExpiredExceptionCode2) => {
  PreviousRequestHasNotExpiredExceptionCode2["PREVIOUS_REQUEST_HAS_NOT_EXPIRED"] = "PREVIOUS_REQUEST_HAS_NOT_EXPIRED";
  return PreviousRequestHasNotExpiredExceptionCode2;
})(PreviousRequestHasNotExpiredExceptionCode || {});
const previousRequestHasNotExpiredExceptionCodePreviousRequestHasNotExpired = "PREVIOUS_REQUEST_HAS_NOT_EXPIRED";
var PriceChangePolicy = /* @__PURE__ */ ((PriceChangePolicy2) => {
  PriceChangePolicy2["DENY_CHANGES"] = "DENY_CHANGES";
  PriceChangePolicy2["ALLOW_INCREASE"] = "ALLOW_INCREASE";
  PriceChangePolicy2["ALLOW_CHANGES"] = "ALLOW_CHANGES";
  PriceChangePolicy2["UNDEFINED"] = "UNDEFINED";
  return PriceChangePolicy2;
})(PriceChangePolicy || {});
const priceChangePolicyDenyChanges = "DENY_CHANGES";
const priceChangePolicyAllowIncrease = "ALLOW_INCREASE";
const priceChangePolicyAllowChanges = "ALLOW_CHANGES";
const priceChangePolicyUndefined = "UNDEFINED";
var ProgramParticipationStatus = /* @__PURE__ */ ((ProgramParticipationStatus2) => {
  ProgramParticipationStatus2["PENDING"] = "PENDING";
  ProgramParticipationStatus2["AVAILABLE"] = "AVAILABLE";
  ProgramParticipationStatus2["COMPLETED"] = "COMPLETED";
  return ProgramParticipationStatus2;
})(ProgramParticipationStatus || {});
const programParticipationStatusPending = "PENDING";
const programParticipationStatusAvailable = "AVAILABLE";
const programParticipationStatusCompleted = "COMPLETED";
var ProgramRequirementType = /* @__PURE__ */ ((ProgramRequirementType2) => {
  ProgramRequirementType2["REGISTRATION"] = "REGISTRATION";
  ProgramRequirementType2["DEPOSIT"] = "DEPOSIT";
  ProgramRequirementType2["BET"] = "BET";
  ProgramRequirementType2["CONFIRMATION"] = "CONFIRMATION";
  ProgramRequirementType2["IDENTIFICATION"] = "IDENTIFICATION";
  return ProgramRequirementType2;
})(ProgramRequirementType || {});
const programRequirementTypeRegistration = "REGISTRATION";
const programRequirementTypeDeposit = "DEPOSIT";
const programRequirementTypeBet = "BET";
const programRequirementTypeConfirmation = "CONFIRMATION";
const programRequirementTypeIdentification = "IDENTIFICATION";
var ProgramRewardType = /* @__PURE__ */ ((ProgramRewardType2) => {
  ProgramRewardType2["FREESPIN"] = "FREESPIN";
  ProgramRewardType2["FREEBET"] = "FREEBET";
  return ProgramRewardType2;
})(ProgramRewardType || {});
const programRewardTypeFreespin = "FREESPIN";
const programRewardTypeFreebet = "FREEBET";
const progressBarNone = "NONE";
const progressBarLinearProgress = "LINEAR_PROGRESS";
const progressUnitApplicationType = "APPLICATION_TYPE";
const progressUnitBet = "BET";
const progressUnitTournament = "TOURNAMENT";
const progressUnitDeposit = "DEPOSIT";
const progressUnitDay = "DAY";
const progressUnitGame = "GAME";
const progressUnitProvider = "PROVIDER";
const progressUnitXp = "XP";
const progressUnitReferral = "REFERRAL";
const progressUnitCurrency = "CURRENCY";
var PromoActionType = /* @__PURE__ */ ((PromoActionType2) => {
  PromoActionType2["NO_PARTICIPATION"] = "NO_PARTICIPATION";
  PromoActionType2["STATIC"] = "STATIC";
  PromoActionType2["LEADERBOARD_SPORTS"] = "LEADERBOARD_SPORTS";
  PromoActionType2["LEADERBOARD_EG"] = "LEADERBOARD_EG";
  PromoActionType2["PLAIN_CONFIRM"] = "PLAIN_CONFIRM";
  PromoActionType2["CBC"] = "CBC";
  PromoActionType2["SBC"] = "SBC";
  PromoActionType2["EGS_CASHBACK"] = "EGS_CASHBACK";
  PromoActionType2["SPORT"] = "SPORT";
  PromoActionType2["EGS"] = "EGS";
  PromoActionType2["FORTUNE_WHEEL"] = "FORTUNE_WHEEL";
  PromoActionType2["COUPON"] = "COUPON";
  PromoActionType2["BONUS_PACK"] = "BONUS_PACK";
  return PromoActionType2;
})(PromoActionType || {});
const promoActionTypeNoParticipation = "NO_PARTICIPATION";
const promoActionTypeStatic = "STATIC";
const promoActionTypeLeaderboardSports = "LEADERBOARD_SPORTS";
const promoActionTypeLeaderboardEg = "LEADERBOARD_EG";
const promoActionTypePlainConfirm = "PLAIN_CONFIRM";
const promoActionTypeCbc = "CBC";
const promoActionTypeSbc = "SBC";
const promoActionTypeEgsCashback = "EGS_CASHBACK";
const promoActionTypeSport = "SPORT";
const promoActionTypeEgs = "EGS";
const promoActionTypeFortuneWheel = "FORTUNE_WHEEL";
const promoActionTypeCoupon = "COUPON";
const promoActionTypeBonusPack = "BONUS_PACK";
var PromotionActionButtonState = /* @__PURE__ */ ((PromotionActionButtonState2) => {
  PromotionActionButtonState2["ACTION"] = "ACTION";
  PromotionActionButtonState2["INFO"] = "INFO";
  return PromotionActionButtonState2;
})(PromotionActionButtonState || {});
const promotionActionButtonStateAction = "ACTION";
const promotionActionButtonStateInfo = "INFO";
var PromotionActionButtonType = /* @__PURE__ */ ((PromotionActionButtonType2) => {
  PromotionActionButtonType2["LOGIN"] = "LOGIN";
  PromotionActionButtonType2["PARTICIPATE"] = "PARTICIPATE";
  PromotionActionButtonType2["PARTICIPATING"] = "PARTICIPATING";
  PromotionActionButtonType2["PARTICIPATION_PROCESSING"] = "PARTICIPATION_PROCESSING";
  PromotionActionButtonType2["ON_BONUS_WALLET"] = "ON_BONUS_WALLET";
  PromotionActionButtonType2["LEADERBOARD"] = "LEADERBOARD";
  PromotionActionButtonType2["EXCHANGE"] = "EXCHANGE";
  PromotionActionButtonType2["EXCHANGED"] = "EXCHANGED";
  PromotionActionButtonType2["CUSTOM"] = "CUSTOM";
  PromotionActionButtonType2["NONE"] = "NONE";
  PromotionActionButtonType2["FREESPIN"] = "FREESPIN";
  PromotionActionButtonType2["PLAY"] = "PLAY";
  PromotionActionButtonType2["IDENTIFICATION_NEEDED"] = "IDENTIFICATION_NEEDED";
  PromotionActionButtonType2["DEPOSIT"] = "DEPOSIT";
  PromotionActionButtonType2["CLAIM"] = "CLAIM";
  return PromotionActionButtonType2;
})(PromotionActionButtonType || {});
const promotionActionButtonTypeLogin = "LOGIN";
const promotionActionButtonTypeParticipate = "PARTICIPATE";
const promotionActionButtonTypeParticipating = "PARTICIPATING";
const promotionActionButtonTypeParticipationProcessing = "PARTICIPATION_PROCESSING";
const promotionActionButtonTypeOnBonusWallet = "ON_BONUS_WALLET";
const promotionActionButtonTypeLeaderboard = "LEADERBOARD";
const promotionActionButtonTypeExchange = "EXCHANGE";
const promotionActionButtonTypeExchanged = "EXCHANGED";
const promotionActionButtonTypeCustom = "CUSTOM";
const promotionActionButtonTypeNone = "NONE";
const promotionActionButtonTypeFreespin = "FREESPIN";
const promotionActionButtonTypePlay = "PLAY";
const promotionActionButtonTypeIdentificationNeeded = "IDENTIFICATION_NEEDED";
const promotionActionButtonTypeDeposit = "DEPOSIT";
const promotionActionButtonTypeClaim = "CLAIM";
var PushNotificationProvider = /* @__PURE__ */ ((PushNotificationProvider2) => {
  PushNotificationProvider2["INTERNAL"] = "INTERNAL";
  PushNotificationProvider2["FIREBASE"] = "FIREBASE";
  return PushNotificationProvider2;
})(PushNotificationProvider || {});
const pushNotificationProviderInternal = "INTERNAL";
const pushNotificationProviderFirebase = "FIREBASE";
var PwaCookieFixMode = /* @__PURE__ */ ((PwaCookieFixMode2) => {
  PwaCookieFixMode2["AUTO"] = "AUTO";
  PwaCookieFixMode2["REDIRECT"] = "REDIRECT";
  PwaCookieFixMode2["OPENTAB"] = "OPENTAB";
  PwaCookieFixMode2["OFF"] = "OFF";
  return PwaCookieFixMode2;
})(PwaCookieFixMode || {});
const pwaCookieFixModeAuto = "AUTO";
const pwaCookieFixModeRedirect = "REDIRECT";
const pwaCookieFixModeOpentab = "OPENTAB";
const pwaCookieFixModeOff = "OFF";
var RateLimiterExceptionCode = /* @__PURE__ */ ((RateLimiterExceptionCode2) => {
  RateLimiterExceptionCode2["RATE_LIMITER"] = "RATE_LIMITER";
  return RateLimiterExceptionCode2;
})(RateLimiterExceptionCode || {});
const rateLimiterExceptionCodeRateLimiter = "RATE_LIMITER";
var Rating = /* @__PURE__ */ ((Rating2) => {
  Rating2["POSITIVE"] = "POSITIVE";
  Rating2["NEGATIVE"] = "NEGATIVE";
  return Rating2;
})(Rating || {});
const ratingPositive = "POSITIVE";
const ratingNegative = "NEGATIVE";
var RecaptchaTheme = /* @__PURE__ */ ((RecaptchaTheme2) => {
  RecaptchaTheme2["DARK"] = "DARK";
  RecaptchaTheme2["LIGHT"] = "LIGHT";
  RecaptchaTheme2["UI_INVERT"] = "UI_INVERT";
  RecaptchaTheme2["UI_MATCH"] = "UI_MATCH";
  return RecaptchaTheme2;
})(RecaptchaTheme || {});
const recaptchaThemeDark = "DARK";
const recaptchaThemeLight = "LIGHT";
const recaptchaThemeUiInvert = "UI_INVERT";
const recaptchaThemeUiMatch = "UI_MATCH";
var RefProgramNotificationType = /* @__PURE__ */ ((RefProgramNotificationType2) => {
  RefProgramNotificationType2["PROGRAM_AVAILABLE"] = "PROGRAM_AVAILABLE";
  RefProgramNotificationType2["PROGRAM_UNAVAILABLE"] = "PROGRAM_UNAVAILABLE";
  RefProgramNotificationType2["REFERRER_REQUIREMENT_SATISFIED"] = "REFERRER_REQUIREMENT_SATISFIED";
  RefProgramNotificationType2["REFERRER_REWARD_ASSIGNED"] = "REFERRER_REWARD_ASSIGNED";
  RefProgramNotificationType2["REFERRAL_REQUIREMENT_SATISFIED"] = "REFERRAL_REQUIREMENT_SATISFIED";
  RefProgramNotificationType2["REFERRAL_REWARD_ASSIGNED"] = "REFERRAL_REWARD_ASSIGNED";
  RefProgramNotificationType2["REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION"] = "REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION";
  RefProgramNotificationType2["BONUS_ELIGIBILITY_RULES"] = "BONUS_ELIGIBILITY_RULES";
  return RefProgramNotificationType2;
})(RefProgramNotificationType || {});
const refProgramNotificationTypeProgramAvailable = "PROGRAM_AVAILABLE";
const refProgramNotificationTypeProgramUnavailable = "PROGRAM_UNAVAILABLE";
const refProgramNotificationTypeReferrerRequirementSatisfied = "REFERRER_REQUIREMENT_SATISFIED";
const refProgramNotificationTypeReferrerRewardAssigned = "REFERRER_REWARD_ASSIGNED";
const refProgramNotificationTypeReferralRequirementSatisfied = "REFERRAL_REQUIREMENT_SATISFIED";
const refProgramNotificationTypeReferralRewardAssigned = "REFERRAL_REWARD_ASSIGNED";
const refProgramNotificationTypeReferralRewardAssignedReferrerNotification = "REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION";
const refProgramNotificationTypeBonusEligibilityRules = "BONUS_ELIGIBILITY_RULES";
var RefuseBonusType = /* @__PURE__ */ ((RefuseBonusType2) => {
  RefuseBonusType2["NONE"] = "NONE";
  RefuseBonusType2["ONLY_WRITEOFF"] = "ONLY_WRITEOFF";
  RefuseBonusType2["WRITEOFF_AND_DEPOSIT_RETURN"] = "WRITEOFF_AND_DEPOSIT_RETURN";
  RefuseBonusType2["FREEBET_CANCEL"] = "FREEBET_CANCEL";
  RefuseBonusType2["FREESPIN_CANCEL"] = "FREESPIN_CANCEL";
  return RefuseBonusType2;
})(RefuseBonusType || {});
const refuseBonusTypeNone = "NONE";
const refuseBonusTypeOnlyWriteoff = "ONLY_WRITEOFF";
const refuseBonusTypeWriteoffAndDepositReturn = "WRITEOFF_AND_DEPOSIT_RETURN";
const refuseBonusTypeFreebetCancel = "FREEBET_CANCEL";
const refuseBonusTypeFreespinCancel = "FREESPIN_CANCEL";
var RegistrationStateExceptionCode = /* @__PURE__ */ ((RegistrationStateExceptionCode2) => {
  RegistrationStateExceptionCode2["REGISTRATION_STATE_ERROR"] = "REGISTRATION_STATE_ERROR";
  return RegistrationStateExceptionCode2;
})(RegistrationStateExceptionCode || {});
const registrationStateExceptionCodeRegistrationStateError = "REGISTRATION_STATE_ERROR";
var RemoteApiErrorExceptionCode = /* @__PURE__ */ ((RemoteApiErrorExceptionCode2) => {
  RemoteApiErrorExceptionCode2["INVALID_CAPTCHA"] = "INVALID_CAPTCHA";
  RemoteApiErrorExceptionCode2["CAPTCHA_NEEDED"] = "CAPTCHA_NEEDED";
  RemoteApiErrorExceptionCode2["INVALID_PHONE_NUMBER"] = "INVALID_PHONE_NUMBER";
  RemoteApiErrorExceptionCode2["STREAM_NOT_AVAILABLE"] = "STREAM_NOT_AVAILABLE";
  RemoteApiErrorExceptionCode2["PROMOTION_NOT_FOUND"] = "PROMOTION_NOT_FOUND";
  RemoteApiErrorExceptionCode2["EXPIRED"] = "EXPIRED";
  RemoteApiErrorExceptionCode2["PIN_CODE_IS_BANNED"] = "PIN_CODE_IS_BANNED";
  RemoteApiErrorExceptionCode2["SERVICE_SUSPENDED"] = "SERVICE_SUSPENDED";
  RemoteApiErrorExceptionCode2["CUSTOMER_HISTORY_LIMIT_EXCEEDED"] = "CUSTOMER_HISTORY_LIMIT_EXCEEDED";
  RemoteApiErrorExceptionCode2["WITHDRAWAL_AMOUNT_EXCEEDED"] = "WITHDRAWAL_AMOUNT_EXCEEDED";
  return RemoteApiErrorExceptionCode2;
})(RemoteApiErrorExceptionCode || {});
const remoteApiErrorExceptionCodeInvalidCaptcha = "INVALID_CAPTCHA";
const remoteApiErrorExceptionCodeCaptchaNeeded = "CAPTCHA_NEEDED";
const remoteApiErrorExceptionCodeInvalidPhoneNumber = "INVALID_PHONE_NUMBER";
const remoteApiErrorExceptionCodeStreamNotAvailable = "STREAM_NOT_AVAILABLE";
const remoteApiErrorExceptionCodePromotionNotFound = "PROMOTION_NOT_FOUND";
const remoteApiErrorExceptionCodeExpired = "EXPIRED";
const remoteApiErrorExceptionCodePinCodeIsBanned = "PIN_CODE_IS_BANNED";
const remoteApiErrorExceptionCodeServiceSuspended = "SERVICE_SUSPENDED";
const remoteApiErrorExceptionCodeCustomerHistoryLimitExceeded = "CUSTOMER_HISTORY_LIMIT_EXCEEDED";
const remoteApiErrorExceptionCodeWithdrawalAmountExceeded = "WITHDRAWAL_AMOUNT_EXCEEDED";
var RequestHashingDisabledExceptionCode = /* @__PURE__ */ ((RequestHashingDisabledExceptionCode2) => {
  RequestHashingDisabledExceptionCode2["REQUEST_HASHING_IS_DISABLED"] = "REQUEST_HASHING_IS_DISABLED";
  return RequestHashingDisabledExceptionCode2;
})(RequestHashingDisabledExceptionCode || {});
const requestHashingDisabledExceptionCodeRequestHashingIsDisabled = "REQUEST_HASHING_IS_DISABLED";
var RequestHeader = /* @__PURE__ */ ((RequestHeader2) => {
  RequestHeader2["X_APP_PLATFORM"] = "X_APP_PLATFORM";
  RequestHeader2["X_APP_RENDERING"] = "X_APP_RENDERING";
  RequestHeader2["X_APP_MODERNITY"] = "X_APP_MODERNITY";
  RequestHeader2["X_APP_ENV"] = "X_APP_ENV";
  RequestHeader2["X_APP_SKIN"] = "X_APP_SKIN";
  RequestHeader2["X_APP_OS"] = "X_APP_OS";
  RequestHeader2["X_APP_LAYOUT"] = "X_APP_LAYOUT";
  RequestHeader2["X_APP_BROWSER"] = "X_APP_BROWSER";
  RequestHeader2["X_APP_THEME"] = "X_APP_THEME";
  RequestHeader2["X_APP_VERSION"] = "X_APP_VERSION";
  RequestHeader2["X_APP_LANGUAGE"] = "X_APP_LANGUAGE";
  RequestHeader2["X_APP_PREFERRED_LANG"] = "X_APP_PREFERRED_LANG";
  RequestHeader2["X_APP_REFERRER"] = "X_APP_REFERRER";
  RequestHeader2["X_APP_BUILD_ID"] = "X_APP_BUILD_ID";
  RequestHeader2["X_APP_STORE"] = "X_APP_STORE";
  RequestHeader2["X_APP_DEBUG"] = "X_APP_DEBUG";
  RequestHeader2["X_WM"] = "X_WM";
  RequestHeader2["X_SUBID"] = "X_SUBID";
  RequestHeader2["X_SUBID2"] = "X_SUBID2";
  RequestHeader2["X_SUBID3"] = "X_SUBID3";
  RequestHeader2["X_SUBID4"] = "X_SUBID4";
  RequestHeader2["X_SUBID5"] = "X_SUBID5";
  RequestHeader2["X_CLICK_ID"] = "X_CLICK_ID";
  RequestHeader2["X_WMZONE"] = "X_WMZONE";
  RequestHeader2["X_BTAG"] = "X_BTAG";
  RequestHeader2["X_IABTAG"] = "X_IABTAG";
  RequestHeader2["X_IALOCALE"] = "X_IALOCALE";
  RequestHeader2["X_LOCALE"] = "X_LOCALE";
  RequestHeader2["X_QTAG"] = "X_QTAG";
  RequestHeader2["X_REFTAG"] = "X_REFTAG";
  RequestHeader2["X_RETENTION_ID"] = "X_RETENTION_ID";
  RequestHeader2["X_UTM_SOURCE"] = "X_UTM_SOURCE";
  RequestHeader2["X_UTM_MEDIUM"] = "X_UTM_MEDIUM";
  RequestHeader2["X_UTM_CAMPAIGN"] = "X_UTM_CAMPAIGN";
  RequestHeader2["X_UTM_TERM"] = "X_UTM_TERM";
  RequestHeader2["X_UTM_CONTENT"] = "X_UTM_CONTENT";
  return RequestHeader2;
})(RequestHeader || {});
const requestHeaderXAppPlatform = "X_APP_PLATFORM";
const requestHeaderXAppRendering = "X_APP_RENDERING";
const requestHeaderXAppModernity = "X_APP_MODERNITY";
const requestHeaderXAppEnv = "X_APP_ENV";
const requestHeaderXAppSkin = "X_APP_SKIN";
const requestHeaderXAppOs = "X_APP_OS";
const requestHeaderXAppLayout = "X_APP_LAYOUT";
const requestHeaderXAppBrowser = "X_APP_BROWSER";
const requestHeaderXAppTheme = "X_APP_THEME";
const requestHeaderXAppVersion = "X_APP_VERSION";
const requestHeaderXAppLanguage = "X_APP_LANGUAGE";
const requestHeaderXAppPreferredLang = "X_APP_PREFERRED_LANG";
const requestHeaderXAppReferrer = "X_APP_REFERRER";
const requestHeaderXAppBuildId = "X_APP_BUILD_ID";
const requestHeaderXAppStore = "X_APP_STORE";
const requestHeaderXAppDebug = "X_APP_DEBUG";
const requestHeaderXWm = "X_WM";
const requestHeaderXSubid = "X_SUBID";
const requestHeaderXSubid2 = "X_SUBID2";
const requestHeaderXSubid3 = "X_SUBID3";
const requestHeaderXSubid4 = "X_SUBID4";
const requestHeaderXSubid5 = "X_SUBID5";
const requestHeaderXClickId = "X_CLICK_ID";
const requestHeaderXWmzone = "X_WMZONE";
const requestHeaderXBtag = "X_BTAG";
const requestHeaderXIabtag = "X_IABTAG";
const requestHeaderXIalocale = "X_IALOCALE";
const requestHeaderXLocale = "X_LOCALE";
const requestHeaderXQtag = "X_QTAG";
const requestHeaderXReftag = "X_REFTAG";
const requestHeaderXRetentionId = "X_RETENTION_ID";
const requestHeaderXUtmSource = "X_UTM_SOURCE";
const requestHeaderXUtmMedium = "X_UTM_MEDIUM";
const requestHeaderXUtmCampaign = "X_UTM_CAMPAIGN";
const requestHeaderXUtmTerm = "X_UTM_TERM";
const requestHeaderXUtmContent = "X_UTM_CONTENT";
var RequirementType = /* @__PURE__ */ ((RequirementType2) => {
  RequirementType2["DEPOSIT"] = "DEPOSIT";
  RequirementType2["BET_COUNT"] = "BET_COUNT";
  RequirementType2["BET_AMOUNT"] = "BET_AMOUNT";
  RequirementType2["IDENTIFICATION"] = "IDENTIFICATION";
  RequirementType2["CLOSE_ACTIVE_BONUS"] = "CLOSE_ACTIVE_BONUS";
  RequirementType2["CONFIRMATION"] = "CONFIRMATION";
  RequirementType2["BONUS_CODE_CONFIRMATION"] = "BONUS_CODE_CONFIRMATION";
  RequirementType2["DEPOSIT_BONUS_CODE"] = "DEPOSIT_BONUS_CODE";
  RequirementType2["COMPLEX"] = "COMPLEX";
  return RequirementType2;
})(RequirementType || {});
const requirementTypeDeposit = "DEPOSIT";
const requirementTypeBetCount = "BET_COUNT";
const requirementTypeBetAmount = "BET_AMOUNT";
const requirementTypeIdentification = "IDENTIFICATION";
const requirementTypeCloseActiveBonus = "CLOSE_ACTIVE_BONUS";
const requirementTypeConfirmation = "CONFIRMATION";
const requirementTypeBonusCodeConfirmation = "BONUS_CODE_CONFIRMATION";
const requirementTypeDepositBonusCode = "DEPOSIT_BONUS_CODE";
const requirementTypeComplex = "COMPLEX";
var RequirementValueType = /* @__PURE__ */ ((RequirementValueType2) => {
  RequirementValueType2["MONEY"] = "MONEY";
  RequirementValueType2["COUNT"] = "COUNT";
  return RequirementValueType2;
})(RequirementValueType || {});
const requirementValueTypeMoney = "MONEY";
const requirementValueTypeCount = "COUNT";
var ReserveMode = /* @__PURE__ */ ((ReserveMode2) => {
  ReserveMode2["IFRAME"] = "IFRAME";
  ReserveMode2["REDIRECT"] = "REDIRECT";
  return ReserveMode2;
})(ReserveMode || {});
const reserveModeIframe = "IFRAME";
const reserveModeRedirect = "REDIRECT";
const responsibleGamingLimitTypePlayTimeReminder = "PLAY_TIME_REMINDER";
const responsibleGamingLimitTypeDepositLimit = "DEPOSIT_LIMIT";
const responsibleGamingLimitTypeBetLimit = "BET_LIMIT";
const responsibleGamingLimitTypeSelfExclusion = "SELF_EXCLUSION";
const rewardCenterIdAchievements = "ACHIEVEMENTS";
const rewardCenterIdWheelOfFortune = "WHEEL_OF_FORTUNE";
const rewardCenterIdMyBonuses = "MY_BONUSES";
const rewardCenterIdCashback = "CASHBACK";
const rewardCenterIdReferralProgram = "REFERRAL_PROGRAM";
const rewardCenterIdPromotions = "PROMOTIONS";
const rewardCenterIdBonusCode = "BONUS_CODE";
const rewardCenterIdEgsLevel = "EGS_LEVEL";
const rewardCenterIdLoyaltyProgram = "LOYALTY_PROGRAM";
const runnerTagYes = "YES";
const runnerTagNo = "NO";
const runnerTagUnder = "UNDER";
const runnerTagOver = "OVER";
const runnerTagOdd = "ODD";
const runnerTagEven = "EVEN";
const runnerTagHome = "HOME";
const runnerTagDraw = "DRAW";
const runnerTagAway = "AWAY";
const runnerTagNeither = "NEITHER";
const runnerTagBoth = "BOTH";
const runnerTagCompetitor = "COMPETITOR";
const runnerTagRange = "RANGE";
const runnerTagScore = "SCORE";
const runnerTagOther = "OTHER";
const runnerTagHomedraw = "HOMEDRAW";
const runnerTagHomeaway = "HOMEAWAY";
const runnerTagDrawaway = "DRAWAWAY";
const scaledRatingStar1 = "STAR_1";
const scaledRatingStar2 = "STAR_2";
const scaledRatingStar3 = "STAR_3";
const scaledRatingStar4 = "STAR_4";
const scaledRatingStar5 = "STAR_5";
var SchemaValidationExceptionCode = /* @__PURE__ */ ((SchemaValidationExceptionCode2) => {
  SchemaValidationExceptionCode2["SCHEMA_VALIDATION_ERROR"] = "SCHEMA_VALIDATION_ERROR";
  return SchemaValidationExceptionCode2;
})(SchemaValidationExceptionCode || {});
const schemaValidationExceptionCodeSchemaValidationError = "SCHEMA_VALIDATION_ERROR";
var SelfExclusionTimeUnitType = /* @__PURE__ */ ((SelfExclusionTimeUnitType2) => {
  SelfExclusionTimeUnitType2["MONTHS"] = "MONTHS";
  SelfExclusionTimeUnitType2["HOURS"] = "HOURS";
  return SelfExclusionTimeUnitType2;
})(SelfExclusionTimeUnitType || {});
const selfExclusionTimeUnitTypeMonths = "MONTHS";
const selfExclusionTimeUnitTypeHours = "HOURS";
var SessionExceptionCode = /* @__PURE__ */ ((SessionExceptionCode2) => {
  SessionExceptionCode2["SESSION"] = "SESSION";
  return SessionExceptionCode2;
})(SessionExceptionCode || {});
const sessionExceptionCodeSession = "SESSION";
var ShopOrderStatus = /* @__PURE__ */ ((ShopOrderStatus2) => {
  ShopOrderStatus2["CREATED"] = "CREATED";
  ShopOrderStatus2["IN_PROGRESS"] = "IN_PROGRESS";
  ShopOrderStatus2["DONE"] = "DONE";
  ShopOrderStatus2["CANCELLED"] = "CANCELLED";
  return ShopOrderStatus2;
})(ShopOrderStatus || {});
const shopOrderStatusCreated = "CREATED";
const shopOrderStatusInProgress = "IN_PROGRESS";
const shopOrderStatusDone = "DONE";
const shopOrderStatusCancelled = "CANCELLED";
var SimpleIdentMethod = /* @__PURE__ */ ((SimpleIdentMethod2) => {
  SimpleIdentMethod2["ESIA"] = "ESIA";
  SimpleIdentMethod2["MANUAL"] = "MANUAL";
  SimpleIdentMethod2["UPLOAD"] = "UPLOAD";
  SimpleIdentMethod2["TINKOFF_ID"] = "TINKOFF_ID";
  SimpleIdentMethod2["ESIA_V2"] = "ESIA_V2";
  SimpleIdentMethod2["SBER_ID"] = "SBER_ID";
  SimpleIdentMethod2["MANUAL_VERIFICATION"] = "MANUAL_VERIFICATION";
  return SimpleIdentMethod2;
})(SimpleIdentMethod || {});
const simpleIdentMethodEsia = "ESIA";
const simpleIdentMethodManual = "MANUAL";
const simpleIdentMethodUpload = "UPLOAD";
const simpleIdentMethodTinkoffId = "TINKOFF_ID";
const simpleIdentMethodEsiaV2 = "ESIA_V2";
const simpleIdentMethodSberId = "SBER_ID";
const simpleIdentMethodManualVerification = "MANUAL_VERIFICATION";
var SlipStatus = /* @__PURE__ */ ((SlipStatus2) => {
  SlipStatus2["OK"] = "OK";
  SlipStatus2["MAX_LIMIT"] = "MAX_LIMIT";
  SlipStatus2["EMPTY"] = "EMPTY";
  SlipStatus2["MARKETS_CHANGED"] = "MARKETS_CHANGED";
  SlipStatus2["MANY_ENTRIES"] = "MANY_ENTRIES";
  SlipStatus2["ODDS_LIMIT"] = "ODDS_LIMIT";
  SlipStatus2["INCOMPATIBLE_BETLINES"] = "INCOMPATIBLE_BETLINES";
  SlipStatus2["INCOMPATIBLE_SPORTS"] = "INCOMPATIBLE_SPORTS";
  SlipStatus2["INCOMPATIBLE_LEAGUES"] = "INCOMPATIBLE_LEAGUES";
  SlipStatus2["DUPLICATE_SPORT"] = "DUPLICATE_SPORT";
  SlipStatus2["DUPLICATE_LEAGUE"] = "DUPLICATE_LEAGUE";
  SlipStatus2["DUPLICATE_EVENT"] = "DUPLICATE_EVENT";
  SlipStatus2["DUPLICATE_COMPETITOR"] = "DUPLICATE_COMPETITOR";
  SlipStatus2["SINGLE_BETTING_ONLY"] = "SINGLE_BETTING_ONLY";
  return SlipStatus2;
})(SlipStatus || {});
const slipStatusOk = "OK";
const slipStatusMaxLimit = "MAX_LIMIT";
const slipStatusEmpty = "EMPTY";
const slipStatusMarketsChanged = "MARKETS_CHANGED";
const slipStatusManyEntries = "MANY_ENTRIES";
const slipStatusOddsLimit = "ODDS_LIMIT";
const slipStatusIncompatibleBetlines = "INCOMPATIBLE_BETLINES";
const slipStatusIncompatibleSports = "INCOMPATIBLE_SPORTS";
const slipStatusIncompatibleLeagues = "INCOMPATIBLE_LEAGUES";
const slipStatusDuplicateSport = "DUPLICATE_SPORT";
const slipStatusDuplicateLeague = "DUPLICATE_LEAGUE";
const slipStatusDuplicateEvent = "DUPLICATE_EVENT";
const slipStatusDuplicateCompetitor = "DUPLICATE_COMPETITOR";
const slipStatusSingleBettingOnly = "SINGLE_BETTING_ONLY";
var SlipTypeId = /* @__PURE__ */ ((SlipTypeId2) => {
  SlipTypeId2["SINGLE"] = "SINGLE";
  SlipTypeId2["EXPRESS"] = "EXPRESS";
  SlipTypeId2["SYSTEM"] = "SYSTEM";
  return SlipTypeId2;
})(SlipTypeId || {});
const slipTypeIdSingle = "SINGLE";
const slipTypeIdExpress = "EXPRESS";
const slipTypeIdSystem = "SYSTEM";
var SportBetStatus = /* @__PURE__ */ ((SportBetStatus2) => {
  SportBetStatus2["PLACED"] = "PLACED";
  SportBetStatus2["WON"] = "WON";
  SportBetStatus2["LOST"] = "LOST";
  SportBetStatus2["CASHED_OUT"] = "CASHED_OUT";
  SportBetStatus2["VOIDED"] = "VOIDED";
  return SportBetStatus2;
})(SportBetStatus || {});
const sportBetStatusPlaced = "PLACED";
const sportBetStatusWon = "WON";
const sportBetStatusLost = "LOST";
const sportBetStatusCashedOut = "CASHED_OUT";
const sportBetStatusVoided = "VOIDED";
var SportBonusState = /* @__PURE__ */ ((SportBonusState2) => {
  SportBonusState2["ACTIVE"] = "ACTIVE";
  SportBonusState2["CLOSED"] = "CLOSED";
  return SportBonusState2;
})(SportBonusState || {});
const sportBonusStateActive = "ACTIVE";
const sportBonusStateClosed = "CLOSED";
var SportbookDataType = /* @__PURE__ */ ((SportbookDataType2) => {
  SportbookDataType2["SPORT_BET"] = "SPORT_BET";
  SportbookDataType2["FAST_BET"] = "FAST_BET";
  return SportbookDataType2;
})(SportbookDataType || {});
const sportbookDataTypeSportBet = "SPORT_BET";
const sportbookDataTypeFastBet = "FAST_BET";
var SportFamily = /* @__PURE__ */ ((SportFamily2) => {
  SportFamily2["UNKNOWN"] = "UNKNOWN";
  SportFamily2["Soccer"] = "Soccer";
  SportFamily2["Basketball"] = "Basketball";
  SportFamily2["Baseball"] = "Baseball";
  SportFamily2["IceHockey"] = "IceHockey";
  SportFamily2["Tennis"] = "Tennis";
  SportFamily2["Handball"] = "Handball";
  SportFamily2["Floorball"] = "Floorball";
  SportFamily2["Golf"] = "Golf";
  SportFamily2["Boxing"] = "Boxing";
  SportFamily2["Motorsport"] = "Motorsport";
  SportFamily2["Rugby"] = "Rugby";
  SportFamily2["AustralianRules"] = "AustralianRules";
  SportFamily2["WinterSports"] = "WinterSports";
  SportFamily2["Bandy"] = "Bandy";
  SportFamily2["AmericanFootball"] = "AmericanFootball";
  SportFamily2["Cycling"] = "Cycling";
  SportFamily2["Specials"] = "Specials";
  SportFamily2["Snooker"] = "Snooker";
  SportFamily2["TableTennis"] = "TableTennis";
  SportFamily2["Cricket"] = "Cricket";
  SportFamily2["Darts"] = "Darts";
  SportFamily2["Volleyball"] = "Volleyball";
  SportFamily2["FieldHockey"] = "FieldHockey";
  SportFamily2["WaterPolo"] = "WaterPolo";
  SportFamily2["Curling"] = "Curling";
  SportFamily2["Futsal"] = "Futsal";
  SportFamily2["Olympics"] = "Olympics";
  SportFamily2["Badminton"] = "Badminton";
  SportFamily2["Bowls"] = "Bowls";
  SportFamily2["GaelicFootball"] = "GaelicFootball";
  SportFamily2["BeachVolleyball"] = "BeachVolleyball";
  SportFamily2["Athletics"] = "Athletics";
  SportFamily2["Squash"] = "Squash";
  SportFamily2["BeachSoccer"] = "BeachSoccer";
  SportFamily2["Pesapallo"] = "Pesapallo";
  SportFamily2["Sailing"] = "Sailing";
  SportFamily2["WorldLottery"] = "WorldLottery";
  SportFamily2["ESport"] = "ESport";
  SportFamily2["MMA"] = "MMA";
  SportFamily2["SoccerMythical"] = "SoccerMythical";
  SportFamily2["VirtualSoccer"] = "VirtualSoccer";
  SportFamily2["VirtualSoccerV2"] = "VirtualSoccerV2";
  SportFamily2["VirtualBasketball"] = "VirtualBasketball";
  SportFamily2["VirtualTennis"] = "VirtualTennis";
  SportFamily2["VirtualTennisInplay"] = "VirtualTennisInplay";
  SportFamily2["VirtualCricket"] = "VirtualCricket";
  SportFamily2["AlpineSkiing"] = "AlpineSkiing";
  SportFamily2["SkiJumping"] = "SkiJumping";
  SportFamily2["Biathlon"] = "Biathlon";
  SportFamily2["CrossCountrySkiing"] = "CrossCountrySkiing";
  SportFamily2["FormulaE"] = "FormulaE";
  SportFamily2["HorseRacing"] = "HorseRacing";
  SportFamily2["NordicCombined"] = "NordicCombined";
  SportFamily2["Bobsleigh"] = "Bobsleigh";
  SportFamily2["Luge"] = "Luge";
  SportFamily2["Skeleton"] = "Skeleton";
  SportFamily2["FreestyleSkiing"] = "FreestyleSkiing";
  SportFamily2["ShortTrackSpeedSkating"] = "ShortTrackSpeedSkating";
  SportFamily2["SpeedSkating"] = "SpeedSkating";
  SportFamily2["FigureSkating"] = "FigureSkating";
  SportFamily2["Snowboard"] = "Snowboard";
  SportFamily2["Miscellaneous"] = "Miscellaneous";
  SportFamily2["RollerHockey"] = "RollerHockey";
  SportFamily2["Archery"] = "Archery";
  SportFamily2["Diving"] = "Diving";
  SportFamily2["Equestrian"] = "Equestrian";
  SportFamily2["Fencing"] = "Fencing";
  SportFamily2["Judo"] = "Judo";
  SportFamily2["ModernPentathlon"] = "ModernPentathlon";
  SportFamily2["Canoe"] = "Canoe";
  SportFamily2["Gymnastics"] = "Gymnastics";
  SportFamily2["Rowing"] = "Rowing";
  SportFamily2["Shooting"] = "Shooting";
  SportFamily2["SwimmingSynchronised"] = "SwimmingSynchronised";
  SportFamily2["Swimming"] = "Swimming";
  SportFamily2["Taekwondo"] = "Taekwondo";
  SportFamily2["Trampoline"] = "Trampoline";
  SportFamily2["Triathlon"] = "Triathlon";
  SportFamily2["Weightlifting"] = "Weightlifting";
  SportFamily2["Wrestling"] = "Wrestling";
  SportFamily2["GaelicHurling"] = "GaelicHurling";
  SportFamily2["Pool"] = "Pool";
  SportFamily2["Chess"] = "Chess";
  SportFamily2["GreyhoundRacing"] = "GreyhoundRacing";
  SportFamily2["HarnessRacing"] = "HarnessRacing";
  SportFamily2["Politics"] = "Politics";
  SportFamily2["Entertainment"] = "Entertainment";
  SportFamily2["RinkHockey"] = "RinkHockey";
  SportFamily2["Kabaddi"] = "Kabaddi";
  SportFamily2["RugbyLeague"] = "RugbyLeague";
  SportFamily2["RugbyUnion"] = "RugbyUnion";
  SportFamily2["Basketball3x3"] = "Basketball3x3";
  SportFamily2["Canoeing"] = "Canoeing";
  SportFamily2["MarathonSwimming"] = "MarathonSwimming";
  SportFamily2["ModernPenthatlon"] = "ModernPenthatlon";
  SportFamily2["Skateboarding"] = "Skateboarding";
  SportFamily2["Softball"] = "Softball";
  SportFamily2["Surfing"] = "Surfing";
  SportFamily2["Lacrosse"] = "Lacrosse";
  SportFamily2["Padel"] = "Padel";
  return SportFamily2;
})(SportFamily || {});
const sportFamilyUnknown = "UNKNOWN";
const sportFamilySoccer = "Soccer";
const sportFamilyBasketball = "Basketball";
const sportFamilyBaseball = "Baseball";
const sportFamilyIceHockey = "IceHockey";
const sportFamilyTennis = "Tennis";
const sportFamilyHandball = "Handball";
const sportFamilyFloorball = "Floorball";
const sportFamilyGolf = "Golf";
const sportFamilyBoxing = "Boxing";
const sportFamilyMotorsport = "Motorsport";
const sportFamilyRugby = "Rugby";
const sportFamilyAustralianRules = "AustralianRules";
const sportFamilyWinterSports = "WinterSports";
const sportFamilyBandy = "Bandy";
const sportFamilyAmericanFootball = "AmericanFootball";
const sportFamilyCycling = "Cycling";
const sportFamilySpecials = "Specials";
const sportFamilySnooker = "Snooker";
const sportFamilyTableTennis = "TableTennis";
const sportFamilyCricket = "Cricket";
const sportFamilyDarts = "Darts";
const sportFamilyVolleyball = "Volleyball";
const sportFamilyFieldHockey = "FieldHockey";
const sportFamilyWaterPolo = "WaterPolo";
const sportFamilyCurling = "Curling";
const sportFamilyFutsal = "Futsal";
const sportFamilyOlympics = "Olympics";
const sportFamilyBadminton = "Badminton";
const sportFamilyBowls = "Bowls";
const sportFamilyGaelicFootball = "GaelicFootball";
const sportFamilyBeachVolleyball = "BeachVolleyball";
const sportFamilyAthletics = "Athletics";
const sportFamilySquash = "Squash";
const sportFamilyBeachSoccer = "BeachSoccer";
const sportFamilyPesapallo = "Pesapallo";
const sportFamilySailing = "Sailing";
const sportFamilyWorldLottery = "WorldLottery";
const sportFamilyESport = "ESport";
const sportFamilyMma = "MMA";
const sportFamilySoccerMythical = "SoccerMythical";
const sportFamilyVirtualSoccer = "VirtualSoccer";
const sportFamilyVirtualSoccerV2 = "VirtualSoccerV2";
const sportFamilyVirtualBasketball = "VirtualBasketball";
const sportFamilyVirtualTennis = "VirtualTennis";
const sportFamilyVirtualTennisInplay = "VirtualTennisInplay";
const sportFamilyVirtualCricket = "VirtualCricket";
const sportFamilyAlpineSkiing = "AlpineSkiing";
const sportFamilySkiJumping = "SkiJumping";
const sportFamilyBiathlon = "Biathlon";
const sportFamilyCrossCountrySkiing = "CrossCountrySkiing";
const sportFamilyFormulaE = "FormulaE";
const sportFamilyHorseRacing = "HorseRacing";
const sportFamilyNordicCombined = "NordicCombined";
const sportFamilyBobsleigh = "Bobsleigh";
const sportFamilyLuge = "Luge";
const sportFamilySkeleton = "Skeleton";
const sportFamilyFreestyleSkiing = "FreestyleSkiing";
const sportFamilyShortTrackSpeedSkating = "ShortTrackSpeedSkating";
const sportFamilySpeedSkating = "SpeedSkating";
const sportFamilyFigureSkating = "FigureSkating";
const sportFamilySnowboard = "Snowboard";
const sportFamilyMiscellaneous = "Miscellaneous";
const sportFamilyRollerHockey = "RollerHockey";
const sportFamilyArchery = "Archery";
const sportFamilyDiving = "Diving";
const sportFamilyEquestrian = "Equestrian";
const sportFamilyFencing = "Fencing";
const sportFamilyJudo = "Judo";
const sportFamilyModernPentathlon = "ModernPentathlon";
const sportFamilyCanoe = "Canoe";
const sportFamilyGymnastics = "Gymnastics";
const sportFamilyRowing = "Rowing";
const sportFamilyShooting = "Shooting";
const sportFamilySwimmingSynchronised = "SwimmingSynchronised";
const sportFamilySwimming = "Swimming";
const sportFamilyTaekwondo = "Taekwondo";
const sportFamilyTrampoline = "Trampoline";
const sportFamilyTriathlon = "Triathlon";
const sportFamilyWeightlifting = "Weightlifting";
const sportFamilyWrestling = "Wrestling";
const sportFamilyGaelicHurling = "GaelicHurling";
const sportFamilyPool = "Pool";
const sportFamilyChess = "Chess";
const sportFamilyGreyhoundRacing = "GreyhoundRacing";
const sportFamilyHarnessRacing = "HarnessRacing";
const sportFamilyPolitics = "Politics";
const sportFamilyEntertainment = "Entertainment";
const sportFamilyRinkHockey = "RinkHockey";
const sportFamilyKabaddi = "Kabaddi";
const sportFamilyRugbyLeague = "RugbyLeague";
const sportFamilyRugbyUnion = "RugbyUnion";
const sportFamilyBasketball3x3 = "Basketball3x3";
const sportFamilyCanoeing = "Canoeing";
const sportFamilyMarathonSwimming = "MarathonSwimming";
const sportFamilyModernPenthatlon = "ModernPenthatlon";
const sportFamilySkateboarding = "Skateboarding";
const sportFamilySoftball = "Softball";
const sportFamilySurfing = "Surfing";
const sportFamilyLacrosse = "Lacrosse";
const sportFamilyPadel = "Padel";
var SportFilterKickoffPeriod = /* @__PURE__ */ ((SportFilterKickoffPeriod2) => {
  SportFilterKickoffPeriod2["ALL"] = "ALL";
  SportFilterKickoffPeriod2["TODAY"] = "TODAY";
  SportFilterKickoffPeriod2["TOMORROW"] = "TOMORROW";
  SportFilterKickoffPeriod2["AFTERTOMORROW"] = "AFTERTOMORROW";
  SportFilterKickoffPeriod2["CUSTOM"] = "CUSTOM";
  SportFilterKickoffPeriod2["ONEHOUR"] = "ONEHOUR";
  SportFilterKickoffPeriod2["TWOHOURS"] = "TWOHOURS";
  SportFilterKickoffPeriod2["THREEHOURS"] = "THREEHOURS";
  SportFilterKickoffPeriod2["SIXHOURS"] = "SIXHOURS";
  SportFilterKickoffPeriod2["TWELVEHOURS"] = "TWELVEHOURS";
  SportFilterKickoffPeriod2["TWENTYFOURHOURS"] = "TWENTYFOURHOURS";
  return SportFilterKickoffPeriod2;
})(SportFilterKickoffPeriod || {});
const sportFilterKickoffPeriodAll = "ALL";
const sportFilterKickoffPeriodToday = "TODAY";
const sportFilterKickoffPeriodTomorrow = "TOMORROW";
const sportFilterKickoffPeriodAftertomorrow = "AFTERTOMORROW";
const sportFilterKickoffPeriodCustom = "CUSTOM";
const sportFilterKickoffPeriodOnehour = "ONEHOUR";
const sportFilterKickoffPeriodTwohours = "TWOHOURS";
const sportFilterKickoffPeriodThreehours = "THREEHOURS";
const sportFilterKickoffPeriodSixhours = "SIXHOURS";
const sportFilterKickoffPeriodTwelvehours = "TWELVEHOURS";
const sportFilterKickoffPeriodTwentyfourhours = "TWENTYFOURHOURS";
var SportFreebetState = /* @__PURE__ */ ((SportFreebetState2) => {
  SportFreebetState2["ACTIVATED"] = "ACTIVATED";
  SportFreebetState2["WON"] = "WON";
  SportFreebetState2["LOST"] = "LOST";
  SportFreebetState2["LOST_EXPIRED"] = "LOST_EXPIRED";
  SportFreebetState2["LOST_REFUSED"] = "LOST_REFUSED";
  return SportFreebetState2;
})(SportFreebetState || {});
const sportFreebetStateActivated = "ACTIVATED";
const sportFreebetStateWon = "WON";
const sportFreebetStateLost = "LOST";
const sportFreebetStateLostExpired = "LOST_EXPIRED";
const sportFreebetStateLostRefused = "LOST_REFUSED";
var SportsbookUIVersion = /* @__PURE__ */ ((SportsbookUIVersion2) => {
  SportsbookUIVersion2["DEFAULT"] = "DEFAULT";
  SportsbookUIVersion2["V1"] = "V1";
  return SportsbookUIVersion2;
})(SportsbookUIVersion || {});
const sportsbookUIVersionDefault = "DEFAULT";
const sportsbookUIVersionV1 = "V1";
var SportWagerResult = /* @__PURE__ */ ((SportWagerResult2) => {
  SportWagerResult2["SUCCESSFUL"] = "SUCCESSFUL";
  SportWagerResult2["CANCELED_EXPIRED"] = "CANCELED_EXPIRED";
  SportWagerResult2["CANCELED_REFUSED"] = "CANCELED_REFUSED";
  SportWagerResult2["CANCELED_EMPTY_BALANCE"] = "CANCELED_EMPTY_BALANCE";
  SportWagerResult2["CANCELED_BY_ERROR"] = "CANCELED_BY_ERROR";
  return SportWagerResult2;
})(SportWagerResult || {});
const sportWagerResultSuccessful = "SUCCESSFUL";
const sportWagerResultCanceledExpired = "CANCELED_EXPIRED";
const sportWagerResultCanceledRefused = "CANCELED_REFUSED";
const sportWagerResultCanceledEmptyBalance = "CANCELED_EMPTY_BALANCE";
const sportWagerResultCanceledByError = "CANCELED_BY_ERROR";
var StartCodeType = /* @__PURE__ */ ((StartCodeType2) => {
  StartCodeType2["REDIRECT"] = "REDIRECT";
  StartCodeType2["HTML_INJECTION"] = "HTML_INJECTION";
  return StartCodeType2;
})(StartCodeType || {});
const startCodeTypeRedirect = "REDIRECT";
const startCodeTypeHtmlInjection = "HTML_INJECTION";
var Status = /* @__PURE__ */ ((Status2) => {
  Status2["INIT"] = "INIT";
  Status2["ERROR"] = "ERROR";
  Status2["SUCCESS"] = "SUCCESS";
  Status2["DATA_REQUESTED"] = "DATA_REQUESTED";
  Status2["WAITING"] = "WAITING";
  return Status2;
})(Status || {});
const statusInit = "INIT";
const statusError = "ERROR";
const statusSuccess = "SUCCESS";
const statusDataRequested = "DATA_REQUESTED";
const statusWaiting = "WAITING";
var StatusColor = /* @__PURE__ */ ((StatusColor2) => {
  StatusColor2["DEFAULT"] = "DEFAULT";
  StatusColor2["PENDING"] = "PENDING";
  StatusColor2["POSITIVE"] = "POSITIVE";
  StatusColor2["NEGATIVE"] = "NEGATIVE";
  StatusColor2["DEBIT_CREDIT"] = "DEBIT_CREDIT";
  return StatusColor2;
})(StatusColor || {});
const statusColorDefault = "DEFAULT";
const statusColorPending = "PENDING";
const statusColorPositive = "POSITIVE";
const statusColorNegative = "NEGATIVE";
const statusColorDebitCredit = "DEBIT_CREDIT";
var SupportType = /* @__PURE__ */ ((SupportType2) => {
  SupportType2["CHAT"] = "CHAT";
  SupportType2["CALLBACK"] = "CALLBACK";
  return SupportType2;
})(SupportType || {});
const supportTypeChat = "CHAT";
const supportTypeCallback = "CALLBACK";
var Theme = /* @__PURE__ */ ((Theme2) => {
  Theme2["LIGHT"] = "LIGHT";
  Theme2["DARK"] = "DARK";
  Theme2["AUTO"] = "AUTO";
  Theme2["MIDNIGHT"] = "MIDNIGHT";
  return Theme2;
})(Theme || {});
const themeLight = "LIGHT";
const themeDark = "DARK";
const themeAuto = "AUTO";
const themeMidnight = "MIDNIGHT";
var ThirdPartyErrorCode = /* @__PURE__ */ ((ThirdPartyErrorCode2) => {
  ThirdPartyErrorCode2["TINKOFF_ACCESS_DENIED"] = "TINKOFF_ACCESS_DENIED";
  ThirdPartyErrorCode2["SBER_UNKNOWN_PHONE_NUMBER"] = "SBER_UNKNOWN_PHONE_NUMBER";
  ThirdPartyErrorCode2["TINKOFF_UNKNOWN_PHONE_NUMBER"] = "TINKOFF_UNKNOWN_PHONE_NUMBER";
  ThirdPartyErrorCode2["VERIFICATION_FAILURE"] = "VERIFICATION_FAILURE";
  return ThirdPartyErrorCode2;
})(ThirdPartyErrorCode || {});
const thirdPartyErrorCodeTinkoffAccessDenied = "TINKOFF_ACCESS_DENIED";
const thirdPartyErrorCodeSberUnknownPhoneNumber = "SBER_UNKNOWN_PHONE_NUMBER";
const thirdPartyErrorCodeTinkoffUnknownPhoneNumber = "TINKOFF_UNKNOWN_PHONE_NUMBER";
const thirdPartyErrorCodeVerificationFailure = "VERIFICATION_FAILURE";
var TimerMode = /* @__PURE__ */ ((TimerMode2) => {
  TimerMode2["MINUTES"] = "MINUTES";
  TimerMode2["SECONDS"] = "SECONDS";
  return TimerMode2;
})(TimerMode || {});
const timerModeMinutes = "MINUTES";
const timerModeSeconds = "SECONDS";
var TopWinnersTabType = /* @__PURE__ */ ((TopWinnersTabType2) => {
  TopWinnersTabType2["EGS"] = "EGS";
  TopWinnersTabType2["SPORT"] = "SPORT";
  return TopWinnersTabType2;
})(TopWinnersTabType || {});
const topWinnersTabTypeEgs = "EGS";
const topWinnersTabTypeSport = "SPORT";
var TradingStatus = /* @__PURE__ */ ((TradingStatus2) => {
  TradingStatus2["OPEN"] = "OPEN";
  TradingStatus2["SUSPENDED"] = "SUSPENDED";
  TradingStatus2["CLOSED"] = "CLOSED";
  return TradingStatus2;
})(TradingStatus || {});
const tradingStatusOpen = "OPEN";
const tradingStatusSuspended = "SUSPENDED";
const tradingStatusClosed = "CLOSED";
var TransactionGroup = /* @__PURE__ */ ((TransactionGroup2) => {
  TransactionGroup2["BET"] = "BET";
  TransactionGroup2["PAYMENT"] = "PAYMENT";
  TransactionGroup2["LEONSHOP"] = "LEONSHOP";
  TransactionGroup2["MG"] = "MG";
  TransactionGroup2["EGSOW"] = "EGSOW";
  TransactionGroup2["EGS"] = "EGS";
  TransactionGroup2["MARKETING"] = "MARKETING";
  return TransactionGroup2;
})(TransactionGroup || {});
const transactionGroupBet = "BET";
const transactionGroupPayment = "PAYMENT";
const transactionGroupLeonshop = "LEONSHOP";
const transactionGroupMg = "MG";
const transactionGroupEgsow = "EGSOW";
const transactionGroupEgs = "EGS";
const transactionGroupMarketing = "MARKETING";
var TransactionStatusColor = /* @__PURE__ */ ((TransactionStatusColor2) => {
  TransactionStatusColor2["WHITE"] = "WHITE";
  TransactionStatusColor2["YELLOW"] = "YELLOW";
  TransactionStatusColor2["GREEN"] = "GREEN";
  TransactionStatusColor2["RED"] = "RED";
  return TransactionStatusColor2;
})(TransactionStatusColor || {});
const transactionStatusColorWhite = "WHITE";
const transactionStatusColorYellow = "YELLOW";
const transactionStatusColorGreen = "GREEN";
const transactionStatusColorRed = "RED";
var TransferType = /* @__PURE__ */ ((TransferType2) => {
  TransferType2["WIN"] = "WIN";
  TransferType2["BONUS"] = "BONUS";
  TransferType2["WIN_PLUS_BONUS"] = "WIN_PLUS_BONUS";
  return TransferType2;
})(TransferType || {});
const transferTypeWin = "WIN";
const transferTypeBonus = "BONUS";
const transferTypeWinPlusBonus = "WIN_PLUS_BONUS";
const transitionRouteSettingsMySubscriptions = "SETTINGS_MY_SUBSCRIPTIONS";
const transitionRouteSettingsPersonalInfo = "SETTINGS_PERSONAL_INFO";
const transitionRouteSettingsAccountVerification = "SETTINGS_ACCOUNT_VERIFICATION";
const transitionRouteSettingsPin = "SETTINGS_PIN";
const transitionRouteSettings2fa = "SETTINGS_2FA";
const transitionRouteDeposit = "DEPOSIT";
const transitionRouteAllSlots = "ALL_SLOTS";
const transitionRouteTournament = "TOURNAMENT";
const transitionRouteLiveCasino = "LIVE_CASINO";
const transitionRouteUnknown = "UNKNOWN";
var Tsupis1BindCustomerError = /* @__PURE__ */ ((Tsupis1BindCustomerError2) => {
  Tsupis1BindCustomerError2["TECHNICAL"] = "TECHNICAL";
  Tsupis1BindCustomerError2["OVERBOUND"] = "OVERBOUND";
  Tsupis1BindCustomerError2["ALREADY_BOUND"] = "ALREADY_BOUND";
  Tsupis1BindCustomerError2["INCORRECT_PERSONAL_DATA"] = "INCORRECT_PERSONAL_DATA";
  Tsupis1BindCustomerError2["LOCKED_CUSTOMER"] = "LOCKED_CUSTOMER";
  Tsupis1BindCustomerError2["INTERNAL"] = "INTERNAL";
  Tsupis1BindCustomerError2["TIMEOUT"] = "TIMEOUT";
  return Tsupis1BindCustomerError2;
})(Tsupis1BindCustomerError || {});
const tsupis1BindCustomerErrorTechnical = "TECHNICAL";
const tsupis1BindCustomerErrorOverbound = "OVERBOUND";
const tsupis1BindCustomerErrorAlreadyBound = "ALREADY_BOUND";
const tsupis1BindCustomerErrorIncorrectPersonalData = "INCORRECT_PERSONAL_DATA";
const tsupis1BindCustomerErrorLockedCustomer = "LOCKED_CUSTOMER";
const tsupis1BindCustomerErrorInternal = "INTERNAL";
const tsupis1BindCustomerErrorTimeout = "TIMEOUT";
var TsupisAuthResult = /* @__PURE__ */ ((TsupisAuthResult2) => {
  TsupisAuthResult2["AUTHENTICATION_SUCCESS"] = "AUTHENTICATION_SUCCESS";
  TsupisAuthResult2["APPLICATION_DISABLED"] = "APPLICATION_DISABLED";
  TsupisAuthResult2["IDENTIFICATION_REQUIRED"] = "IDENTIFICATION_REQUIRED";
  TsupisAuthResult2["SMS_CODE_INCORRECT"] = "SMS_CODE_INCORRECT";
  TsupisAuthResult2["SMS_CODE_EXPIRED"] = "SMS_CODE_EXPIRED";
  TsupisAuthResult2["PHONE_NUMBER_INCORRECT"] = "PHONE_NUMBER_INCORRECT";
  TsupisAuthResult2["MAX_ATTEMPTS_REACHED"] = "MAX_ATTEMPTS_REACHED";
  TsupisAuthResult2["ACCESS_TO_DEVICE_DISABLED"] = "ACCESS_TO_DEVICE_DISABLED";
  TsupisAuthResult2["BETTING_DISABLED"] = "BETTING_DISABLED";
  TsupisAuthResult2["USER_IS_NOT_AUTHORIZED"] = "USER_IS_NOT_AUTHORIZED";
  TsupisAuthResult2["PHONE_NUMBER_BLOCKED"] = "PHONE_NUMBER_BLOCKED";
  TsupisAuthResult2["INCORRECT_IDENT_DATA"] = "INCORRECT_IDENT_DATA";
  TsupisAuthResult2["IDENT_DATA_IS_NOT_VERIFIED"] = "IDENT_DATA_IS_NOT_VERIFIED";
  TsupisAuthResult2["PURSES_LIMIT_EXCEEDED"] = "PURSES_LIMIT_EXCEEDED";
  TsupisAuthResult2["PHONE_NUMBER_ALREADY_USED_BY_USER"] = "PHONE_NUMBER_ALREADY_USED_BY_USER";
  TsupisAuthResult2["DOCUMENT_EXPIRED"] = "DOCUMENT_EXPIRED";
  TsupisAuthResult2["INVALID_CITIZENSHIP"] = "INVALID_CITIZENSHIP";
  TsupisAuthResult2["INVALID_INN"] = "INVALID_INN";
  TsupisAuthResult2["INN_PROVIDER_IS_BUSY"] = "INN_PROVIDER_IS_BUSY";
  TsupisAuthResult2["INVALID_AUTH_DETAILS"] = "INVALID_AUTH_DETAILS";
  TsupisAuthResult2["WRONG_API_USAGE"] = "WRONG_API_USAGE";
  TsupisAuthResult2["REQUIRED_PARAMETER_IN_WRONG_FORMAT"] = "REQUIRED_PARAMETER_IN_WRONG_FORMAT";
  TsupisAuthResult2["TECH_ERROR"] = "TECH_ERROR";
  TsupisAuthResult2["UNKNOWN_ERROR"] = "UNKNOWN_ERROR";
  TsupisAuthResult2["CUSTOMER_CABIN_REGISTRATION_REQUIRED"] = "CUSTOMER_CABIN_REGISTRATION_REQUIRED";
  TsupisAuthResult2["CUSTOMER_PHONE_CHANGE_FORBIDDEN"] = "CUSTOMER_PHONE_CHANGE_FORBIDDEN";
  TsupisAuthResult2["CUSTOMER_NOT_UPDATED"] = "CUSTOMER_NOT_UPDATED";
  TsupisAuthResult2["CUSTOMER_REGPHONE_EXISTS"] = "CUSTOMER_REGPHONE_EXISTS";
  return TsupisAuthResult2;
})(TsupisAuthResult || {});
const tsupisAuthResultAuthenticationSuccess = "AUTHENTICATION_SUCCESS";
const tsupisAuthResultApplicationDisabled = "APPLICATION_DISABLED";
const tsupisAuthResultIdentificationRequired = "IDENTIFICATION_REQUIRED";
const tsupisAuthResultSmsCodeIncorrect = "SMS_CODE_INCORRECT";
const tsupisAuthResultSmsCodeExpired = "SMS_CODE_EXPIRED";
const tsupisAuthResultPhoneNumberIncorrect = "PHONE_NUMBER_INCORRECT";
const tsupisAuthResultMaxAttemptsReached = "MAX_ATTEMPTS_REACHED";
const tsupisAuthResultAccessToDeviceDisabled = "ACCESS_TO_DEVICE_DISABLED";
const tsupisAuthResultBettingDisabled = "BETTING_DISABLED";
const tsupisAuthResultUserIsNotAuthorized = "USER_IS_NOT_AUTHORIZED";
const tsupisAuthResultPhoneNumberBlocked = "PHONE_NUMBER_BLOCKED";
const tsupisAuthResultIncorrectIdentData = "INCORRECT_IDENT_DATA";
const tsupisAuthResultIdentDataIsNotVerified = "IDENT_DATA_IS_NOT_VERIFIED";
const tsupisAuthResultPursesLimitExceeded = "PURSES_LIMIT_EXCEEDED";
const tsupisAuthResultPhoneNumberAlreadyUsedByUser = "PHONE_NUMBER_ALREADY_USED_BY_USER";
const tsupisAuthResultDocumentExpired = "DOCUMENT_EXPIRED";
const tsupisAuthResultInvalidCitizenship = "INVALID_CITIZENSHIP";
const tsupisAuthResultInvalidInn = "INVALID_INN";
const tsupisAuthResultInnProviderIsBusy = "INN_PROVIDER_IS_BUSY";
const tsupisAuthResultInvalidAuthDetails = "INVALID_AUTH_DETAILS";
const tsupisAuthResultWrongApiUsage = "WRONG_API_USAGE";
const tsupisAuthResultRequiredParameterInWrongFormat = "REQUIRED_PARAMETER_IN_WRONG_FORMAT";
const tsupisAuthResultTechError = "TECH_ERROR";
const tsupisAuthResultUnknownError = "UNKNOWN_ERROR";
const tsupisAuthResultCustomerCabinRegistrationRequired = "CUSTOMER_CABIN_REGISTRATION_REQUIRED";
const tsupisAuthResultCustomerPhoneChangeForbidden = "CUSTOMER_PHONE_CHANGE_FORBIDDEN";
const tsupisAuthResultCustomerNotUpdated = "CUSTOMER_NOT_UPDATED";
const tsupisAuthResultCustomerRegphoneExists = "CUSTOMER_REGPHONE_EXISTS";
var TsupisBindingRequestStatus = /* @__PURE__ */ ((TsupisBindingRequestStatus2) => {
  TsupisBindingRequestStatus2["NOT_CALLED"] = "NOT_CALLED";
  TsupisBindingRequestStatus2["PENDING"] = "PENDING";
  TsupisBindingRequestStatus2["DONE"] = "DONE";
  return TsupisBindingRequestStatus2;
})(TsupisBindingRequestStatus || {});
const tsupisBindingRequestStatusNotCalled = "NOT_CALLED";
const tsupisBindingRequestStatusPending = "PENDING";
const tsupisBindingRequestStatusDone = "DONE";
var TsupisProviderType = /* @__PURE__ */ ((TsupisProviderType2) => {
  TsupisProviderType2["TSUPIS_2"] = "TSUPIS_2";
  TsupisProviderType2["TSUPIS_1"] = "TSUPIS_1";
  return TsupisProviderType2;
})(TsupisProviderType || {});
const tsupisProviderTypeTsupis2 = "TSUPIS_2";
const tsupisProviderTypeTsupis1 = "TSUPIS_1";
var UiAccountType = /* @__PURE__ */ ((UiAccountType2) => {
  UiAccountType2["MAIN"] = "MAIN";
  UiAccountType2["CBC"] = "CBC";
  UiAccountType2["SBC"] = "SBC";
  return UiAccountType2;
})(UiAccountType || {});
const uiAccountTypeMain = "MAIN";
const uiAccountTypeCbc = "CBC";
const uiAccountTypeSbc = "SBC";
var UnknownCardTokenExceptionCode = /* @__PURE__ */ ((UnknownCardTokenExceptionCode2) => {
  UnknownCardTokenExceptionCode2["UNKNOWN_CARD_TOKEN_ERROR"] = "UNKNOWN_CARD_TOKEN_ERROR";
  return UnknownCardTokenExceptionCode2;
})(UnknownCardTokenExceptionCode || {});
const unknownCardTokenExceptionCodeUnknownCardTokenError = "UNKNOWN_CARD_TOKEN_ERROR";
var UnknownRequestHashExceptionCode = /* @__PURE__ */ ((UnknownRequestHashExceptionCode2) => {
  UnknownRequestHashExceptionCode2["UNKNOWN_REQUEST_HASH"] = "UNKNOWN_REQUEST_HASH";
  return UnknownRequestHashExceptionCode2;
})(UnknownRequestHashExceptionCode || {});
const unknownRequestHashExceptionCodeUnknownRequestHash = "UNKNOWN_REQUEST_HASH";
var UnsupportedBannerExceptionCode = /* @__PURE__ */ ((UnsupportedBannerExceptionCode2) => {
  UnsupportedBannerExceptionCode2["UNSUPPORTED_BANNER"] = "UNSUPPORTED_BANNER";
  return UnsupportedBannerExceptionCode2;
})(UnsupportedBannerExceptionCode || {});
const unsupportedBannerExceptionCodeUnsupportedBanner = "UNSUPPORTED_BANNER";
const validationSchemaExample = "EXAMPLE";
const validationSchemaLogin = "LOGIN";
const validationSchemaRegistrationFullLeonRu = "REGISTRATION_FULL_LEON_RU";
const validationSchemaRegistrationFullLru = "REGISTRATION_FULL_LRU";
const validationSchemaRestorePasswordSendSmsToPhone = "RESTORE_PASSWORD_SEND_SMS_TO_PHONE";
const validationSchemaRestorePasswordSendLinkToEmail = "RESTORE_PASSWORD_SEND_LINK_TO_EMAIL";
const validationSchemaRestorePasswordByEmail = "RESTORE_PASSWORD_BY_EMAIL";
const validationSchemaRestorePasswordByPhone = "RESTORE_PASSWORD_BY_PHONE";
const validationSchemaRestorePasswordCheckSms = "RESTORE_PASSWORD_CHECK_SMS";
const validationSchemaCustomerLimits = "CUSTOMER_LIMITS";
const validationSchemaPersonalData = "PERSONAL_DATA";
const validationSchemaChangePassword = "CHANGE_PASSWORD";
const validationSchemaSupportCallback = "SUPPORT_CALLBACK";
const validationSchemaSupportFeedback = "SUPPORT_FEEDBACK";
const verificationStatusUnavailable = "UNAVAILABLE";
const verificationStatusNeedsVerif = "NEEDS_VERIF";
const verificationStatusVerifPending = "VERIF_PENDING";
const verificationStatusVerified = "VERIFIED";
const verificationStatusVerifStarted = "VERIF_STARTED";
var VideoVerificationStatus = /* @__PURE__ */ ((VideoVerificationStatus2) => {
  VideoVerificationStatus2["NONE"] = "NONE";
  VideoVerificationStatus2["PENDING"] = "PENDING";
  VideoVerificationStatus2["IGNORED"] = "IGNORED";
  VideoVerificationStatus2["INVITED"] = "INVITED";
  VideoVerificationStatus2["NO_ANSWER"] = "NO_ANSWER";
  VideoVerificationStatus2["SCHEDULED"] = "SCHEDULED";
  VideoVerificationStatus2["ON_REVIEW"] = "ON_REVIEW";
  VideoVerificationStatus2["PASSED"] = "PASSED";
  VideoVerificationStatus2["NOT_PASSED"] = "NOT_PASSED";
  VideoVerificationStatus2["REFUSED"] = "REFUSED";
  VideoVerificationStatus2["MISSED"] = "MISSED";
  VideoVerificationStatus2["ACCEPT_IGNORE"] = "ACCEPT_IGNORE";
  VideoVerificationStatus2["ACCEPT_REFUSAL"] = "ACCEPT_REFUSAL";
  VideoVerificationStatus2["UNKNOWN"] = "UNKNOWN";
  return VideoVerificationStatus2;
})(VideoVerificationStatus || {});
const videoVerificationStatusNone = "NONE";
const videoVerificationStatusPending = "PENDING";
const videoVerificationStatusIgnored = "IGNORED";
const videoVerificationStatusInvited = "INVITED";
const videoVerificationStatusNoAnswer = "NO_ANSWER";
const videoVerificationStatusScheduled = "SCHEDULED";
const videoVerificationStatusOnReview = "ON_REVIEW";
const videoVerificationStatusPassed = "PASSED";
const videoVerificationStatusNotPassed = "NOT_PASSED";
const videoVerificationStatusRefused = "REFUSED";
const videoVerificationStatusMissed = "MISSED";
const videoVerificationStatusAcceptIgnore = "ACCEPT_IGNORE";
const videoVerificationStatusAcceptRefusal = "ACCEPT_REFUSAL";
const videoVerificationStatusUnknown = "UNKNOWN";
const vipFastTrackStatusDisabled = "DISABLED";
const vipFastTrackStatusNotLoggedIn = "NOT_LOGGED_IN";
const vipFastTrackStatusAvailable = "AVAILABLE";
const vipFastTrackStatusVip = "VIP";
var VirtualSportTournament = /* @__PURE__ */ ((VirtualSportTournament2) => {
  VirtualSportTournament2["FOOTBALL_WORLD_CUP"] = "FOOTBALL_WORLD_CUP";
  VirtualSportTournament2["BASKETBALL"] = "BASKETBALL";
  VirtualSportTournament2["FOOTBALL_EUROCUP"] = "FOOTBALL_EUROCUP";
  VirtualSportTournament2["FOOTBALL_LEAGUE_MODE"] = "FOOTBALL_LEAGUE_MODE";
  VirtualSportTournament2["FOOTBALL_NATIONS_CUP"] = "FOOTBALL_NATIONS_CUP";
  VirtualSportTournament2["TENNIS_INPLAY"] = "TENNIS_INPLAY";
  VirtualSportTournament2["FOOTBALL_CHAMPIONS_CUP"] = "FOOTBALL_CHAMPIONS_CUP";
  VirtualSportTournament2["FOOTBALL_BUNDESLIGA"] = "FOOTBALL_BUNDESLIGA";
  VirtualSportTournament2["CRICKET"] = "CRICKET";
  return VirtualSportTournament2;
})(VirtualSportTournament || {});
const virtualSportTournamentFootballWorldCup = "FOOTBALL_WORLD_CUP";
const virtualSportTournamentBasketball = "BASKETBALL";
const virtualSportTournamentFootballEurocup = "FOOTBALL_EUROCUP";
const virtualSportTournamentFootballLeagueMode = "FOOTBALL_LEAGUE_MODE";
const virtualSportTournamentFootballNationsCup = "FOOTBALL_NATIONS_CUP";
const virtualSportTournamentTennisInplay = "TENNIS_INPLAY";
const virtualSportTournamentFootballChampionsCup = "FOOTBALL_CHAMPIONS_CUP";
const virtualSportTournamentFootballBundesliga = "FOOTBALL_BUNDESLIGA";
const virtualSportTournamentCricket = "CRICKET";
var VpnProvider = /* @__PURE__ */ ((VpnProvider2) => {
  VpnProvider2["OPENVPN"] = "OPENVPN";
  VpnProvider2["SHADOWSOCKS"] = "SHADOWSOCKS";
  return VpnProvider2;
})(VpnProvider || {});
const vpnProviderOpenvpn = "OPENVPN";
const vpnProviderShadowsocks = "SHADOWSOCKS";
const vsportBetStatusPlaced = "PLACED";
const vsportBetStatusWon = "WON";
const vsportBetStatusLost = "LOST";
const vsportBetStatusVoided = "VOIDED";
var Web2FontFamily = /* @__PURE__ */ ((Web2FontFamily2) => {
  Web2FontFamily2["DEFAULT"] = "DEFAULT";
  Web2FontFamily2["MONTSERRAT"] = "MONTSERRAT";
  return Web2FontFamily2;
})(Web2FontFamily || {});
const web2FontFamilyDefault = "DEFAULT";
const web2FontFamilyMontserrat = "MONTSERRAT";
var WidgetType = /* @__PURE__ */ ((WidgetType2) => {
  WidgetType2["BETRADAR"] = "BETRADAR";
  WidgetType2["LSPORTS"] = "LSPORTS";
  WidgetType2["BETGENIUS"] = "BETGENIUS";
  return WidgetType2;
})(WidgetType || {});
const widgetTypeBetradar = "BETRADAR";
const widgetTypeLsports = "LSPORTS";
const widgetTypeBetgenius = "BETGENIUS";
var ZetaDcpConfigType = /* @__PURE__ */ ((ZetaDcpConfigType2) => {
  ZetaDcpConfigType2["MAIN"] = "MAIN";
  ZetaDcpConfigType2["ALL"] = "ALL";
  ZetaDcpConfigType2["LANDING"] = "LANDING";
  ZetaDcpConfigType2["REGISTRATION"] = "REGISTRATION";
  ZetaDcpConfigType2["DEPOSIT"] = "DEPOSIT";
  ZetaDcpConfigType2["FIRST_TIME_DEPOSIT"] = "FIRST_TIME_DEPOSIT";
  return ZetaDcpConfigType2;
})(ZetaDcpConfigType || {});
const zetaDcpConfigTypeMain = "MAIN";
const zetaDcpConfigTypeAll = "ALL";
const zetaDcpConfigTypeLanding = "LANDING";
const zetaDcpConfigTypeRegistration = "REGISTRATION";
const zetaDcpConfigTypeDeposit = "DEPOSIT";
const zetaDcpConfigTypeFirstTimeDeposit = "FIRST_TIME_DEPOSIT";
const EgsGroupFieldsFragmentDoc = `
    fragment EgsGroupFields on EgsGroup {
  id
  name
  url
  iconUrls {
    dark
    light
  }
  metaDescription
  type
  ntBadge
  blockType
  color
}
    `;
const Web2ImageFieldsFragmentDoc = `
    fragment Web2ImageFields on Web2Image {
  src
  x1
  x2
  x3
  x1webp
  x2webp
  x3webp
}
    `;
const EgsImagesFieldsFragmentDoc = `
    fragment EgsImagesFields on EgsImages {
  preview {
    ...Web2ImageFields
  }
  background {
    ...Web2ImageFields
  }
  phoneGamePage {
    ...Web2ImageFields
  }
  squareImage {
    ...Web2ImageFields
  }
  jackpot {
    ...Web2ImageFields
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
const EgsGameCategoriesFieldsFragmentDoc = `
    fragment EgsGameCategoriesFields on EgsCategory {
  id
  name
  iconUrl
  url
  type
  nameEn
  ntBadge
}
    `;
const Web2MoneyFieldsFragmentDoc = `
    fragment Web2MoneyFields on Web2Money {
  currency
  value
}
    `;
const EgsLimitsFieldsFragmentDoc = `
    fragment EgsLimitsFields on EgsLimits {
  min {
    ...Web2MoneyFields
  }
  max {
    ...Web2MoneyFields
  }
}
    ${Web2MoneyFieldsFragmentDoc}`;
const EgsGameDetailsFieldsFragmentDoc = `
    fragment EgsGameDetailsFields on EgsGameDetails {
  id
  name
  url
  group {
    ...EgsGroupFields
  }
  description
  metaDescription
  isFavorite
  hasDemo
  images {
    ...EgsImagesFields
  }
  categories {
    ...EgsGameCategoriesFields
  }
  statistics {
    name
    value
  }
  isOneWalletSystem
  typeId
  openMethod
  isAgeCheckNeeded
  isUnavailable
  jackpot {
    amount {
      ...Web2MoneyFields
    }
  }
  availability {
    status
    players {
      current
      total
    }
  }
  limits {
    ...EgsLimitsFields
  }
  providerId
  isVpnAllowed
  isNew
  exclusive
  shortDescription
  blockType
  ntBadge
}
    ${EgsGroupFieldsFragmentDoc}
${EgsImagesFieldsFragmentDoc}
${EgsGameCategoriesFieldsFragmentDoc}
${Web2MoneyFieldsFragmentDoc}
${EgsLimitsFieldsFragmentDoc}`;
const AccountHistoryEgsSessionFieldsFragmentDoc = `
    fragment AccountHistoryEgsSessionFields on EgsAccountHistoryItem {
  sessionCursor
  createdAt
  customerId
  gameType
  gameTypeKey
  currency
  providerName
  gameName
  roundCount
  egsGame {
    ...EgsGameDetailsFields
  }
}
    ${EgsGameDetailsFieldsFragmentDoc}`;
const ChannelDataFieldsFragmentDoc = `
    fragment ChannelDataFields on ChannelDto {
  channel
  channelName
}
    `;
const AccountHistoryMarketingFieldsFragmentDoc = `
    fragment AccountHistoryMarketingFields on BonusAccountHistoryItem {
  bonusAmountLost
  closeReason
  closeReasonKey
  amount
  loyaltyBonusDescKey
  campaignId
  campaignName
  egsGame {
    ...EgsGameDetailsFields
  }
  grantedFreespins
  usedFreespins
  freespinsAbbrKey
  createdAt
  bonusStatus
  statusKey
  bonusCursor
  bonusDataType
  dataNameKey
  bonusDataCategory
  channelData {
    ...ChannelDataFields
  }
  currency
}
    ${EgsGameDetailsFieldsFragmentDoc}
${ChannelDataFieldsFragmentDoc}`;
const AccountHistoryPaymentFieldsFragmentDoc = `
    fragment AccountHistoryPaymentFields on PaymentAccountHistoryItem {
  paymentId
  createdAt
  amount
  currency
  paymentStatus
  statusKey
  paymentCursor
  dataNameKey
  paymentDataType
  paymentDataCategory
  withdrawCancellable
}
    `;
const AccountHistorySportbookFieldsFragmentDoc = `
    fragment AccountHistorySportbookFields on SportbookAccountHistoryItem {
  betId
  createdAt
  currency
  odds
  stake
  winning
  betCursor
  sportBetStatus
  type
  bankerCount
  selCount
  eventName
  marketName
  selName
  systemType
}
    `;
const RoundOverviewFieldsFragmentDoc = `
    fragment RoundOverviewFields on RoundOverviewDto {
  roundCursor
  stake
  winning
  createdAt
  roundId
}
    `;
const LeagueStatisticsTeamFieldsFragmentDoc = `
    fragment LeagueStatisticsTeamFields on League24Team {
  id
  name
  logo
  sport
  country
}
    `;
const LeagueStandingFieldsFragmentDoc = `
    fragment LeagueStandingFields on LeagueStanding {
  leagueTableId
  leagueTableName
  leagueTableRound
  leagueTableType
  seasonName
  seasonYear
  drawTotal
  winTotal
  lossTotal
  matchesTotal
  pointsTotal
  goalsTotal
  promotionId
  promotionName
  sortPositionTotal
  team {
    ...LeagueStatisticsTeamFields
  }
}
    ${LeagueStatisticsTeamFieldsFragmentDoc}`;
const MatchStatisticsTeamFieldsFragmentDoc = `
    fragment MatchStatisticsTeamFields on MatchStatisticsEventTeam {
  id
  name
  type
  country
  logo
}
    `;
const MatchStatisticsMatchFieldsFragmentDoc = `
    fragment MatchStatisticsMatchFields on MatchStatisticsEventData {
  id
  gameScore
  resultScore
  resultScores {
    type
    value
  }
  tournamentName
  winner
  status
  matchDate
  teams {
    ...MatchStatisticsTeamFields
  }
}
    ${MatchStatisticsTeamFieldsFragmentDoc}`;
const MatchStatisticsPlayerFieldsFragmentDoc = `
    fragment MatchStatisticsPlayerFields on MatchStatisticsPlayer {
  shirtNumber
  playerName
  playerShortName
}
    `;
const Scores24ScorerFieldsFragmentDoc = `
    fragment Scores24ScorerFields on Scores24Scorer {
  matchesPlayed
  ranking
  position
  positionFull
  positionShort
  name
  value
  player {
    logo
    playerName
    playerShortName
    shirtNumber
  }
  team {
    name
    logo
    sport
    country
  }
}
    `;
const Scores24TeamFieldsFragmentDoc = `
    fragment Scores24TeamFields on MatchStatisticsTeam {
  id
  name
  logo
  sport
  country
}
    `;
const EgsCustomerBonusFragmentDoc = `
    fragment EgsCustomerBonus on CustomerBonus {
  campaignId
  campaignType
  campaignName
  currency
  bonusId
  bonusState
  availableBonusType
  bonusAmount
  maxBonusAmount
  transferAmount
  refuseAvailable
  pendingRefuse
  step
  stepNumber
  stepCount
  availableBefore
  bonusNumber
  maxBonusCount
  actionUrl
  categoryId
  termsKey
  requirements {
    name
    totalPlacedBets
    progressBarValue
    type
    requiredValue
    currentValue
    valueType
    satisfied
    waitingLastBetResult
  }
  freebet {
    amount
    totalAvailable
    totalActivated
    totalResulted
  }
  freespin {
    count
    usedCount
    nominal
    totalCount
    provider
    gameIds
  }
}
    `;
const SportCustomerBonusFragmentDoc = `
    fragment SportCustomerBonus on CustomerBonus {
  campaignId
  campaignType
  campaignName
  currency
  bonusId
  bonusState
  availableBonusType
  bonusAmount
  maxBonusAmount
  transferAmount
  refuseAvailable
  pendingRefuse
  step
  stepNumber
  stepCount
  availableBefore
  bonusNumber
  maxBonusCount
  actionUrl
  categoryId
  termsKey
  requirements {
    name
    totalPlacedBets
    progressBarValue
    type
    requiredValue
    currentValue
    valueType
    satisfied
    waitingLastBetResult
  }
  freebet {
    amount
    totalAvailable
    totalActivated
    totalResulted
  }
  freespin {
    count
    usedCount
    nominal
    totalCount
  }
}
    `;
const BannerContentFieldsFragmentDoc = `
    fragment BannerContentFields on PromoBannerData {
  headline
  secondaryText
  buttonText
  image {
    ...Web2ImageFields
  }
  imageMiddle {
    ...Web2ImageFields
  }
  bgImage {
    ...Web2ImageFields
  }
  bgImageMiddle {
    ...Web2ImageFields
  }
  money {
    currency
    value
  }
  url
  bgColor
  animation
  buttonIconName
  egsJackpot {
    id
    providerId
    jackpots {
      amount {
        currency
        value
      }
    }
  }
  isNT
}
    ${Web2ImageFieldsFragmentDoc}`;
const BannerSportEventFragmentDoc = `
    fragment BannerSportEvent on BetlineEvent {
  betline
  id
  kickoff
  status
  url
  matchPhase
  name
  open
  markets {
    id
    name
    cols
    handicap
    hasZeroMargin
    marketTypeId
    open
    primary
    runners {
      open
      handicap
      name
      id
      c
      price
      priceStr
      r
      tags
    }
  }
  liveStatus {
    stage
    score
    detailedPhase
    setScores
    progress
    progressSetupEnabled
  }
  competitors {
    homeAway
    id
    name
  }
}
    `;
const BannerDataFragmentDoc = `
    fragment BannerData on Banner {
  id
  bannerId
  pathType
  location
  customLocationId
  content {
    ...BannerContentFields
  }
  bannerType
  event {
    ...BannerSportEvent
  }
  isNT
  isTextShadowHidden
  textColor
  isNewBannerView
  bannerVersion
}
    ${BannerContentFieldsFragmentDoc}
${BannerSportEventFragmentDoc}`;
const CustomerDataPhoneFieldsFragmentDoc = `
    fragment CustomerDataPhoneFields on CustomerData {
  phone
  phoneCountryIsoCode
}
    `;
const CustomerDataFieldsFragmentDoc = `
    fragment CustomerDataFields on CustomerData {
  ...CustomerDataPhoneFields
  publicId
  lastDepositedSystemId
  customerLogin
  language
  country
  countryName
  exAllowedCountry
  forbiddenCountry
  city
  address
  isCCUseAllowed
  registrationType
  isRegistrationFinish
  unreadMessagesCount
  balance
  currency
  avatar {
    anchorIgnore
    image {
      src
      x1
      x2
      x3
      x1webp
      x2webp
      x3webp
    }
    background
  }
  currencyFormatted {
    key
    value
  }
  priceChangePolicy
  totalHandicapPriceChangePolicy
  hasFavGames
  email
  emailSubscription
  verificationNeeded
  verificationActive
  isSimpleRegistrant
  isSelfExcluded
  selfExclusionUntil
  selfExclusionMessage
  fullName
  isVip
  vipStatus
  useStandardBet
  standardBetAmount
  sameStakeForSingleByDefault
  showSlipOnFirstAdded
  fastBets {
    enabled
    values
    maxValue
    minValue
    showMax
  }
  historyFilterOptions {
    order
    dateType
    dateRange
    btrTitle
    isDefault
    title
  }
  customSettings {
    settingName
    settingValue
  }
  ident {
    canGamble
  }
  g2svFeatureStatus
  g2svCustomerStatus
  affiliatePartnerStatus
  isCountryConfirmed
  additionalRegistrationNeeded
  personalAppDomain
  personalDomainData {
    name
    type
    priority
    isShowPopup
  }
  showVerification
  fastTrackToken
  verificationType
  verificationStatus
  verificationLevel
  additionalVerificationNeeded
  isEkycRequestable
  isEmailConfirmed
  isAppsFlyerEnabled
  isAppsFlyerDepositQualified
  isHiddenBalance
  nickname
  userLanguageTag
  createPinCodeModalCounter
  isPinCodeRequestedOnLogin
  videoVerificationStatus
  videoVerificationStatusExpiration
  isVideoVerificationOnboardingShown
  latestRequestedVerificationType
  customerConfig {
    hideCommissionHint {
      value
      key
    }
    isHideOnBoardingPage {
      value
      key
    }
    isHideEgsLoyaltyOnboardingPage {
      value
      key
    }
    isHiddenBalance {
      value
      key
    }
    isNicknamePromptHidden {
      value
      key
    }
    isHideOnBoardingOnLeonShopPage {
      value
      key
    }
    isBonusChooserShownBeforeDeposits {
      value
      key
    }
    hideNoSelectedBonusModal {
      value
      key
    }
    isHideLimitModalOnGamePage {
      value
      key
    }
    createPinCodeModalCounter {
      value
      key
    }
    isPinCodeRequestedOnLogin {
      value
      key
    }
    isVideoVerificationOnboardingShown {
      value
      key
    }
    isAchievementsMainOnboardingShown {
      value
      key
    }
    isReferralProgramOnboardingShown {
      value
      key
    }
    isFortuneWheelWindowShown {
      value
      key
    }
    isReferralProgramNotificationClicked {
      value
      key
    }
    isResponsibleGamblingOnboardingShown {
      value
      key
    }
    isOddsTypeAmerican {
      value
      key
    }
    isOddsTypeClassic {
      value
      key
    }
    isEkycPopupOnFirstLoginShown {
      value
      key
    }
    isPrivateProfile {
      value
      key
    }
    isHiddenNickname {
      value
      key
    }
    isLoyaltyProgramShown {
      value
      key
    }
  }
  isAsoTermsConfirmationNeeded
  customerBetType
  customerOddsType
  shortComments
  isCustomerVerificationAsDefaultAvailable
  isWithdrawalBlockedByVerif
  responsibleGamingLimits {
    type
    isSet
  }
  hasFomoBonus
  fomoBonusActionUrl
  fomoBonusCampaignId
  fomoBonusCategoryId
}
    ${CustomerDataPhoneFieldsFragmentDoc}`;
const EgsSkeletonConfigFieldsFragmentDoc = `
    fragment EgsSkeletonConfigFields on EgsSkeletonConfig {
  typeId
  widgetId
  gridType
  swiperRowsNumber
  isSwiperBlock
  titleType
}
    `;
const SlipInfoFieldsFragmentDoc = `
    fragment SlipInfoFields on SlipInfo {
  status
  maxSlipSize
  maxSystemSize
  maxBankers
  minStake
  maxStake
  totalOdds
  totalOddsStr
  totalOddsWithoutAccumulatorBoost
  totalOddsWithoutAccumulatorBoostStr
  accumulatorBoost
  taxPercent
  entries {
    event
    eventName
    competitors
    market
    runner
    runnerName
    marketName
    odds
    oddsStr
    banker
    marketStatus
    betline
    zeroMargin
    isPrimaryMarket
    runnerTags
    marketTypeIdentifier
    marketTypeTag
    ts
  }
  message
  slipType
  slipTypes {
    code
    typeId
    shortName
    name
  }
  freeBets {
    name
    amount
    oddsValid
    freeBetId
    promoActionUrl
    promoActionTermsPage
    promoActionCategoryId
    transferType
  }
  trace {
    lines {
      timestamp
      error
      contents {
        typeId
        data
        name
      }
    }
  }
  taxPercent
}
    `;
const IdentStateFieldsFragmentDoc = `
    fragment IdentStateFields on IdentState {
  identificationLevel
  identificationStage
  identificationStatus
  identificationStatusOrder
  isIdentBarVisible
  canGamble
  gambleStatus
  form {
    passport {
      src
      fileName
      status
    }
  }
  esiaLoginData {
    status
    loginError
  }
  manualCustomerData {
    lastName
    firstName
    middleName
    docType
    docNr
    birthDate
    inn
    status
    version
  }
  blocked
  incorrectType
  identHubConfig {
    defaultMethod
    orderedMethods
  }
  simpleIdentDefaultType
  confirmedPhoneAuth
  phoneChangeAllowed
  phoneIdentificationRequired
  tsupis1CustomerError
  tsupis2ErrorCode
  tsupisBindingRequestStatus
  esiaResultPending
  isPassportExpired
  thirdPartyErrorCode
}
    `;
const AbstractLobbyItemFieldsFragmentDoc = `
    fragment AbstractLobbyItemFields on AbstractLobbyItem {
  name
  typeId
  widgetId
  allButtonText
  widgetOptions {
    swiperRowsNumber
    gridType
    isSwiperAuto
    isSwiperBlock
  }
  iconUrl {
    dark
    light
  }
  titleType
  redirectPath
}
    `;
const EgsGameFieldsFragmentDoc = `
    fragment EgsGameFields on EgsGame {
  id
  name
  url
  group {
    ...EgsGroupFields
  }
  description
  metaDescription
  isFavorite
  hasDemo
  isNew
  images {
    ...EgsImagesFields
  }
  categories {
    ...EgsGameCategoriesFields
  }
  statistics {
    name
    value
  }
  isOneWalletSystem
  typeId
  openMethod
  isUnavailable
  blockType
  jackpot {
    amount {
      ...Web2MoneyFields
    }
  }
  availability {
    status
    players {
      current
      total
    }
  }
  limits {
    ...EgsLimitsFields
  }
  providerId
  isVpnAllowed
  shortDescription
  ntBadge
  exclusive
}
    ${EgsGroupFieldsFragmentDoc}
${EgsImagesFieldsFragmentDoc}
${EgsGameCategoriesFieldsFragmentDoc}
${Web2MoneyFieldsFragmentDoc}
${EgsLimitsFieldsFragmentDoc}`;
const EgsTopWinnerFieldsFragmentDoc = `
    fragment EgsTopWinnerFields on EgsTopWinner {
  egsGame {
    ...EgsGameFields
  }
  winAmount {
    ...Web2MoneyFields
  }
  player {
    name
    login
  }
}
    ${EgsGameFieldsFragmentDoc}
${Web2MoneyFieldsFragmentDoc}`;
const PaginationCursorItemFieldsFragmentDoc = `
    fragment PaginationCursorItemFields on PaginationCursorItem {
  limit
  offset
  total
}
    `;
const LobbyAllTopWinnersItemFieldsFragmentDoc = `
    fragment LobbyAllTopWinnersItemFields on LobbyAllTopWinnersItem {
  egsTopWinners {
    winners {
      ...EgsTopWinnerFields
    }
    next {
      ...PaginationCursorItemFields
    }
  }
  sportTopWinners {
    winners {
      odds
      sportBetEvents {
        eventId
        eventName
      }
      winAmount {
        currency
        value
      }
      player {
        name
        login
      }
    }
    next {
      ...PaginationCursorItemFields
    }
  }
  primaryTab
}
    ${EgsTopWinnerFieldsFragmentDoc}
${PaginationCursorItemFieldsFragmentDoc}`;
const LobbyBannerItemFieldsFragmentDoc = `
    fragment LobbyBannerItemFields on LobbyBannersItem {
  banners {
    ...BannerContentFields
  }
}
    ${BannerContentFieldsFragmentDoc}`;
const LobbyGamesCategoryItemFieldsFragmentDoc = `
    fragment LobbyGamesCategoryItemFields on LobbyGamesCategoryItem {
  category {
    ...EgsGameCategoriesFields
  }
  games {
    ...EgsGameFields
  }
  next {
    ...PaginationCursorItemFields
  }
}
    ${EgsGameCategoriesFieldsFragmentDoc}
${EgsGameFieldsFragmentDoc}
${PaginationCursorItemFieldsFragmentDoc}`;
const LobbyGroupsItemFieldsFragmentDoc = `
    fragment LobbyGroupsItemFields on LobbyGroupsItem {
  groups {
    ...EgsGroupFields
  }
}
    ${EgsGroupFieldsFragmentDoc}`;
const LobbyPromotionsItemFieldsFragmentDoc = `
    fragment LobbyPromotionsItemFields on LobbyPromotionsItem {
  url
  promotions {
    ...BannerContentFields
  }
}
    ${BannerContentFieldsFragmentDoc}`;
const LobbySportlineTopMatchesItemFieldsFragmentDoc = `
    fragment LobbySportlineTopMatchesItemFields on LobbySportlineTopMatchesItem {
  events
  flags
  ctag
  vtag
}
    `;
const LobbyTopWinnersItemFieldsFragmentDoc = `
    fragment LobbyTopWinnersItemFields on LobbyTopWinnersItem {
  category {
    ...EgsGameCategoriesFields
  }
  filter
  gameType
  winners {
    ...EgsTopWinnerFields
  }
  next {
    ...PaginationCursorItemFields
  }
}
    ${EgsGameCategoriesFieldsFragmentDoc}
${EgsTopWinnerFieldsFragmentDoc}
${PaginationCursorItemFieldsFragmentDoc}`;
const AlcrNotificationFieldsFragmentDoc = `
    fragment AlcrNotificationFields on AlcrNotification {
  title
  message
  ctaButtonLink
  ctaButtonText
  imageUrl
  displayType
  iconName
  buttonAction
}
    `;
const BonusActivationNotificationFieldsFragmentDoc = `
    fragment BonusActivationNotificationFields on BonusActivationNotification {
  amount
  depositAmount
  depositTransferAmount
  currency
  actionUrl
  categoryId
}
    `;
const CryptoDepositStatusNotificationFieldsFragmentDoc = `
    fragment CryptoDepositStatusNotificationFields on CryptoDepositStatusNotification {
  amountStr
  currency
  initialTimestamp
  delay
}
    `;
const DepositNotificationFieldsFragmentDoc = `
    fragment DepositNotificationFields on DepositNotification {
  amount
  currency
  date
  delay
}
    `;
const FomoBonusCreatedFieldsFragmentDoc = `
    fragment FomoBonusCreatedFields on FomoBonusCreated {
  message
  bonusCampaignName
  bonusCampaignId
  bonusId
  categoryId
  actionUrl
  terms
  availableBefore
  availableBeforeStr
}
    `;
const FrontNotificationFieldsFragmentDoc = `
    fragment FrontNotificationFields on FrontNotification {
  id
  timestamp
  type
  isClicked
  isDelivered
  isRead
  delay
  restrictedRoutes
}
    `;
const RefProgramNotificationFieldsFragmentDoc = `
    fragment RefProgramNotificationFields on RefProgramNotification {
  payload {
    type
    ... on ProgramAvailable {
      programId
      referrerId
    }
    ... on BonusEligibilityRules {
      currency
      rewards {
        depositAmount
        rewardAmount {
          playerReward {
            count
            nominalValue
            amount
            rewardType
          }
          referralReward {
            count
            nominalValue
            amount
            rewardType
          }
        }
      }
      referralRequirements {
        type
        satisfied
        requiredValue
        currency
      }
    }
    ... on ReferralRewardAssigned {
      referrerId
      referralId
      programId
      reward {
        count
        nominalValue
        amount
        rewardType
      }
    }
    ... on ReferralRewardAssignedReferrerNotification {
      programId
      reward {
        count
        nominalValue
        amount
        rewardType
      }
    }
  }
}
    `;
const VideoNotificationFieldsFragmentDoc = `
    fragment VideoNotificationFields on VideoVerificationNotification {
  status
  message
}
    `;
const VerificationTokenFieldsFragmentDoc = `
    fragment VerificationTokenFields on VerificationGetTokenResponse {
  token
  purseMask
}
    `;
const getAllAccountHistoryDocument = `
    query getAllAccountHistory($options: AllFindRequest!) {
  queries {
    accountHistory {
      getAllAccountHistory(input: $options) {
        ts
        data {
          items {
            dataGroup
            ...AccountHistorySportbookFields
            ...AccountHistoryPaymentFields
            ...AccountHistoryEgsSessionFields
            ...AccountHistoryMarketingFields
          }
          nextPageCursor
        }
      }
    }
  }
}
    ${AccountHistorySportbookFieldsFragmentDoc}
${AccountHistoryPaymentFieldsFragmentDoc}
${AccountHistoryEgsSessionFieldsFragmentDoc}
${AccountHistoryMarketingFieldsFragmentDoc}`;
const getAllAccountHistoryDataGroupsDocument = `
    query getAllAccountHistoryDataGroups($options: EmptyQueryRequest!) {
  queries {
    accountHistory {
      getAllAccountHistoryDataGroups(input: $options) {
        ts
        data {
          groups {
            group
            groupKey
          }
        }
      }
    }
  }
}
    `;
const getEgsChannelsDocument = `
    query getEgsChannels($options: EmptyQueryRequest!) {
  queries {
    accountHistory {
      getEgsChannels(input: $options) {
        ts
        data {
          channels {
            ...ChannelDataFields
          }
        }
      }
    }
  }
}
    ${ChannelDataFieldsFragmentDoc}`;
const getEgsGameTypesDocument = `
    query getEgsGameTypes($options: EmptyQueryRequest!) {
  queries {
    accountHistory {
      getEgsGameTypes(input: $options) {
        ts
        data {
          gameTypes {
            type
            typeKey
          }
        }
      }
    }
  }
}
    `;
const getEgsRoundsDocument = `
    query getEgsRounds($options: EgsRoundsFindRequest!) {
  queries {
    accountHistory {
      getEgsRounds(input: $options) {
        ts
        data {
          sessionCreatedAt
          gameType
          gameTypeKey
          internalGameId
          currency
          roundCount
          egsGame {
            ...EgsGameDetailsFields
          }
          rounds {
            ...RoundOverviewFields
          }
          nextPageCursor
        }
      }
    }
  }
}
    ${EgsGameDetailsFieldsFragmentDoc}
${RoundOverviewFieldsFragmentDoc}`;
const getEgsRoundsCursorDocument = `
    query getEgsRoundsCursor($options: EgsRoundsFindRequestCursor!) {
  queries {
    accountHistory {
      getEgsRoundsCursor(input: $options) {
        ts
        data {
          currency
          roundCursor
          stake
          winning
          createdAt
          loyaltyBonus
          loyaltyBonusCurrency
          roundTypeText
          providerName
          gameName
          roundId
          egsGame {
            ...EgsGameDetailsFields
          }
        }
      }
    }
  }
}
    ${EgsGameDetailsFieldsFragmentDoc}`;
const getEgsSessionsDocument = `
    query getEgsSessions($options: EgsSessionsFindRequest!) {
  queries {
    accountHistory {
      getEgsSessions(input: $options) {
        ts
        data {
          sessions {
            ...AccountHistoryEgsSessionFields
          }
          nextPageCursor
        }
      }
    }
  }
}
    ${AccountHistoryEgsSessionFieldsFragmentDoc}`;
const getEgsTransactionsDocument = `
    query getEgsTransactions($options: EgsTransactionsFindRequest!) {
  queries {
    accountHistory {
      getEgsTransactions(input: $options) {
        ts
        data {
          transactions {
            gameTxId
            createdAt
            type
            typeKey
            amount
          }
          nextPageCursor
        }
      }
    }
  }
}
    `;
const getMarketingBonusesDocument = `
    query getMarketingBonuses($options: BonusFindRequest!) {
  queries {
    accountHistory {
      getMarketingBonuses(input: $options) {
        ts
        data {
          bonuses {
            dataGroup
            ...AccountHistoryMarketingFields
          }
          nextPageCursor
        }
      }
    }
  }
}
    ${AccountHistoryMarketingFieldsFragmentDoc}`;
const getMarketingBonusCursorDocument = `
    query getMarketingBonusCursor($options: BonusFindRequestCursor!) {
  queries {
    accountHistory {
      getMarketingBonusesCursor(input: $options) {
        ts
        data {
          item {
            createdAt
            bonusStatus
            statusKey
            dataNameKey
            bonusDataType
            bonusDataCategory
            ... on BonusTransferredToBonusWalletDetails {
              bonusId
              campaignId
              campaignName
              currency
              amount
            }
            ... on BonusFailedDetailsDto {
              bonusId
              campaignId
              campaignName
              currency
              bonusAmountLost
              closeReason
              closeReasonKey
            }
            ... on EgsBonusToMainWalletActivatedDetailsDto {
              bonusId
              campaignId
              campaignName
            }
            ... on EgsFreespinDetailsDto {
              bonusId
              campaignId
              campaignName
              egsGames {
                ...EgsGameDetailsFields
              }
              channelData {
                ...ChannelDataFields
              }
              grantedFreespins
              usedFreespins
              freespinsAbbrKey
              gameTypeKey
              eligibleGamesKey
              allGamesKey
            }
            ... on EgsProviderRewardDetailsDto {
              channelData {
                ...ChannelDataFields
              }
              rewardType
              roundId
            }
            ... on LoyaltyBonusDetailsDto {
              amount
              currency
              loyaltyBonusDescKey
            }
            ... on SportFreebetDetailsDto {
              bonusId
              campaignId
              campaignName
              currency
              amount
              expiredAt
              betCursor
              betEventName
              betType
              betTypeKey
            }
          }
        }
      }
    }
  }
}
    ${EgsGameDetailsFieldsFragmentDoc}
${ChannelDataFieldsFragmentDoc}`;
const getMarketingDataCategoriesDocument = `
    query getMarketingDataCategories($options: EmptyQueryRequest!) {
  queries {
    accountHistory {
      getMarketingDataCategories(input: $options) {
        ts
        data {
          categories {
            category
            categoryKey
          }
        }
      }
    }
  }
}
    `;
const getMarketingStatusesDocument = `
    query getMarketingStatuses($options: EmptyQueryRequest!) {
  queries {
    accountHistory {
      getMarketingStatuses(input: $options) {
        ts
        data {
          statuses {
            status
            statusKey
          }
        }
      }
    }
  }
}
    `;
const getPaymentsDocument = `
    query getPayments($options: PaymentFindRequest!) {
  queries {
    accountHistory {
      getPayments(input: $options) {
        ts
        data {
          payments {
            ...AccountHistoryPaymentFields
          }
          nextPageCursor
        }
      }
    }
  }
}
    ${AccountHistoryPaymentFieldsFragmentDoc}`;
const getPaymentsCursorDocument = `
    query getPaymentsCursor($options: PaymentsFindRequestCursor!) {
  queries {
    accountHistory {
      getPaymentsCursor(input: $options) {
        ts
        data {
          cursorData {
            paymentId
            createdAt
            amount
            currency
            paymentStatus
            statusKey
            dataNameKey
            typeNameKey
            paymentDataType
            paymentDataCategory
            ... on PaymentLeaderboardCashRewardDto_PaymentDetails {
              rewardId
            }
            ... on PaymentEgsProviderRewardDto_PaymentDetails {
              channelData {
                ...ChannelDataFields
              }
              roundId
            }
            ... on PaymentMarketingSportBonusDepositDto_PaymentDetails {
              bonusId
              campaignId
              campaignName
            }
          }
        }
      }
    }
  }
}
    ${ChannelDataFieldsFragmentDoc}`;
const getPaymentsDataCategoriesDocument = `
    query getPaymentsDataCategories($options: EmptyQueryRequest!) {
  queries {
    accountHistory {
      getPaymentsDataCategories(input: $options) {
        ts
        data {
          categories {
            category
            categoryKey
          }
        }
      }
    }
  }
}
    `;
const getPaymentsStatusesDocument = `
    query getPaymentsStatuses($options: EmptyQueryRequest!) {
  queries {
    accountHistory {
      getPaymentsStatuses(input: $options) {
        ts
        data {
          statuses {
            status
            statusKey
          }
        }
      }
    }
  }
}
    `;
const getSportsbookBetsDocument = `
    query getSportsbookBets($options: SportsbookBetsFindRequest!) {
  queries {
    accountHistory {
      getSportsbookBets(input: $options) {
        ts
        data {
          bets {
            ...AccountHistorySportbookFields
          }
          nextPageCursor
        }
      }
    }
  }
}
    ${AccountHistorySportbookFieldsFragmentDoc}`;
const getSportsbookSportsDocument = `
    query getSportsbookSports($options: EmptyQueryRequest!) {
  queries {
    accountHistory {
      getSportsbookSports(input: $options) {
        ts
        data {
          sports {
            sportId
          }
        }
      }
    }
  }
}
    `;
const getVSportBetsDocument = `
    query getVSportBets($options: VSportBetsFindRequest!) {
  queries {
    accountHistory {
      getVSportBets(input: $options) {
        ts
        data {
          bets {
            betId
            createdAt
            currency
            odds
            stake
            winning
            vSportStatus
            type
            bankerCount
            selCount
            eventName
            marketName
            selName
            systemType
            betCursor
          }
          nextPageCursor
        }
      }
    }
  }
}
    `;
const getVSportSportsDocument = `
    query getVSportSports($options: EmptyQueryRequest!) {
  queries {
    accountHistory {
      getVSportSports(input: $options) {
        ts
        data {
          sports {
            sportId
          }
        }
      }
    }
  }
}
    `;
const doAchievementMarkAsViewedDocument = `
    query doAchievementMarkAsViewed($options: MarkAchievementAsViewedRequest!) {
  mutations {
    achievements {
      markAsViewed(input: $options) {
        ts
        data {
          anchorIgnore
          result
        }
      }
    }
  }
}
    `;
const doAchievementModifyAvatarDocument = `
    query doAchievementModifyAvatar($options: ModifyAvatarRequest!) {
  mutations {
    achievements {
      modifyAvatar(input: $options) {
        ts
        data {
          anchorIgnore
          image {
            src
            x1
            x2
            x3
            x1webp
            x2webp
            x3webp
          }
          background
        }
      }
    }
  }
}
    `;
const doAchievementRewardDocument = `
    query doAchievementReward($options: AchievementRewardRequest!) {
  mutations {
    achievements {
      reward(input: $options) {
        ts
        data {
          anchorIgnore
          id
          progress {
            expected
            actual
            unitKey
            unitName
          }
          category
          transitionRoute
          transitionParams {
            key
            value
          }
          rewards {
            id
            type
            status
            background
            description
            descriptionTitle
            image {
              src
              x1
              x2
              x3
              x1webp
              x2webp
              x3webp
            }
          }
          name
          description
          descriptionTitle
          image {
            src
            x1
            x2
            x3
            x1webp
            x2webp
            x3webp
          }
          satisfied
          viewed
          best
          satisfiedAt
        }
      }
    }
  }
}
    `;
const getAchievementDocument = `
    query getAchievement($options: AchievementRequest!) {
  queries {
    achievements {
      getAchievement(input: $options) {
        ts
        data {
          anchorIgnore
          id
          progress {
            expected
            actual
            unitKey
            unitName
          }
          category
          transitionRoute
          transitionParams {
            key
            value
          }
          rewards {
            id
            type
            status
            background
            description
            descriptionTitle
            image {
              src
              x1
              x2
              x3
              x1webp
              x2webp
              x3webp
            }
          }
          name
          description
          descriptionTitle
          image {
            src
            x1
            x2
            x3
            x1webp
            x2webp
            x3webp
          }
          satisfied
          viewed
          best
          satisfiedAt
        }
      }
    }
  }
}
    `;
const getAchievementsDocument = `
    query getAchievements($options: AchievementsRequest!) {
  queries {
    achievements {
      getAchievements(input: $options) {
        ts
        data {
          anchorIgnore
          items {
            anchorIgnore
            id
            progress {
              expected
              actual
              unitKey
              unitName
            }
            category
            rewards {
              id
              type
              status
            }
            name
            description
            descriptionTitle
            image {
              src
              x1
              x2
              x3
              x1webp
              x2webp
              x3webp
            }
            satisfied
            viewed
            best
            satisfiedAt
          }
          next {
            limit
            offset
            total
          }
        }
      }
    }
  }
}
    `;
const getAchievementsAvailabilityDocument = `
    query getAchievementsAvailability($options: EmptyQueryRequest!) {
  queries {
    achievements {
      getAvailability(input: $options) {
        ts
        data {
          anchorIgnore
          available
        }
      }
    }
  }
}
    `;
const getAchievementsAvatarsAndBackgroundsDocument = `
    query getAchievementsAvatarsAndBackgrounds($options: EmptyQueryRequest) {
  queries {
    achievements {
      getAvatarsAndBackgrounds(input: $options) {
        ts
        data {
          anchorIgnore
          avatars {
            id
            name
            image {
              src
              x1
              x2
              x3
              x1webp
              x2webp
              x3webp
            }
            background
            condition
            conditionTitle
            achievementId
            status
          }
          backgrounds {
            id
            name
            image {
              src
              x1
              x2
              x3
              x1webp
              x2webp
              x3webp
            }
            background
            condition
            conditionTitle
            achievementId
            status
          }
        }
      }
    }
  }
}
    `;
const getAchievementsCategoriesDocument = `
    query getAchievementsCategories($options: EmptyQueryRequest!) {
  queries {
    achievements {
      getCategories(input: $options) {
        ts
        data {
          anchorIgnore
          categories {
            name
            key
            order
          }
        }
      }
    }
  }
}
    `;
const getAchievementsProfileDocument = `
    query getAchievementsProfile($options: AchievementProfileRequest!) {
  queries {
    achievements {
      getProfile(input: $options) {
        ts
        data {
          anchorIgnore
          unlocked
          totalAchievements
          biggestWin
          totalWinnings
          currency
          nickname
          sharingUrl
          avatar {
            anchorIgnore
            image {
              src
              x1
              x2
              x3
              x1webp
              x2webp
              x3webp
            }
            background
          }
        }
      }
    }
  }
}
    `;
const doCollectRewardDocument = `
    query doCollectReward($options: CustomerLeveCollectRewardRequest!) {
  mutations {
    levels {
      collectReward(input: $options) {
        ts
        data {
          currentLevelId
          nextLevelId
          subLevelProgress {
            currentReward {
              id
              type
              bonusAmount
              cashbackPercent
              rewardName
              state
            }
            expectedTurnover
            rewards {
              id
              type
              bonusAmount
              cashbackPercent
              rewardName
              state
            }
          }
          levelSettings {
            levelId
            rewards {
              id
              state
            }
          }
        }
      }
    }
  }
}
    `;
const doConfirmParticipationDocument = `
    query doConfirmParticipation {
  mutations {
    levels {
      confirmParticipation {
        ts
        data {
          confirmed
        }
      }
    }
  }
}
    `;
const getBenefitCustomerLevelDocument = `
    query getBenefitCustomerLevel {
  queries {
    levels {
      customerLevel {
        ts
        data {
          programEnabled
          confirmed
          currentLevelId
          nextLevelId
          currentCashbackPercent
          nextPeriodCashbackPercent
          actualTurnover
          currency
          levelSettings {
            levelId
            progressStatus
            order
            expectedTurnover
            cashbackPercent
            viewSettings {
              image {
                ...Web2ImageFields
              }
              levelName
              description
            }
            rewards {
              id
              type
              bonusAmount
              cashbackPercent
              rewardName
              state
            }
            subLevelProgress {
              currentReward {
                id
                type
                bonusAmount
                cashbackPercent
                rewardName
                state
                expectedTurnover
              }
              rewards {
                id
                type
                bonusAmount
                cashbackPercent
                rewardName
                state
                expectedTurnover
              }
            }
          }
          benefits {
            image {
              ...Web2ImageFields
            }
            benefitName
            valueType
            availableForLevels {
              key
              value
            }
          }
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
const getBetlineLeagueStandingsDocument = `
    query getBetlineLeagueStandings($options: LeagueStandingsRequest!) {
  queries {
    betLine {
      getLeagueStandings(input: $options) {
        ts
        data {
          standings {
            ...LeagueStandingFields
          }
        }
      }
    }
  }
}
    ${LeagueStandingFieldsFragmentDoc}`;
const getBetlineLeagueStatusDocument = `
    query getBetlineLeagueStatus($options: LeagueIdRequest!) {
  queries {
    betLine {
      getLeagueStatus(input: $options) {
        ts
        data {
          enabledEntities
        }
      }
    }
  }
}
    `;
const getBetlineLeagueTopPlayersDocument = `
    query getBetlineLeagueTopPlayers($options: LeagueIdRequest!) {
  queries {
    betLine {
      getLeagueTopPlayersByLeagueId(input: $options) {
        ts
        data {
          assists {
            ...Scores24ScorerFields
          }
          scorers {
            ...Scores24ScorerFields
          }
        }
      }
    }
  }
}
    ${Scores24ScorerFieldsFragmentDoc}`;
const getBetlineMatchEventStatisticsDocument = `
    query getBetlineMatchEventStatistics($options: MatchStatisticsEventRequest!) {
  queries {
    betLine {
      getMatchStatisticsEvent(input: $options) {
        ts
        data {
          id
          matchDate
          family
          teams {
            ...MatchStatisticsTeamFields
          }
          joinMatches {
            ...MatchStatisticsMatchFields
          }
          homeMatches {
            ...MatchStatisticsMatchFields
          }
          awayMatches {
            ...MatchStatisticsMatchFields
          }
          scores24Head2Head {
            firstMatchYear
            draws
            total
            goals
            wins
          }
        }
      }
    }
  }
}
    ${MatchStatisticsTeamFieldsFragmentDoc}
${MatchStatisticsMatchFieldsFragmentDoc}`;
const getBetlineMatchStatisticsDocument = `
    query getBetlineMatchStatistics($options: MatchStatisticsRequest!) {
  queries {
    betLine {
      getMatchStatistics(input: $options) {
        ts
        data {
          id
          matchDate
          family
          resultScore
          teams {
            ...MatchStatisticsTeamFields
          }
          cards {
            team
            time
            color
            playerDto {
              ...MatchStatisticsPlayerFields
            }
          }
          goals {
            type
            awayTeamScore
            homeTeamScore
            time
            team
            assists
            disabled
            playerDto {
              ...MatchStatisticsPlayerFields
            }
          }
          scores {
            type
            value
          }
          substitutions {
            team
            time
            incoming {
              ...MatchStatisticsPlayerFields
            }
            outgoing {
              ...MatchStatisticsPlayerFields
            }
          }
          periods {
            type
            groups {
              type
              items {
                type
                name
                firstTeamPercent
                firstTeamValue
                firstTeamTotal
                secondTeamPercent
                secondTeamTotal
                secondTeamValue
              }
            }
          }
        }
      }
    }
  }
}
    ${MatchStatisticsTeamFieldsFragmentDoc}
${MatchStatisticsPlayerFieldsFragmentDoc}`;
const getCustomerMatchStreamEventsDocument = `
    query getCustomerMatchStreamEvents {
  queries {
    matchStream {
      getCustomerEvents {
        ts
        data {
          customerStreams
          result
        }
      }
    }
  }
}
    `;
const getCustomerStreamsDocument = `
    query getCustomerStreams {
  queries {
    matchStream {
      getCustomerStreams {
        ts
        data {
          result
          customerStreams {
            eventId
            isPreview
            isAvailableForPrematch
          }
        }
      }
    }
  }
}
    `;
const getLeagueDataDocument = `
    query getLeagueData($options: LeagueIdRequest!) {
  queries {
    betLine {
      getLeagueData(input: $options) {
        ts
        data {
          id
          family
          country {
            logo
            name
            iso
          }
          currentSeasonStart
          currentSeasonEnd
          currentSeasonYear
          logoUrl
          background {
            src {
              ...Web2ImageFields
            }
          }
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
const getLeaguePlayoffByLeagueIdDocument = `
    query getLeaguePlayoffByLeagueId($options: LeagueIdRequest!) {
  queries {
    betLine {
      getLeaguePlayoffByLeagueId(input: $options) {
        ts
        data {
          id
          name
          currentCupRound
          rounds {
            order
            description
            blocks {
              blockId
              blockName
              order
              matchesInRound
              result
              matches {
                ...MatchStatisticsMatchFields
              }
              participants {
                winner
                team {
                  ...Scores24TeamFields
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${MatchStatisticsMatchFieldsFragmentDoc}
${Scores24TeamFieldsFragmentDoc}`;
const getLeagueStandingsByLeagueIdDocument = `
    query getLeagueStandingsByLeagueId($options: LeagueIdRequest!) {
  queries {
    betLine {
      getLeagueStandingsByLeagueId(input: $options) {
        ts
        data {
          standings {
            ...LeagueStandingFields
          }
        }
      }
    }
  }
}
    ${LeagueStandingFieldsFragmentDoc}`;
const getLeagueStatisticsByLeagueIdDocument = `
    query getLeagueStatisticsByLeagueId($options: LeagueIdRequest!) {
  queries {
    betLine {
      getLeagueStatisticsByLeagueId(input: $options) {
        ts
        data {
          average
          sum
          overallGoals
          overallResults
        }
      }
    }
  }
}
    `;
const getMatchStreamUrlDocument = `
    query getMatchStreamUrl($options: MatchStreamInput!) {
  queries {
    matchStream {
      getMatchStreamUrl(input: $options) {
        ts
        data {
          src
          result
        }
      }
    }
  }
}
    `;
const doCalculatePackDocument = `
    query doCalculatePack($options: CalculatePackRequest!) {
  mutations {
    bonusGame {
      calculatePack(input: $options) {
        ts
        data {
          state
        }
      }
    }
  }
}
    `;
const doCalculateRoundDocument = `
    query doCalculateRound($options: CalculateRoundRequest!) {
  mutations {
    bonusGame {
      calculateRound(input: $options) {
        ts
        data {
          roundId
          calculatedAt
          rewardId
          details {
            rewardType
            freespinCount
            bonusAmount
            customerCurrency
            bonusPointMultiplier
            game {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;
const doCollectPackRewardDocument = `
    query doCollectPackReward($options: CollectPackRewardRequest!) {
  mutations {
    bonusGame {
      collectPackReward(input: $options) {
        ts
        data {
          state
        }
      }
    }
  }
}
    `;
const getAllPacksDocument = `
    query getAllPacks($options: CustomerPackRequest!) {
  queries {
    bonusGame {
      getAllPacks(input: $options) {
        ts
        data {
          packs {
            calculated
            closedAt
            collected
            day
            dayNumber
            startedAt
            state
            visualDetails {
              image {
                src
                x1
                x1webp
                x2
                x2webp
                x3
                x3webp
              }
              text
              title
            }
          }
        }
      }
    }
  }
}
    `;
const getPacksDocument = `
    query getPacks($options: CustomerPackRequest!) {
  queries {
    bonusGame {
      getPacks(input: $options) {
        ts
        data {
          campaignClosedAt
          closedAt
          day
          nextPackStartedAt
          state
          rewards {
            collected
            rewardId
            type
            bonusDetails {
              bonusAmount
              bonusAmountPercent
              currency
              freespinCount
              freespinGameIds
              maxBonusAmount
              termsOfActions
              campaignTerms
            }
            visualDetails {
              image {
                src
                x1
                x1webp
                x2
                x2webp
                x3
                x3webp
              }
              text
              title
            }
          }
        }
      }
    }
  }
}
    `;
const getRoundsDocument = `
    query getRounds($options: RoundsRequest!) {
  queries {
    bonusGame {
      getRounds(input: $options) {
        ts
        data {
          key
          round {
            roundId
            campaignStartDate
            campaignEndDate
            actualRoundStartDate
            actualRoundNumber
            roundsCount
            deposit
            canPurchaseAdditionalRound
            dayNumber
            active
            rewards {
              rewardId
              order
              type
              imageData {
                src
              }
            }
            calculatedResult {
              details {
                bonusAmount
                bonusAmount
                bonusPointMultiplier
                customerCurrency
                freespinCount
                rewardType
                game {
                  name
                  id
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
const doConfirmCashbackDocument = `
    query doConfirmCashback {
  mutations {
    cashback {
      confirmCashback {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doRefuseCashbackDocument = `
    query doRefuseCashback {
  mutations {
    cashback {
      refuseCashback {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const getCashbackDocument = `
    query getCashback {
  queries {
    cashback {
      getCashback {
        ts
        data {
          cashback {
            campaignStartDate
            campaignEndDate
            actualRoundEndDate
            actualRoundStartDate
            confirmation {
              confirmationState
              confirmedAt
              canceled_at
              declineReason
            }
            rounds {
              roundStartDate
              roundEndDate
              cashbackAmount
            }
            currency
            actionUrl
            categoryId
            suspended
            bonusAutoTransferEnabled
            wagerProgress {
              bonusId
              currentAmount
              requiredAmount
            }
          }
        }
      }
    }
  }
}
    `;
const addFavoriteGameDocument = `
    query addFavoriteGame($options: ChangeFavoriteGamesRequest!) {
  mutations {
    externalGames {
      favoriteGames {
        add(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
const getCasinoLoyaltyStatusDocument = `
    query getCasinoLoyaltyStatus {
  queries {
    bonuses {
      getCasinoLoyaltyStatus {
        ts
        data {
          id
          name
          currentPoints
          multiplier
          pointsUntilNextLevel
          levelSettings {
            id
            name
            description
            imageUrl
            background
            settingsBackground
            order
            requiredPoints
            multiplier
            freespinCount
            bonusPointsCount
            bonusAmount
            image {
              ...Web2ImageFields
            }
            freespinAvailable
            freespinSpent
            freespinGameId
            freespinProvider
          }
          hideEgsLoyaltyOnboardingPage
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
const getPromoJackpotsDocument = `
    query getPromoJackpots($options: PromoJackpotsQueryRequest!) {
  queries {
    externalGames {
      getPromoJackpots(input: $options) {
        ts
        data {
          jackpots {
            id
            providerId
            jackpots {
              amount {
                currency
                value
              }
            }
          }
        }
      }
    }
  }
}
    `;
const getPromotionsByEgsGameDocument = `
    query getPromotionsByEgsGame($options: GetPromotionsByEgsGameRequest!) {
  queries {
    promotions {
      getPromotionsByEgsGame(input: $options) {
        ts
        data {
          result
          promotions {
            actionUrl
            endDateNumber
          }
        }
      }
    }
  }
}
    `;
const getVpnConfigDocument = `
    query getVpnConfig($options: GetEgsGameRequest!) {
  queries {
    externalGames {
      getVpnConfigs(input: $options) {
        ts
        data {
          vpnConfigs {
            configuration
            login
            password
            provider
          }
        }
      }
    }
  }
}
    `;
const removeFavoriteGameDocument = `
    query removeFavoriteGame($options: ChangeFavoriteGamesRequest!) {
  mutations {
    externalGames {
      favoriteGames {
        remove(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
const checkPushTokenExistsDocument = `
    query checkPushTokenExists($options: NotificationCheckQueryRequest!) {
  queries {
    customer {
      notifications {
        check(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
const clearCookiesDocument = `
    query clearCookies($options: ClearCookieRequest!) {
  mutations {
    cookies {
      clearCookies(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doMarkDeviceUntrustedDocument = `
    query doMarkDeviceUntrusted($options: MarkDeviceUntrustedRequest!) {
  mutations {
    customer {
      markDeviceUntrusted(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doModifyTimeZoneDocument = `
    query doModifyTimeZone($options: ModifyTimeZoneRequest!) {
  mutations {
    customer {
      modifyTimeZone(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doRefuseBonusDocument = `
    query doRefuseBonus($options: RefuseBonusRequest!) {
  mutations {
    bonuses {
      refuseBonus(input: $options) {
        ts
        data {
          result
          refuseType
          writeOffAmount
          returnDepositAmount
          freebetAmount
          freespinCount
          currency
        }
      }
    }
  }
}
    `;
const getBonusMatrixDocument = `
    query getBonusMatrix($options: BonusMatrixRequest!) {
  queries {
    bonuses {
      getBonusMatrix(input: $options) {
        ts
        data {
          data {
            requirementType
            name
            requiredValue
            currentValue
            valueType
            satisfied
            bonusAmount
            freespin {
              count
              nominal
            }
          }
        }
      }
    }
  }
}
    `;
const getCustomerBonusesDocument = `
    query getCustomerBonuses($options: CustomerBonusesRequest!) {
  queries {
    bonuses {
      getCustomerBonuses(input: $options) {
        ts
        data {
          sport {
            ...SportCustomerBonus
          }
          egs {
            ...EgsCustomerBonus
          }
          hideOnBoardingPage
        }
      }
    }
  }
}
    ${SportCustomerBonusFragmentDoc}
${EgsCustomerBonusFragmentDoc}`;
const saveBetSlipConfigurationDocument = `
    query saveBetSlipConfiguration($options: SaveBetSlipConfigurationRequest!) {
  mutations {
    customer {
      slip {
        saveBetSlipConfiguration(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
const customerIdentityCheckDocument = `
    query customerIdentityCheck($options: CustomerIdentityCheckRequest!) {
  queries {
    customerIdentity {
      customerIdentityCheck(input: $options) {
        ts
        data {
          result
          isTrusted
        }
      }
    }
  }
}
    `;
const disableCustomerModeDocument = `
    query disableCustomerMode($options: G2SVCodeRequest!) {
  mutations {
    g2sv {
      disableCustomerMode(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doActivateAffiliateProgramDocument = `
    query doActivateAffiliateProgram($options: ActivateAffiliateProgramRequest!) {
  mutations {
    affiliate {
      activateAffiliateProgram(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doActivateBonusCodeDocument = `
    query doActivateBonusCode($options: JoinByBonusCodeRequest!) {
  mutations {
    bonuses {
      joinByBonusCode(input: $options) {
        ts
        data {
          result
          campaign {
            actionUrl
            name
            categoryId
          }
        }
      }
    }
  }
}
    `;
const doAddLoginDeviceDocument = `
    query doAddLoginDevice($options: AddLoginDeviceRequest!) {
  mutations {
    customer {
      addLoginDevice(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doAppsFlyerQualifiedDepositsReportedDocument = `
    query doAppsFlyerQualifiedDepositsReported($options: EmptyMutationRequest!) {
  mutations {
    finance {
      setDepositMetricsNotified(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doCashOutDocument = `
    query doCashOut($options: BetCashOutRequest!) {
  mutations {
    bet {
      doCashOut(input: $options) {
        ts
        data {
          status
          amount
          msg
          newAmount
          reason
          remainingDelay
        }
      }
    }
  }
}
    `;
const doChangeCustomerNicknameDocument = `
    query doChangeCustomerNickname($options: ChangeNicknameRequest!) {
  mutations {
    customer {
      changeNickname(input: $options) {
        ts
        data {
          msg
          result
        }
      }
    }
  }
}
    `;
const doChangeCustomerSettingsDocument = `
    query doChangeCustomerSettings($options: ChangeCustomSettingsRequest!) {
  mutations {
    customer {
      changeCustomerSettings(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doChangeLanguageDocument = `
    query doChangeLanguage($options: ChangeLanguageRequest!) {
  mutations {
    system {
      changeLanguage(input: $options) {
        ts
        data {
          currentLanguageTag
          currentLang
          urlLocale
        }
      }
    }
  }
}
    `;
const doChangePasswordDocument = `
    query doChangePassword($options: ChangePasswordRequest!) {
  mutations {
    customer {
      changePassword(input: $options) {
        ts
        data {
          msg
          result
        }
      }
    }
  }
}
    `;
const doConfirmBonusDocument = `
    query doConfirmBonus {
  mutations {
    bonuses {
      confirm {
        ts
        data {
          confirmed
        }
      }
    }
  }
}
    `;
const doCreateBonusCodeDocument = `
    query doCreateBonusCode($options: CreateBonusCodeRequest!) {
  mutations {
    affiliate {
      createBonusCode(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doDeleteAccountDocument = `
    query doDeleteAccount {
  mutations {
    account {
      setSelfArchive(input: {}) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doEncashBonusDocument = `
    query doEncashBonus($options: EncashBonusRequest!) {
  mutations {
    finance {
      encashBonus(input: $options) {
        ts
        data {
          msg
          result
        }
      }
    }
  }
}
    `;
const doEventSubscribeDocument = `
    query doEventSubscribe($options: BetLineChangeSubscribeRequest!) {
  mutations {
    betLine {
      change {
        eventSubscribe(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
const doEventUnsubscribeDocument = `
    query doEventUnsubscribe($options: BetLineChangeUnsubscribeRequest!) {
  mutations {
    betLine {
      change {
        eventUnsubscribe(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
const doFavoriteSportEventRemoveDocument = `
    query doFavoriteSportEventRemove($options: ChangeCustomerMatchRequest!) {
  mutations {
    customer {
      matches {
        removeMatch(input: $options) {
          ts
          data {
            count
          }
        }
      }
    }
  }
}
    `;
const doFavoriteSportEventsAddDocument = `
    query doFavoriteSportEventsAdd($options: ChangeCustomerMatchesRequest!) {
  mutations {
    customer {
      matches {
        addMatches(input: $options) {
          ts
          data {
            count
          }
        }
      }
    }
  }
}
    `;
const doLoginDocument = `
    query doLogin($options: LoginInput!) {
  mutations {
    auth {
      login(loginInput: $options) {
        ts
        data {
          phoneIdentificationRequired
          showFortuneWheelWindow
        }
      }
    }
  }
}
    `;
const doLoginCasDocument = `
    query doLoginCas($options: LoginSsoInput!) {
  mutations {
    auth {
      loginSso(loginInput: $options) {
        ts
        data {
          phoneIdentificationRequired
          showFortuneWheelWindow
        }
      }
    }
  }
}
    `;
const doLoginFingerprintDocument = `
    query doLoginFingerprint($options: LoginFingerprintInput!) {
  mutations {
    auth {
      loginFingerprint(loginInput: $options) {
        ts
        data {
          phoneIdentificationRequired
          showFortuneWheelWindow
        }
      }
    }
  }
}
    `;
const doLoginG2svDocument = `
    query doLoginG2sv($options: LoginG2SVInput!) {
  mutations {
    auth {
      loginG2sv(loginInput: $options) {
        ts
        data {
          phoneIdentificationRequired
          showFortuneWheelWindow
        }
      }
    }
  }
}
    `;
const doLoginPinCodeDocument = `
    query doLoginPinCode($options: LoginPinCodeInput!) {
  mutations {
    auth {
      loginPinCode(loginInput: $options) {
        ts
        data {
          customerData {
            customerLogin
          }
        }
      }
    }
  }
}
    `;
const doPostponeWebPushTokenDocument = `
    query doPostponeWebPushToken($options: EmptyMutationRequest!) {
  mutations {
    webPushNotifications {
      postpone(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doReadCustomerNotificationDocument = `
    query doReadCustomerNotification($options: UpdateMessageStatusRequest!) {
  mutations {
    customer {
      updateMessageStatus(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doRefuseMeetingDocument = `
    query doRefuseMeeting($options: EmptyMutationRequest!) {
  mutations {
    verification {
      refuse(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doRegisterCustomerOrderDocument = `
    query doRegisterCustomerOrder($options: RegisterShopOrderRequest!) {
  mutations {
    leonShop {
      registerCustomerOrder(input: $options) {
        ts
        data {
          orderId
          order {
            campaignType
            freespinGameIds
          }
        }
      }
    }
  }
}
    `;
const doRegisterPushTokenDocument = `
    query doRegisterPushToken($options: NotificationRegisterV2Request!) {
  mutations {
    customer {
      notifications {
        registerV2(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
const doRequestCallbackDocument = `
    query doRequestCallback($options: RequestCallbackRequest!) {
  mutations {
    callback {
      request(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doSaveBetSlipDocument = `
    query doSaveBetSlip($options: BetSlipSaveRequest!) {
  mutations {
    betSlip {
      saveBetSlip(input: $options) {
        ts
        data {
          bookingCode
          sharedLink
          imgSrc
        }
      }
    }
  }
}
    `;
const doSaveBetSlipByBetIdDocument = `
    query doSaveBetSlipByBetId($options: BetSlipSaveByBetRequest!) {
  mutations {
    betSlip {
      saveBetSlipByBet(input: $options) {
        ts
        data {
          bookingCode
          sharedLink
          imgSrc
        }
      }
    }
  }
}
    `;
const doSaveFastBetsDocument = `
    query doSaveFastBets($options: [Int!]!) {
  mutations {
    customer {
      saveFastBets(fastBets: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doSaveLogDocument = `
    query doSaveLog($options: SaveLogInput!) {
  mutations {
    logging {
      saveLog(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doSavePriceChangePolicyV2Document = `
    query doSavePriceChangePolicyV2($options: PriceChangePolicyRequest!) {
  mutations {
    customer {
      savePriceChangePolicyV2(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doSaveShowMaxFastBetDocument = `
    query doSaveShowMaxFastBet($options: SaveShowMaxFastBetRequest!) {
  mutations {
    customer {
      saveShowMaxFastBet(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doSaveSportFiltersDocument = `
    query doSaveSportFilters($options: SportFiltersRequest!) {
  mutations {
    customer {
      saveSportFilters(sportFilters: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doScheduleMeetingDocument = `
    query doScheduleMeeting($options: ScheduleMeetingRequest!) {
  mutations {
    verification {
      scheduleMeeting(input: $options) {
        ts
        data {
          roomUrl
        }
      }
    }
  }
}
    `;
const doSelfExclusionDocument = `
    query doSelfExclusion($options: DoSelfExclusionRequest!) {
  mutations {
    limits {
      doSelfExclusion(input: $options) {
        ts
        data {
          result
          selfExclusion {
            until
            from
            isSelfExcluded
          }
        }
      }
    }
  }
}
    `;
const doSelfExclusionFlexDocument = `
    query doSelfExclusionFlex($options: SetSelfExclusionRequest!) {
  mutations {
    limits {
      doSelfExclusionFlex(input: $options) {
        ts
        data {
          result
          selfExclusion {
            until
            from
            isSelfExcluded
          }
        }
      }
    }
  }
}
    `;
const doSendFeedbackDocument = `
    query doSendFeedback($options: SendFeedbackRequest!) {
  mutations {
    feedback {
      send(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doSendWeb2FeedbackDocument = `
    query doSendWeb2Feedback($options: Web2FeedbackRequest!) {
  mutations {
    feedback {
      sendWeb2Feedback(input: $options) {
        data {
          result
        }
        ts
      }
    }
  }
}
    `;
const doSendWeb2FeedbackByCategoryDocument = `
    query doSendWeb2FeedbackByCategory($options: Web2FeedbackByCategoryRequest!) {
  mutations {
    feedback {
      sendWeb2FeedbackByCategory(input: $options) {
        data {
          result
        }
        ts
      }
    }
  }
}
    `;
const doToggleCustomerLeagueDocument = `
    query doToggleCustomerLeague($options: ToggleLeagueRequest!) {
  mutations {
    customer {
      leagues {
        toggleLeague(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
const doTsupisCardDeleteDocument = `
    query doTsupisCardDelete($options: TsupisCardDeleteRequest!) {
  mutations {
    finance {
      tsupisCardDelete(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doUpdateCustomerDataDocument = `
    query doUpdateCustomerData($options: UpdateUserDataRequest!) {
  mutations {
    customer {
      updateUserData(input: $options) {
        ts
        data {
          msg
          result
        }
      }
    }
  }
}
    `;
const doUpdateNotificationConsentsDocument = `
    query doUpdateNotificationConsents($options: UpdateNotificationConsentsRequest!) {
  mutations {
    customer {
      updateNotificationConsents(input: $options) {
        ts
        data {
          msg
          result
        }
      }
    }
  }
}
    `;
const doWithdrawAllSportBonusesDocument = `
    query doWithdrawAllSportBonuses($options: EmptyMutationRequest) {
  mutations {
    bonuses {
      withdrawAllSportBonuses(input: $options) {
        ts
        data {
          withdrawnAmount
          unwithdrawnAmount
        }
      }
    }
  }
}
    `;
const doWithdrawSportBonusDocument = `
    query doWithdrawSportBonus($options: WithdrawSportBonusRequest!) {
  mutations {
    bonuses {
      withdrawSportBonus(input: $options) {
        ts
        data {
          withdrawnAmount
          unwithdrawnAmount
        }
      }
    }
  }
}
    `;
const doWithdrawalCancelDocument = `
    query doWithdrawalCancel($options: WithdrawalCancelRequest!) {
  mutations {
    finance {
      withdrawal {
        cancel(input: $options) {
          ts
          data {
            result
            msg
          }
        }
      }
    }
  }
}
    `;
const getEgsCategoriesDocument = `
    query getEgsCategories($options: EgsCategoriesRequest!) {
  queries {
    externalGames {
      getCategories(input: $options) {
        ts
        data {
          result
          categories {
            ...EgsGameCategoriesFields
          }
        }
      }
    }
  }
}
    ${EgsGameCategoriesFieldsFragmentDoc}`;
const getEgsGameByIdDocument = `
    query getEgsGameById($options: GetEgsGameRequest!) {
  queries {
    externalGames {
      getGame(input: $options) {
        ts
        data {
          game {
            ...EgsGameFields
          }
        }
      }
    }
  }
}
    ${EgsGameFieldsFragmentDoc}`;
const getEgsGameByUrlDocument = `
    query getEgsGameByUrl($options: GetEgsGameByUrlRequest!) {
  queries {
    externalGames {
      getGameByUrl(input: $options) {
        ts
        data {
          game {
            ...EgsGameFields
          }
        }
      }
    }
  }
}
    ${EgsGameFieldsFragmentDoc}`;
const getEgsGamesDocument = `
    query getEgsGames($options: EgsGamesRequest!) {
  queries {
    externalGames {
      getGames(input: $options) {
        ts
        data {
          games {
            ...EgsGameFields
          }
          next {
            ...PaginationCursorItemFields
          }
        }
      }
    }
  }
}
    ${EgsGameFieldsFragmentDoc}
${PaginationCursorItemFieldsFragmentDoc}`;
const getEgsGroupsDocument = `
    query getEgsGroups($options: EgsGroupsRequest!) {
  queries {
    externalGames {
      getGroups(input: $options) {
        ts
        data {
          result
          groups {
            ...EgsGroupFields
          }
        }
      }
    }
  }
}
    ${EgsGroupFieldsFragmentDoc}`;
const getEgsTopWinnersDocument = `
    query getEgsTopWinners($options: EgsTopWinnersRequest!) {
  queries {
    externalGames {
      getTopWinners(input: $options) {
        ts
        data {
          winners {
            ...EgsTopWinnerFields
          }
          next {
            limit
            offset
          }
        }
      }
    }
  }
}
    ${EgsTopWinnerFieldsFragmentDoc}`;
const startEgsGameDocument = `
    query startEgsGame($options: EgsStartGameRequest!) {
  mutations {
    externalGames {
      startGame(input: $options) {
        ts
        data {
          gameUrl
          startCode
          maxBetLimit {
            amount
            currency
          }
          freeSpinInfo {
            amount
            currency
            count
            left
          }
          hideLimitModalOnGamePage
          showBonusMaxBetLimitPopup
        }
      }
    }
  }
}
    `;
const enableCustomerModeDocument = `
    query enableCustomerMode($options: G2SVEnableCustomerModeRequest!) {
  mutations {
    g2sv {
      enableCustomerMode(input: $options) {
        ts
        data {
          disposableKeys
        }
      }
    }
  }
}
    `;
const enableFingerprintLoginDocument = `
    query enableFingerprintLogin($options: EnableFingerprintLoginRequest!) {
  mutations {
    customer {
      enableFingerprintLogin(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const generateNicknameDocument = `
    query generateNickname($options: GenerateNicknameRequest!) {
  queries {
    customer {
      generateNickname(input: $options) {
        ts
        data {
          nickname
        }
      }
    }
  }
}
    `;
const getAccountTransactionDocument = `
    query getAccountTransaction($options: AccountTransactionRequest!) {
  queries {
    accountTransaction {
      getTransactionInfo(input: $options) {
        ts
        data {
          transaction {
            status
            transactionGroup
            typeName
            statusColor
            ... on BetTx {
              status
              transactionGroup
              acceptDate
              lName
              eventId
              isCalculated
              cashoutAmount
              sName
              type
              oddsType
              odd
              winVal
              winValStr
              stake
              bonusPoints
              isLive
              maxWin
              name
              id
              startDate
              outcome
              sportRulesInterval
              freeBetId
              liveStatus {
                score
                stage
                setScores
                progress
                stageCode
              }
              items {
                winStatusClass
                winStatus
                startDate
                sName
                lName
                name
                runnerName
                oddsType
                odd
                isBanker
                isLive
                score
              }
              combiType
              combiStatus
              statusColor
              currency
            }
            ... on PaymentTx {
              transactionGroup
              amount
              date
              currency
              purse
              status
              isWithdrawCancellable
              statusColor
              currency
              statusId
            }
            ... on CustomerAccountHistoryTx {
              id
              timestamp
              credit
              debit
              currency
            }
          }
        }
      }
    }
  }
}
    `;
const getAffiliatedCustomersDocument = `
    query getAffiliatedCustomers($options: AffiliatedLoginsRequest!) {
  queries {
    affiliate {
      getAffiliatedCustomers(input: $options) {
        ts
        data {
          affiliatedCustomers {
            login
            bonusCode
            createdAt
          }
        }
      }
    }
  }
}
    `;
const getApiSettingsDocument = `
    query getApiSettings {
  queries {
    system {
      apiSettings {
        getApiSettings {
          ts
          data {
            graphqlBatchRequestsMaxSize
          }
        }
      }
    }
  }
}
    `;
const getAuthenticatorUriDocument = `
    query getAuthenticatorUri {
  queries {
    g2sv {
      getAuthenticatorUri {
        ts
        data {
          authenticatorUri
          secret
        }
      }
    }
  }
}
    `;
const getAuthenticatorUriQRCodeDocument = `
    query getAuthenticatorUriQRCode($options: QRCodeRequest!) {
  queries {
    g2sv {
      getQRCode(input: $options) {
        ts
        data {
          qrCode
        }
      }
    }
  }
}
    `;
const getAvailableBonusesForTransferDocument = `
    query getAvailableBonusesForTransfer($options: EmptyQueryRequest) {
  queries {
    bonuses {
      getAvailableBonusesForTransfer(input: $options) {
        ts
        data {
          frozen {
            type
            balance
          }
          totalTransferAmount
          totalBonusAmount
          currencyCode
        }
      }
    }
  }
}
    `;
const getAvailableFormsDocument = `
    query getAvailableForms {
  queries {
    registration {
      getAvailableForms {
        ts
        data {
          isExcludedCountry
          forms {
            uiFormSchema {
              order
              fields {
                isFrontOnly
                id
                widget
                hidden
                title
                defaultValue
                disabled
                hintMessage
                type
                encrypted
                labels {
                  key
                  value
                }
                options {
                  key
                  value
                }
              }
            }
            formValidationSchema
            registrationType
            trigger
            formError
            formErrorCaption
            formSuccess
            validated
            hasFieldsErrors
            duplicateUser
            formName
            affiliateId
            stage
            priority
            failsCount
            schemaId
            formStep
            formProgress
            sendSms {
              formParams {
                key
                value
              }
              schemaId
            }
            goBack {
              formParams {
                key
                value
              }
              schemaId
            }
          }
          regBannerUrl
        }
      }
    }
  }
}
    `;
const getAvailableMeetingSlotsDocument = `
    query getAvailableMeetingSlots($options: AvailableMeetingSlotsRequest) {
  queries {
    verification {
      getAvailableMeetingSlots(input: $options) {
        ts
        data {
          availableMeetingSlots {
            id
            endTime
            startTime
            roomUrl
          }
        }
      }
    }
  }
}
    `;
const getBalanceDocument = `
    query getBalance($options: EmptyQueryRequest) {
  queries {
    finance {
      getBalance(input: $options) {
        ts
        data {
          balance
          lastModifiedAt
          cbcBalance
          currency
          depositMetrics {
            status
            firstDeposit
            paymentSystemId
            systemAmount
            paymentId
          }
          cashbackBalance
          cashbackEndDate
        }
      }
    }
  }
}
    `;
const getBannerDocument = `
    query getBanner($options: GetBannerRequest!) {
  queries {
    banners {
      getBanner(input: $options) {
        ts
        data {
          result
          banner {
            ...BannerData
          }
        }
      }
    }
  }
}
    ${BannerDataFragmentDoc}`;
const getBannersDocument = `
    query getBanners {
  queries {
    banners {
      getBanners {
        ts
        data {
          bannerGroups {
            id
            name
            weight
            isSlider
            displayRoutes {
              name
              parameters {
                name
                availableValues
              }
            }
            autoplayDelay
            isNT
            banners {
              ...BannerData
            }
          }
        }
      }
    }
  }
}
    ${BannerDataFragmentDoc}`;
const getBatchSlipInfoDocument = `
    query getBatchSlipInfo($options: BatchSlipInfoRequest!) {
  queries {
    betSlip {
      getBatchSlipInfo(input: $options) {
        ts
        data {
          slipEntries {
            ...SlipInfoFields
          }
          combiAvailable
          accumulatorBoostMinOddsStr
        }
      }
    }
  }
}
    ${SlipInfoFieldsFragmentDoc}`;
const getBetDocument = `
    query getBet($options: BetDetailsRequest!) {
  queries {
    bet {
      getBet(input: $options) {
        ts
        data {
          skksInfo {
            id
            hash
            operationDate
            operationType
          }
          amount {
            bet {
              betId
              sportcatalogBet
              curAbbrev
              acceptDate
              calculationDate
              statusNameServer
              betLine
              sport
              league
              sportId
              eventId
              marketId
              eventName
              startDate
              oddsType
              outcomeStr
              odds
              maxWin
              stake
              winning
              combiStatus
              webshopCancelable
              items {
                sportId
                marketId
                sportName
                marketName
                leagueName
                runnerName
                eventName
                winStatus
                eventStartTime
                oddsType
                betId
                price
                isBanker
              }
              bonusPoints
              hasPincode
              runnerId
              asianId
              oddsTypeId
              oddsTypeOutcome
            }
            statusCode
            status
            isLive
            cashout
            bankers
            isReturn
            isWin
            isCalculated
            isVSport
            winStr
            vsportStatLinks {
              key
              value
            }
            combiTypeText
          }
          status
        }
      }
    }
  }
}
    `;
const getBetLimitsDocument = `
    query getBetLimits($options: CustomerLimitsRequest) {
  queries {
    limits {
      getBetLimits(input: $options) {
        ts
        data {
          limits {
            dailyLimit
            weeklyLimit
            monthlyLimit
            dailyPercent
            dailyLeft
            dailyEndAt
            weeklyPercent
            weeklyLeft
            weeklyEndAt
            monthlyPercent
            monthlyLeft
            monthlyEndAt
          }
          limitsUpdatableAt {
            dailyUpdatableAt
            weeklyUpdatableAt
            monthlyUpdatableAt
          }
        }
      }
    }
  }
}
    `;
const getBetSlipDocument = `
    query getBetSlip($options: BetSlipRequest!) {
  queries {
    betSlip {
      getBetSlip(input: $options) {
        ts
        data {
          slipEntries {
            ts
            event
            market
            runner
            odds
            oddsStr
            banker
            marketStatus
            betline
            zeroMargin
            competitors
            eventName
            runnerName
            marketName
            primaryMarket
            runnerTags
            marketTypeIdentifier
            marketTypeTag
          }
          slipType
          slipSize
        }
      }
    }
  }
}
    `;
const getBetgeniusWidgetConfigurationDocument = `
    query getBetgeniusWidgetConfiguration($options: EmptyQueryRequest!) {
  queries {
    configContent {
      getBetgeniusWidgetConfiguration(input: $options) {
        ts
        data {
          active
          productionUrl
          customLang
          activeSports
          widgetStyles
        }
      }
    }
  }
}
    `;
const getBonusCodesDocument = `
    query getBonusCodes($options: EmptyQueryRequest) {
  queries {
    affiliate {
      getBonusCodes(input: $options) {
        ts
        data {
          bonusCodes {
            id
            bonusCode
            link
          }
          maxCount
        }
      }
    }
  }
}
    `;
const getBonusLoyaltyDocument = `
    query getBonusLoyalty {
  queries {
    bonuses {
      getBonusLoyalty {
        ts
        data {
          loyalty {
            amount
            amountNumber
            progress
            progressPercentString
            remainingAmount
            remainingAmountNumber
            isParticipating
          }
          hideOnBoardingOnLeonShopPage
          showExchangeTitle
        }
      }
    }
  }
}
    `;
const getBonusTransferAmountDocument = `
    query getBonusTransferAmount($options: BonusTransferAmountRequest!) {
  queries {
    bonuses {
      getBonusTransferAmount(input: $options) {
        ts
        data {
          transferAmount
          currencyCode
        }
      }
    }
  }
}
    `;
const getBonusWagersDocument = `
    query getBonusWagers($options: BonusWagersRequest!) {
  queries {
    bonuses {
      getBonusWagers(input: $options) {
        ts
        data {
          bonuses {
            category
            currency
            progress
            state
            walletBalanceNumber
            widgetName
            ... on Bonus {
              amountNumber
              availableAmountNumber
              requiredAmountNumber
              campaignName
              expiredAt
              expiringThrough
              expiredTimestamp
              isExpired
              bonusId
              actionUrl
              categoryId
            }
            ... on BonusAccumulative {
              isLeonshopShown
              bonusCurrency
              remainingAmountNumber
              bonusAmountNumber
            }
            ... on BonusFirstDeposit {
              isLeonshopShown
              bonusCurrency
              requiredAmountNumber
              bonusAmountNumber
            }
          }
        }
      }
    }
  }
}
    `;
const getBonusesDocument = `
    query getBonuses($options: EmptyQueryRequest) {
  queries {
    bonuses {
      getBonuses(input: $options) {
        ts
        data {
          anchorIgnore
          bonuses {
            bonusCode
            campaignId
            campaignName
            campaignType
            minDepositAmount {
              amount
              currency
            }
            maxDepositAmount {
              amount
              currency
            }
            depositAmountPercent
            description
            terms
            imgUrl
            actionUrl
            categoryId
            image {
              ...Web2ImageFields
            }
          }
          isBonusChooserShownBeforeDeposits
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
const getCampaignCouponsDocument = `
    query getCampaignCoupons($options: CampaignCouponsRequest!) {
  queries {
    bonusGame {
      getCampaignCoupons(input: $options) {
        ts
        data {
          total
          type
          coupons
        }
      }
    }
  }
}
    `;
const getCashoutOfferDocument = `
    query getCashoutOffer($options: BetCashOutRequest!) {
  queries {
    bet {
      getOffer(input: $options) {
        ts
        data {
          status
          msg
          amount
        }
      }
    }
  }
}
    `;
const getCmsContentSpintaxBatchTranslationsDocument = `
    query getCmsContentSpintaxBatchTranslations($options: SpintaxBatchContentRequest!) {
  queries {
    system {
      cms {
        getSpintaxBatch(input: $options) {
          ts
          data {
            absent
            url
            key
            type
            title
            translations {
              lang
              html
            }
            updatedAt
            deleted
          }
        }
      }
    }
  }
}
    `;
const getCmsContentTranslationsDocument = `
    query getCmsContentTranslations($options: ContentInput!) {
  queries {
    system {
      cms {
        content(input: $options) {
          ts
          data {
            translations {
              html
              lang
            }
          }
        }
      }
    }
  }
}
    `;
const getConsentsDocument = `
    query getConsents($options: EmptyQueryRequest!) {
  queries {
    customer {
      notifications {
        getConsents(input: $options) {
          ts
          data {
            consents {
              pushNotification
              email
              sms
              telephone
              siteNotification
            }
          }
        }
      }
    }
  }
}
    `;
const getCookieDocument = `
    query getCookie($options: InitInput!) {
  queries {
    cookies {
      getCookies(input: $options) {
        ts
        data {
          cookies {
            name
            value
          }
        }
      }
    }
  }
}
    `;
const getCustomerHistoryDocument = `
    query getCustomerHistory($options: CustomerHistoryRequest!) {
  queries {
    customer {
      getHistory(input: $options) {
        ts
        data {
          count
          items {
            credit
            debet
            id
            kind
            eventStartDateGMT
            status
            transactionGroup
            transactionType
            transactionTypeId
            comments
            rowId
            accountType
            typeName
            ... on BetHistoryItem {
              coefficient
              isBetSold
              isCombiOrSystem
              isWin
              combiTypeText
              isEnabledForCashout
            }
            ... on PaymentHistoryItem {
              isWithdrawCancellable
            }
          }
          result
          message
        }
      }
    }
  }
}
    `;
const getCustomerHistoryItemDocument = `
    query getCustomerHistoryItem($options: CustomerHistoryItemRequest!) {
  queries {
    account {
      getHistoryItemV2(input: $options) {
        ts
        data {
          item {
            accountType
            credit
            date
            debit
            objectId
            objectType
            rootTxId
            status
            statusName
            txTypeKind
            txTypeName
            transactionTypeId
            itemStatusColor
            cardOrder
            ... on ExtendedAccountLeonShopHistoryItem {
              placeholders {
                value
                type
                name
                currency
              }
            }
            ... on ExtendedAccountBetHistoryItem {
              betClientPriceWithoutAccumulatorBoost
              betClientPriceStrWithoutAccumulatorBoost
              creditBeforeTax
              betEventParts
              accumulatorBoost
              betType
              betClientPrice
              betClientPriceStr
              combiStatus
              cashoutAmount
              combiStatusText
              countItems
              betMarket
              betOutcome
              taxAmount
              taxPercent
              bonusPoints
              betSystemN
              betSystemM
              freebetId
              betEventId
              betLine
              betLeague
              liveStatus {
                penaltyScore
                progress
                score
                setScores
                stage
                stageCode
              }
              maxWin
              kickoff
              outcomeState
              outcomeStateText
              outcomeStateColor
              eventUrl {
                eventId
                event
                league
                region
                sport
              }
              betItems {
                banker
                betLine
                event
                eventParts
                eventUrl {
                  eventId
                  event
                  league
                  region
                  sport
                }
                kickoff
                league
                liveStatus {
                  penaltyScore
                  progress
                  score
                  setScores
                  stage
                  stageCode
                }
                matchResult {
                  score {
                    away
                    home
                  }
                }
                market
                outcome
                outcomeState
                outcomeStateColor
                outcomeStateText
                price
                priceStr
              }
              matchResult {
                phaseScores {
                  score {
                    away
                    home
                  }
                }
                score {
                  away
                  home
                }
                placeBetMatchScore {
                  away
                  home
                }
                penaltyScore {
                  away
                  home
                }
                extraTimeMatchScore {
                  away
                  home
                }
                yellowCards {
                  away
                  home
                }
                redCards {
                  away
                  home
                }
                yellowRedCards {
                  away
                  home
                }
                cornersScore {
                  away
                  home
                }
              }
            }
            ... on ExtendedAccountPaymentHistoryItem {
              withdrawCancellable
              fromAccountType
              toAccountType
              taxAmount
              errorCode
              errorMessage
              ndflAmounts {
                origAmount
                taxedAmnt
                tax
              }
            }
            ... on ExtendedAccountEgsHistoryItem {
              odds
              egsGameName
            }
          }
        }
      }
    }
  }
}
    `;
const getCustomerHistoryListDocument = `
    query getCustomerHistoryList($options: CustomerHistoryRequest!) {
  queries {
    account {
      getHistory(input: $options) {
        ts
        data {
          count
          items {
            accountType
            credit
            date
            debit
            objectId
            objectType
            rootTxId
            status
            statusName
            txTypeKind
            txTypeName
            transactionTypeId
            itemStatusColor
            cardOrder
            ... on AccountBetHistoryItem {
              betEventParts
              betType
              betClientPrice
              betClientPriceStr
              combiStatus
              cashoutAmount
              combiStatusText
              countItems
              betMarket
              betOutcome
              outcomeState
              taxAmount
            }
            ... on AccountPaymentHistoryItem {
              errorCode
              errorMessage
              withdrawCancellable
            }
            ... on AccountLeonShopHistoryItem {
              placeholders {
                value
                type
                name
                currency
              }
            }
          }
        }
      }
    }
  }
}
    `;
const getCustomerLeagueDocument = `
    query getCustomerLeague($options: GetLeagueRequest!) {
  queries {
    customer {
      leagues {
        getLeague(input: $options) {
          ts
          data {
            leaguesId
          }
        }
      }
    }
  }
}
    `;
const getCustomerLeaguesDocument = `
    query getCustomerLeagues($options: LeaguesRequest!) {
  queries {
    customer {
      leagues {
        getLeagues(input: $options) {
          ts
          data {
            myLeagues {
              leagues {
                leaguesId
              }
            }
            result
          }
        }
      }
    }
  }
}
    `;
const getCustomerMatchesDocument = `
    query getCustomerMatches($options: CustomerMatchesIdRequest!) {
  queries {
    customer {
      matches {
        listMatchesIds(input: $options) {
          ts
          data {
            ids
          }
        }
      }
    }
  }
}
    `;
const getCustomerNotificationsDocument = `
    query getCustomerNotifications($options: OperatorMessagesRequest!) {
  queries {
    customer {
      listOperatorMessages(input: $options) {
        ts
        data {
          allOperatorMessages {
            id
            title
            message
            timestampCreation
            status
          }
        }
      }
    }
  }
}
    `;
const getCustomerNotificationsCountDocument = `
    query getCustomerNotificationsCount($options: CountUnreadMessagesRequest!) {
  queries {
    customer {
      countUnreadMessages(input: $options) {
        ts
        data {
          unreadMessagesCount
        }
      }
    }
  }
}
    `;
const getCustomerOffersDocument = `
    query getCustomerOffers {
  queries {
    leonShop {
      getCustomerOffers {
        ts
        data {
          offers {
            id
            name
            price
            imageUrl
            description
            image {
              ...Web2ImageFields
            }
            placeholders {
              name
              type
              value
              currency
            }
          }
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
const getCustomerOrdersDocument = `
    query getCustomerOrders($options: PaginationRequest!) {
  queries {
    leonShop {
      getCustomerOrders(input: $options) {
        ts
        data {
          orders {
            content {
              id
              price
              status
              createdAt
            }
            empty
            first
            last
            number
            numberOfElements
            size
            sort {
              empty
              sorted
              unsorted
            }
            totalElements
            totalPages
          }
        }
      }
    }
  }
}
    `;
const getCustomerProfileFieldsDocument = `
    query getCustomerProfileFields {
  queries {
    registration {
      getCustomerProfileFields {
        ts
        data {
          uiFormSchema {
            order
            fields {
              isFrontOnly
              id
              widget
              hidden
              title
              defaultValue
              disabled
              hintMessage
              type
              labels {
                key
                value
              }
              options {
                key
                value
              }
            }
          }
          formValidationSchema
          schemaId
          formStep
        }
      }
    }
  }
}
    `;
const getCustomerVerifyDataDocument = `
    query getCustomerVerifyData {
  queries {
    customer {
      getDocVerifyData {
        ts
        data {
          verifyData {
            supportedFormats
            maxExtensionSize
            maxFilesNumber
            documentReasons {
              id
              localizedName
            }
          }
        }
      }
    }
  }
}
    `;
const getDepositLimitsDocument = `
    query getDepositLimits($options: CustomerLimitsRequest) {
  queries {
    limits {
      getDepositLimits(input: $options) {
        ts
        data {
          limits {
            dailyLimit
            weeklyLimit
            monthlyLimit
            dailyPercent
            dailyLeft
            dailyEndAt
            weeklyPercent
            weeklyLeft
            weeklyEndAt
            monthlyPercent
            monthlyLeft
            monthlyEndAt
          }
          limitsUpdatableAt {
            dailyUpdatableAt
            weeklyUpdatableAt
            monthlyUpdatableAt
          }
        }
      }
    }
  }
}
    `;
const getEncashBonusDataDocument = `
    query getEncashBonusData {
  queries {
    finance {
      encashBonusData {
        ts
        data {
          max
          min
          oneCoin
          result
        }
      }
    }
  }
}
    `;
const getFAQCategoriesDocument = `
    query getFAQCategories($options: EmptyQueryRequest) {
  queries {
    faq {
      getCategories(input: $options) {
        data {
          categories {
            urlId
            title
            items {
              urlId
              title
              content
            }
          }
        }
        ts
      }
    }
  }
}
    `;
const getFAQItemsDocument = `
    query getFAQItems($options: FAQListRequest!) {
  queries {
    faq {
      getList(input: $options) {
        data {
          items {
            id
            order
            title
            text
            categories
          }
        }
        ts
      }
    }
  }
}
    `;
const getFeedbackTypesDocument = `
    query getFeedbackTypes($options: FeedbackDetailsRequest!) {
  queries {
    feedback {
      getDetails(input: $options) {
        data {
          feedbackTypes {
            key
            title
          }
        }
        ts
      }
    }
  }
}
    `;
const getFormSchemaDocument = `
    query getFormSchema($options: ValidationSchemaInput!) {
  queries {
    system {
      schemaContent(input: $options) {
        data {
          content
        }
        ts
      }
    }
  }
}
    `;
const getFreebetDataDocument = `
    query getFreebetData($options: RegisterFormsInput!) {
  queries {
    registration {
      getAvailableForms(input: $options) {
        ts
        data {
          freeBetActionId: freeBetActionClass
          freeBetActionBannerUrl
          freeBetTermsPage
          freeBetBcode
          regBannerUrl
        }
      }
    }
  }
}
    `;
const getGeneralStatsDocument = `
    query getGeneralStats($options: AffiliateGeneralStatsRequest!) {
  queries {
    affiliate {
      getGeneralStats(input: $options) {
        ts
        data {
          operations {
            date
            debit
            credit
            pendingDebit
            pendingCredit
            balance
            pendingBalance
            typeId
          }
          stats {
            balance
            pendingBalance
            percent
            affiliatedCustomersCount
            profitForPeriod
            profit
          }
          totalCount
        }
      }
    }
  }
}
    `;
const getInlineChatsDocument = `
    query getInlineChats($options: LiveChatInlineChatsRequest) {
  queries {
    liveChat {
      getInlineChats(input: $options) {
        ts
        data {
          items {
            id
            urls
            text
            description
            subject
            timeout
            hideCondition
            animations
            supportTypes
          }
        }
      }
    }
  }
}
    `;
const getIntercomUserHashDocument = `
    query getIntercomUserHash($options: EmptyQueryRequest!) {
  queries {
    faq {
      getIntercomConfig(input: $options) {
        ts
        data {
          userHash
          appId
          apiUrl
        }
      }
    }
  }
}
    `;
const getLSportsWidgetConfigurationDocument = `
    query getLSportsWidgetConfiguration($options: EmptyQueryRequest!) {
  queries {
    configContent {
      getLSportsWidgetConfiguration(input: $options) {
        ts
        data {
          active
          productionUrl
          customLang
          activeSports
        }
      }
    }
  }
}
    `;
const getLastBetResultDocument = `
    query getLastBetResult($options: EmptyQueryRequest) {
  queries {
    betSlip {
      getLastResult(input: $options) {
        ts
        data {
          duration
          results {
            accepted
            betId
            errorCode
            errorMessage
            readyForCashout
            slipInfo {
              ...SlipInfoFields
            }
            betTransfer {
              items {
                sportId
                marketId
                sportName
                leagueName
                marketName
                runnerName
                eventName
                regionName
                winStatus
                isBanker
                eventId
              }
            }
          }
        }
      }
    }
  }
}
    ${SlipInfoFieldsFragmentDoc}`;
const getLeaderBoardDocument = `
    query getLeaderBoard($options: LeaderBoardWinnersRequest!) {
  queries {
    promotions {
      getLeaderBoard(input: $options) {
        ts
        data {
          winners {
            login
            name
            place
            prize
            score
          }
          page
          nextPage
          customerPosition {
            login
            name
            place
            prize
            score
          }
        }
      }
    }
  }
}
    `;
const getLexGuardSessionDocument = `
    query getLexGuardSession {
  queries {
    system {
      getLexGuardSession {
        ts
        data {
          id
          expiresAt
        }
      }
    }
  }
}
    `;
const getLiveChatSettingsDocument = `
    query getLiveChatSettings($options: LiveChatDetailsRequest) {
  queries {
    liveChat {
      getDetails(input: $options) {
        ts
        data {
          chatData {
            code
            session
            endpoint
            hotlineActive
          }
          chatEnabled
        }
      }
    }
  }
}
    `;
const getLiveChatStatusDocument = `
    query getLiveChatStatus($options: LiveChatDetailsRequest) {
  queries {
    liveChat {
      getDetails(input: $options) {
        ts
        data {
          chatEnabled
        }
      }
    }
  }
}
    `;
const getNavigationConfigDocument = `
    query getNavigationConfig {
  queries {
    system {
      getNavigation(input: {list: []}) {
        ts
        data {
          navigation
        }
      }
    }
  }
}
    `;
const getNearbyCitiesDocument = `
    query getNearbyCities {
  queries {
    autocomplete {
      nearbyCities {
        ts
        data {
          items {
            mainText
            secondaryText
            placeId
          }
        }
      }
    }
  }
}
    `;
const getOnboardingDocument = `
    query getOnboarding($options: OnboardingsRequest) {
  queries {
    onboarding {
      getItems(input: $options) {
        ts
        data {
          items {
            id
            autoShow
            slides {
              content
            }
          }
        }
      }
    }
  }
}
    `;
const getPartnersDocument = `
    query getPartners($options: EmptyQueryRequest) {
  queries {
    partner {
      getPartners(input: $options) {
        ts
        data {
          isDetailsLinkEnabled
          link {
            key
            value
          }
          items {
            url
            name
            description
            logo {
              ...Web2ImageFields
            }
            backgroundImage {
              ...Web2ImageFields
            }
            backgroundImageMobile {
              ...Web2ImageFields
            }
            bannerImage {
              ...Web2ImageFields
            }
            buttonTitle
            contentPage
            navigation
            showLogotypeInFooter
            isFakePartner
            isBanner
            images {
              dark {
                ...Web2ImageFields
              }
              light {
                ...Web2ImageFields
              }
              alt
            }
          }
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
const getPendingBetsDocument = `
    query getPendingBets($options: PendingBetsRequest!) {
  queries {
    customer {
      getPendingBets(input: $options) {
        ts
        data {
          totalCount
          bets {
            betId
            combiStatus
            eventId
            eventNameParts
            isEnabledForCashout
            itemsQuantity
            odds
            oddsStr
            oddsType
            outcomeSr
            stake
            acceptDate
            freeBetId
            isLive
            cashoutAmount
          }
        }
      }
    }
  }
}
    `;
const getPopularQueriesDocument = `
    query getPopularQueries($options: EmptyQueryRequest) {
  queries {
    search {
      getPopularQueries(input: $options) {
        data {
          list
        }
        ts
      }
    }
  }
}
    `;
const getPushSettingsDocument = `
    query getPushSettings {
  queries {
    customer {
      getPushNotificationSettings {
        ts
        data {
          isBetOutcomePushEnabled
          isWinOnlyBetOutcomePushEnabled
        }
      }
    }
  }
}
    `;
const getRootSettingsDocument = `
    query getRootSettings($options: RootSettingsInput!) {
  queries {
    system {
      rootSettings(input: $options) {
        ts
        data {
          ctag
          imageCdnUrl
          defaultModuleUpdateInterval
          defaultUICurrency
          ssoServerUrl
          isCustomerLoggedIn
          moduleUpdateIntervals {
            name
            interval
          }
          isNT
          countryCode
          skin
          formatMoneyLocale
          desktopScreenMinWidth
          slipDefaultAmount
        }
      }
    }
  }
}
    `;
const getRootSettingsInternationalDocument = `
    query getRootSettingsInternational($options: RootSettingsInput!) {
  queries {
    system {
      rootSettings(input: $options) {
        ts
        data {
          ctag
          imageCdnUrl
          defaultUICurrency
          ssoServerUrl
          isCustomerLoggedIn
          isNT
          countryCode
          availableLanguages {
            code
            name
            prefix
            flag
          }
          currentLanguageTag
          replacedUrlPart
          urlLocale
          countriesData: phonePrefixes {
            code
            name
            phonePrefix
            popular
          }
          formatMoneyLocale
          skin
          desktopScreenMinWidth
          slipDefaultAmount
        }
      }
    }
  }
}
    `;
const getRoutingConfigsDocument = `
    query getRoutingConfigs {
  queries {
    system {
      getRouting {
        ts
        data {
          result
          routing
        }
      }
    }
  }
}
    `;
const getRulesDocument = `
    query getRules {
  queries {
    rules {
      getRules {
        ts
        data {
          rules {
            id
            caption
            isPrintEnabled
            children {
              id
              caption
              isPrintEnabled
              children {
                id
                caption
                contentPageKey
                isPrintEnabled
              }
            }
          }
        }
      }
    }
  }
}
    `;
const getSearchDataDocument = `
    query getSearchData($options: SearchRequest!) {
  queries {
    search {
      search(input: $options) {
        data {
          events {
            id
            leagueId
            sportId
            league
            score
            kickoff
            teams
            message
            isLive
          }
          games {
            systemId
            id
            title
            provider
            hasDemo
            imageUrl
            imageUrl2x
            imageUrl3x
            launchUrl
            demoQueryParam
            isNew
            simpleRegistrationShouldBeCompleted
            isAggregated
            system
          }
          message
          result
        }
        ts
      }
    }
  }
}
    `;
const getSelfExclusionDocument = `
    query getSelfExclusion($options: CustomerLimitsRequest) {
  queries {
    limits {
      getDepositLimits(input: $options) {
        ts
        data {
          selfExclusion {
            from
            until
            isSelfExcluded
          }
        }
      }
    }
  }
}
    `;
const getServiceWorkerConfigurationDocument = `
    query getServiceWorkerConfiguration {
  queries {
    serviceWorker {
      getConfiguration {
        ts
        data {
          result
          app {
            enabled
            isNT
            ntMode
            configReloadInterval
            logger {
              enabled
              logLevel
              params {
                key
                value
              }
            }
            sentry {
              enabled
              params {
                key
                value
              }
            }
            resolver {
              enabled
              isNT
              maxAge
              mode
              redirectFallback
              resolvers
              customReserveOrigin
              manuallyBlockedOrigins
              frameCookieFixMode
            }
            cache {
              excludes
            }
            fetchExcludes
            customDomains
          }
          shell {
            appHash
            appUrl
            enabled
            isNT
            ntMode
          }
        }
      }
    }
  }
}
    `;
const getSiteConfigSettingsDocument = `
    query getSiteConfigSettings {
  queries {
    system {
      siteConfig {
        ts
        data {
          settings {
            jsBundlesCDNUrl
            themeSwitcherEnabled
            themes {
              id
              isDefault
            }
            googlePlayIconUrl
            appleStoreIconUrl
            feedbackFormMaxChars
            linkCDNUrl
            isServiceSuspended
            affiliateMigrationLink
            untrustedDevicePasswordResetRedirectTimer
            forcedLayoutEnabled
            appVloaderDelayMs
            modulesDefaultUpdateInterval
            modulesCustomUpdateInterval {
              name
              interval
            }
            isSeoBetCmsSpintaxEnabled
            isMetaBetCmsSpintaxEnabled
            isLanguageSelectorFlagsEnabled
            isPlayTimeControlEnabled
            fontFamily
            themeFooterSwitcherEnabled
            isSubscriptionsInvertedEnabled
          }
          socialNetworks {
            bookmakerRatingsUrl
            socialMediaInstagramUrl
            socialMediaRssUrl
            socialMediaTelegramUrl
            socialMediaVkUrl
            socialMediaTwitterUrl
          }
          webSockets {
            isEnabled
            isBalanceEnabled
            isCbcBonusWithdrawalAmountEnabled
            isLoyaltyUpdateEnabled
            isOnDepositEnabled
            isLoyaltyProgramEnabled
            isSocketStickyBonusStatus
            isCashbackEnabled
            isWheelNotificationEnabled
            isFlexibleFreeSpinBetLimitNotificationEnabled
            isFastTrackEnabled
          }
          sportEvents {
            activatedSportUpdateInterval
            sportLineLiveUpdateInterval
            sportLinePrematchUpdateInterval
            sportLineSidebarSportsUpdateInterval
            sportFiltersEnabled
            sportLineUpdateInterval
            comingEventsEnabled
            comingEventsKickoffFilter
            comingEventsLimit
          }
          sportLine {
            slipEventsUpdateInterval
            pendingBetsUpdateInterval
            stakeInputValidationTimeout
            deleteClosedEventTimeout
            sportlineCatalogUrl
            familyFilterResetEnabled
            onlyActivatedSportUpdateEnabled
            onlyActivatedSportUpdateOnChangeEnabled
            doUseRelativeTimeForSportline
            doRemoveDuplicateEvents
            filterTransitionFadeEffectEnabled
            filterTransitionFadeOutDuration
            filterTransitionFadeInDuration
            liveStreamEnabled
            liveStreamWidgetFloatingEnabled
            sportRadarWidgetFloatingEnabled
            lsportsWidgetEnabled
            betgeniusWidgetEnabled
            sportConfigurationV2 {
              sportFamily
              config {
                widgetType
                timerMode
                progressBar
              }
            }
            sportUrlMapping {
              sportId
              sportUrl
            }
            marketGroupsTabsEnabled
            marketTypesSelectionEnabled
            listMarketsDropdownEnabled
            listMarketsDropdownLimit
            relatedEventsLimit
            runnersCountDisplayEnabled
            cmsMainMarketsUsageEnabled
            maxTopLeaguesTabsInSidebar
            maxTopLeaguesInTab
            timeReversedIndicatorEnabled
            eventDateFormat
            cybersportV2Enabled
            topEventsEnabled
            topEventsLogosEnabled
            prematchFavoriteEnabled
            eventChangeSubscriptionEnabled
            topEventsJackpotsSectionEnabled
            zeroMarginEnabled
            sportEventStatisticsEnabled
            noMarketsBlockedBtnEnabled
            sportLeagueStatisticsEnabled
            liveOutrightsEnabled
            leagueHeaderDataRequestEnabled
            sportHeaderDataRequestEnabled
            regionHeaderDataRequestEnabled
            virtualSportLiveEventsEnabled
            oddsFormatSwitcherEnabled
            displayPrematchEventsInLiveEnabled
            virtualListEnabled
          }
          promotions {
            leaderboardPageSize
            promotionsHomepageCategoryId
            isPromotionsLeaderboardArchiveEnabled
            isPromotionsHighlightLinkEnabled
            isPromotionsRequestNicknameEnabled
            isPromotionsLikeEnabled
            promotionsLikeFeedbackCountdown
            isTempCouponFeatureEnabled
            tempCouponCampaignInterval {
              from
              to
            }
            festivalCampaignId
            festivalActionUrl
            festivalTournamentActionUrls
            festivalCouponCampaignId
            isCashbackLoyaltyModalEnabled
          }
          profile {
            subscriptionBlockEnabled
            profileReferralProgramEnabled
            referralProgramSocialItems {
              name
              url
              title
              description
              quote
              hashtags
              media
              restrictedLocales
              iconName
              backgroundColor
              status
            }
            referralProgramMainBlockEnabled
            profileBetsCounterEnabled
            profilePersonalDataDisallowUserInputEnabled
          }
          vipFastTrack {
            isVipFastTrackEnabled
          }
          captcha {
            loginCaptchaStatus
            passwordRecoveryCaptchaStatus
            registrationCaptchaStatus
            loginCaptchaType
            passwordRecoveryCaptchaType
            registrationCaptchaType
            captchaSecuritySignEnabled
            captchaIframeUrl
            captchaApiUrl
            useIframeForCaptcha
            recaptcha {
              theme
              siteKeys {
                captchaType
                key
              }
            }
            recaptchaV3 {
              loginEnabled
              passwordRecoveryEnabled
              registrationEnabled
              siteKey
              timeout
            }
          }
          errorHandler {
            settings {
              enabled
              errorHandlerType
              params {
                key
                value
              }
              ignoredErrors {
                error
                type
              }
              consoleLogLevels
              browserTracingEnabled
              sessionReplayEnabled
              profilingEnabled
            }
            ignoredErrorsCommon {
              error
              type
            }
          }
          partner {
            isPartnersEnabled
            isPartnersV2Enabled
          }
          metricsTools {
            metricsToolsData {
              type
              setup {
                counterId
                config {
                  key
                  value
                }
              }
            }
          }
          slip {
            pendingBetsPaginationCount
            outdatedEventsFilteringEnabled
            multiSinglesEnabled
            fastBets {
              enabled
              values {
                currency
                values
              }
            }
            standardBetLimits {
              min
              max
              currency
            }
            accumulatorBoostEnabled
            accumulatorBoostIsWinBoost
            accumulatorBoostMinOdds
            accumulatorBoostPerOutcomeCount {
              count
              boost
            }
            accumulatorBoostOnlyPrimaryMarkets
            sharedBetSlipBookingCodeEnabled
            sharedBetSlipLinkEnabled
            sharedBetSlipCodeInputEnabled
            currentSlipShareEnabled
            customerBetShareEnabled
            totalHandicapReplaceEnabled
          }
          deposits {
            suggestedAmounts {
              currency
              depositAmounts
            }
            depositMetricsUpdateInterval
            depositMetricsAfterBalanceDelay
            bonusListBeforeDepositEnabled
          }
          support {
            callbackEnabled
            isCallCenterWorking
            faqEnabled
            callCenterCallbackEnabled
            isLivechatAnonymEnabled
            isResponsibleGamblingFeedbackEnabled
            isHelpEmailEnabled
            helpButtonMode
            helpChatType
            helpInstagramUrl
            helpWhatsappUrl
          }
          pushNotifications {
            enabled
            isAnonymousRegistrationEnabled
            isStartOnboardingEnabled
            isPushOnBetsEnabled
            stopRequests
          }
          pushNotificationsWeb {
            openTimeout
            modalEnabled
            postponeHours
            modalRestrictedRoutes
            isEgsTournamentsPromoModalEnabled
          }
          firebase {
            webApiKey
            webProjectId
            webMessagingSenderId
            webAppId
            webMeasurementId
            webVapidKey
          }
          identification {
            acceptedFileTypes {
              documentType
              fileTypes
            }
            maxFilesizes {
              documentType
              filesize
            }
            esiaIdentificationConfig {
              esiaIdentificationType
              etsupisEsiaRedirectUrl
            }
            thirdPartyRetryDelay
          }
          popularSearchQueries {
            popularSearchQueriesData
          }
          downtime {
            enabled
            visibleBeforeHours
            interval {
              from
              to
              shown
            }
          }
          feedback {
            feedbackFormCategories {
              categoryId
              category
            }
            isFeedbackEnabled
          }
          security {
            browserFingerprintingEnabled
            faceTouchIDReminderCount
            faceTouchIDReminderFrequency
            g2svInactiveInterval
            shieldEnabled
            helmetAPIUrl
            lexGuardEnabled
            lexGuardAPIUrl
            lexGuardProfilingSubdomain
            lexGuardOrgId
            phoneWrongAttemptsLimit
            pinCodeRequestedPlaces
            pinCodeModalShowLimit
            pinCodeWrongAttemptsLimit
          }
          search {
            searchSportlineMinchars
          }
          mobileBlocks {
            mobileReserveDomainsCustom
            mobileReserveDomains {
              domain
              blockedSource
              status
            }
          }
          mobileAppCordova {
            mobileAppDownloadEnabled
            mobileAppAndroidDownloadEnabled
            mobileAppIosDownloadEnabled
            mobileAppDownloadTopBannerEnabled
            mobileAppIosDownloadUrl
            mobileAppAndroidDownloadUrl
            mobile5UpdateCheckPeriod
            mobileAppUpdateConfigGeneric {
              apkStorageUrl
              apkName
              appVersion
              isUpdateAvailable
              isApkUpdateMandatory
            }
            mobileAppUpdateConfigCustomerSpecific {
              apkStorageUrl
              apkName
              appVersion
              isUpdateAvailable
              isApkUpdateMandatory
            }
            mobileLoginFingerprintEnabled
            flowLoginEnabled
            ionicAppflowEnabled
            autoThemeEnabled
          }
          appDownloadAd {
            isVisible
          }
          registration {
            isEmailTabEnabledOnPasswordRestorePage
            isPhoneTabEnabledOnPasswordRestorePage
            isSuggestedEmailRegistrationEnabled
            suggestedEmailRegistrationTimeout
            smsResendCodeTimer
            phoneCodeLength {
              phoneCodeMethod
              value
            }
            idleRedirectCount
            marketingLinkIdleRedirect
            marketingLinkParam
            idleTimeToRedirect
            showModalOnLeaveEnabled
            formsEmailHints
            formsEmailHintsEnabled
            isRegistrationDisabled
            millisToSleepAfterCountryChange
            appsFlyerSupportedSdkEvents
            isRegistrationHintMessageEnabled
            registrationHintMessageLink
            footerBannerLogotypesByLocale {
              id
              iconLight
              iconDark
              alt
              url
            }
          }
          bonusWallet {
            bonusCodeInputFormStatus
            bonusLinkLoyalty
            startBonusScreenEnabled
            loyaltyProgramEgsStatus
            delayBeforeNewBonusesListRequest
            bonusLinkEgsLoyalty
          }
          zetaDcp {
            configs {
              type
              params {
                key
                value
              }
            }
            isEnabled
          }
          payments {
            paymentsListsReloadTimeout
            lowBalanceAmounts {
              currency
              value
            }
            isInteracEnabled
            isExpirationValidationDisabled
            isNoMethodMessageEnabled
            balanceButtonCurrencies
            depositMetricsRequestDelay
            isCcTokensEnabled
            isPaymentsMinAmountSuggestionEnabled
            isPaymentsFastSumEnabled
            isPaymentsWithdrawalCancelButtonInSuccessModalEnabled
            isPaymentsFastSumMaxEnabled
            paymentsWithdrawalCancelPopupRoutes {
              routeName
              btrSubject
              btrMessage
              btrButton
              onlyZeroBalanceShow
            }
            paymentsWithdrawalCancelPopupDelay
            paymentsWithdrawalCancelPopupInterval
            zeroBalanceLimit
            isPaymentsBonusSelectorV2Enabled
            isPaymentsBonusSelectorOnListEnabled
            isPaymentsBonusSelectorV2AlternativeLEnabled
            isRequireCityBeforePaymentEnabled
            paymentsCompanyFeeMoreLink
            isPaymentsCompanyFeeEnabled
          }
          sso {
            enabled
          }
          virtualSport {
            isEnabled
            providerUrl
            items {
              tournament
              clientId
              selectedByDefault
              order
              sportUrlName
              isEnabled
            }
          }
          license {
            kwkIconEnabled
            crcIconEnabled
            crcEgamingIconEnabled
            kwkUrl
            crcUrl
            crcEgamingUrl
            crcEgamingLogoUrl
          }
          sportradar {
            pixelManager
            counterId
          }
          fastTrack {
            brandId
            brandName
            notificationsConfigApiUrl
            requestTimeout
            modalsShowTimeout
          }
          fastGames {
            isEnabled
            isLobbyHintEnabled
          }
          externalGames {
            isStoryBannerEnabled
            topWinnersFilter
            gameZeroBalanceReloadTimeout
            vpnInactiveStopTimeout
            gameLoadTimeout
            egsStartGameRetryCounter
            egsStartGameRetryTimeout
            vpnEgsStartGameRetryCounter
            vpnEgsStartGameRetryTimeout
            lazyLoadPagesCounter
          }
          egsSkeletons {
            slotsAllTab {
              ...EgsSkeletonConfigFields
            }
            slotsGamePage {
              ...EgsSkeletonConfigFields
            }
            slotsMyTab {
              ...EgsSkeletonConfigFields
            }
            slotsProvider {
              ...EgsSkeletonConfigFields
            }
            liveAllTab {
              ...EgsSkeletonConfigFields
            }
            liveMyTab {
              ...EgsSkeletonConfigFields
            }
            liveGamePage {
              ...EgsSkeletonConfigFields
            }
            liveProvider {
              ...EgsSkeletonConfigFields
            }
            loyalty {
              ...EgsSkeletonConfigFields
            }
            search {
              ...EgsSkeletonConfigFields
            }
            fastGames {
              ...EgsSkeletonConfigFields
            }
            home {
              ...EgsSkeletonConfigFields
            }
          }
          widgets {
            iframeUrl
            sportradarLiveMatchTrackerEnabled
            sportradarStatisticsEnabled
            sportradarLiveScoreEnabled
          }
          sumSub {
            isWebSdkInProdMode
            sdkUrl
          }
          faq {
            faqEnabled
          }
          domainCheck {
            checkInterval
            msgMaxSizeBytes
            domains
          }
          rules {
            isTitleEnabled
            isNavigationOpen
            isSearchEnabled
            isMobileTabsEnabled
          }
          bonuses {
            bonusesEnable
            bonusesPolling
            isLeonShopEnabled
            isWeb2BonusCashbackWidgetEnabled
            isFomoTopBarIconEnabled
            bannerSliderDotsCount
          }
          fJs {
            isEnabled
            endpointSubdomain
            apiKey
            visitorIdTtl
          }
          footer {
            isFooterSocialNetworksEnabled
            socialNetworkLogotypesByLocale {
              isDefaultColor
              iconDark
              iconLight
              width
              height
              href
              alt
            }
            isFooterLogotypesEnabled
            isFooterOldLogotypesEnabled
            footerLogotypes {
              isDefaultColor
              iconLight
              iconDark
              height
              alt
              href
              width
              height
            }
            footerLogotypesByLocale {
              isDefaultColor
              iconLight
              iconDark
              height
              alt
              href
              width
              height
            }
          }
          loyalty {
            isEgsLoyaltyProgramLvlUpModelEnabled
            isEgsLoyaltyEnabled
            isLoyaltyEnabled
            isLoyaltyEnabledMain
          }
          landing {
            isLandingAndroidEnabled
            landingAndroidImageHeader {
              ...Web2ImageFields
            }
            landingAndroidImageGamblingFirst {
              ...Web2ImageFields
            }
            landingAndroidImageGamblingSecond {
              ...Web2ImageFields
            }
            landingAndroidImageGamblingThird {
              ...Web2ImageFields
            }
            landingAndroidImageFree {
              ...Web2ImageFields
            }
            landingAndroidImageBonusFirst {
              ...Web2ImageFields
            }
            landingAndroidImageBonusSecond {
              ...Web2ImageFields
            }
            landingAndroidImageBonusThird {
              ...Web2ImageFields
            }
            landingAndroidImageBackground {
              ...Web2ImageFields
            }
            landingAndroidImageFooter {
              ...Web2ImageFields
            }
            landingCouponsBackground {
              ...Web2ImageFields
            }
            landingCouponsMainImage {
              ...Web2ImageFields
            }
            landingCouponsSecondaryImage {
              ...Web2ImageFields
            }
            isLandingWelcomeEnabled
            calculatorSum {
              currency
              value
              step
              maxValue
              minValue
            }
            landingCalcLeagueId
            landingCalcMatchId
            landingCalcMatchBetline
            landingFestEmbed
            promoActionLinks {
              actionUrl
              categoryId
              id
            }
            welcomeEgsCategory
            isLandingFestivalEnabled
            festPromoDetailsLink
            androidSocialNetworks {
              url
              title
              iconName
            }
            isLandingVipEnabled
            landingVipFaqItems {
              title
              description
            }
            isAdventCalendarEnabled
            landingAdventBackgroundImage {
              ...Web2ImageFields
            }
            landingAndroidAnimationRightBottom {
              ...Web2ImageFields
            }
            landingAndroidAnimationRightMiddle {
              ...Web2ImageFields
            }
            landingAndroidAnimationRightTop {
              ...Web2ImageFields
            }
            landingAndroidAnimationLeftBottom {
              ...Web2ImageFields
            }
            landingAndroidAnimationLeftTop {
              ...Web2ImageFields
            }
          }
          header {
            isHeaderBalanceEnabled
          }
          vpnLoader {
            isClickEnabled
            iconsCount
            minIconSize
            maxIconSize
            slideSpeed
          }
          routing {
            homePageType
          }
          verification {
            isVideoVerificationEnabled
          }
          activityReporter {
            activityReporterEnabled
          }
          guest {
            doRedirectGuestWithAccountToLogin
            redirectToLoginTimeout
          }
          responsibleGambling {
            responsibleGamblingV2Enabled
            selfExclusionOptions {
              months
              btrKey
              allowedSelfExclusion
            }
            responsibleGamblingRedirectEnabled
          }
          appflow {
            config {
              isEnabled
              doReset
              appId
              channel
              maxVersions
              minBackgroundDuration
              updateMethod
              host
              debug
            }
          }
          sportsbookUIVersion {
            sportsbookUITopPageVersion
            sportsbookUILivePageVersion
            sportsbookUISportsPageVersion
            sportsbookUILeaguePageVersion
            sportsbookUIRegionPageVersion
            sportsbookUIEventDetailsPageVersion
            sportsbookUINavigationVersion
          }
          login {
            configs {
              type
              isDefault
            }
          }
          accessOptions {
            accessOptionsItems
            accessOptionsSocialItems {
              backgroundColor
              backgroundHoverColor
              iconName
              name
              status
              url
              restrictedLocales
            }
            areAccessOptionsEnabled
          }
          achievements {
            isAchievementEnabled
            isAvatarsEnabled
            sharingConfigs {
              id
            }
          }
          debug {
            debugData
          }
          web2AccountHistory {
            isAccHistoryV2AllTabEnabled
            isAccHistoryV2VsportTabEnabled
            isAccHistoryV2SportsbookTabEnabled
            isAccHistoryV2EgsTabEnabled
            isAccHistoryV2MarketingTabEnabled
            isAccHistoryV2PaymentsTabEnabled
          }
          tpWidget {
            isTPWidgetEnabled
            tpWidgetLandingUrl
          }
          rewardsCenter {
            enabled
            items {
              isComingSoon
              rewardId
              isActive
            }
          }
        }
      }
    }
  }
}
    ${EgsSkeletonConfigFieldsFragmentDoc}
${Web2ImageFieldsFragmentDoc}`;
const getSportradarLiveMatchTrackerConfigurationDocument = `
    query getSportradarLiveMatchTrackerConfiguration($options: EmptyQueryRequest!) {
  queries {
    configContent {
      getSportRadarWidgetConfiguration(input: $options) {
        data {
          active
          theme
          productionUrl
          customLang
          widgets {
            active
            sport
            name
            order
            config
          }
        }
        ts
      }
    }
  }
}
    `;
const getSportradarLiveScoreConfigurationDocument = `
    query getSportradarLiveScoreConfiguration($options: EmptyQueryRequest!) {
  queries {
    configContent {
      getSportRadarLiveScoreConfiguration(input: $options) {
        data {
          productionUrl
          customLang
          cssStyles
        }
        ts
      }
    }
  }
}
    `;
const getSportradarStatisticsConfigurationDocument = `
    query getSportradarStatisticsConfiguration($options: EmptyQueryRequest!) {
  queries {
    configContent {
      getSportRadarStatisticsConfiguration(input: $options) {
        data {
          productionUrl
          customLang
          cssStyles
        }
        ts
      }
    }
  }
}
    `;
const getSubscribedEventsDocument = `
    query getSubscribedEvents($options: EmptyQueryRequest!) {
  queries {
    betLine {
      change {
        getSubscribedEvents(input: $options) {
          ts
          data {
            eventIds
          }
        }
      }
    }
  }
}
    `;
const getTempCouponsDocument = `
    query getTempCoupons($options: TempCouponsRequest!) {
  queries {
    bonuses {
      getTempCoupons(input: $options) {
        ts
        data {
          coupons
        }
      }
    }
  }
}
    `;
const getTranslationsDocument = `
    query getTranslations($options: TranslationBundleByKeysInput!) {
  queries {
    system {
      getTranslationBundleByKeys(input: $options) {
        ts
        data {
          translations {
            key
            value
          }
        }
      }
    }
  }
}
    `;
const getUpcomingMeetingDocument = `
    query getUpcomingMeeting {
  queries {
    verification {
      getUpcomingMeeting {
        ts
        data {
          meetingSlot {
            id
            startTime
            endTime
            roomUrl
          }
          isTimeChangeAvailable
        }
      }
    }
  }
}
    `;
const getUserDataDocument = `
    query getUserData($options: UserDataRequest!) {
  queries {
    customer {
      getUserData(input: $options) {
        ts
        data {
          customerData {
            ...CustomerDataFields
          }
        }
      }
    }
  }
}
    ${CustomerDataFieldsFragmentDoc}`;
const getUserDataPhoneDocument = `
    query getUserDataPhone($options: UserDataRequest!) {
  queries {
    customer {
      getUserData(input: $options) {
        ts
        data {
          customerData {
            ...CustomerDataPhoneFields
          }
        }
      }
    }
  }
}
    ${CustomerDataPhoneFieldsFragmentDoc}`;
const getUserProfileBonusDocument = `
    query getUserProfileBonus($options: CustomerDataRequest!) {
  queries {
    customer {
      getProfileData(input: $options) {
        ts
        data {
          result
          profileData {
            isBonusEnabled
            isEncashEnabled
            isBonusAvailable
            minBonusAmount
            bonusAmount
            unreadOperatorMessagesCount
            waitingBetsAmount
            requestedWithdrawal
            bonusTermsAccepted
            bonusProgramStatus
            bonusProgramStatusClass
            isLeonShopEnabled
            isLeonShopOpen
            leonShopURL
            isHideAccNumIfCannotGamble
            egsWinnings
            requestedWithdrawals {
              requestedAmount
              requestedCount
              singlePaymentId
            }
          }
        }
      }
    }
  }
}
    `;
const getWhatIsNewDataDocument = `
    query getWhatIsNewData {
  queries {
    whatsNew {
      getWhatsNew {
        ts
        data {
          keys {
            key
            content {
              lang
              html
              css
              javascript
            }
            order
            shown
          }
        }
      }
    }
  }
}
    `;
const goBackDocument = `
    query goBack($options: PreviousFormInput!) {
  queries {
    registration {
      goBack(input: $options) {
        ts
        data {
          form {
            uiFormSchema {
              order
              fields {
                id
                widget
                hidden
                title
                defaultValue
                disabled
                hintMessage
                type
                encrypted
                isFrontOnly
                labels {
                  key
                  value
                }
                options {
                  key
                  value
                }
              }
            }
            formValidationSchema
            schemaId
            registrationType
            trigger
            formError
            formErrorCaption
            formSuccess
            validated
            hasFieldsErrors
            duplicateUser
            formName
            affiliateId
            stage
            priority
            failsCount
            formStep
            goBack {
              formParams {
                key
                value
              }
              schemaId
            }
            formProgress
          }
        }
      }
    }
  }
}
    `;
const confirmManualApplicationDocument = `
    query confirmManualApplication($options: ManualCustomerDataConfirmRequest!) {
  mutations {
    identification {
      confirmManualApplication(input: $options) {
        ts
        data {
          manualCustomerData {
            status
          }
        }
      }
    }
  }
}
    `;
const deleteDocumentsDocument = `
    query deleteDocuments($options: String!) {
  mutations {
    identification {
      deleteDoc(docType: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const dropManualDataDocument = `
    query dropManualData {
  mutations {
    identification {
      dropManualData {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const esiaDataFetchDocument = `
    query esiaDataFetch($options: EsiaResponseInput!) {
  mutations {
    identification {
      esiaDataFetch(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const esiaRedirectDocument = `
    query esiaRedirect($options: EmptyMutationRequest!) {
  mutations {
    identification {
      esiaRedirect(input: $options) {
        ts
        data {
          esiaRedirectDate
        }
      }
    }
  }
}
    `;
const etsupisPhoneVerificationInitDocument = `
    query etsupisPhoneVerificationInit($options: EtsupisPhoneVerificationRequest!) {
  mutations {
    identification {
      phoneVerificationInit(input: $options) {
        ts
        data {
          result
          phoneCodeMethod
          canResendCodeAfterSeconds
        }
      }
    }
  }
}
    `;
const etsupisPhoneVerificationVerifyDocument = `
    query etsupisPhoneVerificationVerify($options: EtsupisVerifyRequest!) {
  mutations {
    identification {
      phoneVerificationVerify(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const finishApplicationDocument = `
    query finishApplication {
  mutations {
    identification {
      finishApplication {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const getEsiaAouth2LinkDocument = `
    query getEsiaAouth2Link($options: EsiaAuth2LinkRequest!) {
  queries {
    identification {
      getEsiaAouth2Link(input: $options) {
        ts
        data {
          link
        }
      }
    }
  }
}
    `;
const getIdentificationStateDocument = `
    query getIdentificationState {
  queries {
    identification {
      getState {
        ts
        data {
          identState {
            ...IdentStateFields
          }
        }
      }
    }
  }
}
    ${IdentStateFieldsFragmentDoc}`;
const getThirdPartyIdentificationInitDocument = `
    query getThirdPartyIdentificationInit($options: ThirdPartyIdentificationRequest!) {
  mutations {
    identification {
      thirdPartyIdentificationInit(input: $options) {
        ts
        data {
          redirectUrl
        }
      }
    }
  }
}
    `;
const initIdentAppDocument = `
    query initIdentApp {
  mutations {
    identification {
      initIdentApp {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const saveManualVerificationFormDocument = `
    query saveManualVerificationForm($options: ManualVerificationFormRequest!) {
  mutations {
    identification {
      saveManualVerificationForm(input: $options) {
        ts
        data {
          manualCustomerData {
            firstName
            lastName
            middleName
            docNr
            docType
            birthDate
            inn
            status
            version
          }
        }
      }
    }
  }
}
    `;
const submitManualVerificationFormDocument = `
    query submitManualVerificationForm($options: EmptyMutationRequest!) {
  mutations {
    identification {
      submitManualVerificationForm(input: $options) {
        ts
        data {
          redirectLink
        }
      }
    }
  }
}
    `;
const resetIdentificationRequestDocument = `
    query resetIdentificationRequest {
  mutations {
    identification {
      reset {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const saveIdentificationDraftDataDocument = `
    query saveIdentificationDraftData($options: ManualCustomerDataDraftSetRequest!) {
  mutations {
    identification {
      saveIdentificationDraftData(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const setInnDocument = `
    query setInn($options: SetInnRequest!) {
  mutations {
    identification {
      setInn(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const setManualDataDocument = `
    query setManualData($options: ManualCustomerDataSetRequest!) {
  mutations {
    identification {
      setManualData(input: $options) {
        ts
        data {
          manualCustomerData {
            birthDate
            docNr
            docType
            firstName
            inn
            lastName
            middleName
            status
            version
          }
        }
      }
    }
  }
}
    `;
const submitDocumentsDocument = `
    query submitDocuments {
  mutations {
    identification {
      submit {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const getLobbyDocument = `
    query getLobby($options: LobbyRequest!) {
  queries {
    externalGames {
      getLobby(input: $options) {
        ts
        data {
          items {
            ...AbstractLobbyItemFields
            ... on LobbyGamesCategoryItem {
              ...LobbyGamesCategoryItemFields
            }
            ... on LobbyPromotionsItem {
              ...LobbyPromotionsItemFields
            }
            ... on LobbyGroupsItem {
              ...LobbyGroupsItemFields
            }
            ... on LobbyTopWinnersItem {
              ...LobbyTopWinnersItemFields
            }
            ... on LobbyBannersItem {
              ...LobbyBannerItemFields
            }
            ... on LobbySportlineTopMatchesItem {
              ...LobbySportlineTopMatchesItemFields
            }
            ... on LobbyAllTopWinnersItem {
              ...LobbyAllTopWinnersItemFields
            }
          }
        }
      }
    }
  }
}
    ${AbstractLobbyItemFieldsFragmentDoc}
${LobbyGamesCategoryItemFieldsFragmentDoc}
${LobbyPromotionsItemFieldsFragmentDoc}
${LobbyGroupsItemFieldsFragmentDoc}
${LobbyTopWinnersItemFieldsFragmentDoc}
${LobbyBannerItemFieldsFragmentDoc}
${LobbySportlineTopMatchesItemFieldsFragmentDoc}
${LobbyAllTopWinnersItemFieldsFragmentDoc}`;
const logoutDocument = `
    query logout {
  mutations {
    auth {
      logout {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const makeBatchSlipDocument = `
    query makeBatchSlip($options: BatchMakeBetRequest!) {
  mutations {
    betSlip {
      makeBatchSlip(input: $options) {
        ts
        data {
          duration
          results {
            accepted
            betId
            errorCode
            errorMessage
            readyForCashout
            slipInfo {
              ...SlipInfoFields
            }
            betTransfer {
              items {
                sportId
                marketId
                sportName
                leagueName
                marketName
                runnerName
                eventName
                regionName
                winStatus
                isBanker
                eventId
              }
            }
          }
        }
      }
    }
  }
}
    ${SlipInfoFieldsFragmentDoc}`;
const doDeleteNotificationDocument = `
    query doDeleteNotification($options: DeleteFrontNotificationRequest!) {
  mutations {
    frontNotifications {
      delete(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doMarkAsClickedDocument = `
    query doMarkAsClicked($options: FrontNotificationClickedRequest!) {
  mutations {
    frontNotifications {
      markAsClicked(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doMarkAsDeliveredDocument = `
    query doMarkAsDelivered($options: FrontNotificationDeliveredRequest!) {
  mutations {
    frontNotifications {
      markAsDelivered(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doMarkNotificationDocument = `
    query doMarkNotification($options: ReadFrontNotificationRequest!) {
  mutations {
    frontNotifications {
      markAsRead(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const getNotificationsDocument = `
    query getNotifications($options: FrontNotificationsRequest!) {
  queries {
    frontNotifications {
      getNotifications(input: $options) {
        ts
        data {
          items {
            ...FrontNotificationFields
            ... on BonusActivationNotification {
              ...BonusActivationNotificationFields
            }
            ... on AlcrNotification {
              ...AlcrNotificationFields
            }
            ... on VideoVerificationNotification {
              ...VideoNotificationFields
            }
            ... on RefProgramNotification {
              ...RefProgramNotificationFields
            }
            ... on CryptoDepositStatusNotification {
              ...CryptoDepositStatusNotificationFields
            }
            ... on DepositNotification {
              ...DepositNotificationFields
            }
            ... on FomoBonusCreated {
              ...FomoBonusCreatedFields
            }
          }
          next {
            limit
            offset
          }
        }
      }
    }
  }
}
    ${FrontNotificationFieldsFragmentDoc}
${BonusActivationNotificationFieldsFragmentDoc}
${AlcrNotificationFieldsFragmentDoc}
${VideoNotificationFieldsFragmentDoc}
${RefProgramNotificationFieldsFragmentDoc}
${CryptoDepositStatusNotificationFieldsFragmentDoc}
${DepositNotificationFieldsFragmentDoc}
${FomoBonusCreatedFieldsFragmentDoc}`;
const doCancelRequestedWithdrawalsDocument = `
    query doCancelRequestedWithdrawals($options: EmptyMutationRequest) {
  mutations {
    finance {
      withdrawal {
        cancelRequestedWithdrawals(input: $options) {
          ts
          data {
            totalCount
            totalAmount
          }
        }
      }
    }
  }
}
    `;
const doSubmitDepositDocument = `
    query doSubmitDeposit($options: SubmitPaymentRequest!) {
  mutations {
    finance {
      deposit {
        submit(input: $options) {
          ts
          data {
            formStep
            result
            msg
            confirmationForm
            schemaId
            redirectUrl
            uiFormSchema {
              order
              fields {
                isFrontOnly
                id
                widget
                hidden
                title
                defaultValue
                disabled
                hintMessage
                encrypted
                options {
                  key
                  value
                  valueList {
                    ... on PaymentSystemOptions {
                      label
                      name
                      value
                      isCopyEnabled
                    }
                  }
                }
                type
                labels {
                  key
                  value
                }
              }
            }
            web2OpenIn
            formValidationSchema
            cancel {
              formParams {
                key
                value
              }
              schemaId
            }
            redirectCharset
            redirectMethod
            redirectFormParams
            nettoAmount
            bruttoAmount
            taxAmount
            htmlContent
            htmlContentProperties {
              value
              key
            }
          }
        }
      }
    }
  }
}
    `;
const doSubmitWithdrawalDocument = `
    query doSubmitWithdrawal($options: SubmitPaymentRequest!) {
  mutations {
    finance {
      withdrawal {
        submit(input: $options) {
          ts
          data {
            formStep
            result
            msg
            confirmationForm
            schemaId
            redirectUrl
            uiFormSchema {
              order
              fields {
                isFrontOnly
                id
                widget
                hidden
                title
                defaultValue
                disabled
                hintMessage
                encrypted
                options {
                  key
                  value
                }
                type
                labels {
                  key
                  value
                }
              }
            }
            web2OpenIn
            formValidationSchema
            balance
            balanceTimestamp
            cancel {
              formParams {
                key
                value
              }
              schemaId
            }
            redirectCharset
            redirectFormParams
            redirectMethod
            nettoAmount
            bruttoAmount
            taxAmount
            htmlContent
            paymentId
            htmlContentProperties {
              value
              key
            }
          }
        }
      }
    }
  }
}
    `;
const getCompanyFeeDocument = `
    query getCompanyFee($options: CompanyFeeRequest!) {
  queries {
    finance {
      getCompanyFee(input: $options) {
        ts
        data {
          companyFee
          anchorIgnore
          payableAmount
          companyFeeHoursLimit
          result
        }
      }
    }
  }
}
    `;
const getCryptoNetworkInfoDocument = `
    query getCryptoNetworkInfo($options: CryptoNetworkInfoRequest!) {
  queries {
    finance {
      deposit {
        getCryptoNetworkInfo(input: $options) {
          ts
          data {
            networks {
              key
              value
            }
            current {
              id
              qrCodeImgSrc
              address
              currency
              currencyRate
              cryptoName
              network
            }
          }
        }
      }
    }
  }
}
    `;
const getDepositSystemsListDocument = `
    query getDepositSystemsList($options: GetDepositSystemsRequest) {
  queries {
    finance {
      deposit {
        getDepositSystemsList(input: $options) {
          ts
          data {
            isDepositRequiredEmailVerification
            isFirstDeposit
            paymentCategories {
              name
              isFolded
              paymentSystems {
                id
                name
                logoName
                logoSrc
                logoSrcThemed
                paymentSystemBadge {
                  isActiveAndValid
                }
                minAmount {
                  amount
                  currency
                }
                maxAmount {
                  amount
                  currency
                }
                depositCur
                disabled
                commission
                enrollmentTime
              }
            }
            errorCode
            message
          }
        }
      }
    }
  }
}
    `;
const getDepositSystemsOverviewListDocument = `
    query getDepositSystemsOverviewList($options: DepositSystemInput) {
  queries {
    finance {
      depositSystem {
        getDepositSystemsList(input: $options) {
          ts
          data {
            depositcategories {
              name
              paymentSystems {
                url
                id
                name
                nameBold
                menuName
                isdisabled
                disabledtitle
                disabledcomment
                currencies
                comment
                enrollmentTime
                className
                imageUrl
                logoSrc
                logoName
                logoSrcThemed
              }
            }
          }
        }
      }
    }
  }
}
    `;
const getDepositsRegistrationCompletionStatusDocument = `
    query getDepositsRegistrationCompletionStatus($options: GetDepositSystemInfoRequest!) {
  queries {
    finance {
      deposit {
        getDepositSystemInfo(input: $options) {
          ts
          data {
            additionalRegistrationNeeded
          }
        }
      }
    }
  }
}
    `;
const getDepositsSystemInfoDocument = `
    query getDepositsSystemInfo($options: GetDepositSystemInfoRequest!) {
  queries {
    finance {
      deposit {
        getDepositSystemInfo(input: $options) {
          ts
          data {
            hideNoSelectedBonusModal
            additionalRegistrationNeeded
            isDepositRequiredEmailVerification
            isFirstDeposit
            paymentSystemData {
              buttonName
              defaultInputValue
              showAdditionalInfo
              allowedIncrements {
                currency
                amount
              }
              allowInputSuggestedAmountsOnly
              suggestedAmounts {
                currency
                amounts
              }
              formStep
              paymentType
              id
              logoName
              logoSrc
              logoSrcThemed
              pmtmethod
              minAmount {
                amount
                currency
              }
              maxAmount {
                amount
                currency
              }
              instructions
              enrollmentTime
              paymentSystemId
              paymentType
              name
              description
              isTerminal
              depositCur
              isDepositReadOnly
              formValidationSchema
              schemaId
              openIn
              bankcards {
                pan
                type
                token
                phone
              }
              uiFormSchema {
                order
                fields {
                  isFrontOnly
                  id
                  widget
                  hidden
                  title
                  defaultValue
                  disabled
                  hintMessage
                  encrypted
                  options {
                    key
                    value
                  }
                  type
                  labels {
                    key
                    value
                  }
                }
              }
              isMobileCommerce
              allowNewCard
              bonuses {
                bonusCode
                campaignId
                campaignName
                campaignType
                depositAmountPercent
                description
                imgUrl
                actionUrl
                categoryId
                maxDepositAmount {
                  amount
                  currency
                }
                minDepositAmount {
                  amount
                  currency
                }
                maxBonusAmount
                terms
                image {
                  ...Web2ImageFields
                }
              }
              forcedCustomerVerificationRequired
              isCrypto
              isDeepLinkAllowed
              isP2p
            }
          }
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
const getWithdrawalStatsDocument = `
    query getWithdrawalStats($options: EmptyQueryRequest) {
  queries {
    finance {
      getWithdrawalStats(input: $options) {
        data {
          requestedAmount
          requestedCount
          singlePaymentId
          minPaymentCreatedAt
        }
        ts
      }
    }
  }
}
    `;
const getWithdrawalSystemsListDocument = `
    query getWithdrawalSystemsList($options: GetWithdrawalSystemsRequest) {
  queries {
    finance {
      withdrawal {
        getWithdrawalSystemsList(input: $options) {
          ts
          data {
            paymentCategories {
              name
              isFolded
              paymentSystems {
                id
                name
                logoName
                logoSrc
                logoSrcThemed
                paymentSystemBadge {
                  isActiveAndValid
                }
                minAmount {
                  amount
                  currency
                }
                maxAmount {
                  amount
                  currency
                }
                depositCur
                disabled
                commission
                enrollmentTime
              }
            }
            hideCommissionHint
            errorCode
            message
            companyFeePercent
            companyFeeHoursLimit
          }
        }
      }
    }
  }
}
    `;
const getWithdrawalSystemsOverviewListDocument = `
    query getWithdrawalSystemsOverviewList($options: GetWithdrawalSystemsRequest) {
  queries {
    finance {
      withdrawal {
        getWithdrawalSystemsNotAuthorized(input: $options) {
          ts
          data {
            errorCode
            message
            hideCommissionHint
            paymentCategories {
              name
              paymentSystems {
                id
                name
                logoName
                logoSrc
                minAmount {
                  amount
                  currency
                }
                maxAmount {
                  amount
                  currency
                }
                depositCur
                disabled
                enrollmentTime
                commission
              }
            }
          }
        }
      }
    }
  }
}
    `;
const getWithdrawalsRegistrationCompletionStatusDocument = `
    query getWithdrawalsRegistrationCompletionStatus($options: GetWithdrawalSystemInfoRequest!) {
  queries {
    finance {
      withdrawal {
        getWithdrawalSystemInfo(input: $options) {
          ts
          data {
            additionalRegistrationNeeded
          }
        }
      }
    }
  }
}
    `;
const getWithdrawalsSystemInfoDocument = `
    query getWithdrawalsSystemInfo($options: GetWithdrawalSystemInfoRequest!) {
  queries {
    finance {
      withdrawal {
        getWithdrawalSystemInfo(input: $options) {
          ts
          data {
            additionalRegistrationNeeded
            isWithdrawalApplicationRequired
            isWithdrawalRequiredEmailVerification
            availableForWithdraw
            paymentSystemData {
              companyFeePercent
              companyFeeHoursLimit
              defaultInputValue
              showAdditionalInfo
              allowedIncrements {
                currency
                amount
              }
              allowInputSuggestedAmountsOnly
              suggestedAmounts {
                currency
                amounts
              }
              formStep
              paymentType
              id
              logoName
              logoSrc
              logoSrcThemed
              pmtmethod
              minAmount {
                amount
                currency
              }
              maxAmount {
                amount
                currency
              }
              instructions
              enrollmentTime
              paymentSystemId
              paymentType
              name
              description
              isTerminal
              formValidationSchema
              schemaId
              bankcards {
                pan
                type
                token
                phone
              }
              uiFormSchema {
                order
                fields {
                  isFrontOnly
                  id
                  widget
                  hidden
                  title
                  defaultValue
                  disabled
                  hintMessage
                  encrypted
                  options {
                    key
                    value
                  }
                  type
                  labels {
                    key
                    value
                  }
                }
              }
              isMobileCommerce
              isCrypto
              isDeepLinkAllowed
            }
            hideCommissionHint
            errorCode
            message
          }
        }
      }
    }
  }
}
    `;
const doCreatePinCodeDocument = `
    query doCreatePinCode($options: CreatePinCodeRequest!) {
  mutations {
    pinCode {
      createPinCode(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doDeletePinCodeDocument = `
    query doDeletePinCode($options: DeletePinCodeRequest!) {
  mutations {
    pinCode {
      deletePinCode(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doVerifyPinCodeDocument = `
    query doVerifyPinCode($options: VerifyPinCodeRequest!) {
  mutations {
    pinCode {
      verifyPinCode(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const getPinCodeStatusDocument = `
    query getPinCodeStatus {
  queries {
    pinCode {
      getPinCodeStatus {
        ts
        data {
          wasPinCodeSetOnce
          status
          bannedUntil
        }
      }
    }
  }
}
    `;
const doDeactivateTimerDocument = `
    query doDeactivateTimer($options: EmptyMutationRequest!) {
  mutations {
    playTimeControl {
      deactivateTimer(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doPlayTimeControlDocument = `
    query doPlayTimeControl($options: ActivateTimerRequest!) {
  mutations {
    playTimeControl {
      setTimer(input: $options) {
        ts
        data {
          state {
            hours
            minutes
            timeLeft
            hours
            startDt
            percentLeft
          }
        }
      }
    }
  }
}
    `;
const playTimeControlDocument = `
    query playTimeControl($options: EmptyQueryRequest!) {
  queries {
    playTimeControl {
      getState(input: $options) {
        ts
        data {
          state {
            hours
            minutes
            percentLeft
            startDt
            timeLeft
          }
        }
      }
    }
  }
}
    `;
const doButtonMutationDocument = `
    query doButtonMutation($options: UpdatePromotionRequest!) {
  mutations {
    promotions {
      updatePromotion(input: $options) {
        ts
        data {
          actionButton {
            buttonType
            currencyCode
            state
            title
            value
          }
        }
      }
    }
  }
}
    `;
const doMarkAsShownMutationDocument = `
    query doMarkAsShownMutation($options: MarkAsShownPromotionMutationRequest!) {
  mutations {
    promotions {
      markAsShown(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doPromotionParticipationDocument = `
    query doPromotionParticipation($options: SetParticipationRequest!) {
  mutations {
    promotions {
      setParticipation(input: $options) {
        ts
        data {
          confirmed
        }
      }
    }
  }
}
    `;
const doPromotionRateDocument = `
    query doPromotionRate($options: RatePromotionRequest!) {
  mutations {
    promotions {
      rate(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const getActiveOrAvailableCampaignsDocument = `
    query getActiveOrAvailableCampaigns {
  queries {
    promotions {
      getActiveOrAvailableCampaigns {
        ts
        data {
          campaigns {
            actionUrl
            categoryId
            type
            campaignId
            state
            showNotification
          }
        }
      }
    }
  }
}
    `;
const getBonusWalletPromotionDocument = `
    query getBonusWalletPromotion {
  queries {
    promotions {
      getBonusWalletPromotion {
        ts
        data {
          promotion {
            linkedCategoryId
            howToUseBonusWidget
            termsOfActions
            bonusTitle
            bonusItems {
              bonusItemTitle
              bonusItemImage
              bonusItemDescription
            }
            howWorksTitle
            howWorksItems {
              howWorksItemTitle
              howWorksItemDescription
            }
            actionId
            actionUrl
            bannerUrl
            bannerImage {
              ...Web2ImageFields
            }
            code
            description
            isArchived
            leaderBoardSize
            name
            shortDescription
            nativeScriptDescriptionKey
            startDateNumber
            endDateNumber
            isNew
            socialLinks {
              name
              url
            }
            participationType
            promotionType
            tournaments {
              name
              id
            }
          }
          isCustomerParticipating
          result
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
const getLinkedPromotionsByEgsGameDocument = `
    query getLinkedPromotionsByEgsGame($options: GetPromotionsByEgsGameRequest!) {
  queries {
    promotions {
      getLinkedPromotionsByEgsGame(input: $options) {
        ts
        data {
          result
          promotions {
            categoryId
            actionUrl
            name
            bannerUrl
            bannerImage {
              ...Web2ImageFields
            }
            shortBannerImage {
              ...Web2ImageFields
            }
            endDateNumber
            termsOfActions
            bgColor
          }
          jackpot {
            amount {
              ...Web2MoneyFields
            }
          }
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}
${Web2MoneyFieldsFragmentDoc}`;
const getPromotionCategoriesDocument = `
    query getPromotionCategories {
  queries {
    promotions {
      getPromotionCategories {
        ts
        data {
          categories {
            name
            categoryId
          }
        }
      }
    }
  }
}
    `;
const getPromotionDetailsDocument = `
    query getPromotionDetails($options: PromotionRequest!) {
  queries {
    promotions {
      getPromotion(input: $options) {
        ts
        data {
          promotion {
            sportBonus {
              bonusId
              campaignId
              customerId
              customerLogin
              state
              bonusCreatedAt
              bonusAmount
              transferAmount
              withdrawalAmount
              currencyCode
              bonusRequirements {
                name
                requiredValue
                currentValue
                satisfied
                progressBarValue
                valueType
                localizedName {
                  key
                  value
                }
              }
              confirmation {
                confirmationRequired
                confirmed
              }
              bonusProgress
              wagerProgress
              wager {
                result
                expiredAt
                requirements {
                  name
                  requiredValue
                  currentValue
                  satisfied
                  progressBarValue
                  valueType
                }
              }
              freebet {
                state
                expiredAt
              }
              refuseAvailable
            }
            howToUseBonusWidget
            termsOfActions
            bonusTitle
            bonusItems {
              bonusItemTitle
              bonusItemImage
              bonusItemDescription
            }
            howWorksTitle
            howWorksItems {
              howWorksItemTitle
              howWorksItemDescription
            }
            actionId
            actionUrl
            bannerUrl
            bannerImage {
              ...Web2ImageFields
            }
            code
            description
            metaDescription
            isArchived
            leaderBoardSize
            name
            shortDescription
            nativeScriptDescriptionKey
            activeTournamentStartDate
            activeTournamentEndDate
            startDateNumber
            endDateNumber
            isNew
            socialLinks {
              name
              url
            }
            participationType
            promotionType
            actionButton {
              buttonType
              currencyCode
              state
              title
              value
              customLink
              hintMessage
            }
            tournaments {
              name
              id
            }
            isNT
            isShowEndDateCountdown
            isShowStartDateCountdown
            startDateCountdown
            isHot
            isBlockParticipationButton
            id
            likes
            dislikes
            customerRating
            categoryId
            shortBannerImage {
              ...Web2ImageFields
            }
            bgColor
            landingPageImage {
              ...Web2ImageFields
            }
          }
          isCustomerParticipating
          result
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
const getPromotionNavigationConfigDocument = `
    query getPromotionNavigationConfig {
  queries {
    system {
      getNavigation(input: {list: ["PROMOTION_CATEGORIES_NAVIGATION"]}) {
        ts
        data {
          navigation
        }
      }
    }
  }
}
    `;
const getPromotionsDocument = `
    query getPromotions($options: GetPromotionsRequest!) {
  queries {
    promotions {
      getPromotions(input: $options) {
        ts
        data {
          promotions {
            position
            description
            code
            actionUrl
            name
            shortDescription
            bannerUrl
            shortBannerUrl
            bannerImage {
              ...Web2ImageFields
            }
            shortBannerImage {
              ...Web2ImageFields
            }
            landingPageImage {
              ...Web2ImageFields
            }
            isNew
            endDateNumber
            startDateNumber
            actionId
            nativeScriptDescriptionKey
            leaderBoardSize
            isArchived
            isCustomerParticipating
            categoryId
            isNT
            isHot
            isShowEndDateCountdown
            isShowStartDateCountdown
            startDateCountdown
            isBlockParticipationButton
            id
            likes
            dislikes
            customerRating
            promotionType
            bgColor
          }
          result
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
const doConfirmationsDocument = `
    query doConfirmations($options: EmptyMutationRequest) {
  mutations {
    refProgram {
      confirmations(input: $options) {
        ts
        data {
          confirmed
        }
      }
    }
  }
}
    `;
const doRewardingDocument = `
    query doRewarding($options: RewardRequest!) {
  mutations {
    refProgram {
      reward(input: $options) {
        ts
        data {
          available
          confirmed
          playerRequirements {
            type
            satisfied
            requiredValue
            currency
          }
          rewardOptions {
            rewardTriggerType
            freespinValue
            freebetValue
            rewards {
              depositAmount
              rewardAmount {
                playerReward {
                  count
                  nominalValue
                  amount
                  rewardType
                }
                referralReward {
                  count
                  nominalValue
                  amount
                  rewardType
                }
              }
            }
          }
          totalRewardAmount
          referralsCount
          referrals {
            customerId
            status
            firstName
            lastName
            registrationDate
            requirements {
              type
              satisfied
              requiredValue
              currency
            }
            referralReward {
              count
              nominalValue
              amount
              rewardType
            }
            playerReward {
              count
              nominalValue
              amount
              rewardType
            }
            playerRewardOptions {
              count
              nominalValue
              amount
              rewardType
              game {
                id
                name
              }
              wagerMultiplier
              freebetMinOdds
              freespinProvider
              freespinProviderName
            }
          }
        }
      }
    }
  }
}
    `;
const getAvailabilityDocument = `
    query getAvailability($options: EmptyQueryRequest!) {
  queries {
    refProgram {
      getAvailability(input: $options) {
        ts
        data {
          available
          referralCount
        }
      }
    }
  }
}
    `;
const getDetailsDocument = `
    query getDetails($options: EmptyQueryRequest!) {
  queries {
    refProgram {
      getDetails(input: $options) {
        ts
        data {
          available
          referralsCount
          confirmed
          playerRequirements {
            type
            satisfied
            requiredValue
            currency
          }
          rewardOptions {
            rewardTriggerType
            freespinValue
            freebetValue
            rewards {
              depositAmount
              rewardAmount {
                playerReward {
                  count
                  nominalValue
                  amount
                  rewardType
                }
                referralReward {
                  count
                  nominalValue
                  amount
                  rewardType
                }
              }
            }
          }
          totalRewardAmount
          referralsCount
          referrals {
            customerId
            status
            firstName
            lastName
            registrationDate
            requirements {
              type
              satisfied
              requiredValue
              currency
            }
            referralReward {
              count
              nominalValue
              amount
              rewardType
            }
            playerReward {
              count
              nominalValue
              amount
              rewardType
            }
            playerRewardOptions {
              count
              nominalValue
              amount
              rewardType
              game {
                id
                name
              }
              wagerMultiplier
              freebetMinOdds
              freebetMaxOdds
              freespinProvider
              freespinProviderName
            }
          }
          referralUrl
        }
      }
    }
  }
}
    `;
const getReferralDocument = `
    query getReferral($options: EmptyQueryRequest!) {
  queries {
    refProgram {
      getReferral(input: $options) {
        ts
        data {
          customerId
          rewards {
            depositAmount
            rewardAmount {
              playerReward {
                count
                nominalValue
                amount
                rewardType
              }
              referralReward {
                count
                nominalValue
                amount
                rewardType
              }
            }
          }
          referralDetails {
            customerId
            status
            registrationDate
            requirements {
              type
              satisfied
              requiredValue
              currency
            }
            referralReward {
              count
              nominalValue
              amount
              rewardType
            }
            playerReward {
              count
              nominalValue
              amount
              rewardType
            }
            playerRewardOptions {
              count
              nominalValue
              amount
              rewardType
              game {
                id
                name
              }
              wagerMultiplier
              freebetMinOdds
              freespinProvider
              freespinProviderName
            }
          }
        }
      }
    }
  }
}
    `;
const getRewardsDocument = `
    query getRewards($options: RewardsRequest!) {
  queries {
    refProgram {
      getRewards(input: $options) {
        ts
        data {
          currency
          rewards {
            depositAmount
            rewardAmount {
              playerReward {
                count
                nominalValue
                amount
                rewardType
              }
              referralReward {
                count
                nominalValue
                amount
                rewardType
              }
            }
          }
          referralReward {
            count
            nominalValue
            amount
            rewardType
          }
          referralRequirements {
            type
            satisfied
            requiredValue
            currency
          }
        }
      }
    }
  }
}
    `;
const doRegistrationDocument = `
    query doRegistration($options: RegisterInput!) {
  mutations {
    registration {
      submit(inputForm: $options) {
        ts
        data {
          result
          form {
            uiFormSchema {
              order
              fields {
                isFrontOnly
                id
                widget
                hidden
                title
                defaultValue
                disabled
                hintMessage
                type
                encrypted
                error
                labels {
                  key
                  value
                }
                options {
                  key
                  value
                }
              }
            }
            registrationType
            schemaId
            formValidationSchema
            formStep
            formName
            priority
            stage
            formProgress
            formError
            validated
            hasFieldsErrors
            duplicateUser
            failsCount
            sendSms {
              formParams {
                key
                value
              }
              schemaId
            }
            goBack {
              formParams {
                key
                value
              }
              schemaId
            }
          }
          generatedPassword
          customerData {
            ...CustomerDataFields
          }
        }
      }
    }
  }
}
    ${CustomerDataFieldsFragmentDoc}`;
const getCurrenciesByCountryDocument = `
    query getCurrenciesByCountry($options: CountryCurrenciesRequest!) {
  queries {
    registration {
      getCurrenciesByCountry(input: $options) {
        ts
        data {
          currencies {
            text
            value
          }
        }
      }
    }
  }
}
    `;
const getIncompleteRegistrationFieldsDocument = `
    query getIncompleteRegistrationFields($options: IncompleteFieldsRequest!) {
  queries {
    registration {
      getIncompleteRegistrationFields(input: $options) {
        ts
        data {
          schemaId
          formValidationSchema
          uiFormSchema {
            order
            fields {
              isFrontOnly
              id
              widget
              hidden
              title
              defaultValue
              disabled
              hintMessage
              options {
                key
                value
              }
              labels {
                key
                value
              }
            }
          }
        }
      }
    }
  }
}
    `;
const updateIncompleteRegistrationFieldsDocument = `
    query updateIncompleteRegistrationFields($options: UpdateIncompleteFieldsRequest!) {
  mutations {
    registration {
      updateIncompleteRegistrationFields(input: $options) {
        ts
        data {
          result
          registrationForm {
            schemaId
            formValidationSchema
            uiFormSchema {
              order
              fields {
                error
                isFrontOnly
                id
                widget
                hidden
                title
                defaultValue
                disabled
                hintMessage
                options {
                  key
                  value
                }
                labels {
                  key
                  value
                }
              }
            }
            trigger
            formError
            formStep
            formErrorCaption
            formSuccess
            validated
            hasFieldsErrors
            duplicateUser
            formName
            affiliateId
            stage
            priority
            failsCount
          }
        }
      }
    }
  }
}
    `;
const removeCustomerMessageDocument = `
    query removeCustomerMessage($options: DeleteMessageRequest!) {
  mutations {
    customer {
      messages {
        deleteMessage(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
const resendVerificationEmailDocument = `
    query resendVerificationEmail($options: EmptyMutationRequest!) {
  mutations {
    customer {
      resendVerificationEmail(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const resetDisposableKeysDocument = `
    query resetDisposableKeys($options: G2SVCodeRequest!) {
  mutations {
    g2sv {
      resetDisposableKeys(input: $options) {
        ts
        data {
          disposableKeys
        }
      }
    }
  }
}
    `;
const resetShownDocument = `
    query resetShown($options: EmptyMutationRequest) {
  mutations {
    whatsNew {
      resetShown(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const checkRestorePasswordSmsDocument = `
    query checkRestorePasswordSms($options: PwdRecoveryPhoneCheckSmsInput!) {
  mutations {
    pwdRecoveryPhone {
      checkSms(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const checkRestorePasswordUidDocument = `
    query checkRestorePasswordUid($options: PwdRecoveryEmailCheckUidInput!) {
  mutations {
    pwdRecoveryEmail {
      isValidUid(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doRestorePasswordByEmailDocument = `
    query doRestorePasswordByEmail($options: PwdRecoveryEmailChangePwdInput!) {
  mutations {
    pwdRecoveryEmail {
      changePwdByEmail(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const doRestorePasswordByPhoneDocument = `
    query doRestorePasswordByPhone($options: PwdRecoveryPhoneChangePwdInput!) {
  mutations {
    pwdRecoveryPhone {
      changePwdByPhone(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const resetPinCodeEmailDocument = `
    query resetPinCodeEmail($options: ResetPinCodeRequest!) {
  mutations {
    pinCode {
      resetPinCodeEmail(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const resetPinCodePhoneDocument = `
    query resetPinCodePhone($options: ResetPinCodeRequest!) {
  mutations {
    pinCode {
      resetPinCodePhone(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const sendRestorePasswordLinkToEmailDocument = `
    query sendRestorePasswordLinkToEmail($options: PwdRecoveryEmailSendUidInput!) {
  mutations {
    pwdRecoveryEmail {
      sendUid(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const sendRestorePasswordSmsOrCallToPhoneDocument = `
    query sendRestorePasswordSmsOrCallToPhone($options: PwdRecoveryPhoneSendSmsInput!) {
  mutations {
    pwdRecoveryPhone {
      sendPasswordResetVerificationCode(input: $options) {
        ts
        data {
          formStep
        }
      }
    }
  }
}
    `;
const sendRestorePasswordSmsToPhoneDocument = `
    query sendRestorePasswordSmsToPhone($options: PwdRecoveryPhoneSendSmsInput!) {
  mutations {
    pwdRecoveryPhone {
      sendSms(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const sendRestorePinLinkToEmailDocument = `
    query sendRestorePinLinkToEmail($options: EmptyMutationRequest!) {
  mutations {
    pinCode {
      sendResetTokenEmail(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const sendRestorePinLinkToPhoneDocument = `
    query sendRestorePinLinkToPhone($options: SendResetTokenPhoneRequest!) {
  mutations {
    pinCode {
      sendResetTokenPhone(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const savePushSettingsDocument = `
    query savePushSettings($options: PushNotificationSettingsRequest!) {
  mutations {
    customer {
      savePushNotificationSettings(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const getSpintaxMetaDataDocument = `
    query getSpintaxMetaData($options: SpintaxMetadataRequest!) {
  queries {
    system {
      cms {
        getSpintaxMetadata(input: $options) {
          ts
          data {
            result
            metadata
          }
        }
      }
    }
  }
}
    `;
const setAdditionalPropsUserDocument = `
    query setAdditionalPropsUser($options: CustomerConfigRequest!) {
  mutations {
    customer {
      config {
        setConfig(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
const setBannerClosedDocument = `
    query setBannerClosed($options: SetBannerClosedRequest!) {
  mutations {
    banners {
      setBannerClosed(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const setBetLimitsDocument = `
    query setBetLimits($options: SetCustomerLimitsRequest!) {
  mutations {
    limits {
      setBetLimits(input: $options) {
        ts
        data {
          result
          limitsUpdatableAt {
            dailyUpdatableAt
            weeklyUpdatableAt
            monthlyUpdatableAt
          }
          limits {
            dailyLimit
            weeklyLimit
            monthlyLimit
            dailyPercent
            dailyLeft
            dailyEndAt
            weeklyPercent
            weeklyLeft
            weeklyEndAt
            monthlyPercent
            monthlyLeft
            monthlyEndAt
          }
        }
      }
    }
  }
}
    `;
const setCoookieDocument = `
    query setCoookie($options: SetCookieRequest!) {
  mutations {
    cookies {
      setCookie(input: $options) {
        ts
        data {
          cookies {
            name
            value
            version
            maxAge
            path
            secure
            httpOnly
          }
        }
      }
    }
  }
}
    `;
const setDepositLimitsDocument = `
    query setDepositLimits($options: SetCustomerLimitsRequest!) {
  mutations {
    limits {
      setDepositLimits(input: $options) {
        ts
        data {
          result
          limitsUpdatableAt {
            dailyUpdatableAt
            weeklyUpdatableAt
            monthlyUpdatableAt
          }
          limits {
            dailyLimit
            weeklyLimit
            monthlyLimit
            dailyPercent
            dailyLeft
            dailyEndAt
            weeklyPercent
            weeklyLeft
            weeklyEndAt
            monthlyPercent
            monthlyLeft
            monthlyEndAt
          }
        }
      }
    }
  }
}
    `;
const setDowntimeShownDocument = `
    query setDowntimeShown($options: SetDowntimeShownRequest!) {
  mutations {
    cookies {
      setDowntimeShown(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const setWhatsNewDocument = `
    query setWhatsNew($options: SetWhatsNewRequest!) {
  mutations {
    whatsNew {
      setWhatsNew(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const getTsupisLegalAgreementsVersionsDocument = `
    query getTsupisLegalAgreementsVersions($options: LegalAgreementsRequest!) {
  queries {
    registration {
      getLegalAgreement(input: $options) {
        ts
        data {
          offer {
            version
          }
          personalDataAgreement {
            version
          }
        }
      }
    }
  }
}
    `;
const getTsupisOfferAgreementsDocument = `
    query getTsupisOfferAgreements($options: LegalAgreementsRequest!) {
  queries {
    registration {
      getLegalAgreement(input: $options) {
        ts
        data {
          offer {
            version
            text
          }
        }
      }
    }
  }
}
    `;
const getTsupisPersonalDataAgreementsDocument = `
    query getTsupisPersonalDataAgreements($options: LegalAgreementsRequest!) {
  queries {
    registration {
      getLegalAgreement(input: $options) {
        ts
        data {
          personalDataAgreement {
            version
            text
          }
        }
      }
    }
  }
}
    `;
const updateCustomerProfileFieldsDocument = `
    query updateCustomerProfileFields($options: UpdateIncompleteFieldsRequest!) {
  mutations {
    registration {
      updateCustomerProfileFields(input: $options) {
        ts
        data {
          result
          registrationForm {
            registrationType
            uiFormSchema {
              order
              fields {
                error
                isFrontOnly
                id
                widget
                hidden
                title
                defaultValue
                disabled
                hintMessage
                options {
                  key
                  value
                }
                labels {
                  key
                  value
                }
              }
            }
            formValidationSchema
            schemaId
            formStep
            trigger
            formError
            formErrorCaption
            formSuccess
            validated
            hasFieldsErrors
            duplicateUser
            formName
            affiliateId
            stage
            priority
            failsCount
          }
        }
      }
    }
  }
}
    `;
const getVerificationReasonsDocument = `
    query getVerificationReasons($options: EmptyQueryRequest!) {
  queries {
    verification {
      getReasons(input: $options) {
        ts
        data {
          reasons {
            id
            localizedName
          }
        }
      }
    }
  }
}
    `;
const getVerificationTokenDocument = `
    query getVerificationToken($options: GetTokenRequest!) {
  queries {
    verification {
      getTokenV2(input: $options) {
        ts
        data {
          ... on VerificationGetTokenResponse {
            ...VerificationTokenFields
          }
        }
      }
    }
  }
}
    ${VerificationTokenFieldsFragmentDoc}`;
const makeCustomerVerificationDocument = `
    query makeCustomerVerification($options: CustVerifFromLbCustomerRequest!) {
  mutations {
    verification {
      makeCustomerVerification(input: $options) {
        ts
        data {
          ... on VerificationGetTokenResponse {
            ...VerificationTokenFields
          }
        }
      }
    }
  }
}
    ${VerificationTokenFieldsFragmentDoc}`;
const makeVipFastTrackVerificationDocument = `
    query makeVipFastTrackVerification($options: EmptyMutationRequest!) {
  mutations {
    verification {
      makeVipFastTrackVerification(input: $options) {
        ts
        data {
          ... on VerificationGetTokenResponse {
            ...VerificationTokenFields
          }
        }
      }
    }
  }
}
    ${VerificationTokenFieldsFragmentDoc}`;
const verifyEmailDocument = `
    query verifyEmail($options: VerifyEmailRequest!) {
  mutations {
    customer {
      verifyEmail(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
const getStateDocument = `
    query getState($options: EmptyQueryRequest!) {
  queries {
    vipFastTrack {
      getState(input: $options) {
        ts
        data {
          status
          verificationStatus
          depositStatus
          depositAmount
          depositCurrency
        }
      }
    }
  }
}
    `;
const withdrawBonusCBCDocument = `
    query withdrawBonusCBC {
  mutations {
    bonuses {
      withdrawBonus {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
let mocks = {};
function mockGetAllAccountHistory(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getAllAccountHistory"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getAllAccountHistory"] === callback) {
      mocks["getAllAccountHistory"] = void 0;
    }
  };
}
async function getAllAccountHistory(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getAllAccountHistory"]) return Reflect.apply(mocks["getAllAccountHistory"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getAllAccountHistoryDocument), variables, cb, requestOptions, getAllAccountHistory);
}
function mockGetAllAccountHistoryDataGroups(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getAllAccountHistoryDataGroups"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getAllAccountHistoryDataGroups"] === callback) {
      mocks["getAllAccountHistoryDataGroups"] = void 0;
    }
  };
}
async function getAllAccountHistoryDataGroups(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getAllAccountHistoryDataGroups"]) return Reflect.apply(mocks["getAllAccountHistoryDataGroups"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getAllAccountHistoryDataGroupsDocument), variables, cb, requestOptions, getAllAccountHistoryDataGroups);
}
function mockGetEgsChannels(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getEgsChannels"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getEgsChannels"] === callback) {
      mocks["getEgsChannels"] = void 0;
    }
  };
}
async function getEgsChannels(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getEgsChannels"]) return Reflect.apply(mocks["getEgsChannels"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getEgsChannelsDocument), variables, cb, requestOptions, getEgsChannels);
}
function mockGetEgsGameTypes(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getEgsGameTypes"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getEgsGameTypes"] === callback) {
      mocks["getEgsGameTypes"] = void 0;
    }
  };
}
async function getEgsGameTypes(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getEgsGameTypes"]) return Reflect.apply(mocks["getEgsGameTypes"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getEgsGameTypesDocument), variables, cb, requestOptions, getEgsGameTypes);
}
function mockGetEgsRounds(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getEgsRounds"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getEgsRounds"] === callback) {
      mocks["getEgsRounds"] = void 0;
    }
  };
}
async function getEgsRounds(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getEgsRounds"]) return Reflect.apply(mocks["getEgsRounds"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getEgsRoundsDocument), variables, cb, requestOptions, getEgsRounds);
}
function mockGetEgsRoundsCursor(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getEgsRoundsCursor"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getEgsRoundsCursor"] === callback) {
      mocks["getEgsRoundsCursor"] = void 0;
    }
  };
}
async function getEgsRoundsCursor(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getEgsRoundsCursor"]) return Reflect.apply(mocks["getEgsRoundsCursor"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getEgsRoundsCursorDocument), variables, cb, requestOptions, getEgsRoundsCursor);
}
function mockGetEgsSessions(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getEgsSessions"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getEgsSessions"] === callback) {
      mocks["getEgsSessions"] = void 0;
    }
  };
}
async function getEgsSessions(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getEgsSessions"]) return Reflect.apply(mocks["getEgsSessions"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getEgsSessionsDocument), variables, cb, requestOptions, getEgsSessions);
}
function mockGetEgsTransactions(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getEgsTransactions"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getEgsTransactions"] === callback) {
      mocks["getEgsTransactions"] = void 0;
    }
  };
}
async function getEgsTransactions(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getEgsTransactions"]) return Reflect.apply(mocks["getEgsTransactions"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getEgsTransactionsDocument), variables, cb, requestOptions, getEgsTransactions);
}
function mockGetMarketingBonuses(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getMarketingBonuses"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getMarketingBonuses"] === callback) {
      mocks["getMarketingBonuses"] = void 0;
    }
  };
}
async function getMarketingBonuses(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getMarketingBonuses"]) return Reflect.apply(mocks["getMarketingBonuses"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getMarketingBonusesDocument), variables, cb, requestOptions, getMarketingBonuses);
}
function mockGetMarketingBonusCursor(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getMarketingBonusCursor"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getMarketingBonusCursor"] === callback) {
      mocks["getMarketingBonusCursor"] = void 0;
    }
  };
}
async function getMarketingBonusCursor(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getMarketingBonusCursor"]) return Reflect.apply(mocks["getMarketingBonusCursor"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getMarketingBonusCursorDocument), variables, cb, requestOptions, getMarketingBonusCursor);
}
function mockGetMarketingDataCategories(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getMarketingDataCategories"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getMarketingDataCategories"] === callback) {
      mocks["getMarketingDataCategories"] = void 0;
    }
  };
}
async function getMarketingDataCategories(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getMarketingDataCategories"]) return Reflect.apply(mocks["getMarketingDataCategories"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getMarketingDataCategoriesDocument), variables, cb, requestOptions, getMarketingDataCategories);
}
function mockGetMarketingStatuses(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getMarketingStatuses"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getMarketingStatuses"] === callback) {
      mocks["getMarketingStatuses"] = void 0;
    }
  };
}
async function getMarketingStatuses(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getMarketingStatuses"]) return Reflect.apply(mocks["getMarketingStatuses"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getMarketingStatusesDocument), variables, cb, requestOptions, getMarketingStatuses);
}
function mockGetPayments(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getPayments"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getPayments"] === callback) {
      mocks["getPayments"] = void 0;
    }
  };
}
async function getPayments(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getPayments"]) return Reflect.apply(mocks["getPayments"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getPaymentsDocument), variables, cb, requestOptions, getPayments);
}
function mockGetPaymentsCursor(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getPaymentsCursor"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getPaymentsCursor"] === callback) {
      mocks["getPaymentsCursor"] = void 0;
    }
  };
}
async function getPaymentsCursor(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getPaymentsCursor"]) return Reflect.apply(mocks["getPaymentsCursor"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getPaymentsCursorDocument), variables, cb, requestOptions, getPaymentsCursor);
}
function mockGetPaymentsDataCategories(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getPaymentsDataCategories"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getPaymentsDataCategories"] === callback) {
      mocks["getPaymentsDataCategories"] = void 0;
    }
  };
}
async function getPaymentsDataCategories(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getPaymentsDataCategories"]) return Reflect.apply(mocks["getPaymentsDataCategories"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getPaymentsDataCategoriesDocument), variables, cb, requestOptions, getPaymentsDataCategories);
}
function mockGetPaymentsStatuses(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getPaymentsStatuses"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getPaymentsStatuses"] === callback) {
      mocks["getPaymentsStatuses"] = void 0;
    }
  };
}
async function getPaymentsStatuses(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getPaymentsStatuses"]) return Reflect.apply(mocks["getPaymentsStatuses"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getPaymentsStatusesDocument), variables, cb, requestOptions, getPaymentsStatuses);
}
function mockGetSportsbookBets(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getSportsbookBets"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getSportsbookBets"] === callback) {
      mocks["getSportsbookBets"] = void 0;
    }
  };
}
async function getSportsbookBets(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getSportsbookBets"]) return Reflect.apply(mocks["getSportsbookBets"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getSportsbookBetsDocument), variables, cb, requestOptions, getSportsbookBets);
}
function mockGetSportsbookSports(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getSportsbookSports"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getSportsbookSports"] === callback) {
      mocks["getSportsbookSports"] = void 0;
    }
  };
}
async function getSportsbookSports(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getSportsbookSports"]) return Reflect.apply(mocks["getSportsbookSports"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getSportsbookSportsDocument), variables, cb, requestOptions, getSportsbookSports);
}
function mockGetVSportBets(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getVSportBets"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getVSportBets"] === callback) {
      mocks["getVSportBets"] = void 0;
    }
  };
}
async function getVSportBets(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getVSportBets"]) return Reflect.apply(mocks["getVSportBets"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getVSportBetsDocument), variables, cb, requestOptions, getVSportBets);
}
function mockGetVSportSports(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getVSportSports"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getVSportSports"] === callback) {
      mocks["getVSportSports"] = void 0;
    }
  };
}
async function getVSportSports(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getVSportSports"]) return Reflect.apply(mocks["getVSportSports"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getVSportSportsDocument), variables, cb, requestOptions, getVSportSports);
}
function mockDoAchievementMarkAsViewed(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doAchievementMarkAsViewed"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doAchievementMarkAsViewed"] === callback) {
      mocks["doAchievementMarkAsViewed"] = void 0;
    }
  };
}
async function doAchievementMarkAsViewed(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doAchievementMarkAsViewed"]) return Reflect.apply(mocks["doAchievementMarkAsViewed"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doAchievementMarkAsViewedDocument), variables, cb, requestOptions, doAchievementMarkAsViewed);
}
function mockDoAchievementModifyAvatar(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doAchievementModifyAvatar"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doAchievementModifyAvatar"] === callback) {
      mocks["doAchievementModifyAvatar"] = void 0;
    }
  };
}
async function doAchievementModifyAvatar(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doAchievementModifyAvatar"]) return Reflect.apply(mocks["doAchievementModifyAvatar"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doAchievementModifyAvatarDocument), variables, cb, requestOptions, doAchievementModifyAvatar);
}
function mockDoAchievementReward(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doAchievementReward"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doAchievementReward"] === callback) {
      mocks["doAchievementReward"] = void 0;
    }
  };
}
async function doAchievementReward(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doAchievementReward"]) return Reflect.apply(mocks["doAchievementReward"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doAchievementRewardDocument), variables, cb, requestOptions, doAchievementReward);
}
function mockGetAchievement(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getAchievement"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getAchievement"] === callback) {
      mocks["getAchievement"] = void 0;
    }
  };
}
async function getAchievement(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getAchievement"]) return Reflect.apply(mocks["getAchievement"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getAchievementDocument), variables, cb, requestOptions, getAchievement);
}
function mockGetAchievements(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getAchievements"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getAchievements"] === callback) {
      mocks["getAchievements"] = void 0;
    }
  };
}
async function getAchievements(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getAchievements"]) return Reflect.apply(mocks["getAchievements"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getAchievementsDocument), variables, cb, requestOptions, getAchievements);
}
function mockGetAchievementsAvailability(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getAchievementsAvailability"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getAchievementsAvailability"] === callback) {
      mocks["getAchievementsAvailability"] = void 0;
    }
  };
}
async function getAchievementsAvailability(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getAchievementsAvailability"]) return Reflect.apply(mocks["getAchievementsAvailability"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getAchievementsAvailabilityDocument), variables, cb, requestOptions, getAchievementsAvailability);
}
function mockGetAchievementsAvatarsAndBackgrounds(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getAchievementsAvatarsAndBackgrounds"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getAchievementsAvatarsAndBackgrounds"] === callback) {
      mocks["getAchievementsAvatarsAndBackgrounds"] = void 0;
    }
  };
}
async function getAchievementsAvatarsAndBackgrounds(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getAchievementsAvatarsAndBackgrounds"]) return Reflect.apply(mocks["getAchievementsAvatarsAndBackgrounds"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getAchievementsAvatarsAndBackgroundsDocument), variables, cb, requestOptions, getAchievementsAvatarsAndBackgrounds);
}
function mockGetAchievementsCategories(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getAchievementsCategories"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getAchievementsCategories"] === callback) {
      mocks["getAchievementsCategories"] = void 0;
    }
  };
}
async function getAchievementsCategories(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getAchievementsCategories"]) return Reflect.apply(mocks["getAchievementsCategories"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getAchievementsCategoriesDocument), variables, cb, requestOptions, getAchievementsCategories);
}
function mockGetAchievementsProfile(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getAchievementsProfile"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getAchievementsProfile"] === callback) {
      mocks["getAchievementsProfile"] = void 0;
    }
  };
}
async function getAchievementsProfile(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getAchievementsProfile"]) return Reflect.apply(mocks["getAchievementsProfile"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getAchievementsProfileDocument), variables, cb, requestOptions, getAchievementsProfile);
}
function mockDoCollectReward(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doCollectReward"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doCollectReward"] === callback) {
      mocks["doCollectReward"] = void 0;
    }
  };
}
async function doCollectReward(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doCollectReward"]) return Reflect.apply(mocks["doCollectReward"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doCollectRewardDocument), variables, cb, requestOptions, doCollectReward);
}
function mockDoConfirmParticipation(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doConfirmParticipation"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doConfirmParticipation"] === callback) {
      mocks["doConfirmParticipation"] = void 0;
    }
  };
}
async function doConfirmParticipation(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doConfirmParticipation"]) return Reflect.apply(mocks["doConfirmParticipation"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doConfirmParticipationDocument), variables, cb, requestOptions, doConfirmParticipation);
}
function mockGetBenefitCustomerLevel(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBenefitCustomerLevel"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBenefitCustomerLevel"] === callback) {
      mocks["getBenefitCustomerLevel"] = void 0;
    }
  };
}
async function getBenefitCustomerLevel(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBenefitCustomerLevel"]) return Reflect.apply(mocks["getBenefitCustomerLevel"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBenefitCustomerLevelDocument), variables, cb, requestOptions, getBenefitCustomerLevel);
}
function mockGetBetlineLeagueStandings(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBetlineLeagueStandings"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBetlineLeagueStandings"] === callback) {
      mocks["getBetlineLeagueStandings"] = void 0;
    }
  };
}
async function getBetlineLeagueStandings(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBetlineLeagueStandings"]) return Reflect.apply(mocks["getBetlineLeagueStandings"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBetlineLeagueStandingsDocument), variables, cb, requestOptions, getBetlineLeagueStandings);
}
function mockGetBetlineLeagueStatus(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBetlineLeagueStatus"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBetlineLeagueStatus"] === callback) {
      mocks["getBetlineLeagueStatus"] = void 0;
    }
  };
}
async function getBetlineLeagueStatus(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBetlineLeagueStatus"]) return Reflect.apply(mocks["getBetlineLeagueStatus"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBetlineLeagueStatusDocument), variables, cb, requestOptions, getBetlineLeagueStatus);
}
function mockGetBetlineLeagueTopPlayers(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBetlineLeagueTopPlayers"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBetlineLeagueTopPlayers"] === callback) {
      mocks["getBetlineLeagueTopPlayers"] = void 0;
    }
  };
}
async function getBetlineLeagueTopPlayers(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBetlineLeagueTopPlayers"]) return Reflect.apply(mocks["getBetlineLeagueTopPlayers"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBetlineLeagueTopPlayersDocument), variables, cb, requestOptions, getBetlineLeagueTopPlayers);
}
function mockGetBetlineMatchEventStatistics(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBetlineMatchEventStatistics"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBetlineMatchEventStatistics"] === callback) {
      mocks["getBetlineMatchEventStatistics"] = void 0;
    }
  };
}
async function getBetlineMatchEventStatistics(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBetlineMatchEventStatistics"]) return Reflect.apply(mocks["getBetlineMatchEventStatistics"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBetlineMatchEventStatisticsDocument), variables, cb, requestOptions, getBetlineMatchEventStatistics);
}
function mockGetBetlineMatchStatistics(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBetlineMatchStatistics"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBetlineMatchStatistics"] === callback) {
      mocks["getBetlineMatchStatistics"] = void 0;
    }
  };
}
async function getBetlineMatchStatistics(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBetlineMatchStatistics"]) return Reflect.apply(mocks["getBetlineMatchStatistics"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBetlineMatchStatisticsDocument), variables, cb, requestOptions, getBetlineMatchStatistics);
}
function mockGetCustomerMatchStreamEvents(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCustomerMatchStreamEvents"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCustomerMatchStreamEvents"] === callback) {
      mocks["getCustomerMatchStreamEvents"] = void 0;
    }
  };
}
async function getCustomerMatchStreamEvents(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCustomerMatchStreamEvents"]) return Reflect.apply(mocks["getCustomerMatchStreamEvents"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCustomerMatchStreamEventsDocument), variables, cb, requestOptions, getCustomerMatchStreamEvents);
}
function mockGetCustomerStreams(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCustomerStreams"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCustomerStreams"] === callback) {
      mocks["getCustomerStreams"] = void 0;
    }
  };
}
async function getCustomerStreams(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCustomerStreams"]) return Reflect.apply(mocks["getCustomerStreams"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCustomerStreamsDocument), variables, cb, requestOptions, getCustomerStreams);
}
function mockGetLeagueData(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getLeagueData"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getLeagueData"] === callback) {
      mocks["getLeagueData"] = void 0;
    }
  };
}
async function getLeagueData(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getLeagueData"]) return Reflect.apply(mocks["getLeagueData"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getLeagueDataDocument), variables, cb, requestOptions, getLeagueData);
}
function mockGetLeaguePlayoffByLeagueId(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getLeaguePlayoffByLeagueId"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getLeaguePlayoffByLeagueId"] === callback) {
      mocks["getLeaguePlayoffByLeagueId"] = void 0;
    }
  };
}
async function getLeaguePlayoffByLeagueId(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getLeaguePlayoffByLeagueId"]) return Reflect.apply(mocks["getLeaguePlayoffByLeagueId"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getLeaguePlayoffByLeagueIdDocument), variables, cb, requestOptions, getLeaguePlayoffByLeagueId);
}
function mockGetLeagueStandingsByLeagueId(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getLeagueStandingsByLeagueId"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getLeagueStandingsByLeagueId"] === callback) {
      mocks["getLeagueStandingsByLeagueId"] = void 0;
    }
  };
}
async function getLeagueStandingsByLeagueId(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getLeagueStandingsByLeagueId"]) return Reflect.apply(mocks["getLeagueStandingsByLeagueId"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getLeagueStandingsByLeagueIdDocument), variables, cb, requestOptions, getLeagueStandingsByLeagueId);
}
function mockGetLeagueStatisticsByLeagueId(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getLeagueStatisticsByLeagueId"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getLeagueStatisticsByLeagueId"] === callback) {
      mocks["getLeagueStatisticsByLeagueId"] = void 0;
    }
  };
}
async function getLeagueStatisticsByLeagueId(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getLeagueStatisticsByLeagueId"]) return Reflect.apply(mocks["getLeagueStatisticsByLeagueId"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getLeagueStatisticsByLeagueIdDocument), variables, cb, requestOptions, getLeagueStatisticsByLeagueId);
}
function mockGetMatchStreamUrl(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getMatchStreamUrl"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getMatchStreamUrl"] === callback) {
      mocks["getMatchStreamUrl"] = void 0;
    }
  };
}
async function getMatchStreamUrl(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getMatchStreamUrl"]) return Reflect.apply(mocks["getMatchStreamUrl"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getMatchStreamUrlDocument), variables, cb, requestOptions, getMatchStreamUrl);
}
function mockDoCalculatePack(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doCalculatePack"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doCalculatePack"] === callback) {
      mocks["doCalculatePack"] = void 0;
    }
  };
}
async function doCalculatePack(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doCalculatePack"]) return Reflect.apply(mocks["doCalculatePack"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doCalculatePackDocument), variables, cb, requestOptions, doCalculatePack);
}
function mockDoCalculateRound(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doCalculateRound"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doCalculateRound"] === callback) {
      mocks["doCalculateRound"] = void 0;
    }
  };
}
async function doCalculateRound(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doCalculateRound"]) return Reflect.apply(mocks["doCalculateRound"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doCalculateRoundDocument), variables, cb, requestOptions, doCalculateRound);
}
function mockDoCollectPackReward(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doCollectPackReward"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doCollectPackReward"] === callback) {
      mocks["doCollectPackReward"] = void 0;
    }
  };
}
async function doCollectPackReward(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doCollectPackReward"]) return Reflect.apply(mocks["doCollectPackReward"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doCollectPackRewardDocument), variables, cb, requestOptions, doCollectPackReward);
}
function mockGetAllPacks(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getAllPacks"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getAllPacks"] === callback) {
      mocks["getAllPacks"] = void 0;
    }
  };
}
async function getAllPacks(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getAllPacks"]) return Reflect.apply(mocks["getAllPacks"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getAllPacksDocument), variables, cb, requestOptions, getAllPacks);
}
function mockGetPacks(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getPacks"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getPacks"] === callback) {
      mocks["getPacks"] = void 0;
    }
  };
}
async function getPacks(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getPacks"]) return Reflect.apply(mocks["getPacks"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getPacksDocument), variables, cb, requestOptions, getPacks);
}
function mockGetRounds(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getRounds"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getRounds"] === callback) {
      mocks["getRounds"] = void 0;
    }
  };
}
async function getRounds(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getRounds"]) return Reflect.apply(mocks["getRounds"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getRoundsDocument), variables, cb, requestOptions, getRounds);
}
function mockDoConfirmCashback(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doConfirmCashback"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doConfirmCashback"] === callback) {
      mocks["doConfirmCashback"] = void 0;
    }
  };
}
async function doConfirmCashback(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doConfirmCashback"]) return Reflect.apply(mocks["doConfirmCashback"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doConfirmCashbackDocument), variables, cb, requestOptions, doConfirmCashback);
}
function mockDoRefuseCashback(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doRefuseCashback"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doRefuseCashback"] === callback) {
      mocks["doRefuseCashback"] = void 0;
    }
  };
}
async function doRefuseCashback(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doRefuseCashback"]) return Reflect.apply(mocks["doRefuseCashback"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doRefuseCashbackDocument), variables, cb, requestOptions, doRefuseCashback);
}
function mockGetCashback(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCashback"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCashback"] === callback) {
      mocks["getCashback"] = void 0;
    }
  };
}
async function getCashback(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCashback"]) return Reflect.apply(mocks["getCashback"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCashbackDocument), variables, cb, requestOptions, getCashback);
}
function mockAddFavoriteGame(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["addFavoriteGame"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["addFavoriteGame"] === callback) {
      mocks["addFavoriteGame"] = void 0;
    }
  };
}
async function addFavoriteGame(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["addFavoriteGame"]) return Reflect.apply(mocks["addFavoriteGame"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(addFavoriteGameDocument), variables, cb, requestOptions, addFavoriteGame);
}
function mockGetCasinoLoyaltyStatus(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCasinoLoyaltyStatus"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCasinoLoyaltyStatus"] === callback) {
      mocks["getCasinoLoyaltyStatus"] = void 0;
    }
  };
}
async function getCasinoLoyaltyStatus(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCasinoLoyaltyStatus"]) return Reflect.apply(mocks["getCasinoLoyaltyStatus"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCasinoLoyaltyStatusDocument), variables, cb, requestOptions, getCasinoLoyaltyStatus);
}
function mockGetPromoJackpots(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getPromoJackpots"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getPromoJackpots"] === callback) {
      mocks["getPromoJackpots"] = void 0;
    }
  };
}
async function getPromoJackpots(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getPromoJackpots"]) return Reflect.apply(mocks["getPromoJackpots"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getPromoJackpotsDocument), variables, cb, requestOptions, getPromoJackpots);
}
function mockGetPromotionsByEgsGame(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getPromotionsByEgsGame"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getPromotionsByEgsGame"] === callback) {
      mocks["getPromotionsByEgsGame"] = void 0;
    }
  };
}
async function getPromotionsByEgsGame(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getPromotionsByEgsGame"]) return Reflect.apply(mocks["getPromotionsByEgsGame"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getPromotionsByEgsGameDocument), variables, cb, requestOptions, getPromotionsByEgsGame);
}
function mockGetVpnConfig(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getVpnConfig"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getVpnConfig"] === callback) {
      mocks["getVpnConfig"] = void 0;
    }
  };
}
async function getVpnConfig(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getVpnConfig"]) return Reflect.apply(mocks["getVpnConfig"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getVpnConfigDocument), variables, cb, requestOptions, getVpnConfig);
}
function mockRemoveFavoriteGame(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["removeFavoriteGame"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["removeFavoriteGame"] === callback) {
      mocks["removeFavoriteGame"] = void 0;
    }
  };
}
async function removeFavoriteGame(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["removeFavoriteGame"]) return Reflect.apply(mocks["removeFavoriteGame"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(removeFavoriteGameDocument), variables, cb, requestOptions, removeFavoriteGame);
}
function mockCheckPushTokenExists(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["checkPushTokenExists"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["checkPushTokenExists"] === callback) {
      mocks["checkPushTokenExists"] = void 0;
    }
  };
}
async function checkPushTokenExists(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["checkPushTokenExists"]) return Reflect.apply(mocks["checkPushTokenExists"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(checkPushTokenExistsDocument), variables, cb, requestOptions, checkPushTokenExists);
}
function mockClearCookies(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["clearCookies"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["clearCookies"] === callback) {
      mocks["clearCookies"] = void 0;
    }
  };
}
async function clearCookies(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["clearCookies"]) return Reflect.apply(mocks["clearCookies"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(clearCookiesDocument), variables, cb, requestOptions, clearCookies);
}
function mockDoMarkDeviceUntrusted(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doMarkDeviceUntrusted"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doMarkDeviceUntrusted"] === callback) {
      mocks["doMarkDeviceUntrusted"] = void 0;
    }
  };
}
async function doMarkDeviceUntrusted(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doMarkDeviceUntrusted"]) return Reflect.apply(mocks["doMarkDeviceUntrusted"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doMarkDeviceUntrustedDocument), variables, cb, requestOptions, doMarkDeviceUntrusted);
}
function mockDoModifyTimeZone(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doModifyTimeZone"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doModifyTimeZone"] === callback) {
      mocks["doModifyTimeZone"] = void 0;
    }
  };
}
async function doModifyTimeZone(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doModifyTimeZone"]) return Reflect.apply(mocks["doModifyTimeZone"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doModifyTimeZoneDocument), variables, cb, requestOptions, doModifyTimeZone);
}
function mockDoRefuseBonus(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doRefuseBonus"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doRefuseBonus"] === callback) {
      mocks["doRefuseBonus"] = void 0;
    }
  };
}
async function doRefuseBonus(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doRefuseBonus"]) return Reflect.apply(mocks["doRefuseBonus"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doRefuseBonusDocument), variables, cb, requestOptions, doRefuseBonus);
}
function mockGetBonusMatrix(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBonusMatrix"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBonusMatrix"] === callback) {
      mocks["getBonusMatrix"] = void 0;
    }
  };
}
async function getBonusMatrix(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBonusMatrix"]) return Reflect.apply(mocks["getBonusMatrix"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBonusMatrixDocument), variables, cb, requestOptions, getBonusMatrix);
}
function mockGetCustomerBonuses(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCustomerBonuses"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCustomerBonuses"] === callback) {
      mocks["getCustomerBonuses"] = void 0;
    }
  };
}
async function getCustomerBonuses(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCustomerBonuses"]) return Reflect.apply(mocks["getCustomerBonuses"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCustomerBonusesDocument), variables, cb, requestOptions, getCustomerBonuses);
}
function mockSaveBetSlipConfiguration(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["saveBetSlipConfiguration"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["saveBetSlipConfiguration"] === callback) {
      mocks["saveBetSlipConfiguration"] = void 0;
    }
  };
}
async function saveBetSlipConfiguration(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["saveBetSlipConfiguration"]) return Reflect.apply(mocks["saveBetSlipConfiguration"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(saveBetSlipConfigurationDocument), variables, cb, requestOptions, saveBetSlipConfiguration);
}
function mockCustomerIdentityCheck(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["customerIdentityCheck"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["customerIdentityCheck"] === callback) {
      mocks["customerIdentityCheck"] = void 0;
    }
  };
}
async function customerIdentityCheck(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["customerIdentityCheck"]) return Reflect.apply(mocks["customerIdentityCheck"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(customerIdentityCheckDocument), variables, cb, requestOptions, customerIdentityCheck);
}
function mockDisableCustomerMode(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["disableCustomerMode"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["disableCustomerMode"] === callback) {
      mocks["disableCustomerMode"] = void 0;
    }
  };
}
async function disableCustomerMode(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["disableCustomerMode"]) return Reflect.apply(mocks["disableCustomerMode"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(disableCustomerModeDocument), variables, cb, requestOptions, disableCustomerMode);
}
function mockDoActivateAffiliateProgram(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doActivateAffiliateProgram"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doActivateAffiliateProgram"] === callback) {
      mocks["doActivateAffiliateProgram"] = void 0;
    }
  };
}
async function doActivateAffiliateProgram(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doActivateAffiliateProgram"]) return Reflect.apply(mocks["doActivateAffiliateProgram"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doActivateAffiliateProgramDocument), variables, cb, requestOptions, doActivateAffiliateProgram);
}
function mockDoActivateBonusCode(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doActivateBonusCode"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doActivateBonusCode"] === callback) {
      mocks["doActivateBonusCode"] = void 0;
    }
  };
}
async function doActivateBonusCode(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doActivateBonusCode"]) return Reflect.apply(mocks["doActivateBonusCode"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doActivateBonusCodeDocument), variables, cb, requestOptions, doActivateBonusCode);
}
function mockDoAddLoginDevice(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doAddLoginDevice"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doAddLoginDevice"] === callback) {
      mocks["doAddLoginDevice"] = void 0;
    }
  };
}
async function doAddLoginDevice(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doAddLoginDevice"]) return Reflect.apply(mocks["doAddLoginDevice"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doAddLoginDeviceDocument), variables, cb, requestOptions, doAddLoginDevice);
}
function mockDoAppsFlyerQualifiedDepositsReported(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doAppsFlyerQualifiedDepositsReported"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doAppsFlyerQualifiedDepositsReported"] === callback) {
      mocks["doAppsFlyerQualifiedDepositsReported"] = void 0;
    }
  };
}
async function doAppsFlyerQualifiedDepositsReported(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doAppsFlyerQualifiedDepositsReported"]) return Reflect.apply(mocks["doAppsFlyerQualifiedDepositsReported"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doAppsFlyerQualifiedDepositsReportedDocument), variables, cb, requestOptions, doAppsFlyerQualifiedDepositsReported);
}
function mockDoCashOut(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doCashOut"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doCashOut"] === callback) {
      mocks["doCashOut"] = void 0;
    }
  };
}
async function doCashOut(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doCashOut"]) return Reflect.apply(mocks["doCashOut"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doCashOutDocument), variables, cb, requestOptions, doCashOut);
}
function mockDoChangeCustomerNickname(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doChangeCustomerNickname"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doChangeCustomerNickname"] === callback) {
      mocks["doChangeCustomerNickname"] = void 0;
    }
  };
}
async function doChangeCustomerNickname(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doChangeCustomerNickname"]) return Reflect.apply(mocks["doChangeCustomerNickname"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doChangeCustomerNicknameDocument), variables, cb, requestOptions, doChangeCustomerNickname);
}
function mockDoChangeCustomerSettings(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doChangeCustomerSettings"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doChangeCustomerSettings"] === callback) {
      mocks["doChangeCustomerSettings"] = void 0;
    }
  };
}
async function doChangeCustomerSettings(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doChangeCustomerSettings"]) return Reflect.apply(mocks["doChangeCustomerSettings"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doChangeCustomerSettingsDocument), variables, cb, requestOptions, doChangeCustomerSettings);
}
function mockDoChangeLanguage(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doChangeLanguage"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doChangeLanguage"] === callback) {
      mocks["doChangeLanguage"] = void 0;
    }
  };
}
async function doChangeLanguage(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doChangeLanguage"]) return Reflect.apply(mocks["doChangeLanguage"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doChangeLanguageDocument), variables, cb, requestOptions, doChangeLanguage);
}
function mockDoChangePassword(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doChangePassword"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doChangePassword"] === callback) {
      mocks["doChangePassword"] = void 0;
    }
  };
}
async function doChangePassword(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doChangePassword"]) return Reflect.apply(mocks["doChangePassword"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doChangePasswordDocument), variables, cb, requestOptions, doChangePassword);
}
function mockDoConfirmBonus(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doConfirmBonus"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doConfirmBonus"] === callback) {
      mocks["doConfirmBonus"] = void 0;
    }
  };
}
async function doConfirmBonus(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doConfirmBonus"]) return Reflect.apply(mocks["doConfirmBonus"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doConfirmBonusDocument), variables, cb, requestOptions, doConfirmBonus);
}
function mockDoCreateBonusCode(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doCreateBonusCode"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doCreateBonusCode"] === callback) {
      mocks["doCreateBonusCode"] = void 0;
    }
  };
}
async function doCreateBonusCode(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doCreateBonusCode"]) return Reflect.apply(mocks["doCreateBonusCode"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doCreateBonusCodeDocument), variables, cb, requestOptions, doCreateBonusCode);
}
function mockDoDeleteAccount(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doDeleteAccount"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doDeleteAccount"] === callback) {
      mocks["doDeleteAccount"] = void 0;
    }
  };
}
async function doDeleteAccount(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doDeleteAccount"]) return Reflect.apply(mocks["doDeleteAccount"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doDeleteAccountDocument), variables, cb, requestOptions, doDeleteAccount);
}
function mockDoEncashBonus(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doEncashBonus"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doEncashBonus"] === callback) {
      mocks["doEncashBonus"] = void 0;
    }
  };
}
async function doEncashBonus(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doEncashBonus"]) return Reflect.apply(mocks["doEncashBonus"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doEncashBonusDocument), variables, cb, requestOptions, doEncashBonus);
}
function mockDoEventSubscribe(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doEventSubscribe"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doEventSubscribe"] === callback) {
      mocks["doEventSubscribe"] = void 0;
    }
  };
}
async function doEventSubscribe(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doEventSubscribe"]) return Reflect.apply(mocks["doEventSubscribe"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doEventSubscribeDocument), variables, cb, requestOptions, doEventSubscribe);
}
function mockDoEventUnsubscribe(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doEventUnsubscribe"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doEventUnsubscribe"] === callback) {
      mocks["doEventUnsubscribe"] = void 0;
    }
  };
}
async function doEventUnsubscribe(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doEventUnsubscribe"]) return Reflect.apply(mocks["doEventUnsubscribe"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doEventUnsubscribeDocument), variables, cb, requestOptions, doEventUnsubscribe);
}
function mockDoFavoriteSportEventRemove(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doFavoriteSportEventRemove"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doFavoriteSportEventRemove"] === callback) {
      mocks["doFavoriteSportEventRemove"] = void 0;
    }
  };
}
async function doFavoriteSportEventRemove(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doFavoriteSportEventRemove"]) return Reflect.apply(mocks["doFavoriteSportEventRemove"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doFavoriteSportEventRemoveDocument), variables, cb, requestOptions, doFavoriteSportEventRemove);
}
function mockDoFavoriteSportEventsAdd(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doFavoriteSportEventsAdd"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doFavoriteSportEventsAdd"] === callback) {
      mocks["doFavoriteSportEventsAdd"] = void 0;
    }
  };
}
async function doFavoriteSportEventsAdd(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doFavoriteSportEventsAdd"]) return Reflect.apply(mocks["doFavoriteSportEventsAdd"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doFavoriteSportEventsAddDocument), variables, cb, requestOptions, doFavoriteSportEventsAdd);
}
function mockDoLogin(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doLogin"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doLogin"] === callback) {
      mocks["doLogin"] = void 0;
    }
  };
}
async function doLogin(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doLogin"]) return Reflect.apply(mocks["doLogin"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doLoginDocument), variables, cb, requestOptions, doLogin);
}
function mockDoLoginCas(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doLoginCas"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doLoginCas"] === callback) {
      mocks["doLoginCas"] = void 0;
    }
  };
}
async function doLoginCas(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doLoginCas"]) return Reflect.apply(mocks["doLoginCas"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doLoginCasDocument), variables, cb, requestOptions, doLoginCas);
}
function mockDoLoginFingerprint(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doLoginFingerprint"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doLoginFingerprint"] === callback) {
      mocks["doLoginFingerprint"] = void 0;
    }
  };
}
async function doLoginFingerprint(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doLoginFingerprint"]) return Reflect.apply(mocks["doLoginFingerprint"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doLoginFingerprintDocument), variables, cb, requestOptions, doLoginFingerprint);
}
function mockDoLoginG2sv(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doLoginG2sv"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doLoginG2sv"] === callback) {
      mocks["doLoginG2sv"] = void 0;
    }
  };
}
async function doLoginG2sv(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doLoginG2sv"]) return Reflect.apply(mocks["doLoginG2sv"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doLoginG2svDocument), variables, cb, requestOptions, doLoginG2sv);
}
function mockDoLoginPinCode(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doLoginPinCode"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doLoginPinCode"] === callback) {
      mocks["doLoginPinCode"] = void 0;
    }
  };
}
async function doLoginPinCode(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doLoginPinCode"]) return Reflect.apply(mocks["doLoginPinCode"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doLoginPinCodeDocument), variables, cb, requestOptions, doLoginPinCode);
}
function mockDoPostponeWebPushToken(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doPostponeWebPushToken"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doPostponeWebPushToken"] === callback) {
      mocks["doPostponeWebPushToken"] = void 0;
    }
  };
}
async function doPostponeWebPushToken(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doPostponeWebPushToken"]) return Reflect.apply(mocks["doPostponeWebPushToken"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doPostponeWebPushTokenDocument), variables, cb, requestOptions, doPostponeWebPushToken);
}
function mockDoReadCustomerNotification(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doReadCustomerNotification"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doReadCustomerNotification"] === callback) {
      mocks["doReadCustomerNotification"] = void 0;
    }
  };
}
async function doReadCustomerNotification(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doReadCustomerNotification"]) return Reflect.apply(mocks["doReadCustomerNotification"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doReadCustomerNotificationDocument), variables, cb, requestOptions, doReadCustomerNotification);
}
function mockDoRefuseMeeting(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doRefuseMeeting"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doRefuseMeeting"] === callback) {
      mocks["doRefuseMeeting"] = void 0;
    }
  };
}
async function doRefuseMeeting(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doRefuseMeeting"]) return Reflect.apply(mocks["doRefuseMeeting"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doRefuseMeetingDocument), variables, cb, requestOptions, doRefuseMeeting);
}
function mockDoRegisterCustomerOrder(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doRegisterCustomerOrder"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doRegisterCustomerOrder"] === callback) {
      mocks["doRegisterCustomerOrder"] = void 0;
    }
  };
}
async function doRegisterCustomerOrder(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doRegisterCustomerOrder"]) return Reflect.apply(mocks["doRegisterCustomerOrder"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doRegisterCustomerOrderDocument), variables, cb, requestOptions, doRegisterCustomerOrder);
}
function mockDoRegisterPushToken(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doRegisterPushToken"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doRegisterPushToken"] === callback) {
      mocks["doRegisterPushToken"] = void 0;
    }
  };
}
async function doRegisterPushToken(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doRegisterPushToken"]) return Reflect.apply(mocks["doRegisterPushToken"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doRegisterPushTokenDocument), variables, cb, requestOptions, doRegisterPushToken);
}
function mockDoRequestCallback(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doRequestCallback"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doRequestCallback"] === callback) {
      mocks["doRequestCallback"] = void 0;
    }
  };
}
async function doRequestCallback(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doRequestCallback"]) return Reflect.apply(mocks["doRequestCallback"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doRequestCallbackDocument), variables, cb, requestOptions, doRequestCallback);
}
function mockDoSaveBetSlip(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doSaveBetSlip"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doSaveBetSlip"] === callback) {
      mocks["doSaveBetSlip"] = void 0;
    }
  };
}
async function doSaveBetSlip(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doSaveBetSlip"]) return Reflect.apply(mocks["doSaveBetSlip"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doSaveBetSlipDocument), variables, cb, requestOptions, doSaveBetSlip);
}
function mockDoSaveBetSlipByBetId(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doSaveBetSlipByBetId"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doSaveBetSlipByBetId"] === callback) {
      mocks["doSaveBetSlipByBetId"] = void 0;
    }
  };
}
async function doSaveBetSlipByBetId(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doSaveBetSlipByBetId"]) return Reflect.apply(mocks["doSaveBetSlipByBetId"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doSaveBetSlipByBetIdDocument), variables, cb, requestOptions, doSaveBetSlipByBetId);
}
function mockDoSaveFastBets(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doSaveFastBets"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doSaveFastBets"] === callback) {
      mocks["doSaveFastBets"] = void 0;
    }
  };
}
async function doSaveFastBets(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doSaveFastBets"]) return Reflect.apply(mocks["doSaveFastBets"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doSaveFastBetsDocument), variables, cb, requestOptions, doSaveFastBets);
}
function mockDoSaveLog(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doSaveLog"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doSaveLog"] === callback) {
      mocks["doSaveLog"] = void 0;
    }
  };
}
async function doSaveLog(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doSaveLog"]) return Reflect.apply(mocks["doSaveLog"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doSaveLogDocument), variables, cb, requestOptions, doSaveLog);
}
function mockDoSavePriceChangePolicyV2(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doSavePriceChangePolicyV2"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doSavePriceChangePolicyV2"] === callback) {
      mocks["doSavePriceChangePolicyV2"] = void 0;
    }
  };
}
async function doSavePriceChangePolicyV2(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doSavePriceChangePolicyV2"]) return Reflect.apply(mocks["doSavePriceChangePolicyV2"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doSavePriceChangePolicyV2Document), variables, cb, requestOptions, doSavePriceChangePolicyV2);
}
function mockDoSaveShowMaxFastBet(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doSaveShowMaxFastBet"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doSaveShowMaxFastBet"] === callback) {
      mocks["doSaveShowMaxFastBet"] = void 0;
    }
  };
}
async function doSaveShowMaxFastBet(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doSaveShowMaxFastBet"]) return Reflect.apply(mocks["doSaveShowMaxFastBet"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doSaveShowMaxFastBetDocument), variables, cb, requestOptions, doSaveShowMaxFastBet);
}
function mockDoSaveSportFilters(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doSaveSportFilters"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doSaveSportFilters"] === callback) {
      mocks["doSaveSportFilters"] = void 0;
    }
  };
}
async function doSaveSportFilters(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doSaveSportFilters"]) return Reflect.apply(mocks["doSaveSportFilters"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doSaveSportFiltersDocument), variables, cb, requestOptions, doSaveSportFilters);
}
function mockDoScheduleMeeting(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doScheduleMeeting"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doScheduleMeeting"] === callback) {
      mocks["doScheduleMeeting"] = void 0;
    }
  };
}
async function doScheduleMeeting(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doScheduleMeeting"]) return Reflect.apply(mocks["doScheduleMeeting"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doScheduleMeetingDocument), variables, cb, requestOptions, doScheduleMeeting);
}
function mockDoSelfExclusion(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doSelfExclusion"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doSelfExclusion"] === callback) {
      mocks["doSelfExclusion"] = void 0;
    }
  };
}
async function doSelfExclusion(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doSelfExclusion"]) return Reflect.apply(mocks["doSelfExclusion"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doSelfExclusionDocument), variables, cb, requestOptions, doSelfExclusion);
}
function mockDoSelfExclusionFlex(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doSelfExclusionFlex"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doSelfExclusionFlex"] === callback) {
      mocks["doSelfExclusionFlex"] = void 0;
    }
  };
}
async function doSelfExclusionFlex(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doSelfExclusionFlex"]) return Reflect.apply(mocks["doSelfExclusionFlex"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doSelfExclusionFlexDocument), variables, cb, requestOptions, doSelfExclusionFlex);
}
function mockDoSendFeedback(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doSendFeedback"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doSendFeedback"] === callback) {
      mocks["doSendFeedback"] = void 0;
    }
  };
}
async function doSendFeedback(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doSendFeedback"]) return Reflect.apply(mocks["doSendFeedback"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doSendFeedbackDocument), variables, cb, requestOptions, doSendFeedback);
}
function mockDoSendWeb2Feedback(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doSendWeb2Feedback"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doSendWeb2Feedback"] === callback) {
      mocks["doSendWeb2Feedback"] = void 0;
    }
  };
}
async function doSendWeb2Feedback(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doSendWeb2Feedback"]) return Reflect.apply(mocks["doSendWeb2Feedback"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doSendWeb2FeedbackDocument), variables, cb, requestOptions, doSendWeb2Feedback);
}
function mockDoSendWeb2FeedbackByCategory(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doSendWeb2FeedbackByCategory"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doSendWeb2FeedbackByCategory"] === callback) {
      mocks["doSendWeb2FeedbackByCategory"] = void 0;
    }
  };
}
async function doSendWeb2FeedbackByCategory(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doSendWeb2FeedbackByCategory"]) return Reflect.apply(mocks["doSendWeb2FeedbackByCategory"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doSendWeb2FeedbackByCategoryDocument), variables, cb, requestOptions, doSendWeb2FeedbackByCategory);
}
function mockDoToggleCustomerLeague(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doToggleCustomerLeague"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doToggleCustomerLeague"] === callback) {
      mocks["doToggleCustomerLeague"] = void 0;
    }
  };
}
async function doToggleCustomerLeague(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doToggleCustomerLeague"]) return Reflect.apply(mocks["doToggleCustomerLeague"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doToggleCustomerLeagueDocument), variables, cb, requestOptions, doToggleCustomerLeague);
}
function mockDoTsupisCardDelete(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doTsupisCardDelete"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doTsupisCardDelete"] === callback) {
      mocks["doTsupisCardDelete"] = void 0;
    }
  };
}
async function doTsupisCardDelete(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doTsupisCardDelete"]) return Reflect.apply(mocks["doTsupisCardDelete"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doTsupisCardDeleteDocument), variables, cb, requestOptions, doTsupisCardDelete);
}
function mockDoUpdateCustomerData(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doUpdateCustomerData"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doUpdateCustomerData"] === callback) {
      mocks["doUpdateCustomerData"] = void 0;
    }
  };
}
async function doUpdateCustomerData(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doUpdateCustomerData"]) return Reflect.apply(mocks["doUpdateCustomerData"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doUpdateCustomerDataDocument), variables, cb, requestOptions, doUpdateCustomerData);
}
function mockDoUpdateNotificationConsents(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doUpdateNotificationConsents"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doUpdateNotificationConsents"] === callback) {
      mocks["doUpdateNotificationConsents"] = void 0;
    }
  };
}
async function doUpdateNotificationConsents(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doUpdateNotificationConsents"]) return Reflect.apply(mocks["doUpdateNotificationConsents"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doUpdateNotificationConsentsDocument), variables, cb, requestOptions, doUpdateNotificationConsents);
}
function mockDoWithdrawAllSportBonuses(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doWithdrawAllSportBonuses"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doWithdrawAllSportBonuses"] === callback) {
      mocks["doWithdrawAllSportBonuses"] = void 0;
    }
  };
}
async function doWithdrawAllSportBonuses(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doWithdrawAllSportBonuses"]) return Reflect.apply(mocks["doWithdrawAllSportBonuses"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doWithdrawAllSportBonusesDocument), variables, cb, requestOptions, doWithdrawAllSportBonuses);
}
function mockDoWithdrawSportBonus(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doWithdrawSportBonus"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doWithdrawSportBonus"] === callback) {
      mocks["doWithdrawSportBonus"] = void 0;
    }
  };
}
async function doWithdrawSportBonus(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doWithdrawSportBonus"]) return Reflect.apply(mocks["doWithdrawSportBonus"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doWithdrawSportBonusDocument), variables, cb, requestOptions, doWithdrawSportBonus);
}
function mockDoWithdrawalCancel(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doWithdrawalCancel"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doWithdrawalCancel"] === callback) {
      mocks["doWithdrawalCancel"] = void 0;
    }
  };
}
async function doWithdrawalCancel(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doWithdrawalCancel"]) return Reflect.apply(mocks["doWithdrawalCancel"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doWithdrawalCancelDocument), variables, cb, requestOptions, doWithdrawalCancel);
}
function mockGetEgsCategories(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getEgsCategories"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getEgsCategories"] === callback) {
      mocks["getEgsCategories"] = void 0;
    }
  };
}
async function getEgsCategories(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getEgsCategories"]) return Reflect.apply(mocks["getEgsCategories"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getEgsCategoriesDocument), variables, cb, requestOptions, getEgsCategories);
}
function mockGetEgsGameById(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getEgsGameById"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getEgsGameById"] === callback) {
      mocks["getEgsGameById"] = void 0;
    }
  };
}
async function getEgsGameById(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getEgsGameById"]) return Reflect.apply(mocks["getEgsGameById"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getEgsGameByIdDocument), variables, cb, requestOptions, getEgsGameById);
}
function mockGetEgsGameByUrl(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getEgsGameByUrl"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getEgsGameByUrl"] === callback) {
      mocks["getEgsGameByUrl"] = void 0;
    }
  };
}
async function getEgsGameByUrl(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getEgsGameByUrl"]) return Reflect.apply(mocks["getEgsGameByUrl"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getEgsGameByUrlDocument), variables, cb, requestOptions, getEgsGameByUrl);
}
function mockGetEgsGames(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getEgsGames"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getEgsGames"] === callback) {
      mocks["getEgsGames"] = void 0;
    }
  };
}
async function getEgsGames(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getEgsGames"]) return Reflect.apply(mocks["getEgsGames"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getEgsGamesDocument), variables, cb, requestOptions, getEgsGames);
}
function mockGetEgsGroups(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getEgsGroups"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getEgsGroups"] === callback) {
      mocks["getEgsGroups"] = void 0;
    }
  };
}
async function getEgsGroups(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getEgsGroups"]) return Reflect.apply(mocks["getEgsGroups"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getEgsGroupsDocument), variables, cb, requestOptions, getEgsGroups);
}
function mockGetEgsTopWinners(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getEgsTopWinners"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getEgsTopWinners"] === callback) {
      mocks["getEgsTopWinners"] = void 0;
    }
  };
}
async function getEgsTopWinners(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getEgsTopWinners"]) return Reflect.apply(mocks["getEgsTopWinners"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getEgsTopWinnersDocument), variables, cb, requestOptions, getEgsTopWinners);
}
function mockStartEgsGame(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["startEgsGame"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["startEgsGame"] === callback) {
      mocks["startEgsGame"] = void 0;
    }
  };
}
async function startEgsGame(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["startEgsGame"]) return Reflect.apply(mocks["startEgsGame"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(startEgsGameDocument), variables, cb, requestOptions, startEgsGame);
}
function mockEnableCustomerMode(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["enableCustomerMode"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["enableCustomerMode"] === callback) {
      mocks["enableCustomerMode"] = void 0;
    }
  };
}
async function enableCustomerMode(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["enableCustomerMode"]) return Reflect.apply(mocks["enableCustomerMode"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(enableCustomerModeDocument), variables, cb, requestOptions, enableCustomerMode);
}
function mockEnableFingerprintLogin(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["enableFingerprintLogin"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["enableFingerprintLogin"] === callback) {
      mocks["enableFingerprintLogin"] = void 0;
    }
  };
}
async function enableFingerprintLogin(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["enableFingerprintLogin"]) return Reflect.apply(mocks["enableFingerprintLogin"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(enableFingerprintLoginDocument), variables, cb, requestOptions, enableFingerprintLogin);
}
function mockGenerateNickname(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["generateNickname"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["generateNickname"] === callback) {
      mocks["generateNickname"] = void 0;
    }
  };
}
async function generateNickname(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["generateNickname"]) return Reflect.apply(mocks["generateNickname"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(generateNicknameDocument), variables, cb, requestOptions, generateNickname);
}
function mockGetAccountTransaction(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getAccountTransaction"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getAccountTransaction"] === callback) {
      mocks["getAccountTransaction"] = void 0;
    }
  };
}
async function getAccountTransaction(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getAccountTransaction"]) return Reflect.apply(mocks["getAccountTransaction"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getAccountTransactionDocument), variables, cb, requestOptions, getAccountTransaction);
}
function mockGetAffiliatedCustomers(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getAffiliatedCustomers"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getAffiliatedCustomers"] === callback) {
      mocks["getAffiliatedCustomers"] = void 0;
    }
  };
}
async function getAffiliatedCustomers(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getAffiliatedCustomers"]) return Reflect.apply(mocks["getAffiliatedCustomers"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getAffiliatedCustomersDocument), variables, cb, requestOptions, getAffiliatedCustomers);
}
function mockGetApiSettings(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getApiSettings"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getApiSettings"] === callback) {
      mocks["getApiSettings"] = void 0;
    }
  };
}
async function getApiSettings(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getApiSettings"]) return Reflect.apply(mocks["getApiSettings"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getApiSettingsDocument), variables, cb, requestOptions, getApiSettings);
}
function mockGetAuthenticatorUri(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getAuthenticatorUri"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getAuthenticatorUri"] === callback) {
      mocks["getAuthenticatorUri"] = void 0;
    }
  };
}
async function getAuthenticatorUri(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getAuthenticatorUri"]) return Reflect.apply(mocks["getAuthenticatorUri"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getAuthenticatorUriDocument), variables, cb, requestOptions, getAuthenticatorUri);
}
function mockGetAuthenticatorUriQRCode(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getAuthenticatorUriQRCode"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getAuthenticatorUriQRCode"] === callback) {
      mocks["getAuthenticatorUriQRCode"] = void 0;
    }
  };
}
async function getAuthenticatorUriQRCode(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getAuthenticatorUriQRCode"]) return Reflect.apply(mocks["getAuthenticatorUriQRCode"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getAuthenticatorUriQRCodeDocument), variables, cb, requestOptions, getAuthenticatorUriQRCode);
}
function mockGetAvailableBonusesForTransfer(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getAvailableBonusesForTransfer"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getAvailableBonusesForTransfer"] === callback) {
      mocks["getAvailableBonusesForTransfer"] = void 0;
    }
  };
}
async function getAvailableBonusesForTransfer(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getAvailableBonusesForTransfer"]) return Reflect.apply(mocks["getAvailableBonusesForTransfer"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getAvailableBonusesForTransferDocument), variables, cb, requestOptions, getAvailableBonusesForTransfer);
}
function mockGetAvailableForms(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getAvailableForms"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getAvailableForms"] === callback) {
      mocks["getAvailableForms"] = void 0;
    }
  };
}
async function getAvailableForms(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getAvailableForms"]) return Reflect.apply(mocks["getAvailableForms"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getAvailableFormsDocument), variables, cb, requestOptions, getAvailableForms);
}
function mockGetAvailableMeetingSlots(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getAvailableMeetingSlots"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getAvailableMeetingSlots"] === callback) {
      mocks["getAvailableMeetingSlots"] = void 0;
    }
  };
}
async function getAvailableMeetingSlots(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getAvailableMeetingSlots"]) return Reflect.apply(mocks["getAvailableMeetingSlots"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getAvailableMeetingSlotsDocument), variables, cb, requestOptions, getAvailableMeetingSlots);
}
function mockGetBalance(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBalance"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBalance"] === callback) {
      mocks["getBalance"] = void 0;
    }
  };
}
async function getBalance(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBalance"]) return Reflect.apply(mocks["getBalance"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBalanceDocument), variables, cb, requestOptions, getBalance);
}
function mockGetBanner(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBanner"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBanner"] === callback) {
      mocks["getBanner"] = void 0;
    }
  };
}
async function getBanner(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBanner"]) return Reflect.apply(mocks["getBanner"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBannerDocument), variables, cb, requestOptions, getBanner);
}
function mockGetBanners(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBanners"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBanners"] === callback) {
      mocks["getBanners"] = void 0;
    }
  };
}
async function getBanners(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBanners"]) return Reflect.apply(mocks["getBanners"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBannersDocument), variables, cb, requestOptions, getBanners);
}
function mockGetBatchSlipInfo(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBatchSlipInfo"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBatchSlipInfo"] === callback) {
      mocks["getBatchSlipInfo"] = void 0;
    }
  };
}
async function getBatchSlipInfo(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBatchSlipInfo"]) return Reflect.apply(mocks["getBatchSlipInfo"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBatchSlipInfoDocument), variables, cb, requestOptions, getBatchSlipInfo);
}
function mockGetBet(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBet"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBet"] === callback) {
      mocks["getBet"] = void 0;
    }
  };
}
async function getBet(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBet"]) return Reflect.apply(mocks["getBet"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBetDocument), variables, cb, requestOptions, getBet);
}
function mockGetBetLimits(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBetLimits"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBetLimits"] === callback) {
      mocks["getBetLimits"] = void 0;
    }
  };
}
async function getBetLimits(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBetLimits"]) return Reflect.apply(mocks["getBetLimits"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBetLimitsDocument), variables, cb, requestOptions, getBetLimits);
}
function mockGetBetSlip(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBetSlip"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBetSlip"] === callback) {
      mocks["getBetSlip"] = void 0;
    }
  };
}
async function getBetSlip(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBetSlip"]) return Reflect.apply(mocks["getBetSlip"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBetSlipDocument), variables, cb, requestOptions, getBetSlip);
}
function mockGetBetgeniusWidgetConfiguration(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBetgeniusWidgetConfiguration"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBetgeniusWidgetConfiguration"] === callback) {
      mocks["getBetgeniusWidgetConfiguration"] = void 0;
    }
  };
}
async function getBetgeniusWidgetConfiguration(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBetgeniusWidgetConfiguration"]) return Reflect.apply(mocks["getBetgeniusWidgetConfiguration"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBetgeniusWidgetConfigurationDocument), variables, cb, requestOptions, getBetgeniusWidgetConfiguration);
}
function mockGetBonusCodes(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBonusCodes"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBonusCodes"] === callback) {
      mocks["getBonusCodes"] = void 0;
    }
  };
}
async function getBonusCodes(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBonusCodes"]) return Reflect.apply(mocks["getBonusCodes"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBonusCodesDocument), variables, cb, requestOptions, getBonusCodes);
}
function mockGetBonusLoyalty(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBonusLoyalty"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBonusLoyalty"] === callback) {
      mocks["getBonusLoyalty"] = void 0;
    }
  };
}
async function getBonusLoyalty(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBonusLoyalty"]) return Reflect.apply(mocks["getBonusLoyalty"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBonusLoyaltyDocument), variables, cb, requestOptions, getBonusLoyalty);
}
function mockGetBonusTransferAmount(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBonusTransferAmount"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBonusTransferAmount"] === callback) {
      mocks["getBonusTransferAmount"] = void 0;
    }
  };
}
async function getBonusTransferAmount(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBonusTransferAmount"]) return Reflect.apply(mocks["getBonusTransferAmount"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBonusTransferAmountDocument), variables, cb, requestOptions, getBonusTransferAmount);
}
function mockGetBonusWagers(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBonusWagers"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBonusWagers"] === callback) {
      mocks["getBonusWagers"] = void 0;
    }
  };
}
async function getBonusWagers(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBonusWagers"]) return Reflect.apply(mocks["getBonusWagers"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBonusWagersDocument), variables, cb, requestOptions, getBonusWagers);
}
function mockGetBonuses(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBonuses"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBonuses"] === callback) {
      mocks["getBonuses"] = void 0;
    }
  };
}
async function getBonuses(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBonuses"]) return Reflect.apply(mocks["getBonuses"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBonusesDocument), variables, cb, requestOptions, getBonuses);
}
function mockGetCampaignCoupons(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCampaignCoupons"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCampaignCoupons"] === callback) {
      mocks["getCampaignCoupons"] = void 0;
    }
  };
}
async function getCampaignCoupons(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCampaignCoupons"]) return Reflect.apply(mocks["getCampaignCoupons"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCampaignCouponsDocument), variables, cb, requestOptions, getCampaignCoupons);
}
function mockGetCashoutOffer(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCashoutOffer"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCashoutOffer"] === callback) {
      mocks["getCashoutOffer"] = void 0;
    }
  };
}
async function getCashoutOffer(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCashoutOffer"]) return Reflect.apply(mocks["getCashoutOffer"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCashoutOfferDocument), variables, cb, requestOptions, getCashoutOffer);
}
function mockGetCmsContentSpintaxBatchTranslations(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCmsContentSpintaxBatchTranslations"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCmsContentSpintaxBatchTranslations"] === callback) {
      mocks["getCmsContentSpintaxBatchTranslations"] = void 0;
    }
  };
}
async function getCmsContentSpintaxBatchTranslations(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCmsContentSpintaxBatchTranslations"]) return Reflect.apply(mocks["getCmsContentSpintaxBatchTranslations"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCmsContentSpintaxBatchTranslationsDocument), variables, cb, requestOptions, getCmsContentSpintaxBatchTranslations);
}
function mockGetCmsContentTranslations(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCmsContentTranslations"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCmsContentTranslations"] === callback) {
      mocks["getCmsContentTranslations"] = void 0;
    }
  };
}
async function getCmsContentTranslations(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCmsContentTranslations"]) return Reflect.apply(mocks["getCmsContentTranslations"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCmsContentTranslationsDocument), variables, cb, requestOptions, getCmsContentTranslations);
}
function mockGetConsents(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getConsents"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getConsents"] === callback) {
      mocks["getConsents"] = void 0;
    }
  };
}
async function getConsents(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getConsents"]) return Reflect.apply(mocks["getConsents"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getConsentsDocument), variables, cb, requestOptions, getConsents);
}
function mockGetCookie(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCookie"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCookie"] === callback) {
      mocks["getCookie"] = void 0;
    }
  };
}
async function getCookie(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCookie"]) return Reflect.apply(mocks["getCookie"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCookieDocument), variables, cb, requestOptions, getCookie);
}
function mockGetCustomerHistory(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCustomerHistory"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCustomerHistory"] === callback) {
      mocks["getCustomerHistory"] = void 0;
    }
  };
}
async function getCustomerHistory(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCustomerHistory"]) return Reflect.apply(mocks["getCustomerHistory"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCustomerHistoryDocument), variables, cb, requestOptions, getCustomerHistory);
}
function mockGetCustomerHistoryItem(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCustomerHistoryItem"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCustomerHistoryItem"] === callback) {
      mocks["getCustomerHistoryItem"] = void 0;
    }
  };
}
async function getCustomerHistoryItem(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCustomerHistoryItem"]) return Reflect.apply(mocks["getCustomerHistoryItem"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCustomerHistoryItemDocument), variables, cb, requestOptions, getCustomerHistoryItem);
}
function mockGetCustomerHistoryList(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCustomerHistoryList"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCustomerHistoryList"] === callback) {
      mocks["getCustomerHistoryList"] = void 0;
    }
  };
}
async function getCustomerHistoryList(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCustomerHistoryList"]) return Reflect.apply(mocks["getCustomerHistoryList"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCustomerHistoryListDocument), variables, cb, requestOptions, getCustomerHistoryList);
}
function mockGetCustomerLeague(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCustomerLeague"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCustomerLeague"] === callback) {
      mocks["getCustomerLeague"] = void 0;
    }
  };
}
async function getCustomerLeague(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCustomerLeague"]) return Reflect.apply(mocks["getCustomerLeague"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCustomerLeagueDocument), variables, cb, requestOptions, getCustomerLeague);
}
function mockGetCustomerLeagues(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCustomerLeagues"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCustomerLeagues"] === callback) {
      mocks["getCustomerLeagues"] = void 0;
    }
  };
}
async function getCustomerLeagues(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCustomerLeagues"]) return Reflect.apply(mocks["getCustomerLeagues"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCustomerLeaguesDocument), variables, cb, requestOptions, getCustomerLeagues);
}
function mockGetCustomerMatches(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCustomerMatches"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCustomerMatches"] === callback) {
      mocks["getCustomerMatches"] = void 0;
    }
  };
}
async function getCustomerMatches(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCustomerMatches"]) return Reflect.apply(mocks["getCustomerMatches"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCustomerMatchesDocument), variables, cb, requestOptions, getCustomerMatches);
}
function mockGetCustomerNotifications(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCustomerNotifications"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCustomerNotifications"] === callback) {
      mocks["getCustomerNotifications"] = void 0;
    }
  };
}
async function getCustomerNotifications(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCustomerNotifications"]) return Reflect.apply(mocks["getCustomerNotifications"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCustomerNotificationsDocument), variables, cb, requestOptions, getCustomerNotifications);
}
function mockGetCustomerNotificationsCount(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCustomerNotificationsCount"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCustomerNotificationsCount"] === callback) {
      mocks["getCustomerNotificationsCount"] = void 0;
    }
  };
}
async function getCustomerNotificationsCount(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCustomerNotificationsCount"]) return Reflect.apply(mocks["getCustomerNotificationsCount"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCustomerNotificationsCountDocument), variables, cb, requestOptions, getCustomerNotificationsCount);
}
function mockGetCustomerOffers(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCustomerOffers"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCustomerOffers"] === callback) {
      mocks["getCustomerOffers"] = void 0;
    }
  };
}
async function getCustomerOffers(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCustomerOffers"]) return Reflect.apply(mocks["getCustomerOffers"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCustomerOffersDocument), variables, cb, requestOptions, getCustomerOffers);
}
function mockGetCustomerOrders(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCustomerOrders"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCustomerOrders"] === callback) {
      mocks["getCustomerOrders"] = void 0;
    }
  };
}
async function getCustomerOrders(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCustomerOrders"]) return Reflect.apply(mocks["getCustomerOrders"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCustomerOrdersDocument), variables, cb, requestOptions, getCustomerOrders);
}
function mockGetCustomerProfileFields(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCustomerProfileFields"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCustomerProfileFields"] === callback) {
      mocks["getCustomerProfileFields"] = void 0;
    }
  };
}
async function getCustomerProfileFields(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCustomerProfileFields"]) return Reflect.apply(mocks["getCustomerProfileFields"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCustomerProfileFieldsDocument), variables, cb, requestOptions, getCustomerProfileFields);
}
function mockGetCustomerVerifyData(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCustomerVerifyData"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCustomerVerifyData"] === callback) {
      mocks["getCustomerVerifyData"] = void 0;
    }
  };
}
async function getCustomerVerifyData(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCustomerVerifyData"]) return Reflect.apply(mocks["getCustomerVerifyData"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCustomerVerifyDataDocument), variables, cb, requestOptions, getCustomerVerifyData);
}
function mockGetDepositLimits(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getDepositLimits"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getDepositLimits"] === callback) {
      mocks["getDepositLimits"] = void 0;
    }
  };
}
async function getDepositLimits(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getDepositLimits"]) return Reflect.apply(mocks["getDepositLimits"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getDepositLimitsDocument), variables, cb, requestOptions, getDepositLimits);
}
function mockGetEncashBonusData(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getEncashBonusData"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getEncashBonusData"] === callback) {
      mocks["getEncashBonusData"] = void 0;
    }
  };
}
async function getEncashBonusData(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getEncashBonusData"]) return Reflect.apply(mocks["getEncashBonusData"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getEncashBonusDataDocument), variables, cb, requestOptions, getEncashBonusData);
}
function mockGetFAQCategories(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getFAQCategories"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getFAQCategories"] === callback) {
      mocks["getFAQCategories"] = void 0;
    }
  };
}
async function getFAQCategories(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getFAQCategories"]) return Reflect.apply(mocks["getFAQCategories"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getFAQCategoriesDocument), variables, cb, requestOptions, getFAQCategories);
}
function mockGetFAQItems(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getFAQItems"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getFAQItems"] === callback) {
      mocks["getFAQItems"] = void 0;
    }
  };
}
async function getFAQItems(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getFAQItems"]) return Reflect.apply(mocks["getFAQItems"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getFAQItemsDocument), variables, cb, requestOptions, getFAQItems);
}
function mockGetFeedbackTypes(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getFeedbackTypes"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getFeedbackTypes"] === callback) {
      mocks["getFeedbackTypes"] = void 0;
    }
  };
}
async function getFeedbackTypes(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getFeedbackTypes"]) return Reflect.apply(mocks["getFeedbackTypes"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getFeedbackTypesDocument), variables, cb, requestOptions, getFeedbackTypes);
}
function mockGetFormSchema(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getFormSchema"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getFormSchema"] === callback) {
      mocks["getFormSchema"] = void 0;
    }
  };
}
async function getFormSchema(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getFormSchema"]) return Reflect.apply(mocks["getFormSchema"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getFormSchemaDocument), variables, cb, requestOptions, getFormSchema);
}
function mockGetFreebetData(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getFreebetData"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getFreebetData"] === callback) {
      mocks["getFreebetData"] = void 0;
    }
  };
}
async function getFreebetData(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getFreebetData"]) return Reflect.apply(mocks["getFreebetData"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getFreebetDataDocument), variables, cb, requestOptions, getFreebetData);
}
function mockGetGeneralStats(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getGeneralStats"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getGeneralStats"] === callback) {
      mocks["getGeneralStats"] = void 0;
    }
  };
}
async function getGeneralStats(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getGeneralStats"]) return Reflect.apply(mocks["getGeneralStats"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getGeneralStatsDocument), variables, cb, requestOptions, getGeneralStats);
}
function mockGetInlineChats(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getInlineChats"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getInlineChats"] === callback) {
      mocks["getInlineChats"] = void 0;
    }
  };
}
async function getInlineChats(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getInlineChats"]) return Reflect.apply(mocks["getInlineChats"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getInlineChatsDocument), variables, cb, requestOptions, getInlineChats);
}
function mockGetIntercomUserHash(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getIntercomUserHash"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getIntercomUserHash"] === callback) {
      mocks["getIntercomUserHash"] = void 0;
    }
  };
}
async function getIntercomUserHash(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getIntercomUserHash"]) return Reflect.apply(mocks["getIntercomUserHash"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getIntercomUserHashDocument), variables, cb, requestOptions, getIntercomUserHash);
}
function mockGetLSportsWidgetConfiguration(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getLSportsWidgetConfiguration"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getLSportsWidgetConfiguration"] === callback) {
      mocks["getLSportsWidgetConfiguration"] = void 0;
    }
  };
}
async function getLSportsWidgetConfiguration(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getLSportsWidgetConfiguration"]) return Reflect.apply(mocks["getLSportsWidgetConfiguration"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getLSportsWidgetConfigurationDocument), variables, cb, requestOptions, getLSportsWidgetConfiguration);
}
function mockGetLastBetResult(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getLastBetResult"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getLastBetResult"] === callback) {
      mocks["getLastBetResult"] = void 0;
    }
  };
}
async function getLastBetResult(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getLastBetResult"]) return Reflect.apply(mocks["getLastBetResult"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getLastBetResultDocument), variables, cb, requestOptions, getLastBetResult);
}
function mockGetLeaderBoard(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getLeaderBoard"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getLeaderBoard"] === callback) {
      mocks["getLeaderBoard"] = void 0;
    }
  };
}
async function getLeaderBoard(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getLeaderBoard"]) return Reflect.apply(mocks["getLeaderBoard"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getLeaderBoardDocument), variables, cb, requestOptions, getLeaderBoard);
}
function mockGetLexGuardSession(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getLexGuardSession"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getLexGuardSession"] === callback) {
      mocks["getLexGuardSession"] = void 0;
    }
  };
}
async function getLexGuardSession(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getLexGuardSession"]) return Reflect.apply(mocks["getLexGuardSession"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getLexGuardSessionDocument), variables, cb, requestOptions, getLexGuardSession);
}
function mockGetLiveChatSettings(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getLiveChatSettings"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getLiveChatSettings"] === callback) {
      mocks["getLiveChatSettings"] = void 0;
    }
  };
}
async function getLiveChatSettings(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getLiveChatSettings"]) return Reflect.apply(mocks["getLiveChatSettings"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getLiveChatSettingsDocument), variables, cb, requestOptions, getLiveChatSettings);
}
function mockGetLiveChatStatus(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getLiveChatStatus"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getLiveChatStatus"] === callback) {
      mocks["getLiveChatStatus"] = void 0;
    }
  };
}
async function getLiveChatStatus(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getLiveChatStatus"]) return Reflect.apply(mocks["getLiveChatStatus"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getLiveChatStatusDocument), variables, cb, requestOptions, getLiveChatStatus);
}
function mockGetNavigationConfig(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getNavigationConfig"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getNavigationConfig"] === callback) {
      mocks["getNavigationConfig"] = void 0;
    }
  };
}
async function getNavigationConfig(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getNavigationConfig"]) return Reflect.apply(mocks["getNavigationConfig"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getNavigationConfigDocument), variables, cb, requestOptions, getNavigationConfig);
}
function mockGetNearbyCities(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getNearbyCities"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getNearbyCities"] === callback) {
      mocks["getNearbyCities"] = void 0;
    }
  };
}
async function getNearbyCities(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getNearbyCities"]) return Reflect.apply(mocks["getNearbyCities"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getNearbyCitiesDocument), variables, cb, requestOptions, getNearbyCities);
}
function mockGetOnboarding(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getOnboarding"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getOnboarding"] === callback) {
      mocks["getOnboarding"] = void 0;
    }
  };
}
async function getOnboarding(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getOnboarding"]) return Reflect.apply(mocks["getOnboarding"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getOnboardingDocument), variables, cb, requestOptions, getOnboarding);
}
function mockGetPartners(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getPartners"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getPartners"] === callback) {
      mocks["getPartners"] = void 0;
    }
  };
}
async function getPartners(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getPartners"]) return Reflect.apply(mocks["getPartners"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getPartnersDocument), variables, cb, requestOptions, getPartners);
}
function mockGetPendingBets(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getPendingBets"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getPendingBets"] === callback) {
      mocks["getPendingBets"] = void 0;
    }
  };
}
async function getPendingBets(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getPendingBets"]) return Reflect.apply(mocks["getPendingBets"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getPendingBetsDocument), variables, cb, requestOptions, getPendingBets);
}
function mockGetPopularQueries(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getPopularQueries"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getPopularQueries"] === callback) {
      mocks["getPopularQueries"] = void 0;
    }
  };
}
async function getPopularQueries(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getPopularQueries"]) return Reflect.apply(mocks["getPopularQueries"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getPopularQueriesDocument), variables, cb, requestOptions, getPopularQueries);
}
function mockGetPushSettings(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getPushSettings"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getPushSettings"] === callback) {
      mocks["getPushSettings"] = void 0;
    }
  };
}
async function getPushSettings(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getPushSettings"]) return Reflect.apply(mocks["getPushSettings"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getPushSettingsDocument), variables, cb, requestOptions, getPushSettings);
}
function mockGetRootSettings(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getRootSettings"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getRootSettings"] === callback) {
      mocks["getRootSettings"] = void 0;
    }
  };
}
async function getRootSettings(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getRootSettings"]) return Reflect.apply(mocks["getRootSettings"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getRootSettingsDocument), variables, cb, requestOptions, getRootSettings);
}
function mockGetRootSettingsInternational(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getRootSettingsInternational"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getRootSettingsInternational"] === callback) {
      mocks["getRootSettingsInternational"] = void 0;
    }
  };
}
async function getRootSettingsInternational(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getRootSettingsInternational"]) return Reflect.apply(mocks["getRootSettingsInternational"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getRootSettingsInternationalDocument), variables, cb, requestOptions, getRootSettingsInternational);
}
function mockGetRoutingConfigs(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getRoutingConfigs"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getRoutingConfigs"] === callback) {
      mocks["getRoutingConfigs"] = void 0;
    }
  };
}
async function getRoutingConfigs(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getRoutingConfigs"]) return Reflect.apply(mocks["getRoutingConfigs"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getRoutingConfigsDocument), variables, cb, requestOptions, getRoutingConfigs);
}
function mockGetRules(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getRules"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getRules"] === callback) {
      mocks["getRules"] = void 0;
    }
  };
}
async function getRules(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getRules"]) return Reflect.apply(mocks["getRules"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getRulesDocument), variables, cb, requestOptions, getRules);
}
function mockGetSearchData(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getSearchData"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getSearchData"] === callback) {
      mocks["getSearchData"] = void 0;
    }
  };
}
async function getSearchData(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getSearchData"]) return Reflect.apply(mocks["getSearchData"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getSearchDataDocument), variables, cb, requestOptions, getSearchData);
}
function mockGetSelfExclusion(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getSelfExclusion"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getSelfExclusion"] === callback) {
      mocks["getSelfExclusion"] = void 0;
    }
  };
}
async function getSelfExclusion(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getSelfExclusion"]) return Reflect.apply(mocks["getSelfExclusion"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getSelfExclusionDocument), variables, cb, requestOptions, getSelfExclusion);
}
function mockGetServiceWorkerConfiguration(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getServiceWorkerConfiguration"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getServiceWorkerConfiguration"] === callback) {
      mocks["getServiceWorkerConfiguration"] = void 0;
    }
  };
}
async function getServiceWorkerConfiguration(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getServiceWorkerConfiguration"]) return Reflect.apply(mocks["getServiceWorkerConfiguration"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getServiceWorkerConfigurationDocument), variables, cb, requestOptions, getServiceWorkerConfiguration);
}
function mockGetSiteConfigSettings(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getSiteConfigSettings"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getSiteConfigSettings"] === callback) {
      mocks["getSiteConfigSettings"] = void 0;
    }
  };
}
async function getSiteConfigSettings(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getSiteConfigSettings"]) return Reflect.apply(mocks["getSiteConfigSettings"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getSiteConfigSettingsDocument), variables, cb, requestOptions, getSiteConfigSettings);
}
function mockGetSportradarLiveMatchTrackerConfiguration(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getSportradarLiveMatchTrackerConfiguration"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getSportradarLiveMatchTrackerConfiguration"] === callback) {
      mocks["getSportradarLiveMatchTrackerConfiguration"] = void 0;
    }
  };
}
async function getSportradarLiveMatchTrackerConfiguration(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getSportradarLiveMatchTrackerConfiguration"]) return Reflect.apply(mocks["getSportradarLiveMatchTrackerConfiguration"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getSportradarLiveMatchTrackerConfigurationDocument), variables, cb, requestOptions, getSportradarLiveMatchTrackerConfiguration);
}
function mockGetSportradarLiveScoreConfiguration(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getSportradarLiveScoreConfiguration"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getSportradarLiveScoreConfiguration"] === callback) {
      mocks["getSportradarLiveScoreConfiguration"] = void 0;
    }
  };
}
async function getSportradarLiveScoreConfiguration(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getSportradarLiveScoreConfiguration"]) return Reflect.apply(mocks["getSportradarLiveScoreConfiguration"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getSportradarLiveScoreConfigurationDocument), variables, cb, requestOptions, getSportradarLiveScoreConfiguration);
}
function mockGetSportradarStatisticsConfiguration(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getSportradarStatisticsConfiguration"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getSportradarStatisticsConfiguration"] === callback) {
      mocks["getSportradarStatisticsConfiguration"] = void 0;
    }
  };
}
async function getSportradarStatisticsConfiguration(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getSportradarStatisticsConfiguration"]) return Reflect.apply(mocks["getSportradarStatisticsConfiguration"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getSportradarStatisticsConfigurationDocument), variables, cb, requestOptions, getSportradarStatisticsConfiguration);
}
function mockGetSubscribedEvents(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getSubscribedEvents"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getSubscribedEvents"] === callback) {
      mocks["getSubscribedEvents"] = void 0;
    }
  };
}
async function getSubscribedEvents(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getSubscribedEvents"]) return Reflect.apply(mocks["getSubscribedEvents"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getSubscribedEventsDocument), variables, cb, requestOptions, getSubscribedEvents);
}
function mockGetTempCoupons(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getTempCoupons"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getTempCoupons"] === callback) {
      mocks["getTempCoupons"] = void 0;
    }
  };
}
async function getTempCoupons(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getTempCoupons"]) return Reflect.apply(mocks["getTempCoupons"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getTempCouponsDocument), variables, cb, requestOptions, getTempCoupons);
}
function mockGetTranslations(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getTranslations"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getTranslations"] === callback) {
      mocks["getTranslations"] = void 0;
    }
  };
}
async function getTranslations(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getTranslations"]) return Reflect.apply(mocks["getTranslations"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getTranslationsDocument), variables, cb, requestOptions, getTranslations);
}
function mockGetUpcomingMeeting(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getUpcomingMeeting"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getUpcomingMeeting"] === callback) {
      mocks["getUpcomingMeeting"] = void 0;
    }
  };
}
async function getUpcomingMeeting(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getUpcomingMeeting"]) return Reflect.apply(mocks["getUpcomingMeeting"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getUpcomingMeetingDocument), variables, cb, requestOptions, getUpcomingMeeting);
}
function mockGetUserData(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getUserData"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getUserData"] === callback) {
      mocks["getUserData"] = void 0;
    }
  };
}
async function getUserData(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getUserData"]) return Reflect.apply(mocks["getUserData"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getUserDataDocument), variables, cb, requestOptions, getUserData);
}
function mockGetUserDataPhone(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getUserDataPhone"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getUserDataPhone"] === callback) {
      mocks["getUserDataPhone"] = void 0;
    }
  };
}
async function getUserDataPhone(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getUserDataPhone"]) return Reflect.apply(mocks["getUserDataPhone"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getUserDataPhoneDocument), variables, cb, requestOptions, getUserDataPhone);
}
function mockGetUserProfileBonus(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getUserProfileBonus"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getUserProfileBonus"] === callback) {
      mocks["getUserProfileBonus"] = void 0;
    }
  };
}
async function getUserProfileBonus(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getUserProfileBonus"]) return Reflect.apply(mocks["getUserProfileBonus"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getUserProfileBonusDocument), variables, cb, requestOptions, getUserProfileBonus);
}
function mockGetWhatIsNewData(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getWhatIsNewData"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getWhatIsNewData"] === callback) {
      mocks["getWhatIsNewData"] = void 0;
    }
  };
}
async function getWhatIsNewData(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getWhatIsNewData"]) return Reflect.apply(mocks["getWhatIsNewData"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getWhatIsNewDataDocument), variables, cb, requestOptions, getWhatIsNewData);
}
function mockGoBack(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["goBack"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["goBack"] === callback) {
      mocks["goBack"] = void 0;
    }
  };
}
async function goBack(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["goBack"]) return Reflect.apply(mocks["goBack"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(goBackDocument), variables, cb, requestOptions, goBack);
}
function mockConfirmManualApplication(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["confirmManualApplication"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["confirmManualApplication"] === callback) {
      mocks["confirmManualApplication"] = void 0;
    }
  };
}
async function confirmManualApplication(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["confirmManualApplication"]) return Reflect.apply(mocks["confirmManualApplication"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(confirmManualApplicationDocument), variables, cb, requestOptions, confirmManualApplication);
}
function mockDeleteDocuments(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["deleteDocuments"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["deleteDocuments"] === callback) {
      mocks["deleteDocuments"] = void 0;
    }
  };
}
async function deleteDocuments(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["deleteDocuments"]) return Reflect.apply(mocks["deleteDocuments"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(deleteDocumentsDocument), variables, cb, requestOptions, deleteDocuments);
}
function mockDropManualData(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["dropManualData"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["dropManualData"] === callback) {
      mocks["dropManualData"] = void 0;
    }
  };
}
async function dropManualData(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["dropManualData"]) return Reflect.apply(mocks["dropManualData"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(dropManualDataDocument), variables, cb, requestOptions, dropManualData);
}
function mockEsiaDataFetch(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["esiaDataFetch"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["esiaDataFetch"] === callback) {
      mocks["esiaDataFetch"] = void 0;
    }
  };
}
async function esiaDataFetch(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["esiaDataFetch"]) return Reflect.apply(mocks["esiaDataFetch"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(esiaDataFetchDocument), variables, cb, requestOptions, esiaDataFetch);
}
function mockEsiaRedirect(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["esiaRedirect"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["esiaRedirect"] === callback) {
      mocks["esiaRedirect"] = void 0;
    }
  };
}
async function esiaRedirect(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["esiaRedirect"]) return Reflect.apply(mocks["esiaRedirect"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(esiaRedirectDocument), variables, cb, requestOptions, esiaRedirect);
}
function mockEtsupisPhoneVerificationInit(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["etsupisPhoneVerificationInit"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["etsupisPhoneVerificationInit"] === callback) {
      mocks["etsupisPhoneVerificationInit"] = void 0;
    }
  };
}
async function etsupisPhoneVerificationInit(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["etsupisPhoneVerificationInit"]) return Reflect.apply(mocks["etsupisPhoneVerificationInit"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(etsupisPhoneVerificationInitDocument), variables, cb, requestOptions, etsupisPhoneVerificationInit);
}
function mockEtsupisPhoneVerificationVerify(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["etsupisPhoneVerificationVerify"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["etsupisPhoneVerificationVerify"] === callback) {
      mocks["etsupisPhoneVerificationVerify"] = void 0;
    }
  };
}
async function etsupisPhoneVerificationVerify(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["etsupisPhoneVerificationVerify"]) return Reflect.apply(mocks["etsupisPhoneVerificationVerify"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(etsupisPhoneVerificationVerifyDocument), variables, cb, requestOptions, etsupisPhoneVerificationVerify);
}
function mockFinishApplication(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["finishApplication"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["finishApplication"] === callback) {
      mocks["finishApplication"] = void 0;
    }
  };
}
async function finishApplication(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["finishApplication"]) return Reflect.apply(mocks["finishApplication"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(finishApplicationDocument), variables, cb, requestOptions, finishApplication);
}
function mockGetEsiaAouth2Link(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getEsiaAouth2Link"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getEsiaAouth2Link"] === callback) {
      mocks["getEsiaAouth2Link"] = void 0;
    }
  };
}
async function getEsiaAouth2Link(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getEsiaAouth2Link"]) return Reflect.apply(mocks["getEsiaAouth2Link"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getEsiaAouth2LinkDocument), variables, cb, requestOptions, getEsiaAouth2Link);
}
function mockGetIdentificationState(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getIdentificationState"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getIdentificationState"] === callback) {
      mocks["getIdentificationState"] = void 0;
    }
  };
}
async function getIdentificationState(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getIdentificationState"]) return Reflect.apply(mocks["getIdentificationState"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getIdentificationStateDocument), variables, cb, requestOptions, getIdentificationState);
}
function mockGetThirdPartyIdentificationInit(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getThirdPartyIdentificationInit"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getThirdPartyIdentificationInit"] === callback) {
      mocks["getThirdPartyIdentificationInit"] = void 0;
    }
  };
}
async function getThirdPartyIdentificationInit(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getThirdPartyIdentificationInit"]) return Reflect.apply(mocks["getThirdPartyIdentificationInit"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getThirdPartyIdentificationInitDocument), variables, cb, requestOptions, getThirdPartyIdentificationInit);
}
function mockInitIdentApp(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["initIdentApp"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["initIdentApp"] === callback) {
      mocks["initIdentApp"] = void 0;
    }
  };
}
async function initIdentApp(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["initIdentApp"]) return Reflect.apply(mocks["initIdentApp"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(initIdentAppDocument), variables, cb, requestOptions, initIdentApp);
}
function mockSaveManualVerificationForm(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["saveManualVerificationForm"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["saveManualVerificationForm"] === callback) {
      mocks["saveManualVerificationForm"] = void 0;
    }
  };
}
async function saveManualVerificationForm(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["saveManualVerificationForm"]) return Reflect.apply(mocks["saveManualVerificationForm"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(saveManualVerificationFormDocument), variables, cb, requestOptions, saveManualVerificationForm);
}
function mockSubmitManualVerificationForm(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["submitManualVerificationForm"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["submitManualVerificationForm"] === callback) {
      mocks["submitManualVerificationForm"] = void 0;
    }
  };
}
async function submitManualVerificationForm(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["submitManualVerificationForm"]) return Reflect.apply(mocks["submitManualVerificationForm"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(submitManualVerificationFormDocument), variables, cb, requestOptions, submitManualVerificationForm);
}
function mockResetIdentificationRequest(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["resetIdentificationRequest"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["resetIdentificationRequest"] === callback) {
      mocks["resetIdentificationRequest"] = void 0;
    }
  };
}
async function resetIdentificationRequest(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["resetIdentificationRequest"]) return Reflect.apply(mocks["resetIdentificationRequest"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(resetIdentificationRequestDocument), variables, cb, requestOptions, resetIdentificationRequest);
}
function mockSaveIdentificationDraftData(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["saveIdentificationDraftData"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["saveIdentificationDraftData"] === callback) {
      mocks["saveIdentificationDraftData"] = void 0;
    }
  };
}
async function saveIdentificationDraftData(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["saveIdentificationDraftData"]) return Reflect.apply(mocks["saveIdentificationDraftData"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(saveIdentificationDraftDataDocument), variables, cb, requestOptions, saveIdentificationDraftData);
}
function mockSetInn(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["setInn"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["setInn"] === callback) {
      mocks["setInn"] = void 0;
    }
  };
}
async function setInn(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["setInn"]) return Reflect.apply(mocks["setInn"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(setInnDocument), variables, cb, requestOptions, setInn);
}
function mockSetManualData(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["setManualData"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["setManualData"] === callback) {
      mocks["setManualData"] = void 0;
    }
  };
}
async function setManualData(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["setManualData"]) return Reflect.apply(mocks["setManualData"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(setManualDataDocument), variables, cb, requestOptions, setManualData);
}
function mockSubmitDocuments(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["submitDocuments"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["submitDocuments"] === callback) {
      mocks["submitDocuments"] = void 0;
    }
  };
}
async function submitDocuments(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["submitDocuments"]) return Reflect.apply(mocks["submitDocuments"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(submitDocumentsDocument), variables, cb, requestOptions, submitDocuments);
}
function mockGetLobby(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getLobby"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getLobby"] === callback) {
      mocks["getLobby"] = void 0;
    }
  };
}
async function getLobby(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getLobby"]) return Reflect.apply(mocks["getLobby"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getLobbyDocument), variables, cb, requestOptions, getLobby);
}
function mockLogout(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["logout"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["logout"] === callback) {
      mocks["logout"] = void 0;
    }
  };
}
async function logout(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["logout"]) return Reflect.apply(mocks["logout"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(logoutDocument), variables, cb, requestOptions, logout);
}
function mockMakeBatchSlip(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["makeBatchSlip"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["makeBatchSlip"] === callback) {
      mocks["makeBatchSlip"] = void 0;
    }
  };
}
async function makeBatchSlip(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["makeBatchSlip"]) return Reflect.apply(mocks["makeBatchSlip"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(makeBatchSlipDocument), variables, cb, requestOptions, makeBatchSlip);
}
function mockDoDeleteNotification(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doDeleteNotification"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doDeleteNotification"] === callback) {
      mocks["doDeleteNotification"] = void 0;
    }
  };
}
async function doDeleteNotification(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doDeleteNotification"]) return Reflect.apply(mocks["doDeleteNotification"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doDeleteNotificationDocument), variables, cb, requestOptions, doDeleteNotification);
}
function mockDoMarkAsClicked(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doMarkAsClicked"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doMarkAsClicked"] === callback) {
      mocks["doMarkAsClicked"] = void 0;
    }
  };
}
async function doMarkAsClicked(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doMarkAsClicked"]) return Reflect.apply(mocks["doMarkAsClicked"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doMarkAsClickedDocument), variables, cb, requestOptions, doMarkAsClicked);
}
function mockDoMarkAsDelivered(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doMarkAsDelivered"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doMarkAsDelivered"] === callback) {
      mocks["doMarkAsDelivered"] = void 0;
    }
  };
}
async function doMarkAsDelivered(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doMarkAsDelivered"]) return Reflect.apply(mocks["doMarkAsDelivered"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doMarkAsDeliveredDocument), variables, cb, requestOptions, doMarkAsDelivered);
}
function mockDoMarkNotification(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doMarkNotification"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doMarkNotification"] === callback) {
      mocks["doMarkNotification"] = void 0;
    }
  };
}
async function doMarkNotification(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doMarkNotification"]) return Reflect.apply(mocks["doMarkNotification"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doMarkNotificationDocument), variables, cb, requestOptions, doMarkNotification);
}
function mockGetNotifications(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getNotifications"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getNotifications"] === callback) {
      mocks["getNotifications"] = void 0;
    }
  };
}
async function getNotifications(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getNotifications"]) return Reflect.apply(mocks["getNotifications"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getNotificationsDocument), variables, cb, requestOptions, getNotifications);
}
function mockDoCancelRequestedWithdrawals(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doCancelRequestedWithdrawals"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doCancelRequestedWithdrawals"] === callback) {
      mocks["doCancelRequestedWithdrawals"] = void 0;
    }
  };
}
async function doCancelRequestedWithdrawals(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doCancelRequestedWithdrawals"]) return Reflect.apply(mocks["doCancelRequestedWithdrawals"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doCancelRequestedWithdrawalsDocument), variables, cb, requestOptions, doCancelRequestedWithdrawals);
}
function mockDoSubmitDeposit(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doSubmitDeposit"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doSubmitDeposit"] === callback) {
      mocks["doSubmitDeposit"] = void 0;
    }
  };
}
async function doSubmitDeposit(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doSubmitDeposit"]) return Reflect.apply(mocks["doSubmitDeposit"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doSubmitDepositDocument), variables, cb, requestOptions, doSubmitDeposit);
}
function mockDoSubmitWithdrawal(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doSubmitWithdrawal"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doSubmitWithdrawal"] === callback) {
      mocks["doSubmitWithdrawal"] = void 0;
    }
  };
}
async function doSubmitWithdrawal(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doSubmitWithdrawal"]) return Reflect.apply(mocks["doSubmitWithdrawal"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doSubmitWithdrawalDocument), variables, cb, requestOptions, doSubmitWithdrawal);
}
function mockGetCompanyFee(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCompanyFee"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCompanyFee"] === callback) {
      mocks["getCompanyFee"] = void 0;
    }
  };
}
async function getCompanyFee(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCompanyFee"]) return Reflect.apply(mocks["getCompanyFee"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCompanyFeeDocument), variables, cb, requestOptions, getCompanyFee);
}
function mockGetCryptoNetworkInfo(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCryptoNetworkInfo"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCryptoNetworkInfo"] === callback) {
      mocks["getCryptoNetworkInfo"] = void 0;
    }
  };
}
async function getCryptoNetworkInfo(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCryptoNetworkInfo"]) return Reflect.apply(mocks["getCryptoNetworkInfo"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCryptoNetworkInfoDocument), variables, cb, requestOptions, getCryptoNetworkInfo);
}
function mockGetDepositSystemsList(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getDepositSystemsList"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getDepositSystemsList"] === callback) {
      mocks["getDepositSystemsList"] = void 0;
    }
  };
}
async function getDepositSystemsList(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getDepositSystemsList"]) return Reflect.apply(mocks["getDepositSystemsList"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getDepositSystemsListDocument), variables, cb, requestOptions, getDepositSystemsList);
}
function mockGetDepositSystemsOverviewList(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getDepositSystemsOverviewList"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getDepositSystemsOverviewList"] === callback) {
      mocks["getDepositSystemsOverviewList"] = void 0;
    }
  };
}
async function getDepositSystemsOverviewList(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getDepositSystemsOverviewList"]) return Reflect.apply(mocks["getDepositSystemsOverviewList"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getDepositSystemsOverviewListDocument), variables, cb, requestOptions, getDepositSystemsOverviewList);
}
function mockGetDepositsRegistrationCompletionStatus(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getDepositsRegistrationCompletionStatus"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getDepositsRegistrationCompletionStatus"] === callback) {
      mocks["getDepositsRegistrationCompletionStatus"] = void 0;
    }
  };
}
async function getDepositsRegistrationCompletionStatus(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getDepositsRegistrationCompletionStatus"]) return Reflect.apply(mocks["getDepositsRegistrationCompletionStatus"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getDepositsRegistrationCompletionStatusDocument), variables, cb, requestOptions, getDepositsRegistrationCompletionStatus);
}
function mockGetDepositsSystemInfo(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getDepositsSystemInfo"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getDepositsSystemInfo"] === callback) {
      mocks["getDepositsSystemInfo"] = void 0;
    }
  };
}
async function getDepositsSystemInfo(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getDepositsSystemInfo"]) return Reflect.apply(mocks["getDepositsSystemInfo"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getDepositsSystemInfoDocument), variables, cb, requestOptions, getDepositsSystemInfo);
}
function mockGetWithdrawalStats(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getWithdrawalStats"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getWithdrawalStats"] === callback) {
      mocks["getWithdrawalStats"] = void 0;
    }
  };
}
async function getWithdrawalStats(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getWithdrawalStats"]) return Reflect.apply(mocks["getWithdrawalStats"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getWithdrawalStatsDocument), variables, cb, requestOptions, getWithdrawalStats);
}
function mockGetWithdrawalSystemsList(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getWithdrawalSystemsList"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getWithdrawalSystemsList"] === callback) {
      mocks["getWithdrawalSystemsList"] = void 0;
    }
  };
}
async function getWithdrawalSystemsList(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getWithdrawalSystemsList"]) return Reflect.apply(mocks["getWithdrawalSystemsList"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getWithdrawalSystemsListDocument), variables, cb, requestOptions, getWithdrawalSystemsList);
}
function mockGetWithdrawalSystemsOverviewList(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getWithdrawalSystemsOverviewList"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getWithdrawalSystemsOverviewList"] === callback) {
      mocks["getWithdrawalSystemsOverviewList"] = void 0;
    }
  };
}
async function getWithdrawalSystemsOverviewList(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getWithdrawalSystemsOverviewList"]) return Reflect.apply(mocks["getWithdrawalSystemsOverviewList"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getWithdrawalSystemsOverviewListDocument), variables, cb, requestOptions, getWithdrawalSystemsOverviewList);
}
function mockGetWithdrawalsRegistrationCompletionStatus(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getWithdrawalsRegistrationCompletionStatus"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getWithdrawalsRegistrationCompletionStatus"] === callback) {
      mocks["getWithdrawalsRegistrationCompletionStatus"] = void 0;
    }
  };
}
async function getWithdrawalsRegistrationCompletionStatus(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getWithdrawalsRegistrationCompletionStatus"]) return Reflect.apply(mocks["getWithdrawalsRegistrationCompletionStatus"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getWithdrawalsRegistrationCompletionStatusDocument), variables, cb, requestOptions, getWithdrawalsRegistrationCompletionStatus);
}
function mockGetWithdrawalsSystemInfo(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getWithdrawalsSystemInfo"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getWithdrawalsSystemInfo"] === callback) {
      mocks["getWithdrawalsSystemInfo"] = void 0;
    }
  };
}
async function getWithdrawalsSystemInfo(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getWithdrawalsSystemInfo"]) return Reflect.apply(mocks["getWithdrawalsSystemInfo"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getWithdrawalsSystemInfoDocument), variables, cb, requestOptions, getWithdrawalsSystemInfo);
}
function mockDoCreatePinCode(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doCreatePinCode"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doCreatePinCode"] === callback) {
      mocks["doCreatePinCode"] = void 0;
    }
  };
}
async function doCreatePinCode(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doCreatePinCode"]) return Reflect.apply(mocks["doCreatePinCode"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doCreatePinCodeDocument), variables, cb, requestOptions, doCreatePinCode);
}
function mockDoDeletePinCode(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doDeletePinCode"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doDeletePinCode"] === callback) {
      mocks["doDeletePinCode"] = void 0;
    }
  };
}
async function doDeletePinCode(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doDeletePinCode"]) return Reflect.apply(mocks["doDeletePinCode"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doDeletePinCodeDocument), variables, cb, requestOptions, doDeletePinCode);
}
function mockDoVerifyPinCode(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doVerifyPinCode"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doVerifyPinCode"] === callback) {
      mocks["doVerifyPinCode"] = void 0;
    }
  };
}
async function doVerifyPinCode(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doVerifyPinCode"]) return Reflect.apply(mocks["doVerifyPinCode"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doVerifyPinCodeDocument), variables, cb, requestOptions, doVerifyPinCode);
}
function mockGetPinCodeStatus(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getPinCodeStatus"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getPinCodeStatus"] === callback) {
      mocks["getPinCodeStatus"] = void 0;
    }
  };
}
async function getPinCodeStatus(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getPinCodeStatus"]) return Reflect.apply(mocks["getPinCodeStatus"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getPinCodeStatusDocument), variables, cb, requestOptions, getPinCodeStatus);
}
function mockDoDeactivateTimer(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doDeactivateTimer"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doDeactivateTimer"] === callback) {
      mocks["doDeactivateTimer"] = void 0;
    }
  };
}
async function doDeactivateTimer(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doDeactivateTimer"]) return Reflect.apply(mocks["doDeactivateTimer"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doDeactivateTimerDocument), variables, cb, requestOptions, doDeactivateTimer);
}
function mockDoPlayTimeControl(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doPlayTimeControl"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doPlayTimeControl"] === callback) {
      mocks["doPlayTimeControl"] = void 0;
    }
  };
}
async function doPlayTimeControl(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doPlayTimeControl"]) return Reflect.apply(mocks["doPlayTimeControl"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doPlayTimeControlDocument), variables, cb, requestOptions, doPlayTimeControl);
}
function mockPlayTimeControl(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["playTimeControl"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["playTimeControl"] === callback) {
      mocks["playTimeControl"] = void 0;
    }
  };
}
async function playTimeControl(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["playTimeControl"]) return Reflect.apply(mocks["playTimeControl"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(playTimeControlDocument), variables, cb, requestOptions, playTimeControl);
}
function mockDoButtonMutation(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doButtonMutation"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doButtonMutation"] === callback) {
      mocks["doButtonMutation"] = void 0;
    }
  };
}
async function doButtonMutation(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doButtonMutation"]) return Reflect.apply(mocks["doButtonMutation"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doButtonMutationDocument), variables, cb, requestOptions, doButtonMutation);
}
function mockDoMarkAsShownMutation(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doMarkAsShownMutation"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doMarkAsShownMutation"] === callback) {
      mocks["doMarkAsShownMutation"] = void 0;
    }
  };
}
async function doMarkAsShownMutation(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doMarkAsShownMutation"]) return Reflect.apply(mocks["doMarkAsShownMutation"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doMarkAsShownMutationDocument), variables, cb, requestOptions, doMarkAsShownMutation);
}
function mockDoPromotionParticipation(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doPromotionParticipation"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doPromotionParticipation"] === callback) {
      mocks["doPromotionParticipation"] = void 0;
    }
  };
}
async function doPromotionParticipation(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doPromotionParticipation"]) return Reflect.apply(mocks["doPromotionParticipation"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doPromotionParticipationDocument), variables, cb, requestOptions, doPromotionParticipation);
}
function mockDoPromotionRate(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doPromotionRate"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doPromotionRate"] === callback) {
      mocks["doPromotionRate"] = void 0;
    }
  };
}
async function doPromotionRate(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doPromotionRate"]) return Reflect.apply(mocks["doPromotionRate"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doPromotionRateDocument), variables, cb, requestOptions, doPromotionRate);
}
function mockGetActiveOrAvailableCampaigns(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getActiveOrAvailableCampaigns"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getActiveOrAvailableCampaigns"] === callback) {
      mocks["getActiveOrAvailableCampaigns"] = void 0;
    }
  };
}
async function getActiveOrAvailableCampaigns(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getActiveOrAvailableCampaigns"]) return Reflect.apply(mocks["getActiveOrAvailableCampaigns"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getActiveOrAvailableCampaignsDocument), variables, cb, requestOptions, getActiveOrAvailableCampaigns);
}
function mockGetBonusWalletPromotion(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getBonusWalletPromotion"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getBonusWalletPromotion"] === callback) {
      mocks["getBonusWalletPromotion"] = void 0;
    }
  };
}
async function getBonusWalletPromotion(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getBonusWalletPromotion"]) return Reflect.apply(mocks["getBonusWalletPromotion"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getBonusWalletPromotionDocument), variables, cb, requestOptions, getBonusWalletPromotion);
}
function mockGetLinkedPromotionsByEgsGame(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getLinkedPromotionsByEgsGame"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getLinkedPromotionsByEgsGame"] === callback) {
      mocks["getLinkedPromotionsByEgsGame"] = void 0;
    }
  };
}
async function getLinkedPromotionsByEgsGame(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getLinkedPromotionsByEgsGame"]) return Reflect.apply(mocks["getLinkedPromotionsByEgsGame"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getLinkedPromotionsByEgsGameDocument), variables, cb, requestOptions, getLinkedPromotionsByEgsGame);
}
function mockGetPromotionCategories(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getPromotionCategories"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getPromotionCategories"] === callback) {
      mocks["getPromotionCategories"] = void 0;
    }
  };
}
async function getPromotionCategories(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getPromotionCategories"]) return Reflect.apply(mocks["getPromotionCategories"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getPromotionCategoriesDocument), variables, cb, requestOptions, getPromotionCategories);
}
function mockGetPromotionDetails(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getPromotionDetails"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getPromotionDetails"] === callback) {
      mocks["getPromotionDetails"] = void 0;
    }
  };
}
async function getPromotionDetails(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getPromotionDetails"]) return Reflect.apply(mocks["getPromotionDetails"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getPromotionDetailsDocument), variables, cb, requestOptions, getPromotionDetails);
}
function mockGetPromotionNavigationConfig(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getPromotionNavigationConfig"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getPromotionNavigationConfig"] === callback) {
      mocks["getPromotionNavigationConfig"] = void 0;
    }
  };
}
async function getPromotionNavigationConfig(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getPromotionNavigationConfig"]) return Reflect.apply(mocks["getPromotionNavigationConfig"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getPromotionNavigationConfigDocument), variables, cb, requestOptions, getPromotionNavigationConfig);
}
function mockGetPromotions(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getPromotions"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getPromotions"] === callback) {
      mocks["getPromotions"] = void 0;
    }
  };
}
async function getPromotions(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getPromotions"]) return Reflect.apply(mocks["getPromotions"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getPromotionsDocument), variables, cb, requestOptions, getPromotions);
}
function mockDoConfirmations(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doConfirmations"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doConfirmations"] === callback) {
      mocks["doConfirmations"] = void 0;
    }
  };
}
async function doConfirmations(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doConfirmations"]) return Reflect.apply(mocks["doConfirmations"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doConfirmationsDocument), variables, cb, requestOptions, doConfirmations);
}
function mockDoRewarding(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doRewarding"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doRewarding"] === callback) {
      mocks["doRewarding"] = void 0;
    }
  };
}
async function doRewarding(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doRewarding"]) return Reflect.apply(mocks["doRewarding"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doRewardingDocument), variables, cb, requestOptions, doRewarding);
}
function mockGetAvailability(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getAvailability"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getAvailability"] === callback) {
      mocks["getAvailability"] = void 0;
    }
  };
}
async function getAvailability(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getAvailability"]) return Reflect.apply(mocks["getAvailability"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getAvailabilityDocument), variables, cb, requestOptions, getAvailability);
}
function mockGetDetails(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getDetails"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getDetails"] === callback) {
      mocks["getDetails"] = void 0;
    }
  };
}
async function getDetails(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getDetails"]) return Reflect.apply(mocks["getDetails"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getDetailsDocument), variables, cb, requestOptions, getDetails);
}
function mockGetReferral(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getReferral"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getReferral"] === callback) {
      mocks["getReferral"] = void 0;
    }
  };
}
async function getReferral(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getReferral"]) return Reflect.apply(mocks["getReferral"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getReferralDocument), variables, cb, requestOptions, getReferral);
}
function mockGetRewards(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getRewards"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getRewards"] === callback) {
      mocks["getRewards"] = void 0;
    }
  };
}
async function getRewards(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getRewards"]) return Reflect.apply(mocks["getRewards"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getRewardsDocument), variables, cb, requestOptions, getRewards);
}
function mockDoRegistration(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doRegistration"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doRegistration"] === callback) {
      mocks["doRegistration"] = void 0;
    }
  };
}
async function doRegistration(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doRegistration"]) return Reflect.apply(mocks["doRegistration"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doRegistrationDocument), variables, cb, requestOptions, doRegistration);
}
function mockGetCurrenciesByCountry(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getCurrenciesByCountry"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getCurrenciesByCountry"] === callback) {
      mocks["getCurrenciesByCountry"] = void 0;
    }
  };
}
async function getCurrenciesByCountry(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getCurrenciesByCountry"]) return Reflect.apply(mocks["getCurrenciesByCountry"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getCurrenciesByCountryDocument), variables, cb, requestOptions, getCurrenciesByCountry);
}
function mockGetIncompleteRegistrationFields(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getIncompleteRegistrationFields"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getIncompleteRegistrationFields"] === callback) {
      mocks["getIncompleteRegistrationFields"] = void 0;
    }
  };
}
async function getIncompleteRegistrationFields(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getIncompleteRegistrationFields"]) return Reflect.apply(mocks["getIncompleteRegistrationFields"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getIncompleteRegistrationFieldsDocument), variables, cb, requestOptions, getIncompleteRegistrationFields);
}
function mockUpdateIncompleteRegistrationFields(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["updateIncompleteRegistrationFields"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["updateIncompleteRegistrationFields"] === callback) {
      mocks["updateIncompleteRegistrationFields"] = void 0;
    }
  };
}
async function updateIncompleteRegistrationFields(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["updateIncompleteRegistrationFields"]) return Reflect.apply(mocks["updateIncompleteRegistrationFields"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(updateIncompleteRegistrationFieldsDocument), variables, cb, requestOptions, updateIncompleteRegistrationFields);
}
function mockRemoveCustomerMessage(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["removeCustomerMessage"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["removeCustomerMessage"] === callback) {
      mocks["removeCustomerMessage"] = void 0;
    }
  };
}
async function removeCustomerMessage(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["removeCustomerMessage"]) return Reflect.apply(mocks["removeCustomerMessage"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(removeCustomerMessageDocument), variables, cb, requestOptions, removeCustomerMessage);
}
function mockResendVerificationEmail(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["resendVerificationEmail"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["resendVerificationEmail"] === callback) {
      mocks["resendVerificationEmail"] = void 0;
    }
  };
}
async function resendVerificationEmail(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["resendVerificationEmail"]) return Reflect.apply(mocks["resendVerificationEmail"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(resendVerificationEmailDocument), variables, cb, requestOptions, resendVerificationEmail);
}
function mockResetDisposableKeys(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["resetDisposableKeys"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["resetDisposableKeys"] === callback) {
      mocks["resetDisposableKeys"] = void 0;
    }
  };
}
async function resetDisposableKeys(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["resetDisposableKeys"]) return Reflect.apply(mocks["resetDisposableKeys"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(resetDisposableKeysDocument), variables, cb, requestOptions, resetDisposableKeys);
}
function mockResetShown(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["resetShown"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["resetShown"] === callback) {
      mocks["resetShown"] = void 0;
    }
  };
}
async function resetShown(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["resetShown"]) return Reflect.apply(mocks["resetShown"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(resetShownDocument), variables, cb, requestOptions, resetShown);
}
function mockCheckRestorePasswordSms(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["checkRestorePasswordSms"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["checkRestorePasswordSms"] === callback) {
      mocks["checkRestorePasswordSms"] = void 0;
    }
  };
}
async function checkRestorePasswordSms(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["checkRestorePasswordSms"]) return Reflect.apply(mocks["checkRestorePasswordSms"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(checkRestorePasswordSmsDocument), variables, cb, requestOptions, checkRestorePasswordSms);
}
function mockCheckRestorePasswordUid(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["checkRestorePasswordUid"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["checkRestorePasswordUid"] === callback) {
      mocks["checkRestorePasswordUid"] = void 0;
    }
  };
}
async function checkRestorePasswordUid(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["checkRestorePasswordUid"]) return Reflect.apply(mocks["checkRestorePasswordUid"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(checkRestorePasswordUidDocument), variables, cb, requestOptions, checkRestorePasswordUid);
}
function mockDoRestorePasswordByEmail(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doRestorePasswordByEmail"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doRestorePasswordByEmail"] === callback) {
      mocks["doRestorePasswordByEmail"] = void 0;
    }
  };
}
async function doRestorePasswordByEmail(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doRestorePasswordByEmail"]) return Reflect.apply(mocks["doRestorePasswordByEmail"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doRestorePasswordByEmailDocument), variables, cb, requestOptions, doRestorePasswordByEmail);
}
function mockDoRestorePasswordByPhone(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["doRestorePasswordByPhone"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["doRestorePasswordByPhone"] === callback) {
      mocks["doRestorePasswordByPhone"] = void 0;
    }
  };
}
async function doRestorePasswordByPhone(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["doRestorePasswordByPhone"]) return Reflect.apply(mocks["doRestorePasswordByPhone"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(doRestorePasswordByPhoneDocument), variables, cb, requestOptions, doRestorePasswordByPhone);
}
function mockResetPinCodeEmail(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["resetPinCodeEmail"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["resetPinCodeEmail"] === callback) {
      mocks["resetPinCodeEmail"] = void 0;
    }
  };
}
async function resetPinCodeEmail(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["resetPinCodeEmail"]) return Reflect.apply(mocks["resetPinCodeEmail"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(resetPinCodeEmailDocument), variables, cb, requestOptions, resetPinCodeEmail);
}
function mockResetPinCodePhone(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["resetPinCodePhone"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["resetPinCodePhone"] === callback) {
      mocks["resetPinCodePhone"] = void 0;
    }
  };
}
async function resetPinCodePhone(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["resetPinCodePhone"]) return Reflect.apply(mocks["resetPinCodePhone"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(resetPinCodePhoneDocument), variables, cb, requestOptions, resetPinCodePhone);
}
function mockSendRestorePasswordLinkToEmail(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["sendRestorePasswordLinkToEmail"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["sendRestorePasswordLinkToEmail"] === callback) {
      mocks["sendRestorePasswordLinkToEmail"] = void 0;
    }
  };
}
async function sendRestorePasswordLinkToEmail(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["sendRestorePasswordLinkToEmail"]) return Reflect.apply(mocks["sendRestorePasswordLinkToEmail"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(sendRestorePasswordLinkToEmailDocument), variables, cb, requestOptions, sendRestorePasswordLinkToEmail);
}
function mockSendRestorePasswordSmsOrCallToPhone(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["sendRestorePasswordSmsOrCallToPhone"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["sendRestorePasswordSmsOrCallToPhone"] === callback) {
      mocks["sendRestorePasswordSmsOrCallToPhone"] = void 0;
    }
  };
}
async function sendRestorePasswordSmsOrCallToPhone(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["sendRestorePasswordSmsOrCallToPhone"]) return Reflect.apply(mocks["sendRestorePasswordSmsOrCallToPhone"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(sendRestorePasswordSmsOrCallToPhoneDocument), variables, cb, requestOptions, sendRestorePasswordSmsOrCallToPhone);
}
function mockSendRestorePasswordSmsToPhone(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["sendRestorePasswordSmsToPhone"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["sendRestorePasswordSmsToPhone"] === callback) {
      mocks["sendRestorePasswordSmsToPhone"] = void 0;
    }
  };
}
async function sendRestorePasswordSmsToPhone(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["sendRestorePasswordSmsToPhone"]) return Reflect.apply(mocks["sendRestorePasswordSmsToPhone"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(sendRestorePasswordSmsToPhoneDocument), variables, cb, requestOptions, sendRestorePasswordSmsToPhone);
}
function mockSendRestorePinLinkToEmail(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["sendRestorePinLinkToEmail"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["sendRestorePinLinkToEmail"] === callback) {
      mocks["sendRestorePinLinkToEmail"] = void 0;
    }
  };
}
async function sendRestorePinLinkToEmail(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["sendRestorePinLinkToEmail"]) return Reflect.apply(mocks["sendRestorePinLinkToEmail"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(sendRestorePinLinkToEmailDocument), variables, cb, requestOptions, sendRestorePinLinkToEmail);
}
function mockSendRestorePinLinkToPhone(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["sendRestorePinLinkToPhone"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["sendRestorePinLinkToPhone"] === callback) {
      mocks["sendRestorePinLinkToPhone"] = void 0;
    }
  };
}
async function sendRestorePinLinkToPhone(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["sendRestorePinLinkToPhone"]) return Reflect.apply(mocks["sendRestorePinLinkToPhone"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(sendRestorePinLinkToPhoneDocument), variables, cb, requestOptions, sendRestorePinLinkToPhone);
}
function mockSavePushSettings(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["savePushSettings"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["savePushSettings"] === callback) {
      mocks["savePushSettings"] = void 0;
    }
  };
}
async function savePushSettings(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["savePushSettings"]) return Reflect.apply(mocks["savePushSettings"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(savePushSettingsDocument), variables, cb, requestOptions, savePushSettings);
}
function mockGetSpintaxMetaData(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getSpintaxMetaData"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getSpintaxMetaData"] === callback) {
      mocks["getSpintaxMetaData"] = void 0;
    }
  };
}
async function getSpintaxMetaData(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getSpintaxMetaData"]) return Reflect.apply(mocks["getSpintaxMetaData"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getSpintaxMetaDataDocument), variables, cb, requestOptions, getSpintaxMetaData);
}
function mockSetAdditionalPropsUser(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["setAdditionalPropsUser"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["setAdditionalPropsUser"] === callback) {
      mocks["setAdditionalPropsUser"] = void 0;
    }
  };
}
async function setAdditionalPropsUser(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["setAdditionalPropsUser"]) return Reflect.apply(mocks["setAdditionalPropsUser"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(setAdditionalPropsUserDocument), variables, cb, requestOptions, setAdditionalPropsUser);
}
function mockSetBannerClosed(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["setBannerClosed"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["setBannerClosed"] === callback) {
      mocks["setBannerClosed"] = void 0;
    }
  };
}
async function setBannerClosed(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["setBannerClosed"]) return Reflect.apply(mocks["setBannerClosed"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(setBannerClosedDocument), variables, cb, requestOptions, setBannerClosed);
}
function mockSetBetLimits(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["setBetLimits"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["setBetLimits"] === callback) {
      mocks["setBetLimits"] = void 0;
    }
  };
}
async function setBetLimits(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["setBetLimits"]) return Reflect.apply(mocks["setBetLimits"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(setBetLimitsDocument), variables, cb, requestOptions, setBetLimits);
}
function mockSetCoookie(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["setCoookie"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["setCoookie"] === callback) {
      mocks["setCoookie"] = void 0;
    }
  };
}
async function setCoookie(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["setCoookie"]) return Reflect.apply(mocks["setCoookie"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(setCoookieDocument), variables, cb, requestOptions, setCoookie);
}
function mockSetDepositLimits(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["setDepositLimits"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["setDepositLimits"] === callback) {
      mocks["setDepositLimits"] = void 0;
    }
  };
}
async function setDepositLimits(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["setDepositLimits"]) return Reflect.apply(mocks["setDepositLimits"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(setDepositLimitsDocument), variables, cb, requestOptions, setDepositLimits);
}
function mockSetDowntimeShown(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["setDowntimeShown"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["setDowntimeShown"] === callback) {
      mocks["setDowntimeShown"] = void 0;
    }
  };
}
async function setDowntimeShown(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["setDowntimeShown"]) return Reflect.apply(mocks["setDowntimeShown"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(setDowntimeShownDocument), variables, cb, requestOptions, setDowntimeShown);
}
function mockSetWhatsNew(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["setWhatsNew"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["setWhatsNew"] === callback) {
      mocks["setWhatsNew"] = void 0;
    }
  };
}
async function setWhatsNew(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["setWhatsNew"]) return Reflect.apply(mocks["setWhatsNew"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(setWhatsNewDocument), variables, cb, requestOptions, setWhatsNew);
}
function mockGetTsupisLegalAgreementsVersions(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getTsupisLegalAgreementsVersions"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getTsupisLegalAgreementsVersions"] === callback) {
      mocks["getTsupisLegalAgreementsVersions"] = void 0;
    }
  };
}
async function getTsupisLegalAgreementsVersions(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getTsupisLegalAgreementsVersions"]) return Reflect.apply(mocks["getTsupisLegalAgreementsVersions"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getTsupisLegalAgreementsVersionsDocument), variables, cb, requestOptions, getTsupisLegalAgreementsVersions);
}
function mockGetTsupisOfferAgreements(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getTsupisOfferAgreements"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getTsupisOfferAgreements"] === callback) {
      mocks["getTsupisOfferAgreements"] = void 0;
    }
  };
}
async function getTsupisOfferAgreements(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getTsupisOfferAgreements"]) return Reflect.apply(mocks["getTsupisOfferAgreements"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getTsupisOfferAgreementsDocument), variables, cb, requestOptions, getTsupisOfferAgreements);
}
function mockGetTsupisPersonalDataAgreements(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getTsupisPersonalDataAgreements"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getTsupisPersonalDataAgreements"] === callback) {
      mocks["getTsupisPersonalDataAgreements"] = void 0;
    }
  };
}
async function getTsupisPersonalDataAgreements(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getTsupisPersonalDataAgreements"]) return Reflect.apply(mocks["getTsupisPersonalDataAgreements"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getTsupisPersonalDataAgreementsDocument), variables, cb, requestOptions, getTsupisPersonalDataAgreements);
}
function mockUpdateCustomerProfileFields(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["updateCustomerProfileFields"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["updateCustomerProfileFields"] === callback) {
      mocks["updateCustomerProfileFields"] = void 0;
    }
  };
}
async function updateCustomerProfileFields(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["updateCustomerProfileFields"]) return Reflect.apply(mocks["updateCustomerProfileFields"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(updateCustomerProfileFieldsDocument), variables, cb, requestOptions, updateCustomerProfileFields);
}
function mockGetVerificationReasons(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getVerificationReasons"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getVerificationReasons"] === callback) {
      mocks["getVerificationReasons"] = void 0;
    }
  };
}
async function getVerificationReasons(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getVerificationReasons"]) return Reflect.apply(mocks["getVerificationReasons"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getVerificationReasonsDocument), variables, cb, requestOptions, getVerificationReasons);
}
function mockGetVerificationToken(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getVerificationToken"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getVerificationToken"] === callback) {
      mocks["getVerificationToken"] = void 0;
    }
  };
}
async function getVerificationToken(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getVerificationToken"]) return Reflect.apply(mocks["getVerificationToken"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getVerificationTokenDocument), variables, cb, requestOptions, getVerificationToken);
}
function mockMakeCustomerVerification(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["makeCustomerVerification"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["makeCustomerVerification"] === callback) {
      mocks["makeCustomerVerification"] = void 0;
    }
  };
}
async function makeCustomerVerification(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["makeCustomerVerification"]) return Reflect.apply(mocks["makeCustomerVerification"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(makeCustomerVerificationDocument), variables, cb, requestOptions, makeCustomerVerification);
}
function mockMakeVipFastTrackVerification(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["makeVipFastTrackVerification"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["makeVipFastTrackVerification"] === callback) {
      mocks["makeVipFastTrackVerification"] = void 0;
    }
  };
}
async function makeVipFastTrackVerification(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["makeVipFastTrackVerification"]) return Reflect.apply(mocks["makeVipFastTrackVerification"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(makeVipFastTrackVerificationDocument), variables, cb, requestOptions, makeVipFastTrackVerification);
}
function mockVerifyEmail(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["verifyEmail"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["verifyEmail"] === callback) {
      mocks["verifyEmail"] = void 0;
    }
  };
}
async function verifyEmail(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["verifyEmail"]) return Reflect.apply(mocks["verifyEmail"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(verifyEmailDocument), variables, cb, requestOptions, verifyEmail);
}
function mockGetState(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["getState"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["getState"] === callback) {
      mocks["getState"] = void 0;
    }
  };
}
async function getState(client, cb, variables, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["getState"]) return Reflect.apply(mocks["getState"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(getStateDocument), variables, cb, requestOptions, getState);
}
function mockWithdrawBonusCBC(callback) {
  if (process.env.NODE_ENV === "test") {
    mocks["withdrawBonusCBC"] = callback;
  }
  return () => {
    if (process.env.NODE_ENV === "test" && mocks["withdrawBonusCBC"] === callback) {
      mocks["withdrawBonusCBC"] = void 0;
    }
  };
}
async function withdrawBonusCBC(client, cb, variables = {}, requestOptions = {}) {
  if (process.env.NODE_ENV === "test" && mocks["withdrawBonusCBC"]) return Reflect.apply(mocks["withdrawBonusCBC"], null, [client, cb, variables, requestOptions]);
  if (client === void 0) throw new Error("Unexpected state");
  return client.requestGraphql(print(withdrawBonusCBCDocument), variables, cb, requestOptions, withdrawBonusCBC);
}
function useGetAllAccountHistory(client, middleware) {
  return useApiMethod(client, getAllAccountHistory, middleware);
}
function useGetAllAccountHistoryDataGroups(client, middleware) {
  return useApiMethod(client, getAllAccountHistoryDataGroups, middleware);
}
function useGetEgsChannels(client, middleware) {
  return useApiMethod(client, getEgsChannels, middleware);
}
function useGetEgsGameTypes(client, middleware) {
  return useApiMethod(client, getEgsGameTypes, middleware);
}
function useGetEgsRounds(client, middleware) {
  return useApiMethod(client, getEgsRounds, middleware);
}
function useGetEgsRoundsCursor(client, middleware) {
  return useApiMethod(client, getEgsRoundsCursor, middleware);
}
function useGetEgsSessions(client, middleware) {
  return useApiMethod(client, getEgsSessions, middleware);
}
function useGetEgsTransactions(client, middleware) {
  return useApiMethod(client, getEgsTransactions, middleware);
}
function useGetMarketingBonuses(client, middleware) {
  return useApiMethod(client, getMarketingBonuses, middleware);
}
function useGetMarketingBonusCursor(client, middleware) {
  return useApiMethod(client, getMarketingBonusCursor, middleware);
}
function useGetMarketingDataCategories(client, middleware) {
  return useApiMethod(client, getMarketingDataCategories, middleware);
}
function useGetMarketingStatuses(client, middleware) {
  return useApiMethod(client, getMarketingStatuses, middleware);
}
function useGetPayments(client, middleware) {
  return useApiMethod(client, getPayments, middleware);
}
function useGetPaymentsCursor(client, middleware) {
  return useApiMethod(client, getPaymentsCursor, middleware);
}
function useGetPaymentsDataCategories(client, middleware) {
  return useApiMethod(client, getPaymentsDataCategories, middleware);
}
function useGetPaymentsStatuses(client, middleware) {
  return useApiMethod(client, getPaymentsStatuses, middleware);
}
function useGetSportsbookBets(client, middleware) {
  return useApiMethod(client, getSportsbookBets, middleware);
}
function useGetSportsbookSports(client, middleware) {
  return useApiMethod(client, getSportsbookSports, middleware);
}
function useGetVSportBets(client, middleware) {
  return useApiMethod(client, getVSportBets, middleware);
}
function useGetVSportSports(client, middleware) {
  return useApiMethod(client, getVSportSports, middleware);
}
function useDoAchievementMarkAsViewed(client, middleware) {
  return useApiMethod(client, doAchievementMarkAsViewed, middleware);
}
function useDoAchievementModifyAvatar(client, middleware) {
  return useApiMethod(client, doAchievementModifyAvatar, middleware);
}
function useDoAchievementReward(client, middleware) {
  return useApiMethod(client, doAchievementReward, middleware);
}
function useGetAchievement(client, middleware) {
  return useApiMethod(client, getAchievement, middleware);
}
function useGetAchievements(client, middleware) {
  return useApiMethod(client, getAchievements, middleware);
}
function useGetAchievementsAvailability(client, middleware) {
  return useApiMethod(client, getAchievementsAvailability, middleware);
}
function useGetAchievementsAvatarsAndBackgrounds(client, middleware) {
  return useApiMethod(client, getAchievementsAvatarsAndBackgrounds, middleware);
}
function useGetAchievementsCategories(client, middleware) {
  return useApiMethod(client, getAchievementsCategories, middleware);
}
function useGetAchievementsProfile(client, middleware) {
  return useApiMethod(client, getAchievementsProfile, middleware);
}
function useDoCollectReward(client, middleware) {
  return useApiMethod(client, doCollectReward, middleware);
}
function useDoConfirmParticipation(client, middleware) {
  return useApiMethod(client, doConfirmParticipation, middleware);
}
function useGetBenefitCustomerLevel(client, middleware) {
  return useApiMethod(client, getBenefitCustomerLevel, middleware);
}
function useGetBetlineLeagueStandings(client, middleware) {
  return useApiMethod(client, getBetlineLeagueStandings, middleware);
}
function useGetBetlineLeagueStatus(client, middleware) {
  return useApiMethod(client, getBetlineLeagueStatus, middleware);
}
function useGetBetlineLeagueTopPlayers(client, middleware) {
  return useApiMethod(client, getBetlineLeagueTopPlayers, middleware);
}
function useGetBetlineMatchEventStatistics(client, middleware) {
  return useApiMethod(client, getBetlineMatchEventStatistics, middleware);
}
function useGetBetlineMatchStatistics(client, middleware) {
  return useApiMethod(client, getBetlineMatchStatistics, middleware);
}
function useGetCustomerMatchStreamEvents(client, middleware) {
  return useApiMethod(client, getCustomerMatchStreamEvents, middleware);
}
function useGetCustomerStreams(client, middleware) {
  return useApiMethod(client, getCustomerStreams, middleware);
}
function useGetLeagueData(client, middleware) {
  return useApiMethod(client, getLeagueData, middleware);
}
function useGetLeaguePlayoffByLeagueId(client, middleware) {
  return useApiMethod(client, getLeaguePlayoffByLeagueId, middleware);
}
function useGetLeagueStandingsByLeagueId(client, middleware) {
  return useApiMethod(client, getLeagueStandingsByLeagueId, middleware);
}
function useGetLeagueStatisticsByLeagueId(client, middleware) {
  return useApiMethod(client, getLeagueStatisticsByLeagueId, middleware);
}
function useGetMatchStreamUrl(client, middleware) {
  return useApiMethod(client, getMatchStreamUrl, middleware);
}
function useDoCalculatePack(client, middleware) {
  return useApiMethod(client, doCalculatePack, middleware);
}
function useDoCalculateRound(client, middleware) {
  return useApiMethod(client, doCalculateRound, middleware);
}
function useDoCollectPackReward(client, middleware) {
  return useApiMethod(client, doCollectPackReward, middleware);
}
function useGetAllPacks(client, middleware) {
  return useApiMethod(client, getAllPacks, middleware);
}
function useGetPacks(client, middleware) {
  return useApiMethod(client, getPacks, middleware);
}
function useGetRounds(client, middleware) {
  return useApiMethod(client, getRounds, middleware);
}
function useDoConfirmCashback(client, middleware) {
  return useApiMethod(client, doConfirmCashback, middleware);
}
function useDoRefuseCashback(client, middleware) {
  return useApiMethod(client, doRefuseCashback, middleware);
}
function useGetCashback(client, middleware) {
  return useApiMethod(client, getCashback, middleware);
}
function useAddFavoriteGame(client, middleware) {
  return useApiMethod(client, addFavoriteGame, middleware);
}
function useGetCasinoLoyaltyStatus(client, middleware) {
  return useApiMethod(client, getCasinoLoyaltyStatus, middleware);
}
function useGetPromoJackpots(client, middleware) {
  return useApiMethod(client, getPromoJackpots, middleware);
}
function useGetPromotionsByEgsGame(client, middleware) {
  return useApiMethod(client, getPromotionsByEgsGame, middleware);
}
function useGetVpnConfig(client, middleware) {
  return useApiMethod(client, getVpnConfig, middleware);
}
function useRemoveFavoriteGame(client, middleware) {
  return useApiMethod(client, removeFavoriteGame, middleware);
}
function useCheckPushTokenExists(client, middleware) {
  return useApiMethod(client, checkPushTokenExists, middleware);
}
function useClearCookies(client, middleware) {
  return useApiMethod(client, clearCookies, middleware);
}
function useDoMarkDeviceUntrusted(client, middleware) {
  return useApiMethod(client, doMarkDeviceUntrusted, middleware);
}
function useDoModifyTimeZone(client, middleware) {
  return useApiMethod(client, doModifyTimeZone, middleware);
}
function useDoRefuseBonus(client, middleware) {
  return useApiMethod(client, doRefuseBonus, middleware);
}
function useGetBonusMatrix(client, middleware) {
  return useApiMethod(client, getBonusMatrix, middleware);
}
function useGetCustomerBonuses(client, middleware) {
  return useApiMethod(client, getCustomerBonuses, middleware);
}
function useSaveBetSlipConfiguration(client, middleware) {
  return useApiMethod(client, saveBetSlipConfiguration, middleware);
}
function useCustomerIdentityCheck(client, middleware) {
  return useApiMethod(client, customerIdentityCheck, middleware);
}
function useDisableCustomerMode(client, middleware) {
  return useApiMethod(client, disableCustomerMode, middleware);
}
function useDoActivateAffiliateProgram(client, middleware) {
  return useApiMethod(client, doActivateAffiliateProgram, middleware);
}
function useDoActivateBonusCode(client, middleware) {
  return useApiMethod(client, doActivateBonusCode, middleware);
}
function useDoAddLoginDevice(client, middleware) {
  return useApiMethod(client, doAddLoginDevice, middleware);
}
function useDoAppsFlyerQualifiedDepositsReported(client, middleware) {
  return useApiMethod(client, doAppsFlyerQualifiedDepositsReported, middleware);
}
function useDoCashOut(client, middleware) {
  return useApiMethod(client, doCashOut, middleware);
}
function useDoChangeCustomerNickname(client, middleware) {
  return useApiMethod(client, doChangeCustomerNickname, middleware);
}
function useDoChangeCustomerSettings(client, middleware) {
  return useApiMethod(client, doChangeCustomerSettings, middleware);
}
function useDoChangeLanguage(client, middleware) {
  return useApiMethod(client, doChangeLanguage, middleware);
}
function useDoChangePassword(client, middleware) {
  return useApiMethod(client, doChangePassword, middleware);
}
function useDoConfirmBonus(client, middleware) {
  return useApiMethod(client, doConfirmBonus, middleware);
}
function useDoCreateBonusCode(client, middleware) {
  return useApiMethod(client, doCreateBonusCode, middleware);
}
function useDoDeleteAccount(client, middleware) {
  return useApiMethod(client, doDeleteAccount, middleware);
}
function useDoEncashBonus(client, middleware) {
  return useApiMethod(client, doEncashBonus, middleware);
}
function useDoEventSubscribe(client, middleware) {
  return useApiMethod(client, doEventSubscribe, middleware);
}
function useDoEventUnsubscribe(client, middleware) {
  return useApiMethod(client, doEventUnsubscribe, middleware);
}
function useDoFavoriteSportEventRemove(client, middleware) {
  return useApiMethod(client, doFavoriteSportEventRemove, middleware);
}
function useDoFavoriteSportEventsAdd(client, middleware) {
  return useApiMethod(client, doFavoriteSportEventsAdd, middleware);
}
function useDoLogin(client, middleware) {
  return useApiMethod(client, doLogin, middleware);
}
function useDoLoginCas(client, middleware) {
  return useApiMethod(client, doLoginCas, middleware);
}
function useDoLoginFingerprint(client, middleware) {
  return useApiMethod(client, doLoginFingerprint, middleware);
}
function useDoLoginG2sv(client, middleware) {
  return useApiMethod(client, doLoginG2sv, middleware);
}
function useDoLoginPinCode(client, middleware) {
  return useApiMethod(client, doLoginPinCode, middleware);
}
function useDoPostponeWebPushToken(client, middleware) {
  return useApiMethod(client, doPostponeWebPushToken, middleware);
}
function useDoReadCustomerNotification(client, middleware) {
  return useApiMethod(client, doReadCustomerNotification, middleware);
}
function useDoRefuseMeeting(client, middleware) {
  return useApiMethod(client, doRefuseMeeting, middleware);
}
function useDoRegisterCustomerOrder(client, middleware) {
  return useApiMethod(client, doRegisterCustomerOrder, middleware);
}
function useDoRegisterPushToken(client, middleware) {
  return useApiMethod(client, doRegisterPushToken, middleware);
}
function useDoRequestCallback(client, middleware) {
  return useApiMethod(client, doRequestCallback, middleware);
}
function useDoSaveBetSlip(client, middleware) {
  return useApiMethod(client, doSaveBetSlip, middleware);
}
function useDoSaveBetSlipByBetId(client, middleware) {
  return useApiMethod(client, doSaveBetSlipByBetId, middleware);
}
function useDoSaveFastBets(client, middleware) {
  return useApiMethod(client, doSaveFastBets, middleware);
}
function useDoSaveLog(client, middleware) {
  return useApiMethod(client, doSaveLog, middleware);
}
function useDoSavePriceChangePolicyV2(client, middleware) {
  return useApiMethod(client, doSavePriceChangePolicyV2, middleware);
}
function useDoSaveShowMaxFastBet(client, middleware) {
  return useApiMethod(client, doSaveShowMaxFastBet, middleware);
}
function useDoSaveSportFilters(client, middleware) {
  return useApiMethod(client, doSaveSportFilters, middleware);
}
function useDoScheduleMeeting(client, middleware) {
  return useApiMethod(client, doScheduleMeeting, middleware);
}
function useDoSelfExclusion(client, middleware) {
  return useApiMethod(client, doSelfExclusion, middleware);
}
function useDoSelfExclusionFlex(client, middleware) {
  return useApiMethod(client, doSelfExclusionFlex, middleware);
}
function useDoSendFeedback(client, middleware) {
  return useApiMethod(client, doSendFeedback, middleware);
}
function useDoSendWeb2Feedback(client, middleware) {
  return useApiMethod(client, doSendWeb2Feedback, middleware);
}
function useDoSendWeb2FeedbackByCategory(client, middleware) {
  return useApiMethod(client, doSendWeb2FeedbackByCategory, middleware);
}
function useDoToggleCustomerLeague(client, middleware) {
  return useApiMethod(client, doToggleCustomerLeague, middleware);
}
function useDoTsupisCardDelete(client, middleware) {
  return useApiMethod(client, doTsupisCardDelete, middleware);
}
function useDoUpdateCustomerData(client, middleware) {
  return useApiMethod(client, doUpdateCustomerData, middleware);
}
function useDoUpdateNotificationConsents(client, middleware) {
  return useApiMethod(client, doUpdateNotificationConsents, middleware);
}
function useDoWithdrawAllSportBonuses(client, middleware) {
  return useApiMethod(client, doWithdrawAllSportBonuses, middleware);
}
function useDoWithdrawSportBonus(client, middleware) {
  return useApiMethod(client, doWithdrawSportBonus, middleware);
}
function useDoWithdrawalCancel(client, middleware) {
  return useApiMethod(client, doWithdrawalCancel, middleware);
}
function useGetEgsCategories(client, middleware) {
  return useApiMethod(client, getEgsCategories, middleware);
}
function useGetEgsGameById(client, middleware) {
  return useApiMethod(client, getEgsGameById, middleware);
}
function useGetEgsGameByUrl(client, middleware) {
  return useApiMethod(client, getEgsGameByUrl, middleware);
}
function useGetEgsGames(client, middleware) {
  return useApiMethod(client, getEgsGames, middleware);
}
function useGetEgsGroups(client, middleware) {
  return useApiMethod(client, getEgsGroups, middleware);
}
function useGetEgsTopWinners(client, middleware) {
  return useApiMethod(client, getEgsTopWinners, middleware);
}
function useStartEgsGame(client, middleware) {
  return useApiMethod(client, startEgsGame, middleware);
}
function useEnableCustomerMode(client, middleware) {
  return useApiMethod(client, enableCustomerMode, middleware);
}
function useEnableFingerprintLogin(client, middleware) {
  return useApiMethod(client, enableFingerprintLogin, middleware);
}
function useGenerateNickname(client, middleware) {
  return useApiMethod(client, generateNickname, middleware);
}
function useGetAccountTransaction(client, middleware) {
  return useApiMethod(client, getAccountTransaction, middleware);
}
function useGetAffiliatedCustomers(client, middleware) {
  return useApiMethod(client, getAffiliatedCustomers, middleware);
}
function useGetApiSettings(client, middleware) {
  return useApiMethod(client, getApiSettings, middleware);
}
function useGetAuthenticatorUri(client, middleware) {
  return useApiMethod(client, getAuthenticatorUri, middleware);
}
function useGetAuthenticatorUriQRCode(client, middleware) {
  return useApiMethod(client, getAuthenticatorUriQRCode, middleware);
}
function useGetAvailableBonusesForTransfer(client, middleware) {
  return useApiMethod(client, getAvailableBonusesForTransfer, middleware);
}
function useGetAvailableForms(client, middleware) {
  return useApiMethod(client, getAvailableForms, middleware);
}
function useGetAvailableMeetingSlots(client, middleware) {
  return useApiMethod(client, getAvailableMeetingSlots, middleware);
}
function useGetBalance(client, middleware) {
  return useApiMethod(client, getBalance, middleware);
}
function useGetBanner(client, middleware) {
  return useApiMethod(client, getBanner, middleware);
}
function useGetBanners(client, middleware) {
  return useApiMethod(client, getBanners, middleware);
}
function useGetBatchSlipInfo(client, middleware) {
  return useApiMethod(client, getBatchSlipInfo, middleware);
}
function useGetBet(client, middleware) {
  return useApiMethod(client, getBet, middleware);
}
function useGetBetLimits(client, middleware) {
  return useApiMethod(client, getBetLimits, middleware);
}
function useGetBetSlip(client, middleware) {
  return useApiMethod(client, getBetSlip, middleware);
}
function useGetBetgeniusWidgetConfiguration(client, middleware) {
  return useApiMethod(client, getBetgeniusWidgetConfiguration, middleware);
}
function useGetBonusCodes(client, middleware) {
  return useApiMethod(client, getBonusCodes, middleware);
}
function useGetBonusLoyalty(client, middleware) {
  return useApiMethod(client, getBonusLoyalty, middleware);
}
function useGetBonusTransferAmount(client, middleware) {
  return useApiMethod(client, getBonusTransferAmount, middleware);
}
function useGetBonusWagers(client, middleware) {
  return useApiMethod(client, getBonusWagers, middleware);
}
function useGetBonuses(client, middleware) {
  return useApiMethod(client, getBonuses, middleware);
}
function useGetCampaignCoupons(client, middleware) {
  return useApiMethod(client, getCampaignCoupons, middleware);
}
function useGetCashoutOffer(client, middleware) {
  return useApiMethod(client, getCashoutOffer, middleware);
}
function useGetCmsContentSpintaxBatchTranslations(client, middleware) {
  return useApiMethod(client, getCmsContentSpintaxBatchTranslations, middleware);
}
function useGetCmsContentTranslations(client, middleware) {
  return useApiMethod(client, getCmsContentTranslations, middleware);
}
function useGetConsents(client, middleware) {
  return useApiMethod(client, getConsents, middleware);
}
function useGetCookie(client, middleware) {
  return useApiMethod(client, getCookie, middleware);
}
function useGetCustomerHistory(client, middleware) {
  return useApiMethod(client, getCustomerHistory, middleware);
}
function useGetCustomerHistoryItem(client, middleware) {
  return useApiMethod(client, getCustomerHistoryItem, middleware);
}
function useGetCustomerHistoryList(client, middleware) {
  return useApiMethod(client, getCustomerHistoryList, middleware);
}
function useGetCustomerLeague(client, middleware) {
  return useApiMethod(client, getCustomerLeague, middleware);
}
function useGetCustomerLeagues(client, middleware) {
  return useApiMethod(client, getCustomerLeagues, middleware);
}
function useGetCustomerMatches(client, middleware) {
  return useApiMethod(client, getCustomerMatches, middleware);
}
function useGetCustomerNotifications(client, middleware) {
  return useApiMethod(client, getCustomerNotifications, middleware);
}
function useGetCustomerNotificationsCount(client, middleware) {
  return useApiMethod(client, getCustomerNotificationsCount, middleware);
}
function useGetCustomerOffers(client, middleware) {
  return useApiMethod(client, getCustomerOffers, middleware);
}
function useGetCustomerOrders(client, middleware) {
  return useApiMethod(client, getCustomerOrders, middleware);
}
function useGetCustomerProfileFields(client, middleware) {
  return useApiMethod(client, getCustomerProfileFields, middleware);
}
function useGetCustomerVerifyData(client, middleware) {
  return useApiMethod(client, getCustomerVerifyData, middleware);
}
function useGetDepositLimits(client, middleware) {
  return useApiMethod(client, getDepositLimits, middleware);
}
function useGetEncashBonusData(client, middleware) {
  return useApiMethod(client, getEncashBonusData, middleware);
}
function useGetFAQCategories(client, middleware) {
  return useApiMethod(client, getFAQCategories, middleware);
}
function useGetFAQItems(client, middleware) {
  return useApiMethod(client, getFAQItems, middleware);
}
function useGetFeedbackTypes(client, middleware) {
  return useApiMethod(client, getFeedbackTypes, middleware);
}
function useGetFormSchema(client, middleware) {
  return useApiMethod(client, getFormSchema, middleware);
}
function useGetFreebetData(client, middleware) {
  return useApiMethod(client, getFreebetData, middleware);
}
function useGetGeneralStats(client, middleware) {
  return useApiMethod(client, getGeneralStats, middleware);
}
function useGetInlineChats(client, middleware) {
  return useApiMethod(client, getInlineChats, middleware);
}
function useGetIntercomUserHash(client, middleware) {
  return useApiMethod(client, getIntercomUserHash, middleware);
}
function useGetLSportsWidgetConfiguration(client, middleware) {
  return useApiMethod(client, getLSportsWidgetConfiguration, middleware);
}
function useGetLastBetResult(client, middleware) {
  return useApiMethod(client, getLastBetResult, middleware);
}
function useGetLeaderBoard(client, middleware) {
  return useApiMethod(client, getLeaderBoard, middleware);
}
function useGetLexGuardSession(client, middleware) {
  return useApiMethod(client, getLexGuardSession, middleware);
}
function useGetLiveChatSettings(client, middleware) {
  return useApiMethod(client, getLiveChatSettings, middleware);
}
function useGetLiveChatStatus(client, middleware) {
  return useApiMethod(client, getLiveChatStatus, middleware);
}
function useGetNavigationConfig(client, middleware) {
  return useApiMethod(client, getNavigationConfig, middleware);
}
function useGetNearbyCities(client, middleware) {
  return useApiMethod(client, getNearbyCities, middleware);
}
function useGetOnboarding(client, middleware) {
  return useApiMethod(client, getOnboarding, middleware);
}
function useGetPartners(client, middleware) {
  return useApiMethod(client, getPartners, middleware);
}
function useGetPendingBets(client, middleware) {
  return useApiMethod(client, getPendingBets, middleware);
}
function useGetPopularQueries(client, middleware) {
  return useApiMethod(client, getPopularQueries, middleware);
}
function useGetPushSettings(client, middleware) {
  return useApiMethod(client, getPushSettings, middleware);
}
function useGetRootSettings(client, middleware) {
  return useApiMethod(client, getRootSettings, middleware);
}
function useGetRootSettingsInternational(client, middleware) {
  return useApiMethod(client, getRootSettingsInternational, middleware);
}
function useGetRoutingConfigs(client, middleware) {
  return useApiMethod(client, getRoutingConfigs, middleware);
}
function useGetRules(client, middleware) {
  return useApiMethod(client, getRules, middleware);
}
function useGetSearchData(client, middleware) {
  return useApiMethod(client, getSearchData, middleware);
}
function useGetSelfExclusion(client, middleware) {
  return useApiMethod(client, getSelfExclusion, middleware);
}
function useGetServiceWorkerConfiguration(client, middleware) {
  return useApiMethod(client, getServiceWorkerConfiguration, middleware);
}
function useGetSiteConfigSettings(client, middleware) {
  return useApiMethod(client, getSiteConfigSettings, middleware);
}
function useGetSportradarLiveMatchTrackerConfiguration(client, middleware) {
  return useApiMethod(client, getSportradarLiveMatchTrackerConfiguration, middleware);
}
function useGetSportradarLiveScoreConfiguration(client, middleware) {
  return useApiMethod(client, getSportradarLiveScoreConfiguration, middleware);
}
function useGetSportradarStatisticsConfiguration(client, middleware) {
  return useApiMethod(client, getSportradarStatisticsConfiguration, middleware);
}
function useGetSubscribedEvents(client, middleware) {
  return useApiMethod(client, getSubscribedEvents, middleware);
}
function useGetTempCoupons(client, middleware) {
  return useApiMethod(client, getTempCoupons, middleware);
}
function useGetTranslations(client, middleware) {
  return useApiMethod(client, getTranslations, middleware);
}
function useGetUpcomingMeeting(client, middleware) {
  return useApiMethod(client, getUpcomingMeeting, middleware);
}
function useGetUserData(client, middleware) {
  return useApiMethod(client, getUserData, middleware);
}
function useGetUserDataPhone(client, middleware) {
  return useApiMethod(client, getUserDataPhone, middleware);
}
function useGetUserProfileBonus(client, middleware) {
  return useApiMethod(client, getUserProfileBonus, middleware);
}
function useGetWhatIsNewData(client, middleware) {
  return useApiMethod(client, getWhatIsNewData, middleware);
}
function useGoBack(client, middleware) {
  return useApiMethod(client, goBack, middleware);
}
function useConfirmManualApplication(client, middleware) {
  return useApiMethod(client, confirmManualApplication, middleware);
}
function useDeleteDocuments(client, middleware) {
  return useApiMethod(client, deleteDocuments, middleware);
}
function useDropManualData(client, middleware) {
  return useApiMethod(client, dropManualData, middleware);
}
function useEsiaDataFetch(client, middleware) {
  return useApiMethod(client, esiaDataFetch, middleware);
}
function useEsiaRedirect(client, middleware) {
  return useApiMethod(client, esiaRedirect, middleware);
}
function useEtsupisPhoneVerificationInit(client, middleware) {
  return useApiMethod(client, etsupisPhoneVerificationInit, middleware);
}
function useEtsupisPhoneVerificationVerify(client, middleware) {
  return useApiMethod(client, etsupisPhoneVerificationVerify, middleware);
}
function useFinishApplication(client, middleware) {
  return useApiMethod(client, finishApplication, middleware);
}
function useGetEsiaAouth2Link(client, middleware) {
  return useApiMethod(client, getEsiaAouth2Link, middleware);
}
function useGetIdentificationState(client, middleware) {
  return useApiMethod(client, getIdentificationState, middleware);
}
function useGetThirdPartyIdentificationInit(client, middleware) {
  return useApiMethod(client, getThirdPartyIdentificationInit, middleware);
}
function useInitIdentApp(client, middleware) {
  return useApiMethod(client, initIdentApp, middleware);
}
function useSaveManualVerificationForm(client, middleware) {
  return useApiMethod(client, saveManualVerificationForm, middleware);
}
function useSubmitManualVerificationForm(client, middleware) {
  return useApiMethod(client, submitManualVerificationForm, middleware);
}
function useResetIdentificationRequest(client, middleware) {
  return useApiMethod(client, resetIdentificationRequest, middleware);
}
function useSaveIdentificationDraftData(client, middleware) {
  return useApiMethod(client, saveIdentificationDraftData, middleware);
}
function useSetInn(client, middleware) {
  return useApiMethod(client, setInn, middleware);
}
function useSetManualData(client, middleware) {
  return useApiMethod(client, setManualData, middleware);
}
function useSubmitDocuments(client, middleware) {
  return useApiMethod(client, submitDocuments, middleware);
}
function useGetLobby(client, middleware) {
  return useApiMethod(client, getLobby, middleware);
}
function useLogout(client, middleware) {
  return useApiMethod(client, logout, middleware);
}
function useMakeBatchSlip(client, middleware) {
  return useApiMethod(client, makeBatchSlip, middleware);
}
function useDoDeleteNotification(client, middleware) {
  return useApiMethod(client, doDeleteNotification, middleware);
}
function useDoMarkAsClicked(client, middleware) {
  return useApiMethod(client, doMarkAsClicked, middleware);
}
function useDoMarkAsDelivered(client, middleware) {
  return useApiMethod(client, doMarkAsDelivered, middleware);
}
function useDoMarkNotification(client, middleware) {
  return useApiMethod(client, doMarkNotification, middleware);
}
function useGetNotifications(client, middleware) {
  return useApiMethod(client, getNotifications, middleware);
}
function useDoCancelRequestedWithdrawals(client, middleware) {
  return useApiMethod(client, doCancelRequestedWithdrawals, middleware);
}
function useDoSubmitDeposit(client, middleware) {
  return useApiMethod(client, doSubmitDeposit, middleware);
}
function useDoSubmitWithdrawal(client, middleware) {
  return useApiMethod(client, doSubmitWithdrawal, middleware);
}
function useGetCompanyFee(client, middleware) {
  return useApiMethod(client, getCompanyFee, middleware);
}
function useGetCryptoNetworkInfo(client, middleware) {
  return useApiMethod(client, getCryptoNetworkInfo, middleware);
}
function useGetDepositSystemsList(client, middleware) {
  return useApiMethod(client, getDepositSystemsList, middleware);
}
function useGetDepositSystemsOverviewList(client, middleware) {
  return useApiMethod(client, getDepositSystemsOverviewList, middleware);
}
function useGetDepositsRegistrationCompletionStatus(client, middleware) {
  return useApiMethod(client, getDepositsRegistrationCompletionStatus, middleware);
}
function useGetDepositsSystemInfo(client, middleware) {
  return useApiMethod(client, getDepositsSystemInfo, middleware);
}
function useGetWithdrawalStats(client, middleware) {
  return useApiMethod(client, getWithdrawalStats, middleware);
}
function useGetWithdrawalSystemsList(client, middleware) {
  return useApiMethod(client, getWithdrawalSystemsList, middleware);
}
function useGetWithdrawalSystemsOverviewList(client, middleware) {
  return useApiMethod(client, getWithdrawalSystemsOverviewList, middleware);
}
function useGetWithdrawalsRegistrationCompletionStatus(client, middleware) {
  return useApiMethod(client, getWithdrawalsRegistrationCompletionStatus, middleware);
}
function useGetWithdrawalsSystemInfo(client, middleware) {
  return useApiMethod(client, getWithdrawalsSystemInfo, middleware);
}
function useDoCreatePinCode(client, middleware) {
  return useApiMethod(client, doCreatePinCode, middleware);
}
function useDoDeletePinCode(client, middleware) {
  return useApiMethod(client, doDeletePinCode, middleware);
}
function useDoVerifyPinCode(client, middleware) {
  return useApiMethod(client, doVerifyPinCode, middleware);
}
function useGetPinCodeStatus(client, middleware) {
  return useApiMethod(client, getPinCodeStatus, middleware);
}
function useDoDeactivateTimer(client, middleware) {
  return useApiMethod(client, doDeactivateTimer, middleware);
}
function useDoPlayTimeControl(client, middleware) {
  return useApiMethod(client, doPlayTimeControl, middleware);
}
function usePlayTimeControl(client, middleware) {
  return useApiMethod(client, playTimeControl, middleware);
}
function useDoButtonMutation(client, middleware) {
  return useApiMethod(client, doButtonMutation, middleware);
}
function useDoMarkAsShownMutation(client, middleware) {
  return useApiMethod(client, doMarkAsShownMutation, middleware);
}
function useDoPromotionParticipation(client, middleware) {
  return useApiMethod(client, doPromotionParticipation, middleware);
}
function useDoPromotionRate(client, middleware) {
  return useApiMethod(client, doPromotionRate, middleware);
}
function useGetActiveOrAvailableCampaigns(client, middleware) {
  return useApiMethod(client, getActiveOrAvailableCampaigns, middleware);
}
function useGetBonusWalletPromotion(client, middleware) {
  return useApiMethod(client, getBonusWalletPromotion, middleware);
}
function useGetLinkedPromotionsByEgsGame(client, middleware) {
  return useApiMethod(client, getLinkedPromotionsByEgsGame, middleware);
}
function useGetPromotionCategories(client, middleware) {
  return useApiMethod(client, getPromotionCategories, middleware);
}
function useGetPromotionDetails(client, middleware) {
  return useApiMethod(client, getPromotionDetails, middleware);
}
function useGetPromotionNavigationConfig(client, middleware) {
  return useApiMethod(client, getPromotionNavigationConfig, middleware);
}
function useGetPromotions(client, middleware) {
  return useApiMethod(client, getPromotions, middleware);
}
function useDoConfirmations(client, middleware) {
  return useApiMethod(client, doConfirmations, middleware);
}
function useDoRewarding(client, middleware) {
  return useApiMethod(client, doRewarding, middleware);
}
function useGetAvailability(client, middleware) {
  return useApiMethod(client, getAvailability, middleware);
}
function useGetDetails(client, middleware) {
  return useApiMethod(client, getDetails, middleware);
}
function useGetReferral(client, middleware) {
  return useApiMethod(client, getReferral, middleware);
}
function useGetRewards(client, middleware) {
  return useApiMethod(client, getRewards, middleware);
}
function useDoRegistration(client, middleware) {
  return useApiMethod(client, doRegistration, middleware);
}
function useGetCurrenciesByCountry(client, middleware) {
  return useApiMethod(client, getCurrenciesByCountry, middleware);
}
function useGetIncompleteRegistrationFields(client, middleware) {
  return useApiMethod(client, getIncompleteRegistrationFields, middleware);
}
function useUpdateIncompleteRegistrationFields(client, middleware) {
  return useApiMethod(client, updateIncompleteRegistrationFields, middleware);
}
function useRemoveCustomerMessage(client, middleware) {
  return useApiMethod(client, removeCustomerMessage, middleware);
}
function useResendVerificationEmail(client, middleware) {
  return useApiMethod(client, resendVerificationEmail, middleware);
}
function useResetDisposableKeys(client, middleware) {
  return useApiMethod(client, resetDisposableKeys, middleware);
}
function useResetShown(client, middleware) {
  return useApiMethod(client, resetShown, middleware);
}
function useCheckRestorePasswordSms(client, middleware) {
  return useApiMethod(client, checkRestorePasswordSms, middleware);
}
function useCheckRestorePasswordUid(client, middleware) {
  return useApiMethod(client, checkRestorePasswordUid, middleware);
}
function useDoRestorePasswordByEmail(client, middleware) {
  return useApiMethod(client, doRestorePasswordByEmail, middleware);
}
function useDoRestorePasswordByPhone(client, middleware) {
  return useApiMethod(client, doRestorePasswordByPhone, middleware);
}
function useResetPinCodeEmail(client, middleware) {
  return useApiMethod(client, resetPinCodeEmail, middleware);
}
function useResetPinCodePhone(client, middleware) {
  return useApiMethod(client, resetPinCodePhone, middleware);
}
function useSendRestorePasswordLinkToEmail(client, middleware) {
  return useApiMethod(client, sendRestorePasswordLinkToEmail, middleware);
}
function useSendRestorePasswordSmsOrCallToPhone(client, middleware) {
  return useApiMethod(client, sendRestorePasswordSmsOrCallToPhone, middleware);
}
function useSendRestorePasswordSmsToPhone(client, middleware) {
  return useApiMethod(client, sendRestorePasswordSmsToPhone, middleware);
}
function useSendRestorePinLinkToEmail(client, middleware) {
  return useApiMethod(client, sendRestorePinLinkToEmail, middleware);
}
function useSendRestorePinLinkToPhone(client, middleware) {
  return useApiMethod(client, sendRestorePinLinkToPhone, middleware);
}
function useSavePushSettings(client, middleware) {
  return useApiMethod(client, savePushSettings, middleware);
}
function useGetSpintaxMetaData(client, middleware) {
  return useApiMethod(client, getSpintaxMetaData, middleware);
}
function useSetAdditionalPropsUser(client, middleware) {
  return useApiMethod(client, setAdditionalPropsUser, middleware);
}
function useSetBannerClosed(client, middleware) {
  return useApiMethod(client, setBannerClosed, middleware);
}
function useSetBetLimits(client, middleware) {
  return useApiMethod(client, setBetLimits, middleware);
}
function useSetCoookie(client, middleware) {
  return useApiMethod(client, setCoookie, middleware);
}
function useSetDepositLimits(client, middleware) {
  return useApiMethod(client, setDepositLimits, middleware);
}
function useSetDowntimeShown(client, middleware) {
  return useApiMethod(client, setDowntimeShown, middleware);
}
function useSetWhatsNew(client, middleware) {
  return useApiMethod(client, setWhatsNew, middleware);
}
function useGetTsupisLegalAgreementsVersions(client, middleware) {
  return useApiMethod(client, getTsupisLegalAgreementsVersions, middleware);
}
function useGetTsupisOfferAgreements(client, middleware) {
  return useApiMethod(client, getTsupisOfferAgreements, middleware);
}
function useGetTsupisPersonalDataAgreements(client, middleware) {
  return useApiMethod(client, getTsupisPersonalDataAgreements, middleware);
}
function useUpdateCustomerProfileFields(client, middleware) {
  return useApiMethod(client, updateCustomerProfileFields, middleware);
}
function useGetVerificationReasons(client, middleware) {
  return useApiMethod(client, getVerificationReasons, middleware);
}
function useGetVerificationToken(client, middleware) {
  return useApiMethod(client, getVerificationToken, middleware);
}
function useMakeCustomerVerification(client, middleware) {
  return useApiMethod(client, makeCustomerVerification, middleware);
}
function useMakeVipFastTrackVerification(client, middleware) {
  return useApiMethod(client, makeVipFastTrackVerification, middleware);
}
function useVerifyEmail(client, middleware) {
  return useApiMethod(client, verifyEmail, middleware);
}
function useGetState(client, middleware) {
  return useApiMethod(client, getState, middleware);
}
function useWithdrawBonusCBC(client, middleware) {
  return useApiMethod(client, withdrawBonusCBC, middleware);
}
function unMockAll() {
  mocks = {};
}
const values = /* @__PURE__ */ new Set(/* @__PURE__ */ Object.values(InvalidPhoneExceptionCode));
function isInvalidPhoneExceptionCode(value) {
  return typeof value === "string" && values.has(value);
}
var RequestOptionsPriority = /* @__PURE__ */ ((RequestOptionsPriority2) => {
  RequestOptionsPriority2[RequestOptionsPriority2["HIGH"] = 100] = "HIGH";
  RequestOptionsPriority2[RequestOptionsPriority2["NORMAL"] = 0] = "NORMAL";
  RequestOptionsPriority2[RequestOptionsPriority2["LOW"] = -100] = "LOW";
  return RequestOptionsPriority2;
})(RequestOptionsPriority || {});
export {
  AbstractLobbyItemFieldsFragmentDoc,
  AccessDeniedRemoteApiExceptionCode,
  AccountHistoryBetType,
  AccountHistoryBonusCursorStatus,
  AccountHistoryCloseReason,
  AccountHistoryEgsSessionFieldsFragmentDoc,
  AccountHistoryMarketingFieldsFragmentDoc,
  AccountHistoryPaymentFieldsFragmentDoc,
  AccountHistorySource,
  AccountHistorySportbookFieldsFragmentDoc,
  AccountHistoryStatus,
  AccountHistoryType,
  AffiliatePartnerStatus,
  AlcrNotificationFieldsFragmentDoc,
  AllAccountHistoryOutcome,
  AllTransactionHistoryTransactionType,
  AppflowUpdateMethod,
  AvailableBonusType,
  BannerAnimation,
  BannerContentFieldsFragmentDoc,
  BannerDataFragmentDoc,
  BannerSportEventFragmentDoc,
  BannerType,
  BetLine,
  BetType,
  BonusActivationNotificationFieldsFragmentDoc,
  BonusCampaignType,
  BonusCategory,
  BonusCodeType,
  BonusGameRewardType,
  BonusState,
  BonusStep,
  BonusesDataType,
  CampaignState,
  CaptchaMode,
  CaptchaStatus,
  CaptchaType,
  CashOutReason,
  CashOutStatus,
  CashbackConfirmationState,
  CategoryType,
  ChannelDataFieldsFragmentDoc,
  ContentPlace,
  CouponType,
  CryptoDepositStatusNotificationFieldsFragmentDoc,
  CustomerBetType,
  CustomerBonusState,
  CustomerConfig,
  CustomerDataFieldsFragmentDoc,
  CustomerDataPhoneFieldsFragmentDoc,
  CustomerFieldType,
  CustomerHistoryFilterType,
  CustomerIdentificationStage,
  CustomerIdentificationStatus,
  CustomerLimitsExceptionCode,
  CustomerLimitsRange,
  CustomerRegistrationType,
  CustomerVerificationStatus,
  CustomerVerificationType,
  DeleteCardExceptionCode,
  DepositNotificationFieldsFragmentDoc,
  DepositOperationType,
  DisplayType,
  DocumentType,
  EgsAvailabilityStatus,
  EgsBlockType,
  EgsCustomerBonusFragmentDoc,
  EgsGameCategoriesFieldsFragmentDoc,
  EgsGameDetailsFieldsFragmentDoc,
  EgsGameFieldsFragmentDoc,
  EgsGameType,
  EgsGroupFieldsFragmentDoc,
  EgsImagesFieldsFragmentDoc,
  EgsLimitsFieldsFragmentDoc,
  EgsSkeletonConfigFieldsFragmentDoc,
  EgsTopWinnerFieldsFragmentDoc,
  EgsTopWinnersFilter,
  EgsTopWinnersGameType,
  ErrorHandlerType,
  EsiaIdentificationType,
  EtsupisApiType,
  EventTimerMode,
  FeatureVisibility,
  FeedbackChannel,
  FomoBonusCreatedFieldsFragmentDoc,
  FormType,
  FrontNotificationFieldsFragmentDoc,
  FrontNotificationType,
  G2SVRequiredExceptionCode,
  GambleStatus,
  GameOpenMethod,
  GameUnavailableExceptionCode,
  HelpButtonMode,
  HelpChatType,
  HistoryFilter,
  HomeAway,
  HomePageType,
  HttpMethod,
  IdentStateFieldsFragmentDoc,
  IdentificationDocumentType,
  IdentificationLevel,
  IdentificationProvider,
  IgnoredErrorType,
  IllegalMutationsExceptionCode,
  IncorrectCustomerType,
  InlineChatAnimation,
  InlineChatHideCondition,
  InvalidBatchGraphQLRequestBodyIdExceptionCode,
  InvalidBusinessHeaderValueExceptionCode,
  InvalidCodeExceptionCode,
  InvalidGraphqlRequestsCountExceptionCode,
  InvalidPhoneExceptionCode,
  InvalidRequestsCountExceptionCode,
  InvalidTsExceptionCode,
  InvalidUpdatedFromExceptionCode,
  JurisdictionNotSupportedActionExceptionCode,
  LeagueStandingFieldsFragmentDoc,
  LeagueStatisticsTeamFieldsFragmentDoc,
  LobbyAllTopWinnersItemFieldsFragmentDoc,
  LobbyBannerItemFieldsFragmentDoc,
  LobbyGameType,
  LobbyGamesCategoryItemFieldsFragmentDoc,
  LobbyGridType,
  LobbyGroupsItemFieldsFragmentDoc,
  LobbyItemType,
  LobbyItemWidget,
  LobbyPromotionsItemFieldsFragmentDoc,
  LobbySportlineTopMatchesItemFieldsFragmentDoc,
  LobbyTitleType,
  LobbyTopWinnersItemFieldsFragmentDoc,
  LobbyType,
  LogLevel,
  LoginType,
  ManualStatus,
  MarketStatus,
  MatchPhase,
  MatchStatisticsMatchFieldsFragmentDoc,
  MatchStatisticsPlayerFieldsFragmentDoc,
  MatchStatisticsTeamFieldsFragmentDoc,
  MatchStreamAvailabilityType,
  MetricsToolType,
  NavigationSection,
  NoCashbackExceptionCode,
  NoRegisterStrategyExceptionCode,
  NoSchemaExceptionCode,
  NoSuchContentExceptionCode,
  NoSuchHeaderExceptionCode,
  NoSuchTranslationExceptionCode,
  NoValidationSchemaFoundExceptionCode,
  OpenIn,
  OperationStatus,
  OutcomeState,
  PackListState,
  PackReward,
  PackState,
  PaginationCursorItemFieldsFragmentDoc,
  ParticipationType,
  PathType,
  PaymentExceptionCode,
  PaymentType,
  PersonalDomainType,
  PhoneCodeMethod,
  PhoneIdentificationRequired,
  PinCodePlace,
  PinCodeRequiredExceptionCode,
  PinCodeState,
  PreviousRequestHasNotExpiredExceptionCode,
  PriceChangePolicy,
  ProgramParticipationStatus,
  ProgramRequirementType,
  ProgramRewardType,
  PromoActionType,
  PromotionActionButtonState,
  PromotionActionButtonType,
  PushNotificationProvider,
  PwaCookieFixMode,
  RateLimiterExceptionCode,
  Rating,
  RecaptchaTheme,
  RefProgramNotificationFieldsFragmentDoc,
  RefProgramNotificationType,
  RefuseBonusType,
  RegistrationStateExceptionCode,
  RemoteApiErrorExceptionCode,
  RequestHashingDisabledExceptionCode,
  RequestHeader,
  RequestOptionsPriority,
  RequirementType,
  RequirementValueType,
  ReserveMode,
  RoundOverviewFieldsFragmentDoc,
  SchemaValidationExceptionCode,
  Scores24ScorerFieldsFragmentDoc,
  Scores24TeamFieldsFragmentDoc,
  SelfExclusionTimeUnitType,
  SessionExceptionCode,
  ShopOrderStatus,
  SimpleIdentMethod,
  SlipInfoFieldsFragmentDoc,
  SlipStatus,
  SlipTypeId,
  SportBetStatus,
  SportBonusState,
  SportCustomerBonusFragmentDoc,
  SportFamily,
  SportFilterKickoffPeriod,
  SportFreebetState,
  SportWagerResult,
  SportbookDataType,
  SportsbookUIVersion,
  StartCodeType,
  Status,
  StatusColor,
  SupportType,
  Theme,
  ThirdPartyErrorCode,
  TimerMode,
  TopWinnersTabType,
  TradingStatus,
  TransactionGroup,
  TransactionStatusColor,
  TransferType,
  Tsupis1BindCustomerError,
  TsupisAuthResult,
  TsupisBindingRequestStatus,
  TsupisProviderType,
  UiAccountType,
  UnknownCardTokenExceptionCode,
  UnknownRequestHashExceptionCode,
  UnsupportedBannerExceptionCode,
  VerificationTokenFieldsFragmentDoc,
  VideoNotificationFieldsFragmentDoc,
  VideoVerificationStatus,
  VirtualSportTournament,
  VpnProvider,
  Web2FontFamily,
  Web2ImageFieldsFragmentDoc,
  Web2MoneyFieldsFragmentDoc,
  WidgetType,
  ZetaDcpConfigType,
  accessDeniedRemoteApiExceptionCodeAccessDenied,
  accountHistoryBetTypeExpress,
  accountHistoryBetTypeSingle,
  accountHistoryBetTypeSystem,
  accountHistoryBonusCursorStatusActivated,
  accountHistoryBonusCursorStatusCreated,
  accountHistoryBonusCursorStatusResulted,
  accountHistoryCloseReasonError,
  accountHistoryCloseReasonExpired,
  accountHistoryCloseReasonFreespinLost,
  accountHistoryCloseReasonNoBalance,
  accountHistoryCloseReasonRefused,
  accountHistoryCloseReasonSuccessful,
  accountHistoryDataGroupEgs,
  accountHistoryDataGroupFastBet,
  accountHistoryDataGroupMarketing,
  accountHistoryDataGroupPayment,
  accountHistoryDataGroupSportsbook,
  accountHistoryDataGroupVsport,
  accountHistoryFeatureEgs,
  accountHistoryFeatureFastBet,
  accountHistoryFeatureLeonshop,
  accountHistoryFeatureSport,
  accountHistoryFeatureVsport,
  accountHistorySourceCustomer,
  accountHistorySourceEgs,
  accountHistorySourceExternalSystem,
  accountHistorySourceStaff,
  accountHistorySourceSystem,
  accountHistoryStatusBetCalculated,
  accountHistoryStatusBetCalculatedCashout,
  accountHistoryStatusBetCalculatedChecked,
  accountHistoryStatusBetPending,
  accountHistoryStatusBetWaiting,
  accountHistoryStatusExgPerformed,
  accountHistoryStatusLeonshopOrderCancelled,
  accountHistoryStatusLeonshopOrderCreated,
  accountHistoryStatusLeonshopOrderDone,
  accountHistoryStatusLeonshopOrderInProgress,
  accountHistoryStatusMarketingActive,
  accountHistoryStatusMarketingClosed,
  accountHistoryStatusMarketingCreated,
  accountHistoryStatusMarketingPendingActivation,
  accountHistoryStatusMgcLost,
  accountHistoryStatusMgcRefund,
  accountHistoryStatusMgcWon,
  accountHistoryStatusMgcWon2,
  accountHistoryStatusPayBadExecuted,
  accountHistoryStatusPayCancelled,
  accountHistoryStatusPayExecuted,
  accountHistoryStatusPayProcessing,
  accountHistoryStatusPayRegulatorCancelled,
  accountHistoryStatusPayRegulatorRollback,
  accountHistoryStatusPayRollback,
  accountHistoryStatusPayStarted,
  accountHistoryStatusPayWaiting,
  accountHistoryStatusUnknown,
  accountHistoryTypeCorrection,
  accountHistoryTypeCredit,
  accountHistoryTypeDebit,
  accountHistoryTypeEncash,
  accountHistoryTypeLeonshop,
  accountHistoryTypeLoyaltyProgram,
  accountHistoryTypeReset,
  accountTypeBetting,
  accountTypeCasino,
  accountTypeLocked,
  accountTypeMain,
  achievementRewardStatusAvailable,
  achievementRewardStatusExpired,
  achievementRewardStatusNotAvailable,
  achievementRewardStatusReceived,
  achievementRewardStatusRejected,
  achievementRewardStatusRequested,
  achievementRewardTypeAvatar,
  achievementRewardTypeBonus,
  achievementRewardTypeFreespin,
  addFavoriteGame,
  addFavoriteGameDocument,
  affiliatePartnerStatusClosed,
  affiliatePartnerStatusDisabled,
  affiliatePartnerStatusNo,
  affiliatePartnerStatusYes,
  allAccountHistoryOutcomeLose,
  allAccountHistoryOutcomeLoseHalf,
  allAccountHistoryOutcomeReturn,
  allAccountHistoryOutcomeUndefined,
  allAccountHistoryOutcomeWin,
  allAccountHistoryOutcomeWinHalf,
  allTransactionHistoryTransactionTypeBet,
  allTransactionHistoryTransactionTypeLose,
  allTransactionHistoryTransactionTypeRollbackBet,
  allTransactionHistoryTransactionTypeRollbackWin,
  allTransactionHistoryTransactionTypeWin,
  appflowUpdateMethodAuto,
  appflowUpdateMethodBackground,
  appflowUpdateMethodNone,
  availableBonusTypeBonusAmount,
  availableBonusTypeFreebet,
  availableBonusTypeFreespin,
  availableBonusTypeMaxBonusAmount,
  avatarStatusActive,
  avatarStatusAvailable,
  avatarStatusLocked,
  bannerAnimationMoveHorizontally,
  bannerTypeJackpot,
  bannerTypePromo,
  bannerTypeSport,
  bannerVersionV1,
  bannerVersionV2,
  bannerVersionV3,
  betLineLive,
  betLineOutright,
  betLinePrematch,
  betLineVsports,
  betTypeExpress,
  betTypeSingle,
  betTypeSystem,
  bonusAccountHistoryDataCategoryBonusPoints,
  bonusAccountHistoryDataCategoryEgs,
  bonusAccountHistoryDataCategorySport,
  bonusAccountHistoryDataTypeEgsBonusFailed,
  bonusAccountHistoryDataTypeEgsBonusResultTransferredToMainAccount,
  bonusAccountHistoryDataTypeEgsBonusToMainWalletActivated,
  bonusAccountHistoryDataTypeEgsBonusTransferredToBonusWallet,
  bonusAccountHistoryDataTypeEgsFreespin,
  bonusAccountHistoryDataTypeEgsFreespinResultTransferredToBonusWallet,
  bonusAccountHistoryDataTypeEgsProviderReward,
  bonusAccountHistoryDataTypeLoyaltyBonusCorrection,
  bonusAccountHistoryDataTypeLoyaltyBonusDeposit,
  bonusAccountHistoryDataTypeLoyaltyBonusWithdrawal,
  bonusAccountHistoryDataTypeSportBonusFailed,
  bonusAccountHistoryDataTypeSportBonusResultTransferredToMainAccount,
  bonusAccountHistoryDataTypeSportBonusTransferredToBonusWallet,
  bonusAccountHistoryDataTypeSportFreebet,
  bonusAccountHistoryDataTypeSportFreebetResultTransferredToBonusWallet,
  bonusAccountHistoryStatusActivated,
  bonusAccountHistoryStatusCompleted,
  bonusCampaignTypeEgs,
  bonusCampaignTypeSport,
  bonusCategoryAccumulative,
  bonusCategoryCbc,
  bonusCategoryFirstDeposit,
  bonusCategorySbc,
  bonusCategoryUnknown,
  bonusCodeTypeCampaign,
  bonusCodeTypeDeposit,
  bonusCodeTypeRegistration,
  bonusGameRewardTypeBonus,
  bonusGameRewardTypeEmpty,
  bonusGameRewardTypeFreebet,
  bonusGameRewardTypeFreespin,
  bonusGameRewardTypeMultiplier,
  bonusGameRewardTypeSuperPrize,
  bonusStateActive,
  bonusStateCancelled,
  bonusStateEmpty,
  bonusStateSuccessful,
  bonusStateSuccessfulWithActiveBonus,
  bonusStepFreebet,
  bonusStepFreespin,
  bonusStepRequirements,
  bonusStepTransfer,
  bonusStepWager,
  bonusesDataTypeEgsBonusFailed,
  bonusesDataTypeEgsBonusResultTransferredToMainAccount,
  bonusesDataTypeEgsBonusToMainWalletActivated,
  bonusesDataTypeEgsBonusTransferredToBonusWallet,
  bonusesDataTypeEgsFreespin,
  bonusesDataTypeEgsFreespinResultTransferredToBonusWallet,
  bonusesDataTypeEgsProviderReward,
  bonusesDataTypeLoyaltyBonus,
  bonusesDataTypeSportBonusFailed,
  bonusesDataTypeSportBonusResultTransferredToMainAccount,
  bonusesDataTypeSportBonusTransferredToBonusWallet,
  bonusesDataTypeSportFreebet,
  buttonActionClaimCashback,
  buttonActionClose,
  cAHEgsGameTypeAll,
  cAHEgsGameTypeBetgames,
  cAHEgsGameTypeFastgames,
  cAHEgsGameTypeLive,
  cAHEgsGameTypeSlots,
  campaignStateActive,
  campaignStateAvailable,
  campaignStateClosed,
  captchaModeEnforced,
  captchaModeOff,
  captchaModeOn,
  captchaStatusEnforced,
  captchaStatusOff,
  captchaStatusOn,
  captchaTypeAndroidRecaptcha,
  captchaTypeDistortedNumbers,
  captchaTypeInvisibleRecaptcha,
  captchaTypeRecaptcha,
  captchaTypeRecaptchaV3,
  cashOutReasonAmountChanged,
  cashOutReasonBetAlreadyCalculated,
  cashOutReasonBetMissing,
  cashOutReasonCustomerNotIdentified,
  cashOutReasonDisabled,
  cashOutReasonDone,
  cashOutReasonSessionError,
  cashOutReasonValidationError,
  cashOutReasonWaiting,
  cashOutStatusError,
  cashOutStatusOk,
  cashbackConfirmationStateConfirmed,
  cashbackConfirmationStateDeclined,
  cashbackConfirmationStateNotConfirmed,
  cashbackConfirmationStateRefused,
  categoryTypeAll,
  categoryTypeArchived,
  categoryTypeBonusWallet,
  categoryTypeComplex,
  categoryTypeCustom,
  categoryTypeCustomer,
  categoryTypeCustomerArchived,
  checkPushTokenExists,
  checkPushTokenExistsDocument,
  checkRestorePasswordSms,
  checkRestorePasswordSmsDocument,
  checkRestorePasswordUid,
  checkRestorePasswordUidDocument,
  clearCookies,
  clearCookiesDocument,
  confirmManualApplication,
  confirmManualApplicationDocument,
  contentPlaceBetlineEvent,
  contentPlaceBetlineLeague,
  contentPlaceBetlineRegion,
  contentPlaceBetlineSport,
  contentPlaceEgsGame,
  contentPlaceEgsLobby,
  contentPlacePromoaction,
  contentPlaceUnknown,
  contentPlaceVirtualSport,
  couponTypeBonusPack,
  couponTypeSportBet,
  customerBetTypeEgs,
  customerBetTypeSport,
  customerBetTypeUndefined,
  customerBonusStateActive,
  customerBonusStateAvailable,
  customerConfigBonusChooserShownBeforeDeposits,
  customerConfigCreatePinCodeModalCounter,
  customerConfigHideBalance,
  customerConfigHideCommissionHint,
  customerConfigHideEgsLoyaltyOnBoardingPage,
  customerConfigHideLimitModalOnGamePage,
  customerConfigHideNicknamePrompt,
  customerConfigHideNoSelectedBonusModal,
  customerConfigHideOnBoardingOnLeonShopPage,
  customerConfigHideOnBoardingPage,
  customerConfigIsAchievementsMainOnboardingShown,
  customerConfigIsAsoMainOnboardingShown,
  customerConfigIsEkycPopupOnFirstLoginShown,
  customerConfigIsFortuneWheelWindowShown,
  customerConfigIsHiddenNickname,
  customerConfigIsLoyaltyProgramShown,
  customerConfigIsOddsTypeAmerican,
  customerConfigIsOddsTypeClassic,
  customerConfigIsPinCodeRequestedOnLogin,
  customerConfigIsPrivateProfile,
  customerConfigIsReferralProgramNotificationClicked,
  customerConfigIsReferralProgramOnboardingShown,
  customerConfigIsResponsibleGamblingOnboardingShown,
  customerConfigIsVideoVerificationOnboardingShown,
  customerFieldTypeAcceptedOffer,
  customerFieldTypeAcceptedPda,
  customerFieldTypeAddress,
  customerFieldTypeBirthDate,
  customerFieldTypeBonusCode,
  customerFieldTypeCity,
  customerFieldTypeCountrySelect,
  customerFieldTypeCpf,
  customerFieldTypeCurrencySelect,
  customerFieldTypeDailyDepositLimit,
  customerFieldTypeDocumentCountrySelect,
  customerFieldTypeDocumentIssueAgency,
  customerFieldTypeDocumentIssueDate,
  customerFieldTypeDocumentNumber,
  customerFieldTypeDocumentTypeSelect,
  customerFieldTypeEmail,
  customerFieldTypeFirstName,
  customerFieldTypeLastName,
  customerFieldTypeMiddleName,
  customerFieldTypeNickname,
  customerFieldTypeOldPassword,
  customerFieldTypeOptionalInstant,
  customerFieldTypePassword,
  customerFieldTypePersonalNumber,
  customerFieldTypePhoneInput,
  customerFieldTypePromoCode,
  customerFieldTypeRegistrationCode,
  customerFieldTypeSubscribe,
  customerFieldTypeTermsCheckbox,
  customerHistoryFilterTypeDay,
  customerHistoryFilterTypeHour,
  customerHistoryFilterTypeMonth,
  customerIdentificationStageAlreadyIdentified,
  customerIdentificationStageExistingIdentBindingPending,
  customerIdentificationStageFormIsSubmitted,
  customerIdentificationStageFormNotInitialized,
  customerIdentificationStageFormNotSubmitted,
  customerIdentificationStagePhoneNotVerified,
  customerIdentificationStatusApprovedBySupport,
  customerIdentificationStatusApprovedByTsupis,
  customerIdentificationStatusCanGamble,
  customerIdentificationStatusCannotGamble,
  customerIdentificationStatusComplete,
  customerIdentificationStatusDocumentsRequested,
  customerIdentificationStatusNew,
  customerIdentificationStatusRejectedBySupport,
  customerIdentificationStatusRejectedByTsupis,
  customerIdentificationStatusSuspended,
  customerIdentificationStatusWaitingCall,
  customerIdentityCheck,
  customerIdentityCheckDocument,
  customerLevelBenefitValueTypeBoolean,
  customerLevelBenefitValueTypePercent,
  customerLevelRewardStateAvailable,
  customerLevelRewardStateNotAvailable,
  customerLevelRewardStateReceived,
  customerLevelRewardTypeCashback,
  customerLevelRewardTypeFreebet,
  customerLimitsExceptionCodeCustomerLimitsError,
  customerLimitsRangeDaily,
  customerLimitsRangeMonthly,
  customerLimitsRangeWeekly,
  customerRegistrationTypeFull,
  customerRegistrationTypeFullNoPhoneCheck,
  customerRegistrationTypeFullSmsConfirmation,
  customerRegistrationTypeSimpleEmail,
  customerRegistrationTypeSimpleNoPhoneCheck,
  customerRegistrationTypeSimplePhone,
  customerRegistrationTypeSimpleRegApi,
  customerRegistrationTypeSimpleRegApiV2,
  customerRegistrationTypeSimpleSocial,
  customerRegistrationTypeSlottNoPhoneCheck,
  customerRegistrationTypeSlottPhone,
  customerRegistrationTypeUnknown,
  customerVerificationStatusFailed,
  customerVerificationStatusInProcess,
  customerVerificationStatusNone,
  customerVerificationStatusRetry,
  customerVerificationStatusStarted,
  customerVerificationStatusSuccess,
  customerVerificationTypeCustomerVerificationService,
  customerVerificationTypeInternal,
  customerVerificationTypeVideoVerificationService,
  deleteCardExceptionCodeDeleteCard,
  deleteDocuments,
  deleteDocumentsDocument,
  depositOperationTypeDeposit,
  depositOperationTypeDepositCancel,
  depositStatusDepositPending,
  depositStatusDepositSuccess,
  depositStatusNeedsDeposit,
  depositStatusUnavailable,
  disableCustomerMode,
  disableCustomerModeDocument,
  displayTypePush,
  displayTypeSilent,
  displayTypeSnackbar,
  doAchievementMarkAsViewed,
  doAchievementMarkAsViewedDocument,
  doAchievementModifyAvatar,
  doAchievementModifyAvatarDocument,
  doAchievementReward,
  doAchievementRewardDocument,
  doActivateAffiliateProgram,
  doActivateAffiliateProgramDocument,
  doActivateBonusCode,
  doActivateBonusCodeDocument,
  doAddLoginDevice,
  doAddLoginDeviceDocument,
  doAppsFlyerQualifiedDepositsReported,
  doAppsFlyerQualifiedDepositsReportedDocument,
  doButtonMutation,
  doButtonMutationDocument,
  doCalculatePack,
  doCalculatePackDocument,
  doCalculateRound,
  doCalculateRoundDocument,
  doCancelRequestedWithdrawals,
  doCancelRequestedWithdrawalsDocument,
  doCashOut,
  doCashOutDocument,
  doChangeCustomerNickname,
  doChangeCustomerNicknameDocument,
  doChangeCustomerSettings,
  doChangeCustomerSettingsDocument,
  doChangeLanguage,
  doChangeLanguageDocument,
  doChangePassword,
  doChangePasswordDocument,
  doCollectPackReward,
  doCollectPackRewardDocument,
  doCollectReward,
  doCollectRewardDocument,
  doConfirmBonus,
  doConfirmBonusDocument,
  doConfirmCashback,
  doConfirmCashbackDocument,
  doConfirmParticipation,
  doConfirmParticipationDocument,
  doConfirmations,
  doConfirmationsDocument,
  doCreateBonusCode,
  doCreateBonusCodeDocument,
  doCreatePinCode,
  doCreatePinCodeDocument,
  doDeactivateTimer,
  doDeactivateTimerDocument,
  doDeleteAccount,
  doDeleteAccountDocument,
  doDeleteNotification,
  doDeleteNotificationDocument,
  doDeletePinCode,
  doDeletePinCodeDocument,
  doEncashBonus,
  doEncashBonusDocument,
  doEventSubscribe,
  doEventSubscribeDocument,
  doEventUnsubscribe,
  doEventUnsubscribeDocument,
  doFavoriteSportEventRemove,
  doFavoriteSportEventRemoveDocument,
  doFavoriteSportEventsAdd,
  doFavoriteSportEventsAddDocument,
  doLogin,
  doLoginCas,
  doLoginCasDocument,
  doLoginDocument,
  doLoginFingerprint,
  doLoginFingerprintDocument,
  doLoginG2sv,
  doLoginG2svDocument,
  doLoginPinCode,
  doLoginPinCodeDocument,
  doMarkAsClicked,
  doMarkAsClickedDocument,
  doMarkAsDelivered,
  doMarkAsDeliveredDocument,
  doMarkAsShownMutation,
  doMarkAsShownMutationDocument,
  doMarkDeviceUntrusted,
  doMarkDeviceUntrustedDocument,
  doMarkNotification,
  doMarkNotificationDocument,
  doModifyTimeZone,
  doModifyTimeZoneDocument,
  doPlayTimeControl,
  doPlayTimeControlDocument,
  doPostponeWebPushToken,
  doPostponeWebPushTokenDocument,
  doPromotionParticipation,
  doPromotionParticipationDocument,
  doPromotionRate,
  doPromotionRateDocument,
  doReadCustomerNotification,
  doReadCustomerNotificationDocument,
  doRefuseBonus,
  doRefuseBonusDocument,
  doRefuseCashback,
  doRefuseCashbackDocument,
  doRefuseMeeting,
  doRefuseMeetingDocument,
  doRegisterCustomerOrder,
  doRegisterCustomerOrderDocument,
  doRegisterPushToken,
  doRegisterPushTokenDocument,
  doRegistration,
  doRegistrationDocument,
  doRequestCallback,
  doRequestCallbackDocument,
  doRestorePasswordByEmail,
  doRestorePasswordByEmailDocument,
  doRestorePasswordByPhone,
  doRestorePasswordByPhoneDocument,
  doRewarding,
  doRewardingDocument,
  doSaveBetSlip,
  doSaveBetSlipByBetId,
  doSaveBetSlipByBetIdDocument,
  doSaveBetSlipDocument,
  doSaveFastBets,
  doSaveFastBetsDocument,
  doSaveLog,
  doSaveLogDocument,
  doSavePriceChangePolicyV2,
  doSavePriceChangePolicyV2Document,
  doSaveShowMaxFastBet,
  doSaveShowMaxFastBetDocument,
  doSaveSportFilters,
  doSaveSportFiltersDocument,
  doScheduleMeeting,
  doScheduleMeetingDocument,
  doSelfExclusion,
  doSelfExclusionDocument,
  doSelfExclusionFlex,
  doSelfExclusionFlexDocument,
  doSendFeedback,
  doSendFeedbackDocument,
  doSendWeb2Feedback,
  doSendWeb2FeedbackByCategory,
  doSendWeb2FeedbackByCategoryDocument,
  doSendWeb2FeedbackDocument,
  doSubmitDeposit,
  doSubmitDepositDocument,
  doSubmitWithdrawal,
  doSubmitWithdrawalDocument,
  doToggleCustomerLeague,
  doToggleCustomerLeagueDocument,
  doTsupisCardDelete,
  doTsupisCardDeleteDocument,
  doUpdateCustomerData,
  doUpdateCustomerDataDocument,
  doUpdateNotificationConsents,
  doUpdateNotificationConsentsDocument,
  doVerifyPinCode,
  doVerifyPinCodeDocument,
  doWithdrawAllSportBonuses,
  doWithdrawAllSportBonusesDocument,
  doWithdrawSportBonus,
  doWithdrawSportBonusDocument,
  doWithdrawalCancel,
  doWithdrawalCancelDocument,
  documentTypeForeignersPassport,
  documentTypePassport,
  documentTypeRefugeeCertificate,
  documentTypeResidencePermit,
  dropManualData,
  dropManualDataDocument,
  egsAvailabilityStatusAvailable,
  egsAvailabilityStatusBetBehind,
  egsAvailabilityStatusNotAvailable,
  egsBlockTypeGeo,
  egsBlockTypeUnknown,
  egsGameTypeAll,
  egsGameTypeFastgames,
  egsGameTypeJackpots,
  egsGameTypeLive,
  egsGameTypeSlots,
  egsTopWinnersFilterAllTime,
  egsTopWinnersFilterDay,
  egsTopWinnersFilterMonth,
  egsTopWinnersFilterWeek,
  egsTopWinnersGameTypeAll,
  egsTopWinnersGameTypeBetgames,
  egsTopWinnersGameTypeLive,
  egsTopWinnersGameTypeSlots,
  egsTopWinnersGameTypeSportgames,
  enableCustomerMode,
  enableCustomerModeDocument,
  enableFingerprintLogin,
  enableFingerprintLoginDocument,
  errorHandlerTypeSentry,
  esiaDataFetch,
  esiaDataFetchDocument,
  esiaIdentificationTypeEtsupis,
  esiaIdentificationTypeNative,
  esiaRedirect,
  esiaRedirectDocument,
  etsupisApiTypeBapi,
  etsupisApiTypeIapi,
  etsupisPhoneVerificationInit,
  etsupisPhoneVerificationInitDocument,
  etsupisPhoneVerificationVerify,
  etsupisPhoneVerificationVerifyDocument,
  eventTimerModeLive,
  eventTimerModeStatic,
  featureVisibilityAnonymous,
  featureVisibilityBoth,
  featureVisibilityRegistered,
  featureVisibilityVip,
  feedbackChannelDefault,
  feedbackChannelPromoaction,
  feedbackChannelSelfExclusion,
  finishApplication,
  finishApplicationDocument,
  formTypeBoolean,
  formTypeObject,
  formTypeString,
  frontNotificationTypeAlcrNotification,
  frontNotificationTypeBonusCashback,
  frontNotificationTypeBonusExpiredCashback,
  frontNotificationTypeBonusGameLastRoundFeedback,
  frontNotificationTypeBonusGameReward,
  frontNotificationTypeBsDeposit,
  frontNotificationTypeCbcBonusActivated,
  frontNotificationTypeCryptoDepositStatus,
  frontNotificationTypeFomoBonusCreated,
  frontNotificationTypeOnSite,
  frontNotificationTypePlayTimeEnded,
  frontNotificationTypeRefprg,
  frontNotificationTypeResumedCashback,
  frontNotificationTypeSuspendedCashback,
  frontNotificationTypeUnknown,
  frontNotificationTypeVideoVerification,
  g2SVRequiredExceptionCodeG2svRequired,
  gambleStatusApprovedByRegulator,
  gambleStatusApprovedByStaff,
  gambleStatusCanGamble,
  gambleStatusDeclinedByRegulator,
  gambleStatusNone,
  gambleStatusTemporaryBlocked,
  gameOpenMethodIframe,
  gameOpenMethodWindow,
  gameUnavailableExceptionCodeGameUnavailable,
  generateNickname,
  generateNicknameDocument,
  getAccountTransaction,
  getAccountTransactionDocument,
  getAchievement,
  getAchievementDocument,
  getAchievements,
  getAchievementsAvailability,
  getAchievementsAvailabilityDocument,
  getAchievementsAvatarsAndBackgrounds,
  getAchievementsAvatarsAndBackgroundsDocument,
  getAchievementsCategories,
  getAchievementsCategoriesDocument,
  getAchievementsDocument,
  getAchievementsProfile,
  getAchievementsProfileDocument,
  getActiveOrAvailableCampaigns,
  getActiveOrAvailableCampaignsDocument,
  getAffiliatedCustomers,
  getAffiliatedCustomersDocument,
  getAllAccountHistory,
  getAllAccountHistoryDataGroups,
  getAllAccountHistoryDataGroupsDocument,
  getAllAccountHistoryDocument,
  getAllPacks,
  getAllPacksDocument,
  getApiSettings,
  getApiSettingsDocument,
  getAuthenticatorUri,
  getAuthenticatorUriDocument,
  getAuthenticatorUriQRCode,
  getAuthenticatorUriQRCodeDocument,
  getAvailability,
  getAvailabilityDocument,
  getAvailableBonusesForTransfer,
  getAvailableBonusesForTransferDocument,
  getAvailableForms,
  getAvailableFormsDocument,
  getAvailableMeetingSlots,
  getAvailableMeetingSlotsDocument,
  getBalance,
  getBalanceDocument,
  getBanner,
  getBannerDocument,
  getBanners,
  getBannersDocument,
  getBatchSlipInfo,
  getBatchSlipInfoDocument,
  getBenefitCustomerLevel,
  getBenefitCustomerLevelDocument,
  getBet,
  getBetDocument,
  getBetLimits,
  getBetLimitsDocument,
  getBetSlip,
  getBetSlipDocument,
  getBetgeniusWidgetConfiguration,
  getBetgeniusWidgetConfigurationDocument,
  getBetlineLeagueStandings,
  getBetlineLeagueStandingsDocument,
  getBetlineLeagueStatus,
  getBetlineLeagueStatusDocument,
  getBetlineLeagueTopPlayers,
  getBetlineLeagueTopPlayersDocument,
  getBetlineMatchEventStatistics,
  getBetlineMatchEventStatisticsDocument,
  getBetlineMatchStatistics,
  getBetlineMatchStatisticsDocument,
  getBonusCodes,
  getBonusCodesDocument,
  getBonusLoyalty,
  getBonusLoyaltyDocument,
  getBonusMatrix,
  getBonusMatrixDocument,
  getBonusTransferAmount,
  getBonusTransferAmountDocument,
  getBonusWagers,
  getBonusWagersDocument,
  getBonusWalletPromotion,
  getBonusWalletPromotionDocument,
  getBonuses,
  getBonusesDocument,
  getCampaignCoupons,
  getCampaignCouponsDocument,
  getCashback,
  getCashbackDocument,
  getCashoutOffer,
  getCashoutOfferDocument,
  getCasinoLoyaltyStatus,
  getCasinoLoyaltyStatusDocument,
  getCmsContentSpintaxBatchTranslations,
  getCmsContentSpintaxBatchTranslationsDocument,
  getCmsContentTranslations,
  getCmsContentTranslationsDocument,
  getCompanyFee,
  getCompanyFeeDocument,
  getConsents,
  getConsentsDocument,
  getCookie,
  getCookieDocument,
  getCryptoNetworkInfo,
  getCryptoNetworkInfoDocument,
  getCurrenciesByCountry,
  getCurrenciesByCountryDocument,
  getCustomerBonuses,
  getCustomerBonusesDocument,
  getCustomerHistory,
  getCustomerHistoryDocument,
  getCustomerHistoryItem,
  getCustomerHistoryItemDocument,
  getCustomerHistoryList,
  getCustomerHistoryListDocument,
  getCustomerLeague,
  getCustomerLeagueDocument,
  getCustomerLeagues,
  getCustomerLeaguesDocument,
  getCustomerMatchStreamEvents,
  getCustomerMatchStreamEventsDocument,
  getCustomerMatches,
  getCustomerMatchesDocument,
  getCustomerNotifications,
  getCustomerNotificationsCount,
  getCustomerNotificationsCountDocument,
  getCustomerNotificationsDocument,
  getCustomerOffers,
  getCustomerOffersDocument,
  getCustomerOrders,
  getCustomerOrdersDocument,
  getCustomerProfileFields,
  getCustomerProfileFieldsDocument,
  getCustomerStreams,
  getCustomerStreamsDocument,
  getCustomerVerifyData,
  getCustomerVerifyDataDocument,
  getDepositLimits,
  getDepositLimitsDocument,
  getDepositSystemsList,
  getDepositSystemsListDocument,
  getDepositSystemsOverviewList,
  getDepositSystemsOverviewListDocument,
  getDepositsRegistrationCompletionStatus,
  getDepositsRegistrationCompletionStatusDocument,
  getDepositsSystemInfo,
  getDepositsSystemInfoDocument,
  getDetails,
  getDetailsDocument,
  getEgsCategories,
  getEgsCategoriesDocument,
  getEgsChannels,
  getEgsChannelsDocument,
  getEgsGameById,
  getEgsGameByIdDocument,
  getEgsGameByUrl,
  getEgsGameByUrlDocument,
  getEgsGameTypes,
  getEgsGameTypesDocument,
  getEgsGames,
  getEgsGamesDocument,
  getEgsGroups,
  getEgsGroupsDocument,
  getEgsRounds,
  getEgsRoundsCursor,
  getEgsRoundsCursorDocument,
  getEgsRoundsDocument,
  getEgsSessions,
  getEgsSessionsDocument,
  getEgsTopWinners,
  getEgsTopWinnersDocument,
  getEgsTransactions,
  getEgsTransactionsDocument,
  getEncashBonusData,
  getEncashBonusDataDocument,
  getEsiaAouth2Link,
  getEsiaAouth2LinkDocument,
  getFAQCategories,
  getFAQCategoriesDocument,
  getFAQItems,
  getFAQItemsDocument,
  getFeedbackTypes,
  getFeedbackTypesDocument,
  getFormSchema,
  getFormSchemaDocument,
  getFreebetData,
  getFreebetDataDocument,
  getGeneralStats,
  getGeneralStatsDocument,
  getIdentificationState,
  getIdentificationStateDocument,
  getIncompleteRegistrationFields,
  getIncompleteRegistrationFieldsDocument,
  getInlineChats,
  getInlineChatsDocument,
  getIntercomUserHash,
  getIntercomUserHashDocument,
  getLSportsWidgetConfiguration,
  getLSportsWidgetConfigurationDocument,
  getLastBetResult,
  getLastBetResultDocument,
  getLeaderBoard,
  getLeaderBoardDocument,
  getLeagueData,
  getLeagueDataDocument,
  getLeaguePlayoffByLeagueId,
  getLeaguePlayoffByLeagueIdDocument,
  getLeagueStandingsByLeagueId,
  getLeagueStandingsByLeagueIdDocument,
  getLeagueStatisticsByLeagueId,
  getLeagueStatisticsByLeagueIdDocument,
  getLexGuardSession,
  getLexGuardSessionDocument,
  getLinkedPromotionsByEgsGame,
  getLinkedPromotionsByEgsGameDocument,
  getLiveChatSettings,
  getLiveChatSettingsDocument,
  getLiveChatStatus,
  getLiveChatStatusDocument,
  getLobby,
  getLobbyDocument,
  getMarketingBonusCursor,
  getMarketingBonusCursorDocument,
  getMarketingBonuses,
  getMarketingBonusesDocument,
  getMarketingDataCategories,
  getMarketingDataCategoriesDocument,
  getMarketingStatuses,
  getMarketingStatusesDocument,
  getMatchStreamUrl,
  getMatchStreamUrlDocument,
  getNavigationConfig,
  getNavigationConfigDocument,
  getNearbyCities,
  getNearbyCitiesDocument,
  getNotifications,
  getNotificationsDocument,
  getOnboarding,
  getOnboardingDocument,
  getPacks,
  getPacksDocument,
  getPartners,
  getPartnersDocument,
  getPayments,
  getPaymentsCursor,
  getPaymentsCursorDocument,
  getPaymentsDataCategories,
  getPaymentsDataCategoriesDocument,
  getPaymentsDocument,
  getPaymentsStatuses,
  getPaymentsStatusesDocument,
  getPendingBets,
  getPendingBetsDocument,
  getPinCodeStatus,
  getPinCodeStatusDocument,
  getPopularQueries,
  getPopularQueriesDocument,
  getPromoJackpots,
  getPromoJackpotsDocument,
  getPromotionCategories,
  getPromotionCategoriesDocument,
  getPromotionDetails,
  getPromotionDetailsDocument,
  getPromotionNavigationConfig,
  getPromotionNavigationConfigDocument,
  getPromotions,
  getPromotionsByEgsGame,
  getPromotionsByEgsGameDocument,
  getPromotionsDocument,
  getPushSettings,
  getPushSettingsDocument,
  getReferral,
  getReferralDocument,
  getRewards,
  getRewardsDocument,
  getRootSettings,
  getRootSettingsDocument,
  getRootSettingsInternational,
  getRootSettingsInternationalDocument,
  getRounds,
  getRoundsDocument,
  getRoutingConfigs,
  getRoutingConfigsDocument,
  getRules,
  getRulesDocument,
  getSearchData,
  getSearchDataDocument,
  getSelfExclusion,
  getSelfExclusionDocument,
  getServiceWorkerConfiguration,
  getServiceWorkerConfigurationDocument,
  getSiteConfigSettings,
  getSiteConfigSettingsDocument,
  getSpintaxMetaData,
  getSpintaxMetaDataDocument,
  getSportradarLiveMatchTrackerConfiguration,
  getSportradarLiveMatchTrackerConfigurationDocument,
  getSportradarLiveScoreConfiguration,
  getSportradarLiveScoreConfigurationDocument,
  getSportradarStatisticsConfiguration,
  getSportradarStatisticsConfigurationDocument,
  getSportsbookBets,
  getSportsbookBetsDocument,
  getSportsbookSports,
  getSportsbookSportsDocument,
  getState,
  getStateDocument,
  getSubscribedEvents,
  getSubscribedEventsDocument,
  getTempCoupons,
  getTempCouponsDocument,
  getThirdPartyIdentificationInit,
  getThirdPartyIdentificationInitDocument,
  getTranslations,
  getTranslationsDocument,
  getTsupisLegalAgreementsVersions,
  getTsupisLegalAgreementsVersionsDocument,
  getTsupisOfferAgreements,
  getTsupisOfferAgreementsDocument,
  getTsupisPersonalDataAgreements,
  getTsupisPersonalDataAgreementsDocument,
  getUpcomingMeeting,
  getUpcomingMeetingDocument,
  getUserData,
  getUserDataDocument,
  getUserDataPhone,
  getUserDataPhoneDocument,
  getUserProfileBonus,
  getUserProfileBonusDocument,
  getVSportBets,
  getVSportBetsDocument,
  getVSportSports,
  getVSportSportsDocument,
  getVerificationReasons,
  getVerificationReasonsDocument,
  getVerificationToken,
  getVerificationTokenDocument,
  getVpnConfig,
  getVpnConfigDocument,
  getWhatIsNewData,
  getWhatIsNewDataDocument,
  getWithdrawalStats,
  getWithdrawalStatsDocument,
  getWithdrawalSystemsList,
  getWithdrawalSystemsListDocument,
  getWithdrawalSystemsOverviewList,
  getWithdrawalSystemsOverviewListDocument,
  getWithdrawalsRegistrationCompletionStatus,
  getWithdrawalsRegistrationCompletionStatusDocument,
  getWithdrawalsSystemInfo,
  getWithdrawalsSystemInfoDocument,
  goBack,
  goBackDocument,
  helpButtonModeExtChat,
  helpButtonModeHelp,
  helpChatTypeIntercom,
  helpChatTypeLivechat,
  helpChatTypeNone,
  historyFilterAll,
  historyFilterAllBets,
  historyFilterAllBonus,
  historyFilterAllCbc,
  historyFilterAllDeposits,
  historyFilterAllEgs,
  historyFilterAllPayments,
  historyFilterAllSbc,
  historyFilterAllWithdrawals,
  historyFilterCompleted,
  historyFilterCompletedBets,
  historyFilterCompletedDeposits,
  historyFilterCompletedEgs,
  historyFilterCompletedPayments,
  historyFilterCompletedWithdrawals,
  historyFilterLeonshop,
  historyFilterPending,
  historyFilterPendingBets,
  historyFilterPendingEgs,
  historyFilterPendingWithdrawals,
  homeAwayAway,
  homeAwayHome,
  homePageTypeCustom,
  homePageTypeLiveGames,
  homePageTypeSlots,
  homePageTypeSports,
  httpMethodDelete,
  httpMethodGet,
  httpMethodHead,
  httpMethodOptions,
  httpMethodPatch,
  httpMethodPost,
  httpMethodPut,
  httpMethodTrace,
  identificationDocumentTypeFacepass,
  identificationDocumentTypePassport,
  identificationDocumentTypeResidence,
  identificationDocumentTypeThirdPage,
  identificationDocumentTypeVideo,
  identificationLevelFull,
  identificationLevelNone,
  identificationLevelSimple,
  identificationProviderEsiaSimple,
  identificationProviderSber,
  identificationProviderTinkoff,
  ignoredErrorTypeRegexp,
  ignoredErrorTypeText,
  illegalMutationsExceptionCodeInvalidMutationsNumber,
  incorrectCustomerTypeLocked,
  incorrectCustomerTypeNew,
  incorrectCustomerTypeSuspend,
  initIdentApp,
  initIdentAppDocument,
  inlineChatAnimationIcon,
  inlineChatAnimationIconShake,
  inlineChatAnimationSpeechBubble,
  inlineChatHideConditionOnOutsideClick,
  inlineChatHideConditionSeconds10,
  inlineChatHideConditionSeconds3,
  inlineChatHideConditionSeconds5,
  invalidBatchGraphQLRequestBodyIdExceptionCodeInvalidBatchIdError,
  invalidBusinessHeaderValueExceptionCodeInvalidHeaderValue,
  invalidCodeExceptionCodeInvalidCode,
  invalidGraphqlRequestsCountExceptionCodeInvalidGraphqlRequestsNumber,
  invalidI18nKeysHashExceptionCodeInvalidI18nKeysHash,
  invalidPhoneExceptionCodeInvalidPhone,
  invalidRequestsCountExceptionCodeInvalidRequestsNumber,
  invalidTsExceptionCodeInvalidTs,
  invalidUpdatedFromExceptionCodeInvalidUpdatedFrom,
  isInvalidPhoneExceptionCode,
  jurisdictionNotSupportedActionExceptionCodeJurisdictionNotSupportedAction,
  levelSettingsProgressStatusInProgress,
  levelSettingsProgressStatusNotAvailable,
  levelSettingsProgressStatusReceived,
  lobbyGameTypeAll,
  lobbyGameTypeBetgames,
  lobbyGameTypeFastgames,
  lobbyGameTypeLive,
  lobbyGameTypeSlots,
  lobbyGridTypeDefault,
  lobbyGridTypeLarge,
  lobbyGridTypeSmall,
  lobbyItemTypeAllTopWinners,
  lobbyItemTypeBanners,
  lobbyItemTypeGamesCategory,
  lobbyItemTypeGroups,
  lobbyItemTypeJackpots,
  lobbyItemTypePromotions,
  lobbyItemTypeSportbetTopWinners,
  lobbyItemTypeSportlineTopMatches,
  lobbyItemTypeTopWinners,
  lobbyItemWidgetBigSmallRowsList,
  lobbyItemWidgetJackpotList,
  lobbyItemWidgetJackpotSwipeList,
  lobbyItemWidgetList,
  lobbyItemWidgetSquareList,
  lobbyItemWidgetSquareRowsList,
  lobbyItemWidgetSquareSwipeList,
  lobbyItemWidgetSwipeList,
  lobbyTitleTypeDefault,
  lobbyTitleTypeHidden,
  lobbyTitleTypeSmall,
  lobbyTypeAll,
  lobbyTypeFastgames,
  lobbyTypeHome,
  lobbyTypeLiveCasino,
  lobbyTypeLiveGamePage,
  lobbyTypeLoyaltyProgram,
  lobbyTypeSearch,
  lobbyTypeSlots,
  lobbyTypeSlotsGamePage,
  logLevelDebug,
  logLevelError,
  logLevelInfo,
  logLevelWarn,
  loginTypeAccount,
  loginTypeEmail,
  loginTypePhone,
  logout,
  logoutDocument,
  makeBatchSlip,
  makeBatchSlipDocument,
  makeCustomerVerification,
  makeCustomerVerificationDocument,
  makeVipFastTrackVerification,
  makeVipFastTrackVerificationDocument,
  manualCustomerDataVersionV4,
  manualCustomerDataVersionV5,
  manualStatusApiFound,
  manualStatusDraft,
  manualStatusDraftInit,
  manualStatusError,
  manualStatusInit,
  manualStatusSuccess,
  manualStatusUpdated,
  marketStatusClosed,
  marketStatusDuplicate,
  marketStatusLimit,
  marketStatusMissing,
  marketStatusPriceDown,
  marketStatusPriceUp,
  marketStatusSuspended,
  marketTypeTagHandicap,
  marketTypeTagRegular,
  marketTypeTagTotal,
  matchPhaseBreakInPlay,
  matchPhaseGameAbandoned,
  matchPhaseInPlay,
  matchPhaseOvertime,
  matchPhasePostGame,
  matchPhasePreGame,
  matchStreamAvailabilityTypeAll,
  matchStreamAvailabilityTypeNormal,
  matchStreamAvailabilityTypeUnknown,
  matchStreamAvailabilityTypeVip,
  metricsToolTypeGoogleAnalytics,
  metricsToolTypeIncomeAccess,
  metricsToolTypeYandex,
  mockAddFavoriteGame,
  mockCheckPushTokenExists,
  mockCheckRestorePasswordSms,
  mockCheckRestorePasswordUid,
  mockClearCookies,
  mockConfirmManualApplication,
  mockCustomerIdentityCheck,
  mockDeleteDocuments,
  mockDisableCustomerMode,
  mockDoAchievementMarkAsViewed,
  mockDoAchievementModifyAvatar,
  mockDoAchievementReward,
  mockDoActivateAffiliateProgram,
  mockDoActivateBonusCode,
  mockDoAddLoginDevice,
  mockDoAppsFlyerQualifiedDepositsReported,
  mockDoButtonMutation,
  mockDoCalculatePack,
  mockDoCalculateRound,
  mockDoCancelRequestedWithdrawals,
  mockDoCashOut,
  mockDoChangeCustomerNickname,
  mockDoChangeCustomerSettings,
  mockDoChangeLanguage,
  mockDoChangePassword,
  mockDoCollectPackReward,
  mockDoCollectReward,
  mockDoConfirmBonus,
  mockDoConfirmCashback,
  mockDoConfirmParticipation,
  mockDoConfirmations,
  mockDoCreateBonusCode,
  mockDoCreatePinCode,
  mockDoDeactivateTimer,
  mockDoDeleteAccount,
  mockDoDeleteNotification,
  mockDoDeletePinCode,
  mockDoEncashBonus,
  mockDoEventSubscribe,
  mockDoEventUnsubscribe,
  mockDoFavoriteSportEventRemove,
  mockDoFavoriteSportEventsAdd,
  mockDoLogin,
  mockDoLoginCas,
  mockDoLoginFingerprint,
  mockDoLoginG2sv,
  mockDoLoginPinCode,
  mockDoMarkAsClicked,
  mockDoMarkAsDelivered,
  mockDoMarkAsShownMutation,
  mockDoMarkDeviceUntrusted,
  mockDoMarkNotification,
  mockDoModifyTimeZone,
  mockDoPlayTimeControl,
  mockDoPostponeWebPushToken,
  mockDoPromotionParticipation,
  mockDoPromotionRate,
  mockDoReadCustomerNotification,
  mockDoRefuseBonus,
  mockDoRefuseCashback,
  mockDoRefuseMeeting,
  mockDoRegisterCustomerOrder,
  mockDoRegisterPushToken,
  mockDoRegistration,
  mockDoRequestCallback,
  mockDoRestorePasswordByEmail,
  mockDoRestorePasswordByPhone,
  mockDoRewarding,
  mockDoSaveBetSlip,
  mockDoSaveBetSlipByBetId,
  mockDoSaveFastBets,
  mockDoSaveLog,
  mockDoSavePriceChangePolicyV2,
  mockDoSaveShowMaxFastBet,
  mockDoSaveSportFilters,
  mockDoScheduleMeeting,
  mockDoSelfExclusion,
  mockDoSelfExclusionFlex,
  mockDoSendFeedback,
  mockDoSendWeb2Feedback,
  mockDoSendWeb2FeedbackByCategory,
  mockDoSubmitDeposit,
  mockDoSubmitWithdrawal,
  mockDoToggleCustomerLeague,
  mockDoTsupisCardDelete,
  mockDoUpdateCustomerData,
  mockDoUpdateNotificationConsents,
  mockDoVerifyPinCode,
  mockDoWithdrawAllSportBonuses,
  mockDoWithdrawSportBonus,
  mockDoWithdrawalCancel,
  mockDropManualData,
  mockEnableCustomerMode,
  mockEnableFingerprintLogin,
  mockEsiaDataFetch,
  mockEsiaRedirect,
  mockEtsupisPhoneVerificationInit,
  mockEtsupisPhoneVerificationVerify,
  mockFinishApplication,
  mockGenerateNickname,
  mockGetAccountTransaction,
  mockGetAchievement,
  mockGetAchievements,
  mockGetAchievementsAvailability,
  mockGetAchievementsAvatarsAndBackgrounds,
  mockGetAchievementsCategories,
  mockGetAchievementsProfile,
  mockGetActiveOrAvailableCampaigns,
  mockGetAffiliatedCustomers,
  mockGetAllAccountHistory,
  mockGetAllAccountHistoryDataGroups,
  mockGetAllPacks,
  mockGetApiSettings,
  mockGetAuthenticatorUri,
  mockGetAuthenticatorUriQRCode,
  mockGetAvailability,
  mockGetAvailableBonusesForTransfer,
  mockGetAvailableForms,
  mockGetAvailableMeetingSlots,
  mockGetBalance,
  mockGetBanner,
  mockGetBanners,
  mockGetBatchSlipInfo,
  mockGetBenefitCustomerLevel,
  mockGetBet,
  mockGetBetLimits,
  mockGetBetSlip,
  mockGetBetgeniusWidgetConfiguration,
  mockGetBetlineLeagueStandings,
  mockGetBetlineLeagueStatus,
  mockGetBetlineLeagueTopPlayers,
  mockGetBetlineMatchEventStatistics,
  mockGetBetlineMatchStatistics,
  mockGetBonusCodes,
  mockGetBonusLoyalty,
  mockGetBonusMatrix,
  mockGetBonusTransferAmount,
  mockGetBonusWagers,
  mockGetBonusWalletPromotion,
  mockGetBonuses,
  mockGetCampaignCoupons,
  mockGetCashback,
  mockGetCashoutOffer,
  mockGetCasinoLoyaltyStatus,
  mockGetCmsContentSpintaxBatchTranslations,
  mockGetCmsContentTranslations,
  mockGetCompanyFee,
  mockGetConsents,
  mockGetCookie,
  mockGetCryptoNetworkInfo,
  mockGetCurrenciesByCountry,
  mockGetCustomerBonuses,
  mockGetCustomerHistory,
  mockGetCustomerHistoryItem,
  mockGetCustomerHistoryList,
  mockGetCustomerLeague,
  mockGetCustomerLeagues,
  mockGetCustomerMatchStreamEvents,
  mockGetCustomerMatches,
  mockGetCustomerNotifications,
  mockGetCustomerNotificationsCount,
  mockGetCustomerOffers,
  mockGetCustomerOrders,
  mockGetCustomerProfileFields,
  mockGetCustomerStreams,
  mockGetCustomerVerifyData,
  mockGetDepositLimits,
  mockGetDepositSystemsList,
  mockGetDepositSystemsOverviewList,
  mockGetDepositsRegistrationCompletionStatus,
  mockGetDepositsSystemInfo,
  mockGetDetails,
  mockGetEgsCategories,
  mockGetEgsChannels,
  mockGetEgsGameById,
  mockGetEgsGameByUrl,
  mockGetEgsGameTypes,
  mockGetEgsGames,
  mockGetEgsGroups,
  mockGetEgsRounds,
  mockGetEgsRoundsCursor,
  mockGetEgsSessions,
  mockGetEgsTopWinners,
  mockGetEgsTransactions,
  mockGetEncashBonusData,
  mockGetEsiaAouth2Link,
  mockGetFAQCategories,
  mockGetFAQItems,
  mockGetFeedbackTypes,
  mockGetFormSchema,
  mockGetFreebetData,
  mockGetGeneralStats,
  mockGetIdentificationState,
  mockGetIncompleteRegistrationFields,
  mockGetInlineChats,
  mockGetIntercomUserHash,
  mockGetLSportsWidgetConfiguration,
  mockGetLastBetResult,
  mockGetLeaderBoard,
  mockGetLeagueData,
  mockGetLeaguePlayoffByLeagueId,
  mockGetLeagueStandingsByLeagueId,
  mockGetLeagueStatisticsByLeagueId,
  mockGetLexGuardSession,
  mockGetLinkedPromotionsByEgsGame,
  mockGetLiveChatSettings,
  mockGetLiveChatStatus,
  mockGetLobby,
  mockGetMarketingBonusCursor,
  mockGetMarketingBonuses,
  mockGetMarketingDataCategories,
  mockGetMarketingStatuses,
  mockGetMatchStreamUrl,
  mockGetNavigationConfig,
  mockGetNearbyCities,
  mockGetNotifications,
  mockGetOnboarding,
  mockGetPacks,
  mockGetPartners,
  mockGetPayments,
  mockGetPaymentsCursor,
  mockGetPaymentsDataCategories,
  mockGetPaymentsStatuses,
  mockGetPendingBets,
  mockGetPinCodeStatus,
  mockGetPopularQueries,
  mockGetPromoJackpots,
  mockGetPromotionCategories,
  mockGetPromotionDetails,
  mockGetPromotionNavigationConfig,
  mockGetPromotions,
  mockGetPromotionsByEgsGame,
  mockGetPushSettings,
  mockGetReferral,
  mockGetRewards,
  mockGetRootSettings,
  mockGetRootSettingsInternational,
  mockGetRounds,
  mockGetRoutingConfigs,
  mockGetRules,
  mockGetSearchData,
  mockGetSelfExclusion,
  mockGetServiceWorkerConfiguration,
  mockGetSiteConfigSettings,
  mockGetSpintaxMetaData,
  mockGetSportradarLiveMatchTrackerConfiguration,
  mockGetSportradarLiveScoreConfiguration,
  mockGetSportradarStatisticsConfiguration,
  mockGetSportsbookBets,
  mockGetSportsbookSports,
  mockGetState,
  mockGetSubscribedEvents,
  mockGetTempCoupons,
  mockGetThirdPartyIdentificationInit,
  mockGetTranslations,
  mockGetTsupisLegalAgreementsVersions,
  mockGetTsupisOfferAgreements,
  mockGetTsupisPersonalDataAgreements,
  mockGetUpcomingMeeting,
  mockGetUserData,
  mockGetUserDataPhone,
  mockGetUserProfileBonus,
  mockGetVSportBets,
  mockGetVSportSports,
  mockGetVerificationReasons,
  mockGetVerificationToken,
  mockGetVpnConfig,
  mockGetWhatIsNewData,
  mockGetWithdrawalStats,
  mockGetWithdrawalSystemsList,
  mockGetWithdrawalSystemsOverviewList,
  mockGetWithdrawalsRegistrationCompletionStatus,
  mockGetWithdrawalsSystemInfo,
  mockGoBack,
  mockInitIdentApp,
  mockLogout,
  mockMakeBatchSlip,
  mockMakeCustomerVerification,
  mockMakeVipFastTrackVerification,
  mockPlayTimeControl,
  mockRemoveCustomerMessage,
  mockRemoveFavoriteGame,
  mockResendVerificationEmail,
  mockResetDisposableKeys,
  mockResetIdentificationRequest,
  mockResetPinCodeEmail,
  mockResetPinCodePhone,
  mockResetShown,
  mockSaveBetSlipConfiguration,
  mockSaveIdentificationDraftData,
  mockSaveManualVerificationForm,
  mockSavePushSettings,
  mockSendRestorePasswordLinkToEmail,
  mockSendRestorePasswordSmsOrCallToPhone,
  mockSendRestorePasswordSmsToPhone,
  mockSendRestorePinLinkToEmail,
  mockSendRestorePinLinkToPhone,
  mockSetAdditionalPropsUser,
  mockSetBannerClosed,
  mockSetBetLimits,
  mockSetCoookie,
  mockSetDepositLimits,
  mockSetDowntimeShown,
  mockSetInn,
  mockSetManualData,
  mockSetWhatsNew,
  mockStartEgsGame,
  mockSubmitDocuments,
  mockSubmitManualVerificationForm,
  mockUpdateCustomerProfileFields,
  mockUpdateIncompleteRegistrationFields,
  mockVerifyEmail,
  mockWithdrawBonusCBC,
  mocks,
  navigationSectionFooterNavigation,
  navigationSectionHeaderNavigation,
  navigationSectionPromoactionsNavigation,
  navigationSectionSideNavigation,
  noCashbackExceptionCodeNoCampaign,
  noRegisterStrategyExceptionCodeNoRegistrationStrategyFound,
  noSchemaExceptionCodeNoSchemaFound,
  noSuchContentExceptionCodeNoContent,
  noSuchHeaderExceptionCodeNoSuchHeader,
  noSuchTranslationExceptionCodeNoTranslation,
  noValidationSchemaFoundExceptionCodeNoValidationSchema,
  openInIframe,
  openInNewWindow,
  openInSameWindow,
  operationStatusFail,
  operationStatusOk,
  otpCodePlaceWithdrawal,
  otpCodeRequiredExceptionCodeOtpCodeVerificationNeeded,
  outcomeStateInPlay,
  outcomeStateLose,
  outcomeStateLoseHalf,
  outcomeStatePreGame,
  outcomeStateReturn,
  outcomeStateUndefined,
  outcomeStateWin,
  outcomeStateWinHalf,
  packListStateActive,
  packListStateClosed,
  packListStateUpcoming,
  packRewardBonus,
  packRewardCollectibleCard,
  packRewardEmpty,
  packRewardFreebet,
  packRewardFreespin,
  packStateAvailableForCalculation,
  packStateCalculated,
  packStateNotAvailable,
  participationTypeLeonBonus,
  participationTypeNone,
  participationTypePromo,
  pathTypeExternalPath,
  pathTypeInternalPath,
  pathTypeMarketingLeonshop,
  paymentExceptionCodePaymentError,
  paymentTypeDeposit,
  paymentTypeWithdrawal,
  paymentsAccountHistoryDataCategoryCorrection,
  paymentsAccountHistoryDataCategoryDeposit,
  paymentsAccountHistoryDataCategoryDepositBonus,
  paymentsAccountHistoryDataCategoryWithdrawal,
  paymentsAccountHistoryDataTypesCorrectionFromAccount,
  paymentsAccountHistoryDataTypesCorrectionToAccount,
  paymentsAccountHistoryDataTypesDeposit,
  paymentsAccountHistoryDataTypesEgsProviderReward,
  paymentsAccountHistoryDataTypesInfluencerDeposit,
  paymentsAccountHistoryDataTypesInfluencerWithdrawal,
  paymentsAccountHistoryDataTypesLeaderboardCashReward,
  paymentsAccountHistoryDataTypesLoyaltyBonusEncash,
  paymentsAccountHistoryDataTypesManualBsDepositBonus,
  paymentsAccountHistoryDataTypesMarketingEgsBonusDeposit,
  paymentsAccountHistoryDataTypesMarketingSportBonusDeposit,
  paymentsAccountHistoryDataTypesWithdrawal,
  paymentsAccountHistoryStatusCancelled,
  paymentsAccountHistoryStatusExecuted,
  paymentsAccountHistoryStatusPending,
  personalDomainTypeConditional,
  personalDomainTypePersonal,
  phoneCodeMethodCallPasswordCode,
  phoneCodeMethodExternalSms,
  phoneCodeMethodSms,
  phoneIdentificationRequiredNo,
  phoneIdentificationRequiredUnknown,
  phoneIdentificationRequiredYes,
  pinCodePlaceLogin,
  pinCodePlacePinCodeAtLoginSetting,
  pinCodePlacePlayTimeControl,
  pinCodePlaceSelfExclusion,
  pinCodePlaceUnknown,
  pinCodePlaceWithdrawal,
  pinCodeRequiredExceptionCodePinCodeVerificationNeeded,
  pinCodeStateBanned,
  pinCodeStateInitial,
  pinCodeStateReset,
  pinCodeStateSet,
  pinCodeStateUnknown,
  playTimeControl,
  playTimeControlDocument,
  previousRequestHasNotExpiredExceptionCodePreviousRequestHasNotExpired,
  priceChangePolicyAllowChanges,
  priceChangePolicyAllowIncrease,
  priceChangePolicyDenyChanges,
  priceChangePolicyUndefined,
  programParticipationStatusAvailable,
  programParticipationStatusCompleted,
  programParticipationStatusPending,
  programRequirementTypeBet,
  programRequirementTypeConfirmation,
  programRequirementTypeDeposit,
  programRequirementTypeIdentification,
  programRequirementTypeRegistration,
  programRewardTypeFreebet,
  programRewardTypeFreespin,
  progressBarLinearProgress,
  progressBarNone,
  progressUnitApplicationType,
  progressUnitBet,
  progressUnitCurrency,
  progressUnitDay,
  progressUnitDeposit,
  progressUnitGame,
  progressUnitProvider,
  progressUnitReferral,
  progressUnitTournament,
  progressUnitXp,
  promoActionTypeBonusPack,
  promoActionTypeCbc,
  promoActionTypeCoupon,
  promoActionTypeEgs,
  promoActionTypeEgsCashback,
  promoActionTypeFortuneWheel,
  promoActionTypeLeaderboardEg,
  promoActionTypeLeaderboardSports,
  promoActionTypeNoParticipation,
  promoActionTypePlainConfirm,
  promoActionTypeSbc,
  promoActionTypeSport,
  promoActionTypeStatic,
  promotionActionButtonStateAction,
  promotionActionButtonStateInfo,
  promotionActionButtonTypeClaim,
  promotionActionButtonTypeCustom,
  promotionActionButtonTypeDeposit,
  promotionActionButtonTypeExchange,
  promotionActionButtonTypeExchanged,
  promotionActionButtonTypeFreespin,
  promotionActionButtonTypeIdentificationNeeded,
  promotionActionButtonTypeLeaderboard,
  promotionActionButtonTypeLogin,
  promotionActionButtonTypeNone,
  promotionActionButtonTypeOnBonusWallet,
  promotionActionButtonTypeParticipate,
  promotionActionButtonTypeParticipating,
  promotionActionButtonTypeParticipationProcessing,
  promotionActionButtonTypePlay,
  pushNotificationProviderFirebase,
  pushNotificationProviderInternal,
  pwaCookieFixModeAuto,
  pwaCookieFixModeOff,
  pwaCookieFixModeOpentab,
  pwaCookieFixModeRedirect,
  rateLimiterExceptionCodeRateLimiter,
  ratingNegative,
  ratingPositive,
  recaptchaThemeDark,
  recaptchaThemeLight,
  recaptchaThemeUiInvert,
  recaptchaThemeUiMatch,
  refProgramNotificationTypeBonusEligibilityRules,
  refProgramNotificationTypeProgramAvailable,
  refProgramNotificationTypeProgramUnavailable,
  refProgramNotificationTypeReferralRequirementSatisfied,
  refProgramNotificationTypeReferralRewardAssigned,
  refProgramNotificationTypeReferralRewardAssignedReferrerNotification,
  refProgramNotificationTypeReferrerRequirementSatisfied,
  refProgramNotificationTypeReferrerRewardAssigned,
  refuseBonusTypeFreebetCancel,
  refuseBonusTypeFreespinCancel,
  refuseBonusTypeNone,
  refuseBonusTypeOnlyWriteoff,
  refuseBonusTypeWriteoffAndDepositReturn,
  registrationStateExceptionCodeRegistrationStateError,
  remoteApiErrorExceptionCodeCaptchaNeeded,
  remoteApiErrorExceptionCodeCustomerHistoryLimitExceeded,
  remoteApiErrorExceptionCodeExpired,
  remoteApiErrorExceptionCodeInvalidCaptcha,
  remoteApiErrorExceptionCodeInvalidPhoneNumber,
  remoteApiErrorExceptionCodePinCodeIsBanned,
  remoteApiErrorExceptionCodePromotionNotFound,
  remoteApiErrorExceptionCodeServiceSuspended,
  remoteApiErrorExceptionCodeStreamNotAvailable,
  remoteApiErrorExceptionCodeWithdrawalAmountExceeded,
  removeCustomerMessage,
  removeCustomerMessageDocument,
  removeFavoriteGame,
  removeFavoriteGameDocument,
  requestHashingDisabledExceptionCodeRequestHashingIsDisabled,
  requestHeaderXAppBrowser,
  requestHeaderXAppBuildId,
  requestHeaderXAppDebug,
  requestHeaderXAppEnv,
  requestHeaderXAppLanguage,
  requestHeaderXAppLayout,
  requestHeaderXAppModernity,
  requestHeaderXAppOs,
  requestHeaderXAppPlatform,
  requestHeaderXAppPreferredLang,
  requestHeaderXAppReferrer,
  requestHeaderXAppRendering,
  requestHeaderXAppSkin,
  requestHeaderXAppStore,
  requestHeaderXAppTheme,
  requestHeaderXAppVersion,
  requestHeaderXBtag,
  requestHeaderXClickId,
  requestHeaderXIabtag,
  requestHeaderXIalocale,
  requestHeaderXLocale,
  requestHeaderXQtag,
  requestHeaderXReftag,
  requestHeaderXRetentionId,
  requestHeaderXSubid,
  requestHeaderXSubid2,
  requestHeaderXSubid3,
  requestHeaderXSubid4,
  requestHeaderXSubid5,
  requestHeaderXUtmCampaign,
  requestHeaderXUtmContent,
  requestHeaderXUtmMedium,
  requestHeaderXUtmSource,
  requestHeaderXUtmTerm,
  requestHeaderXWm,
  requestHeaderXWmzone,
  requirementTypeBetAmount,
  requirementTypeBetCount,
  requirementTypeBonusCodeConfirmation,
  requirementTypeCloseActiveBonus,
  requirementTypeComplex,
  requirementTypeConfirmation,
  requirementTypeDeposit,
  requirementTypeDepositBonusCode,
  requirementTypeIdentification,
  requirementValueTypeCount,
  requirementValueTypeMoney,
  resendVerificationEmail,
  resendVerificationEmailDocument,
  reserveModeIframe,
  reserveModeRedirect,
  resetDisposableKeys,
  resetDisposableKeysDocument,
  resetIdentificationRequest,
  resetIdentificationRequestDocument,
  resetPinCodeEmail,
  resetPinCodeEmailDocument,
  resetPinCodePhone,
  resetPinCodePhoneDocument,
  resetShown,
  resetShownDocument,
  responsibleGamingLimitTypeBetLimit,
  responsibleGamingLimitTypeDepositLimit,
  responsibleGamingLimitTypePlayTimeReminder,
  responsibleGamingLimitTypeSelfExclusion,
  rewardCenterIdAchievements,
  rewardCenterIdBonusCode,
  rewardCenterIdCashback,
  rewardCenterIdEgsLevel,
  rewardCenterIdLoyaltyProgram,
  rewardCenterIdMyBonuses,
  rewardCenterIdPromotions,
  rewardCenterIdReferralProgram,
  rewardCenterIdWheelOfFortune,
  runnerTagAway,
  runnerTagBoth,
  runnerTagCompetitor,
  runnerTagDraw,
  runnerTagDrawaway,
  runnerTagEven,
  runnerTagHome,
  runnerTagHomeaway,
  runnerTagHomedraw,
  runnerTagNeither,
  runnerTagNo,
  runnerTagOdd,
  runnerTagOther,
  runnerTagOver,
  runnerTagRange,
  runnerTagScore,
  runnerTagUnder,
  runnerTagYes,
  saveBetSlipConfiguration,
  saveBetSlipConfigurationDocument,
  saveIdentificationDraftData,
  saveIdentificationDraftDataDocument,
  saveManualVerificationForm,
  saveManualVerificationFormDocument,
  savePushSettings,
  savePushSettingsDocument,
  scaledRatingStar1,
  scaledRatingStar2,
  scaledRatingStar3,
  scaledRatingStar4,
  scaledRatingStar5,
  schemaValidationExceptionCodeSchemaValidationError,
  selfExclusionTimeUnitTypeHours,
  selfExclusionTimeUnitTypeMonths,
  sendRestorePasswordLinkToEmail,
  sendRestorePasswordLinkToEmailDocument,
  sendRestorePasswordSmsOrCallToPhone,
  sendRestorePasswordSmsOrCallToPhoneDocument,
  sendRestorePasswordSmsToPhone,
  sendRestorePasswordSmsToPhoneDocument,
  sendRestorePinLinkToEmail,
  sendRestorePinLinkToEmailDocument,
  sendRestorePinLinkToPhone,
  sendRestorePinLinkToPhoneDocument,
  sessionExceptionCodeSession,
  setAdditionalPropsUser,
  setAdditionalPropsUserDocument,
  setBannerClosed,
  setBannerClosedDocument,
  setBetLimits,
  setBetLimitsDocument,
  setCoookie,
  setCoookieDocument,
  setDepositLimits,
  setDepositLimitsDocument,
  setDowntimeShown,
  setDowntimeShownDocument,
  setInn,
  setInnDocument,
  setManualData,
  setManualDataDocument,
  setWhatsNew,
  setWhatsNewDocument,
  shopOrderStatusCancelled,
  shopOrderStatusCreated,
  shopOrderStatusDone,
  shopOrderStatusInProgress,
  simpleIdentMethodEsia,
  simpleIdentMethodEsiaV2,
  simpleIdentMethodManual,
  simpleIdentMethodManualVerification,
  simpleIdentMethodSberId,
  simpleIdentMethodTinkoffId,
  simpleIdentMethodUpload,
  slipStatusDuplicateCompetitor,
  slipStatusDuplicateEvent,
  slipStatusDuplicateLeague,
  slipStatusDuplicateSport,
  slipStatusEmpty,
  slipStatusIncompatibleBetlines,
  slipStatusIncompatibleLeagues,
  slipStatusIncompatibleSports,
  slipStatusManyEntries,
  slipStatusMarketsChanged,
  slipStatusMaxLimit,
  slipStatusOddsLimit,
  slipStatusOk,
  slipStatusSingleBettingOnly,
  slipTypeIdExpress,
  slipTypeIdSingle,
  slipTypeIdSystem,
  sportBetStatusCashedOut,
  sportBetStatusLost,
  sportBetStatusPlaced,
  sportBetStatusVoided,
  sportBetStatusWon,
  sportBonusStateActive,
  sportBonusStateClosed,
  sportFamilyAlpineSkiing,
  sportFamilyAmericanFootball,
  sportFamilyArchery,
  sportFamilyAthletics,
  sportFamilyAustralianRules,
  sportFamilyBadminton,
  sportFamilyBandy,
  sportFamilyBaseball,
  sportFamilyBasketball,
  sportFamilyBasketball3x3,
  sportFamilyBeachSoccer,
  sportFamilyBeachVolleyball,
  sportFamilyBiathlon,
  sportFamilyBobsleigh,
  sportFamilyBowls,
  sportFamilyBoxing,
  sportFamilyCanoe,
  sportFamilyCanoeing,
  sportFamilyChess,
  sportFamilyCricket,
  sportFamilyCrossCountrySkiing,
  sportFamilyCurling,
  sportFamilyCycling,
  sportFamilyDarts,
  sportFamilyDiving,
  sportFamilyESport,
  sportFamilyEntertainment,
  sportFamilyEquestrian,
  sportFamilyFencing,
  sportFamilyFieldHockey,
  sportFamilyFigureSkating,
  sportFamilyFloorball,
  sportFamilyFormulaE,
  sportFamilyFreestyleSkiing,
  sportFamilyFutsal,
  sportFamilyGaelicFootball,
  sportFamilyGaelicHurling,
  sportFamilyGolf,
  sportFamilyGreyhoundRacing,
  sportFamilyGymnastics,
  sportFamilyHandball,
  sportFamilyHarnessRacing,
  sportFamilyHorseRacing,
  sportFamilyIceHockey,
  sportFamilyJudo,
  sportFamilyKabaddi,
  sportFamilyLacrosse,
  sportFamilyLuge,
  sportFamilyMarathonSwimming,
  sportFamilyMiscellaneous,
  sportFamilyMma,
  sportFamilyModernPentathlon,
  sportFamilyModernPenthatlon,
  sportFamilyMotorsport,
  sportFamilyNordicCombined,
  sportFamilyOlympics,
  sportFamilyPadel,
  sportFamilyPesapallo,
  sportFamilyPolitics,
  sportFamilyPool,
  sportFamilyRinkHockey,
  sportFamilyRollerHockey,
  sportFamilyRowing,
  sportFamilyRugby,
  sportFamilyRugbyLeague,
  sportFamilyRugbyUnion,
  sportFamilySailing,
  sportFamilyShooting,
  sportFamilyShortTrackSpeedSkating,
  sportFamilySkateboarding,
  sportFamilySkeleton,
  sportFamilySkiJumping,
  sportFamilySnooker,
  sportFamilySnowboard,
  sportFamilySoccer,
  sportFamilySoccerMythical,
  sportFamilySoftball,
  sportFamilySpecials,
  sportFamilySpeedSkating,
  sportFamilySquash,
  sportFamilySurfing,
  sportFamilySwimming,
  sportFamilySwimmingSynchronised,
  sportFamilyTableTennis,
  sportFamilyTaekwondo,
  sportFamilyTennis,
  sportFamilyTrampoline,
  sportFamilyTriathlon,
  sportFamilyUnknown,
  sportFamilyVirtualBasketball,
  sportFamilyVirtualCricket,
  sportFamilyVirtualSoccer,
  sportFamilyVirtualSoccerV2,
  sportFamilyVirtualTennis,
  sportFamilyVirtualTennisInplay,
  sportFamilyVolleyball,
  sportFamilyWaterPolo,
  sportFamilyWeightlifting,
  sportFamilyWinterSports,
  sportFamilyWorldLottery,
  sportFamilyWrestling,
  sportFilterKickoffPeriodAftertomorrow,
  sportFilterKickoffPeriodAll,
  sportFilterKickoffPeriodCustom,
  sportFilterKickoffPeriodOnehour,
  sportFilterKickoffPeriodSixhours,
  sportFilterKickoffPeriodThreehours,
  sportFilterKickoffPeriodToday,
  sportFilterKickoffPeriodTomorrow,
  sportFilterKickoffPeriodTwelvehours,
  sportFilterKickoffPeriodTwentyfourhours,
  sportFilterKickoffPeriodTwohours,
  sportFreebetStateActivated,
  sportFreebetStateLost,
  sportFreebetStateLostExpired,
  sportFreebetStateLostRefused,
  sportFreebetStateWon,
  sportWagerResultCanceledByError,
  sportWagerResultCanceledEmptyBalance,
  sportWagerResultCanceledExpired,
  sportWagerResultCanceledRefused,
  sportWagerResultSuccessful,
  sportbookDataTypeFastBet,
  sportbookDataTypeSportBet,
  sportsbookUIVersionDefault,
  sportsbookUIVersionV1,
  startCodeTypeHtmlInjection,
  startCodeTypeRedirect,
  startEgsGame,
  startEgsGameDocument,
  statusColorDebitCredit,
  statusColorDefault,
  statusColorNegative,
  statusColorPending,
  statusColorPositive,
  statusDataRequested,
  statusError,
  statusInit,
  statusSuccess,
  statusWaiting,
  submitDocuments,
  submitDocumentsDocument,
  submitManualVerificationForm,
  submitManualVerificationFormDocument,
  supportTypeCallback,
  supportTypeChat,
  themeAuto,
  themeDark,
  themeLight,
  themeMidnight,
  thirdPartyErrorCodeSberUnknownPhoneNumber,
  thirdPartyErrorCodeTinkoffAccessDenied,
  thirdPartyErrorCodeTinkoffUnknownPhoneNumber,
  thirdPartyErrorCodeVerificationFailure,
  timerModeMinutes,
  timerModeSeconds,
  topWinnersTabTypeEgs,
  topWinnersTabTypeSport,
  tradingStatusClosed,
  tradingStatusOpen,
  tradingStatusSuspended,
  transactionGroupBet,
  transactionGroupEgs,
  transactionGroupEgsow,
  transactionGroupLeonshop,
  transactionGroupMarketing,
  transactionGroupMg,
  transactionGroupPayment,
  transactionStatusColorGreen,
  transactionStatusColorRed,
  transactionStatusColorWhite,
  transactionStatusColorYellow,
  transferTypeBonus,
  transferTypeWin,
  transferTypeWinPlusBonus,
  transitionRouteAllSlots,
  transitionRouteDeposit,
  transitionRouteLiveCasino,
  transitionRouteSettings2fa,
  transitionRouteSettingsAccountVerification,
  transitionRouteSettingsMySubscriptions,
  transitionRouteSettingsPersonalInfo,
  transitionRouteSettingsPin,
  transitionRouteTournament,
  transitionRouteUnknown,
  tsupis1BindCustomerErrorAlreadyBound,
  tsupis1BindCustomerErrorIncorrectPersonalData,
  tsupis1BindCustomerErrorInternal,
  tsupis1BindCustomerErrorLockedCustomer,
  tsupis1BindCustomerErrorOverbound,
  tsupis1BindCustomerErrorTechnical,
  tsupis1BindCustomerErrorTimeout,
  tsupisAuthResultAccessToDeviceDisabled,
  tsupisAuthResultApplicationDisabled,
  tsupisAuthResultAuthenticationSuccess,
  tsupisAuthResultBettingDisabled,
  tsupisAuthResultCustomerCabinRegistrationRequired,
  tsupisAuthResultCustomerNotUpdated,
  tsupisAuthResultCustomerPhoneChangeForbidden,
  tsupisAuthResultCustomerRegphoneExists,
  tsupisAuthResultDocumentExpired,
  tsupisAuthResultIdentDataIsNotVerified,
  tsupisAuthResultIdentificationRequired,
  tsupisAuthResultIncorrectIdentData,
  tsupisAuthResultInnProviderIsBusy,
  tsupisAuthResultInvalidAuthDetails,
  tsupisAuthResultInvalidCitizenship,
  tsupisAuthResultInvalidInn,
  tsupisAuthResultMaxAttemptsReached,
  tsupisAuthResultPhoneNumberAlreadyUsedByUser,
  tsupisAuthResultPhoneNumberBlocked,
  tsupisAuthResultPhoneNumberIncorrect,
  tsupisAuthResultPursesLimitExceeded,
  tsupisAuthResultRequiredParameterInWrongFormat,
  tsupisAuthResultSmsCodeExpired,
  tsupisAuthResultSmsCodeIncorrect,
  tsupisAuthResultTechError,
  tsupisAuthResultUnknownError,
  tsupisAuthResultUserIsNotAuthorized,
  tsupisAuthResultWrongApiUsage,
  tsupisBindingRequestStatusDone,
  tsupisBindingRequestStatusNotCalled,
  tsupisBindingRequestStatusPending,
  tsupisProviderTypeTsupis1,
  tsupisProviderTypeTsupis2,
  uiAccountTypeCbc,
  uiAccountTypeMain,
  uiAccountTypeSbc,
  unMockAll,
  unknownCardTokenExceptionCodeUnknownCardTokenError,
  unknownRequestHashExceptionCodeUnknownRequestHash,
  unsupportedBannerExceptionCodeUnsupportedBanner,
  updateCustomerProfileFields,
  updateCustomerProfileFieldsDocument,
  updateIncompleteRegistrationFields,
  updateIncompleteRegistrationFieldsDocument,
  useAddFavoriteGame,
  useCheckPushTokenExists,
  useCheckRestorePasswordSms,
  useCheckRestorePasswordUid,
  useClearCookies,
  useConfirmManualApplication,
  useCustomerIdentityCheck,
  useDeleteDocuments,
  useDisableCustomerMode,
  useDoAchievementMarkAsViewed,
  useDoAchievementModifyAvatar,
  useDoAchievementReward,
  useDoActivateAffiliateProgram,
  useDoActivateBonusCode,
  useDoAddLoginDevice,
  useDoAppsFlyerQualifiedDepositsReported,
  useDoButtonMutation,
  useDoCalculatePack,
  useDoCalculateRound,
  useDoCancelRequestedWithdrawals,
  useDoCashOut,
  useDoChangeCustomerNickname,
  useDoChangeCustomerSettings,
  useDoChangeLanguage,
  useDoChangePassword,
  useDoCollectPackReward,
  useDoCollectReward,
  useDoConfirmBonus,
  useDoConfirmCashback,
  useDoConfirmParticipation,
  useDoConfirmations,
  useDoCreateBonusCode,
  useDoCreatePinCode,
  useDoDeactivateTimer,
  useDoDeleteAccount,
  useDoDeleteNotification,
  useDoDeletePinCode,
  useDoEncashBonus,
  useDoEventSubscribe,
  useDoEventUnsubscribe,
  useDoFavoriteSportEventRemove,
  useDoFavoriteSportEventsAdd,
  useDoLogin,
  useDoLoginCas,
  useDoLoginFingerprint,
  useDoLoginG2sv,
  useDoLoginPinCode,
  useDoMarkAsClicked,
  useDoMarkAsDelivered,
  useDoMarkAsShownMutation,
  useDoMarkDeviceUntrusted,
  useDoMarkNotification,
  useDoModifyTimeZone,
  useDoPlayTimeControl,
  useDoPostponeWebPushToken,
  useDoPromotionParticipation,
  useDoPromotionRate,
  useDoReadCustomerNotification,
  useDoRefuseBonus,
  useDoRefuseCashback,
  useDoRefuseMeeting,
  useDoRegisterCustomerOrder,
  useDoRegisterPushToken,
  useDoRegistration,
  useDoRequestCallback,
  useDoRestorePasswordByEmail,
  useDoRestorePasswordByPhone,
  useDoRewarding,
  useDoSaveBetSlip,
  useDoSaveBetSlipByBetId,
  useDoSaveFastBets,
  useDoSaveLog,
  useDoSavePriceChangePolicyV2,
  useDoSaveShowMaxFastBet,
  useDoSaveSportFilters,
  useDoScheduleMeeting,
  useDoSelfExclusion,
  useDoSelfExclusionFlex,
  useDoSendFeedback,
  useDoSendWeb2Feedback,
  useDoSendWeb2FeedbackByCategory,
  useDoSubmitDeposit,
  useDoSubmitWithdrawal,
  useDoToggleCustomerLeague,
  useDoTsupisCardDelete,
  useDoUpdateCustomerData,
  useDoUpdateNotificationConsents,
  useDoVerifyPinCode,
  useDoWithdrawAllSportBonuses,
  useDoWithdrawSportBonus,
  useDoWithdrawalCancel,
  useDropManualData,
  useEnableCustomerMode,
  useEnableFingerprintLogin,
  useEsiaDataFetch,
  useEsiaRedirect,
  useEtsupisPhoneVerificationInit,
  useEtsupisPhoneVerificationVerify,
  useFinishApplication,
  useGenerateNickname,
  useGetAccountTransaction,
  useGetAchievement,
  useGetAchievements,
  useGetAchievementsAvailability,
  useGetAchievementsAvatarsAndBackgrounds,
  useGetAchievementsCategories,
  useGetAchievementsProfile,
  useGetActiveOrAvailableCampaigns,
  useGetAffiliatedCustomers,
  useGetAllAccountHistory,
  useGetAllAccountHistoryDataGroups,
  useGetAllPacks,
  useGetApiSettings,
  useGetAuthenticatorUri,
  useGetAuthenticatorUriQRCode,
  useGetAvailability,
  useGetAvailableBonusesForTransfer,
  useGetAvailableForms,
  useGetAvailableMeetingSlots,
  useGetBalance,
  useGetBanner,
  useGetBanners,
  useGetBatchSlipInfo,
  useGetBenefitCustomerLevel,
  useGetBet,
  useGetBetLimits,
  useGetBetSlip,
  useGetBetgeniusWidgetConfiguration,
  useGetBetlineLeagueStandings,
  useGetBetlineLeagueStatus,
  useGetBetlineLeagueTopPlayers,
  useGetBetlineMatchEventStatistics,
  useGetBetlineMatchStatistics,
  useGetBonusCodes,
  useGetBonusLoyalty,
  useGetBonusMatrix,
  useGetBonusTransferAmount,
  useGetBonusWagers,
  useGetBonusWalletPromotion,
  useGetBonuses,
  useGetCampaignCoupons,
  useGetCashback,
  useGetCashoutOffer,
  useGetCasinoLoyaltyStatus,
  useGetCmsContentSpintaxBatchTranslations,
  useGetCmsContentTranslations,
  useGetCompanyFee,
  useGetConsents,
  useGetCookie,
  useGetCryptoNetworkInfo,
  useGetCurrenciesByCountry,
  useGetCustomerBonuses,
  useGetCustomerHistory,
  useGetCustomerHistoryItem,
  useGetCustomerHistoryList,
  useGetCustomerLeague,
  useGetCustomerLeagues,
  useGetCustomerMatchStreamEvents,
  useGetCustomerMatches,
  useGetCustomerNotifications,
  useGetCustomerNotificationsCount,
  useGetCustomerOffers,
  useGetCustomerOrders,
  useGetCustomerProfileFields,
  useGetCustomerStreams,
  useGetCustomerVerifyData,
  useGetDepositLimits,
  useGetDepositSystemsList,
  useGetDepositSystemsOverviewList,
  useGetDepositsRegistrationCompletionStatus,
  useGetDepositsSystemInfo,
  useGetDetails,
  useGetEgsCategories,
  useGetEgsChannels,
  useGetEgsGameById,
  useGetEgsGameByUrl,
  useGetEgsGameTypes,
  useGetEgsGames,
  useGetEgsGroups,
  useGetEgsRounds,
  useGetEgsRoundsCursor,
  useGetEgsSessions,
  useGetEgsTopWinners,
  useGetEgsTransactions,
  useGetEncashBonusData,
  useGetEsiaAouth2Link,
  useGetFAQCategories,
  useGetFAQItems,
  useGetFeedbackTypes,
  useGetFormSchema,
  useGetFreebetData,
  useGetGeneralStats,
  useGetIdentificationState,
  useGetIncompleteRegistrationFields,
  useGetInlineChats,
  useGetIntercomUserHash,
  useGetLSportsWidgetConfiguration,
  useGetLastBetResult,
  useGetLeaderBoard,
  useGetLeagueData,
  useGetLeaguePlayoffByLeagueId,
  useGetLeagueStandingsByLeagueId,
  useGetLeagueStatisticsByLeagueId,
  useGetLexGuardSession,
  useGetLinkedPromotionsByEgsGame,
  useGetLiveChatSettings,
  useGetLiveChatStatus,
  useGetLobby,
  useGetMarketingBonusCursor,
  useGetMarketingBonuses,
  useGetMarketingDataCategories,
  useGetMarketingStatuses,
  useGetMatchStreamUrl,
  useGetNavigationConfig,
  useGetNearbyCities,
  useGetNotifications,
  useGetOnboarding,
  useGetPacks,
  useGetPartners,
  useGetPayments,
  useGetPaymentsCursor,
  useGetPaymentsDataCategories,
  useGetPaymentsStatuses,
  useGetPendingBets,
  useGetPinCodeStatus,
  useGetPopularQueries,
  useGetPromoJackpots,
  useGetPromotionCategories,
  useGetPromotionDetails,
  useGetPromotionNavigationConfig,
  useGetPromotions,
  useGetPromotionsByEgsGame,
  useGetPushSettings,
  useGetReferral,
  useGetRewards,
  useGetRootSettings,
  useGetRootSettingsInternational,
  useGetRounds,
  useGetRoutingConfigs,
  useGetRules,
  useGetSearchData,
  useGetSelfExclusion,
  useGetServiceWorkerConfiguration,
  useGetSiteConfigSettings,
  useGetSpintaxMetaData,
  useGetSportradarLiveMatchTrackerConfiguration,
  useGetSportradarLiveScoreConfiguration,
  useGetSportradarStatisticsConfiguration,
  useGetSportsbookBets,
  useGetSportsbookSports,
  useGetState,
  useGetSubscribedEvents,
  useGetTempCoupons,
  useGetThirdPartyIdentificationInit,
  useGetTranslations,
  useGetTsupisLegalAgreementsVersions,
  useGetTsupisOfferAgreements,
  useGetTsupisPersonalDataAgreements,
  useGetUpcomingMeeting,
  useGetUserData,
  useGetUserDataPhone,
  useGetUserProfileBonus,
  useGetVSportBets,
  useGetVSportSports,
  useGetVerificationReasons,
  useGetVerificationToken,
  useGetVpnConfig,
  useGetWhatIsNewData,
  useGetWithdrawalStats,
  useGetWithdrawalSystemsList,
  useGetWithdrawalSystemsOverviewList,
  useGetWithdrawalsRegistrationCompletionStatus,
  useGetWithdrawalsSystemInfo,
  useGoBack,
  useInitIdentApp,
  useLogout,
  useMakeBatchSlip,
  useMakeCustomerVerification,
  useMakeVipFastTrackVerification,
  usePlayTimeControl,
  useRemoveCustomerMessage,
  useRemoveFavoriteGame,
  useResendVerificationEmail,
  useResetDisposableKeys,
  useResetIdentificationRequest,
  useResetPinCodeEmail,
  useResetPinCodePhone,
  useResetShown,
  useSaveBetSlipConfiguration,
  useSaveIdentificationDraftData,
  useSaveManualVerificationForm,
  useSavePushSettings,
  useSendRestorePasswordLinkToEmail,
  useSendRestorePasswordSmsOrCallToPhone,
  useSendRestorePasswordSmsToPhone,
  useSendRestorePinLinkToEmail,
  useSendRestorePinLinkToPhone,
  useSetAdditionalPropsUser,
  useSetBannerClosed,
  useSetBetLimits,
  useSetCoookie,
  useSetDepositLimits,
  useSetDowntimeShown,
  useSetInn,
  useSetManualData,
  useSetWhatsNew,
  useStartEgsGame,
  useSubmitDocuments,
  useSubmitManualVerificationForm,
  useUpdateCustomerProfileFields,
  useUpdateIncompleteRegistrationFields,
  useVerifyEmail,
  useWithdrawBonusCBC,
  validationSchemaChangePassword,
  validationSchemaCustomerLimits,
  validationSchemaExample,
  validationSchemaLogin,
  validationSchemaPersonalData,
  validationSchemaRegistrationFullLeonRu,
  validationSchemaRegistrationFullLru,
  validationSchemaRestorePasswordByEmail,
  validationSchemaRestorePasswordByPhone,
  validationSchemaRestorePasswordCheckSms,
  validationSchemaRestorePasswordSendLinkToEmail,
  validationSchemaRestorePasswordSendSmsToPhone,
  validationSchemaSupportCallback,
  validationSchemaSupportFeedback,
  verificationStatusNeedsVerif,
  verificationStatusUnavailable,
  verificationStatusVerifPending,
  verificationStatusVerifStarted,
  verificationStatusVerified,
  verifyEmail,
  verifyEmailDocument,
  videoVerificationStatusAcceptIgnore,
  videoVerificationStatusAcceptRefusal,
  videoVerificationStatusIgnored,
  videoVerificationStatusInvited,
  videoVerificationStatusMissed,
  videoVerificationStatusNoAnswer,
  videoVerificationStatusNone,
  videoVerificationStatusNotPassed,
  videoVerificationStatusOnReview,
  videoVerificationStatusPassed,
  videoVerificationStatusPending,
  videoVerificationStatusRefused,
  videoVerificationStatusScheduled,
  videoVerificationStatusUnknown,
  vipFastTrackStatusAvailable,
  vipFastTrackStatusDisabled,
  vipFastTrackStatusNotLoggedIn,
  vipFastTrackStatusVip,
  virtualSportTournamentBasketball,
  virtualSportTournamentCricket,
  virtualSportTournamentFootballBundesliga,
  virtualSportTournamentFootballChampionsCup,
  virtualSportTournamentFootballEurocup,
  virtualSportTournamentFootballLeagueMode,
  virtualSportTournamentFootballNationsCup,
  virtualSportTournamentFootballWorldCup,
  virtualSportTournamentTennisInplay,
  vpnProviderOpenvpn,
  vpnProviderShadowsocks,
  vsportBetStatusLost,
  vsportBetStatusPlaced,
  vsportBetStatusVoided,
  vsportBetStatusWon,
  web2FontFamilyDefault,
  web2FontFamilyMontserrat,
  widgetTypeBetgenius,
  widgetTypeBetradar,
  widgetTypeLsports,
  withdrawBonusCBC,
  withdrawBonusCBCDocument,
  zetaDcpConfigTypeAll,
  zetaDcpConfigTypeDeposit,
  zetaDcpConfigTypeFirstTimeDeposit,
  zetaDcpConfigTypeLanding,
  zetaDcpConfigTypeMain,
  zetaDcpConfigTypeRegistration
};
