import { isNullOrUndefined } from "@leon-hub/guards";
import { logger } from "@leon-hub/logging";
function getLargestKey(storage2) {
  var _a;
  let largestKey;
  let largestLength = 0;
  for (const key of Object.keys(storage2)) {
    const keyLength = ((_a = storage2.getItem(key)) == null ? void 0 : _a.length) || 0;
    if (keyLength > largestLength) {
      largestLength = keyLength;
      largestKey = key;
    }
  }
  return largestKey;
}
function isQuotaExceededError(error) {
  return error instanceof DOMException && // everything except Firefox
  (error.code === 22 || error.code === 1014 || error.name === "QuotaExceededError" || error.name === "NS_ERROR_DOM_QUOTA_REACHED");
}
class LocalStorageManager {
  constructor() {
    this.triedToOverwrite = false;
    this.setItem = (key, value) => {
      var _a;
      try {
        this.storage.setItem(key, value);
      } catch (error) {
        if (isQuotaExceededError(error) && !this.triedToOverwrite) {
          const largestKey = getLargestKey(this.storage);
          if (largestKey !== void 0) {
            const largestValue = ((_a = this.storage.getItem(largestKey)) == null ? void 0 : _a.slice(0, 2e3)) ?? "<cannot get largest key value>";
            logger.error(`localStorageManager: largestKey=${largestKey}, value=${largestValue}`);
            this.removeItem(largestKey);
          }
          this.triedToOverwrite = true;
          this.setItem(key, value);
        } else {
          logger.error("localStorageManager: unable to save the value", { [key]: value, error });
        }
      }
    };
    this.removeItem = (key) => {
      this.storage.removeItem(key);
    };
    this.storage = window.localStorage;
  }
  key(index) {
    return this.storage.key(index);
  }
  clear(saveKeys) {
    const savedKeys = {};
    if (saveKeys == null ? void 0 : saveKeys.length) {
      for (const key of saveKeys) {
        const value = localStorage.getItem(key);
        if (!isNullOrUndefined(value)) {
          savedKeys[key] = value;
        }
      }
    }
    this.storage.clear();
    for (const key of Object.keys(savedKeys)) {
      this.storage.setItem(key, savedKeys[key]);
    }
  }
  getItem(key) {
    return this.storage.getItem(key);
  }
  get length() {
    return this.storage.length;
  }
}
class MemoryStorageManager {
  constructor(store = {}) {
    this.store = store;
  }
  clear() {
    this.store = {};
  }
  getItem(key) {
    return this.store[key] ?? null;
  }
  setItem(key, value) {
    this.store[key] = value;
  }
  removeItem(key) {
    delete this.store[key];
  }
  key(index) {
    return Object.keys(this.store)[index] ?? null;
  }
  get length() {
    return Object.keys(this.store).length;
  }
}
const getTestingKeyName = () => "__TEST_KEY__";
const TEST_VALUE = "1";
let cache;
function isLocalStorageAvailable() {
  if (process.env.VUE_APP_RENDERING_SSR || process.env.NODE_ENV === "test" || !window) {
    return false;
  }
  if (window.navigator && window.navigator.cookieEnabled === false) {
    return false;
  }
  if (cache !== void 0) return cache;
  const { localStorage: localStorage2 } = globalThis;
  try {
    if (!localStorage2) {
      cache = false;
      return cache;
    }
    const key = getTestingKeyName();
    localStorage2.setItem(key, TEST_VALUE);
    cache = localStorage2.getItem(key) === TEST_VALUE;
    return cache;
  } catch {
  } finally {
    try {
      localStorage2.removeItem(getTestingKeyName());
    } catch {
    }
  }
  return false;
}
let storage;
function getUniversalStorageSingleton() {
  if (!storage) {
    storage = isLocalStorageAvailable() ? new LocalStorageManager() : new MemoryStorageManager();
  }
  return storage;
}
function useLocalStorageManager() {
  return getUniversalStorageSingleton();
}
export {
  LocalStorageManager,
  MemoryStorageManager,
  isLocalStorageAvailable,
  useLocalStorageManager
};
