import { format } from "util";
import { isOneOfValues, assert, isString } from "@leon-hub/guards";
const envPrefix = "VUE_APP_";
const EnvValueEnabled = "1";
const EnvValueDisabled = void 0;
const ERROR_MESSAGE_CONFLICTS = "Conflicting environment variables";
const PRODUCTION = "production";
const DEVELOPMENT = "development";
const TEST = "test";
const definedNodeEnvs = /* @__PURE__ */ Object.freeze([
  TEST,
  PRODUCTION,
  DEVELOPMENT
]);
function ensureEnvValue(env, envKey, values, isOptional) {
  const envValue = env[envKey];
  if (isOptional && envValue === void 0 || isOneOfValues(values, envValue)) {
    return envValue;
  }
  throw new Error(
    format(
      "Unexpected environment value (%s) for key process.env.%s, expected values: %s",
      envValue === void 0 ? "<undefined>" : `"${envValue}"`,
      envKey,
      values.map(String).join(", ")
    )
  );
}
const definedNodeEnvsSet = /* @__PURE__ */ new Set(definedNodeEnvs);
function isDefinedNodeEnv(value) {
  return typeof value === "string" && definedNodeEnvsSet.has(value);
}
function isEnvFlagValue(value) {
  return value === EnvValueEnabled || value === EnvValueDisabled;
}
function capitalize$1(content) {
  return content.toUpperCase();
}
function composeEnvName(prefix, key, value) {
  return capitalize$1(`${prefix}${key}_${value}`);
}
function capitalize(content) {
  return content.toUpperCase();
}
function toEnvVar(prefix, key) {
  return capitalize(`${prefix}${key}`);
}
function composeEnvNames(prefix, key, values) {
  return values.map((value) => toEnvVar(prefix, `${key}_${value}`));
}
function unTypeEnv(env) {
  return env;
}
function decomposeEnvValue(env, key, config, allowedValues, envPrefix2) {
  var _a;
  return ((_a = config[key]) == null ? void 0 : _a.reduce((acc, value) => {
    const envKey = toEnvVar(envPrefix2, `${key}_${value}`);
    const envValue = ensureEnvValue(unTypeEnv(env), envKey, allowedValues);
    if (envValue === void 0) return acc;
    return acc.concat({
      envKey,
      envValue,
      value
    });
  }, [])) ?? [];
}
function getEnvFlagValues() {
  return [
    EnvValueEnabled,
    EnvValueDisabled
  ];
}
const envFlagValues = /* @__PURE__ */ getEnvFlagValues();
function stringify(entries) {
  return JSON.stringify(entries.map(([key, value]) => `${key}=${value}`), null, 2);
}
function createEnvValueGetter(config, envPrefix2) {
  return function getEnvVarValue(env, key) {
    assert(isString(key), "Expected key to be a string.");
    const envKey = toEnvVar(envPrefix2, key);
    const envValue = ensureEnvValue(unTypeEnv(env), envKey, config[key], true);
    const envValues = decomposeEnvValue(env, key, config, envFlagValues, envPrefix2);
    assert(envValues.length < 2, `${ERROR_MESSAGE_CONFLICTS}: ${stringify(
      envValues.map((item) => [item.envKey, item.envValue])
    )}`);
    if (envValues.length === 1) {
      const decomposedEntry = envValues[0];
      const decomposedEnvValue = decomposedEntry.value;
      const decomposedEnvKey = decomposedEntry.envKey;
      if (envValue && decomposedEnvValue) {
        assert(isString(envValue) && isString(decomposedEnvValue));
        assert(envValue === decomposedEnvValue, `${ERROR_MESSAGE_CONFLICTS}: ${stringify([
          [envKey, envValue],
          [decomposedEnvKey, decomposedEnvValue]
        ])}`);
      }
      return decomposedEnvValue;
    }
    return envValue;
  };
}
function createEnvVarSetter(config, envPrefix2) {
  return (env, key, value) => {
    const composedEnvNames = composeEnvNames(envPrefix2, key, config[key]);
    const activeComposedEnvName = value ? toEnvVar(envPrefix2, `${key}_${value}`) : void 0;
    const resultEnv = {
      ...env,
      [toEnvVar(envPrefix2, key)]: value
    };
    return composedEnvNames.reduce((acc, composedEnvName) => ({
      ...acc,
      [composedEnvName]: composedEnvName === activeComposedEnvName ? EnvValueEnabled : EnvValueDisabled
    }), resultEnv);
  };
}
export {
  DEVELOPMENT,
  ERROR_MESSAGE_CONFLICTS,
  EnvValueDisabled,
  EnvValueEnabled,
  PRODUCTION,
  TEST,
  composeEnvName,
  composeEnvNames,
  createEnvValueGetter,
  createEnvVarSetter,
  decomposeEnvValue,
  definedNodeEnvs,
  ensureEnvValue,
  envPrefix,
  getEnvFlagValues,
  isDefinedNodeEnv,
  isEnvFlagValue,
  toEnvVar,
  unTypeEnv
};
