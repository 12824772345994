import { default as default2 } from "lodash.iserror";
import { Validator } from "jsonschema";
class AssertionError extends Error {
  constructor(message) {
    super(message);
    Object.setPrototypeOf(this, AssertionError.prototype);
  }
}
const defaultAssertionMessage = "Assert failed";
function assert(condition, options) {
  let optionsData;
  if (options) {
    optionsData = typeof options === "string" ? {
      message: options,
      error: void 0
    } : options;
  } else {
    optionsData = {
      message: void 0,
      error: void 0
    };
  }
  const {
    message = defaultAssertionMessage,
    error
  } = optionsData;
  if (!condition) {
    if (error) {
      if (typeof error === "function") {
        throw error();
      } else {
        throw error;
      }
    } else {
      throw new AssertionError(message);
    }
  }
}
function assertNever(value) {
  assert(false, `Never expected case: ${value}`);
}
function isArray(value) {
  return Array.isArray(value);
}
function isBoolean(value) {
  return typeof value === "boolean";
}
function isNull(value) {
  return value === null;
}
function isNumber(value) {
  return typeof value === "number" && !Number.isNaN(value);
}
function isObject(argument) {
  return argument !== null && typeof argument === "object";
}
const has = /* @__PURE__ */ Function.call.bind(/* @__PURE__ */ (() => Object.prototype.hasOwnProperty)());
function isObjectHasKey(target, key, validator) {
  const value = target[key];
  return validator ? validator(value) : has(target, String(key));
}
function isOptional(validate, value) {
  return value === void 0 || validate(value);
}
function createOptionalGuard(validate) {
  return isOptional.bind(void 0, validate);
}
function isPlainObject(argument) {
  if (isObject(argument)) {
    if (typeof Object.getPrototypeOf === "function") {
      const proto = Object.getPrototypeOf(argument);
      if (typeof proto === "object") {
        if (proto === null) return true;
        if (!(proto.constructor === Object || proto === Object.prototype)) {
          return false;
        }
      }
    }
    return Object.prototype.toString.call(argument) === "[object Object]";
  }
  return false;
}
const primitivesTypes = ["string", "number", "bigint", "boolean", "symbol", "undefined"];
function isPrimitive(value, target) {
  if (typeof target === "undefined") {
    return target === null || primitivesTypes.includes(typeof target);
  }
  return value === target;
}
function createPrimitiveGuard(primitive) {
  return (value) => isPrimitive(value, primitive);
}
function isString(value) {
  return typeof value === "string";
}
const isTrue = /* @__PURE__ */ createPrimitiveGuard(true);
function log({
  target,
  value,
  key,
  guards,
  guard
}) {
  console.groupCollapsed(`All guards failed for key=${String(key)}`);
  console.log("key", key);
  console.log("value", value);
  console.log("target", target);
  if (guards) {
    console.log("guards", guards);
  }
  if (guard) {
    console.log("guard", guard);
  }
  console.groupEnd();
}
function isValidInterface(target, fieldGuards, { silent = false } = {}) {
  return isObject(target) && [
    ...Object.keys(fieldGuards),
    ...Object.getOwnPropertySymbols(fieldGuards)
  ].every((key) => {
    const guards = fieldGuards[key];
    const value = target[key];
    let result;
    if (Array.isArray(guards)) {
      result = guards.some((guard) => guard(value));
      if (!result && !silent) {
        log({
          key,
          value,
          target,
          guards
        });
      }
    } else {
      const guard = guards;
      result = guard(value);
      if (!result && !silent) {
        log({
          key,
          value,
          target,
          guard
        });
      }
    }
    return result;
  });
}
function bindIsValidInterface(fieldGuards) {
  return (value) => isValidInterface(value, fieldGuards);
}
function ensure(guard, value, options) {
  assert(guard(value), ...options ? [options] : []);
  return value;
}
function isArrayOf(isGuard, value) {
  return Array.isArray(value) && value.every((item) => isGuard(item));
}
function bindIsArrayOf(typeGuard) {
  return (value) => isArrayOf(typeGuard, value);
}
function isArrayOfBooleans(value) {
  return isArray(value) && value.every(isBoolean);
}
function isArrayOfNumbers(value) {
  return isArray(value) && value.every(isNumber);
}
function isArrayOfStrings(items) {
  return isArray(items) && items.every((item) => isString(item));
}
function isNullOrUndefined(value) {
  return typeof value === "undefined" || value === null;
}
function isMeasurable(value, measurePropertyKey) {
  if (!isObject(value)) {
    return false;
  }
  return measurePropertyKey in value;
}
function isEmpty(value) {
  if (isNullOrUndefined(value)) {
    return true;
  }
  if (typeof value === "string" && value.length === 0) {
    return true;
  }
  if (typeof value === "function") {
    return false;
  }
  if (isPlainObject(value) && Object.keys(value).length === 0) {
    return true;
  }
  if (isArray(value) && value.length === 0) {
    return true;
  }
  const wrapped = Object(value);
  return isMeasurable(wrapped, "size") && wrapped.size === 0 || isMeasurable(wrapped, "length") && wrapped.length === 0;
}
const isEnumOfType = (enumValue) => {
  const enumValues = Object.values(enumValue);
  return (item) => enumValues.includes(item);
};
function isEnumValueType(enumValue, value) {
  return typeof Object.values(enumValue)[0] === typeof value;
}
function bindIsEnumValueType(enumValue) {
  return (value) => isEnumValueType(enumValue, value);
}
function isEqual(comparable, value) {
  return value === comparable;
}
const hasSymbol = typeof Symbol === "function" && typeof /* @__PURE__ */ (() => Symbol.toStringTag)() === "symbol";
const defaultGuard = (value) => true;
const isStringValueModule = /* @__PURE__ */ createPrimitiveGuard("Module");
function isESModule(value, guard = defaultGuard) {
  const options = {
    __esModule: isTrue,
    default: guard
  };
  if (hasSymbol) {
    Object.assign(options, {
      [Symbol.toStringTag]: isStringValueModule
    });
  }
  return isValidInterface(value, options);
}
const isFinite = /* @__PURE__ */ (() => Number.isFinite)();
function isFloat(value) {
  return isFinite(value) && value % 1 === 1;
}
function isFunction(argument) {
  return typeof argument === "function";
}
function isNotNull(value) {
  return value !== null;
}
function isNullableString(value) {
  return isString(value) || isNull(value);
}
const isNumberFinite = (value) => isNumber(value) && Number.isFinite(value);
function isSomeGuard(guards, value) {
  return guards.some((guard) => guard(value));
}
function isObjectValues(guards, value) {
  return isObject(value) && Object.values(value).every((item) => isSomeGuard(guards, item));
}
function isOneOfValues(values, value) {
  return values.includes(value);
}
const isOptionalArray = /* @__PURE__ */ createOptionalGuard(isArray);
const isOptionalBoolean = /* @__PURE__ */ createOptionalGuard(isBoolean);
const isOptionalNumber = /* @__PURE__ */ createOptionalGuard(isNumber);
const isOptionalString = /* @__PURE__ */ createOptionalGuard(isString);
function isRegExp(value) {
  return value instanceof RegExp;
}
function isTruthyString(value) {
  return !!(typeof value === "string" && value);
}
function isUndefined(value) {
  return value === void 0;
}
function isDataValidJsonSchema(input, schema, noEmitErrorToConsole = false) {
  const validator = new Validator();
  const result = validator.validate(input, schema);
  if (result.valid) {
    return true;
  }
  if (noEmitErrorToConsole) {
    return false;
  }
  console.error("invalid input", result.errors);
  return false;
}
function isValidObject(input, rules, silent = false) {
  if (!isObject(input)) {
    return false;
  }
  const inputKeys = Object.keys(input);
  const rulesKeys = Object.keys(rules);
  const unknownKeys = rulesKeys.concat(inputKeys).filter((key) => !rulesKeys.includes(key));
  if (unknownKeys.length > 0) {
    if (!silent) {
      console.warn("Unexpected key:", ...unknownKeys);
    }
    return false;
  }
  return rulesKeys.every((key) => {
    const validators = rules[key];
    const result = validators.some((validator) => {
      if (Array.isArray(validator)) {
        return validator.every((andValidator) => andValidator(input[key]));
      }
      return validator(input[key]);
    });
    if (!result && !silent) {
      console.groupCollapsed("Invalid field", key, input[key]);
      console.log("target", input);
      console.groupEnd();
    }
    return result;
  });
}
function unionGuards(...guards) {
  return (value) => guards.every((guard) => guard(value));
}
export {
  assert,
  assertNever,
  bindIsArrayOf,
  bindIsEnumValueType,
  bindIsValidInterface,
  createOptionalGuard,
  createPrimitiveGuard,
  ensure,
  isArray,
  isArrayOf,
  isArrayOfBooleans,
  isArrayOfNumbers,
  isArrayOfStrings,
  isBoolean,
  isESModule,
  isEmpty,
  isEnumOfType,
  isEnumValueType,
  isEqual,
  default2 as isError,
  isFloat,
  isFunction,
  isNotNull,
  isNull,
  isNullOrUndefined,
  isNullableString,
  isNumber,
  isNumberFinite,
  isObject,
  isObjectHasKey,
  isObjectValues,
  isOneOfValues,
  isOptional,
  isOptionalArray,
  isOptionalBoolean,
  isOptionalNumber,
  isOptionalString,
  isPlainObject,
  isPrimitive,
  isRegExp,
  isSomeGuard,
  isString,
  isTruthyString,
  isUndefined,
  isValidInterface,
  isDataValidJsonSchema as isValidJsonSchema,
  isValidObject,
  unionGuards
};
