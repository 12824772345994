import { isLocalStorageAvailable, useLocalStorageManager } from "@leon-hub/local-storage";
var DEBUG_KEY = /* @__PURE__ */ ((DEBUG_KEY2) => {
  DEBUG_KEY2["GENERAL"] = "DEBUG";
  DEBUG_KEY2["POST_MESSAGE"] = "DEBUG_POST_MESSAGE";
  DEBUG_KEY2["ADVANCED_HTTP_REQUESTS_DISABLED"] = "ADVANCED_HTTP_REQUESTS_DISABLED";
  DEBUG_KEY2["APK_UPDATE_EMULATED_DOWNLOAD_FAIL"] = "APK_UPDATE_EMULATED_DOWNLOAD_FAIL";
  DEBUG_KEY2["APK_UPDATE_EMULATED_DOWNLOAD_FAIL_ENOSPC"] = "APK_UPDATE_EMULATED_DOWNLOAD_FAIL_ENOSPC";
  DEBUG_KEY2["APK_UPDATE_EMULATED_INSTALL_FAIL"] = "APK_UPDATE_EMULATED_INSTALL_FAIL";
  return DEBUG_KEY2;
})(DEBUG_KEY || {});
function isDebugEnabled(debugKey = "DEBUG") {
  return isLocalStorageAvailable() ? Boolean(useLocalStorageManager().getItem(debugKey)) : false;
}
function enableDebug(debugKey = "DEBUG") {
  if (isLocalStorageAvailable()) {
    useLocalStorageManager().setItem(debugKey, "1");
  }
}
function disableDebug(debugKey = "DEBUG") {
  if (isLocalStorageAvailable()) {
    useLocalStorageManager().removeItem(debugKey);
  }
}
export {
  DEBUG_KEY,
  disableDebug,
  enableDebug,
  isDebugEnabled
};
