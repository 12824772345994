import { Theme } from "@leon-hub/api-sdk";
import { isValidInterface, isObjectValues, isOptionalNumber, isBoolean, isString, assert } from "@leon-hub/guards";
const defaultConfig = {
  isNT: /* @__PURE__ */ (() => process.env.NODE_ENV)() === "development",
  isQueryHashingEnabled: false,
  theme: { default: /* @__PURE__ */ (() => Theme.DARK)() },
  adPaths: {}
};
function isBuiltinConfig(target) {
  return typeof target === "undefined" || isValidInterface(target, {
    isNT: isBoolean,
    isQueryHashingEnabled: isBoolean,
    time: isOptionalNumber,
    docParseTime: isOptionalNumber,
    theme: (value) => isObjectValues([isString], value),
    adPaths: (value) => isObjectValues([isString], value)
  });
}
function getBuiltinConfig() {
  var _a, _b;
  if (process.env.VUE_APP_RENDERING_SSR) {
    const theme = (_a = useCookie("theme")) == null ? void 0 : _a.toLowerCase();
    return {
      isNT: false,
      isQueryHashingEnabled: false,
      adPaths: {},
      theme: {
        current: theme ?? "",
        default: theme ?? ""
      }
    };
  }
  if (process.env.NODE_ENV === "development") {
    const theme = (_b = document.documentElement.className.match(/app__theme--(\w+)/)) == null ? void 0 : _b.pop();
    return {
      isNT: true,
      isQueryHashingEnabled: false,
      adPaths: {},
      theme: {
        current: theme ?? "",
        default: theme ?? ""
      }
    };
  }
  const config = typeof window === "undefined" ? defaultConfig : window.initConfig || defaultConfig;
  assert(isBuiltinConfig(config), `Invalid builtin config: ${JSON.stringify(config)}`);
  return config;
}
export {
  defaultConfig,
  getBuiltinConfig,
  isBuiltinConfig
};
