import { assert, isFunction, isObject, isArray, isUndefined, isString, isFloat } from "@leon-hub/guards";
import { v4 } from "uuid";
import { Comment } from "vue";
import crypto from "node:crypto";
function isVisibleInDOM(element) {
  const computedStyles = window.getComputedStyle(element);
  const visible = computedStyles.visibility !== "hidden";
  if (visible) {
    return visible;
  }
  return false;
}
function getClientDimension() {
  return {
    clientHeight: document.documentElement.clientHeight || 0,
    clientWidth: document.documentElement.clientWidth || 0
  };
}
function getPixelRatio() {
  if (process.env.VUE_APP_RENDERING_SSR) {
    return void 0;
  }
  const { devicePixelRatio } = window;
  return !devicePixelRatio ? void 0 : Math.floor(Math.min(devicePixelRatio, 3));
}
function getViewportDimension() {
  return {
    viewportHeight: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
    viewportWidth: Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  };
}
const responseTimeDeadlineMs = 50;
let requestIdleCallbackFn = (callback) => {
  const start = Date.now();
  return +setTimeout(() => {
    callback({
      didTimeout: false,
      timeRemaining() {
        return Math.max(0, responseTimeDeadlineMs - (Date.now() - start));
      }
    });
  }, 1);
};
if (/* @__PURE__ */ (() => process.env.VUE_APP_RENDERING_CSR)() && typeof window !== "undefined" && "requestIdleCallback" in window) {
  requestIdleCallbackFn = /* @__PURE__ */ (() => window.requestIdleCallback)();
}
const requestIdleCallback = /* @__PURE__ */ requestIdleCallbackFn.bind(globalThis);
const requestIdleCallback$1 = /* @__PURE__ */ (() => process.env.VUE_APP_RENDERING_SSR)() ? requestIdleCallbackFn : requestIdleCallback;
class IdleTimeQueueManager {
  constructor(options2 = {
    timeout: 1e3
  }) {
    this.options = options2;
    this.taskList = [];
    this.currentTaskNumber = 0;
    this.totalTasksToComplete = 0;
    this.totalTasksHaveBeenCompleted = 0;
    this.taskHandle = void 0;
  }
  static getInstance() {
    if (!IdleTimeQueueManager.instance) {
      IdleTimeQueueManager.instance = new IdleTimeQueueManager();
    }
    return IdleTimeQueueManager.instance;
  }
  runTaskQueue(deadline) {
    while ((deadline.timeRemaining() > 0 || deadline.didTimeout) && this.taskList.length) {
      const task = this.taskList.shift();
      this.currentTaskNumber += 1;
      assert(task, "Task should not be undefined");
      task.handler(task.payload);
      this.totalTasksHaveBeenCompleted += 1;
      this.totalTasksToComplete -= 1;
    }
    if (this.taskList.length) {
      this.taskHandle = requestIdleCallback$1(this.runTaskQueue.bind(this), { timeout: this.options.timeout });
    } else {
      this.taskHandle = 0;
    }
  }
  enqueueTask(taskHandler, payload) {
    this.taskList.push({
      handler: taskHandler,
      payload
    });
    this.totalTasksToComplete += 1;
    if (!this.taskHandle) {
      this.taskHandle = requestIdleCallback$1(this.runTaskQueue.bind(this), { timeout: this.options.timeout });
    }
  }
}
class ScriptElement {
  constructor(options2) {
    this.options = options2;
  }
  addScript() {
    return new Promise((resolve, reject) => {
      const { name } = this.options;
      const source = "src" in this.options ? this.options.src : void 0;
      const content = "content" in this.options ? this.options.content : void 0;
      if (!source && !name) {
        reject(new Error("Empty script selector"));
        return;
      }
      const querySelector = name ? `script[n="${name}"]` : `script[src="${source}"]`;
      const existedScript = document.querySelector(querySelector);
      if (existedScript) {
        this.script = existedScript;
        if (existedScript.dataset.loaded) {
          resolve();
        } else {
          existedScript.addEventListener("load", () => resolve());
        }
        return;
      }
      const js = document.createElement("script");
      js.type = "text/javascript";
      js.async = !!this.options.async;
      js.defer = !!this.options.defer;
      if (this.options.onload && isFunction(this.options.onload)) {
        js.onload = this.options.onload;
      }
      if (this.options.onerror && isFunction(this.options.onerror)) {
        js.onerror = this.options.onerror;
      }
      if (name) {
        js.setAttribute("n", name);
      }
      if (source) {
        js.src = source;
        js.addEventListener("load", () => {
          js.dataset.loaded = "loaded";
          return resolve();
        });
        js.addEventListener("error", () => reject(new Error(`Can't load script ${js.src}`)));
        this.script = js;
        this.appendedToTarget = this.options.appendTarget || "head";
        document[this.appendedToTarget].append(js);
        return;
      }
      if (content) {
        js.innerHTML = content;
        js.dataset.loaded = "loaded";
        this.script = js;
        this.appendedToTarget = this.options.appendTarget || "body";
        document[this.appendedToTarget].append(js);
        resolve();
        return;
      }
      reject(new Error("Insert script error"));
    });
  }
  removeScript() {
    if (this.script && this.appendedToTarget) {
      document[this.appendedToTarget].removeChild(this.script);
    }
  }
}
class StyleElement {
  constructor(options2) {
    this.options = options2;
  }
  addStyle() {
    return new Promise((resolve, reject) => {
      const { name } = this.options;
      const source = "src" in this.options ? this.options.src : void 0;
      const content = "content" in this.options ? this.options.content : void 0;
      if (!source && !name) {
        reject(new Error("Empty style selector"));
        return;
      }
      const querySelector = name ? source ? `link[n="${name}"]` : `style[n="${name}"]` : `link[href="${source}"]`;
      const existedElement = document.querySelector(querySelector);
      if (existedElement) {
        this.styleElement = existedElement;
        if (existedElement.dataset.loaded) {
          resolve();
        } else {
          existedElement.addEventListener("load", () => resolve());
        }
        return;
      }
      if (source) {
        const element = document.createElement("link");
        if (name) {
          element.setAttribute("n", name);
        }
        element.href = source;
        element.type = "text/css";
        element.rel = "stylesheet";
        element.addEventListener("load", () => {
          element.dataset.loaded = "loaded";
          return resolve();
        });
        element.addEventListener("error", () => reject(new Error(`Can't load style ${element.href}`)));
        this.styleElement = element;
        this.appendedToTarget = this.options.appendTarget || "head";
        document[this.appendedToTarget].append(element);
        return;
      }
      if (content) {
        const element = document.createElement("style");
        if (name) {
          element.setAttribute("n", name);
        }
        element.type = "text/css";
        element.innerHTML = content;
        element.dataset.loaded = "loaded";
        this.styleElement = element;
        this.appendedToTarget = this.options.appendTarget || "body";
        document[this.appendedToTarget].append(element);
        resolve();
        return;
      }
      reject(new Error("Insert script error"));
    });
  }
  removeStyle() {
    if (this.styleElement && this.appendedToTarget) {
      document[this.appendedToTarget].removeChild(this.styleElement);
    }
  }
}
class Timer {
  static setInterval(callback, timeout) {
    if (!process.env.VUE_APP_RENDERING_SSR && !process.env.VUE_APP_PRERENDER) {
      return window.setInterval(callback, timeout);
    }
    return 0;
  }
  static setTimeout(callback, timeout) {
    if (!process.env.VUE_APP_RENDERING_SSR && !process.env.VUE_APP_PRERENDER) {
      return window.setTimeout(callback, timeout);
    }
    return 0;
  }
  static clearTimeout(timeout) {
    if (!process.env.VUE_APP_RENDERING_SSR && !process.env.VUE_APP_PRERENDER) {
      window.clearTimeout(timeout);
    }
  }
  static clearInterval(timeout) {
    if (!process.env.VUE_APP_RENDERING_SSR && !process.env.VUE_APP_PRERENDER) {
      window.clearInterval(timeout);
    }
  }
}
var DecodeSymbol = /* @__PURE__ */ ((DecodeSymbol2) => {
  DecodeSymbol2["DEFAULT"] = "*";
  DecodeSymbol2["BULL"] = "•";
  DecodeSymbol2["ZERO"] = "0";
  return DecodeSymbol2;
})(DecodeSymbol || {});
class DecodeValue {
  constructor(value, props) {
    this.regex = `[亜哀挨愛曖悪握圧扱宛嵐安案暗以衣位囲医依委威為畏胃尉異移萎偉椅彙意違維慰遺緯
                            域育一壱逸茨芋引印因咽姻員院淫陰飲隠韻右宇羽雨唄鬱畝浦運雲永泳英映栄営詠影鋭衛易疫益液駅
                            悦越謁閲円延沿炎怨宴媛援園煙猿遠鉛塩]+`;
    this.regexp = new RegExp(this.regex, "g");
    this.symbol = "*";
    this.value = value;
    if (props == null ? void 0 : props.symbol)
      this.symbol = props.symbol;
    if (props == null ? void 0 : props.regex)
      this.regex = props.regex;
  }
  toDecodedString() {
    return this.value.replace(this.regexp, (foundSymbols) => {
      let symbolsCount = foundSymbols.length;
      let result = "";
      while (symbolsCount) {
        result += this.symbol;
        symbolsCount -= 1;
      }
      return result;
    });
  }
}
function getRandomValues(array) {
  if (process.env.VUE_APP_RENDERING_SSR || process.env.NODE_ENV === "test") {
    return crypto.getRandomValues(array);
  } else {
    const crypto2 = window.crypto || window.msCrypto;
    if (crypto2) {
      return crypto2.getRandomValues(array);
    }
  }
  throw new Error("No crypto support");
}
const ruPatternLocaleDateTime = "DD MMMM yyyy";
const addLeadingZero = (value, maxLength) => `${value}`.padStart(maxLength, "0");
var TimeFormats = /* @__PURE__ */ ((TimeFormats2) => {
  TimeFormats2["hoursMinutes"] = "hh:mm";
  TimeFormats2["hoursMinutesSeconds"] = "hh:mm:ss";
  TimeFormats2["day"] = "dd";
  TimeFormats2["monthShortLocal"] = "MMM";
  TimeFormats2["dayMonth"] = "dd.MM";
  TimeFormats2["dayMonthShortYear"] = "dd.MM.yy";
  TimeFormats2["dayMonthYear"] = "dd.MM.yyyy";
  TimeFormats2["dayMonthYearHoursMinutes"] = "dd.MM.yyyy hh:mm";
  TimeFormats2["dayMonthHoursMinutes"] = "dd.MM hh:mm";
  TimeFormats2["minutesSeconds"] = "nn:ss";
  TimeFormats2["inputDate"] = "yyyy-MM-dd";
  TimeFormats2["inputDateTimeLocal"] = "yyyy-MM-ddThh:mm";
  return TimeFormats2;
})(TimeFormats || {});
var InputDateFormats = /* @__PURE__ */ ((InputDateFormats2) => {
  InputDateFormats2["dayMonthYear"] = "dd.MM.yyyy";
  InputDateFormats2["inputDate"] = "yyyy-MM-dd";
  return InputDateFormats2;
})(InputDateFormats || {});
function toDate(timestamp) {
  return typeof timestamp === "number" || typeof timestamp === "string" ? new Date(timestamp) : timestamp;
}
function isToday(timestamp) {
  const date = toDate(timestamp);
  const today = /* @__PURE__ */ new Date();
  return date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
}
const _DateTime = class _DateTime {
  constructor(time, format) {
    this.dateNormalization = {
      [
        "dd.MM.yyyy"
        /* dayMonthYear */
      ]: {
        from: /^(\d{2})\.(\d{2})\.(\d{4})$/,
        to: "$3-$2-$1"
      },
      [
        "yyyy-MM-dd"
        /* inputDate */
      ]: {
        from: /^/,
        to: ""
      }
    };
    if (typeof time === "number") {
      this.timestamp = time;
    } else if (format) {
      this.timestamp = this.getTimestamp(time, format);
    } else {
      this.timestamp = Number.parseInt(time, 10);
    }
    if (Number.isNaN(this.timestamp)) {
      throw new TypeError(`Unexpected timestamp: "${time}"`);
    }
  }
  static getDayMonthYearLocalized(locale, day, month, year) {
    switch (locale.toLowerCase()) {
      case "de_de":
      case "ru_ru":
      case "ru_kz":
        return `${day}.${month}.${year}`;
      case "en_ca":
      case "en_nz":
      case "fr_ca":
        return `${year}-${month}-${day}`;
      case "en_us":
      case "el_gr":
      case "en_in":
      case "en_au":
      case "en_se":
      case "pt_pt":
      case "br_br":
      case "fr_fr":
      case "es_cl":
      case "es_pe":
      case "th_th":
      case "vn_vn":
      case "tr_tr":
      case "it_it":
      case "sw_tz":
      default:
        return `${day}/${month}/${year}`;
    }
  }
  static getPrettyDay(date) {
    const day = date.getDate();
    return day < 10 ? `0${day}` : `${day}`;
  }
  static getPrettyMonth(date) {
    const mm = date.getMonth() + 1;
    return addLeadingZero(mm, 2);
  }
  static getDay(date) {
    return `${_DateTime.getPrettyDay(date)}`;
  }
  static getShortMonthLocalized(date, locale) {
    return `${date.toLocaleString(locale == null ? void 0 : locale.replace(/_/g, "-"), { month: "short" })}`;
  }
  static getDayAndMonth(date) {
    return `${_DateTime.getPrettyDay(date)}.${_DateTime.getPrettyMonth(date)}`;
  }
  static getDayMonthYear(date, locale) {
    const dayMonth = _DateTime.getDayAndMonth(date);
    const day = _DateTime.getPrettyDay(date);
    const month = _DateTime.getPrettyMonth(date);
    const year = date.getFullYear();
    return locale ? _DateTime.getDayMonthYearLocalized(locale, day, month, year.toString()) : `${dayMonth}.${year}`;
  }
  static getDayMonthShortYear(date, locale) {
    const dayMonth = _DateTime.getDayAndMonth(date);
    const day = _DateTime.getPrettyDay(date);
    const month = _DateTime.getPrettyMonth(date);
    const year = date.getFullYear();
    const shortYear = year.toString().slice(2, 4);
    return locale ? _DateTime.getDayMonthYearLocalized(locale, day, month, shortYear.toString()) : `${dayMonth}.${shortYear}`;
  }
  static getDayMonthYearHoursMinutes(date) {
    const dayMonth = _DateTime.getDayAndMonth(date);
    const year = date.getFullYear();
    const time = _DateTime.getHoursAndMinutes(date);
    return `${dayMonth}.${year} ${time}`;
  }
  static getDayMonthHoursMinutes(date) {
    const dayMonth = _DateTime.getDayAndMonth(date);
    const time = _DateTime.getHoursAndMinutes(date);
    return `${dayMonth} ${time}`;
  }
  static getMinutesSeconds(date) {
    const mm = date.getMinutes();
    const ss = date.getSeconds();
    return `${addLeadingZero(mm, 2)}:${addLeadingZero(ss, 2)}`;
  }
  static getInputDateTimeLocal(date) {
    const day = _DateTime.getPrettyDay(date);
    const month = _DateTime.getPrettyMonth(date);
    const year = date.getFullYear();
    const time = _DateTime.getHoursAndMinutes(date);
    return `${year}-${month}-${day}T${time}`;
  }
  static getInputDate(date) {
    const day = _DateTime.getPrettyDay(date);
    const month = _DateTime.getPrettyMonth(date);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }
  static getTimezoneOffset(timestamp = 0) {
    const date = new Date(timestamp);
    return date.getTimezoneOffset();
  }
  static formatTimeStamp(timestamp, format, locale) {
    const date = new Date(timestamp);
    switch (format) {
      case "hh:mm":
        return _DateTime.getHoursAndMinutes(date);
      case "hh:mm:ss":
        return _DateTime.getHoursMinutesSeconds(date);
      case "dd.MM":
        return _DateTime.getDayAndMonth(date);
      case "dd.MM.yyyy":
        return _DateTime.getDayMonthYear(date, locale);
      case "dd.MM.yy":
        return _DateTime.getDayMonthShortYear(date, locale);
      case "dd.MM.yyyy hh:mm":
        return _DateTime.getDayMonthYearHoursMinutes(date);
      case "dd.MM hh:mm":
        return _DateTime.getDayMonthHoursMinutes(date);
      case "nn:ss":
        return _DateTime.getMinutesSeconds(date);
      case "yyyy-MM-dd":
        return _DateTime.getInputDate(date);
      case "yyyy-MM-ddThh:mm":
        return _DateTime.getInputDateTimeLocal(date);
      case "dd":
        return _DateTime.getDay(date);
      case "MMM":
        return _DateTime.getShortMonthLocalized(date, locale);
      default:
        throw new Error(`Unexpected format: "${format}"`);
    }
  }
  static roundToDayStart(timestamp) {
    const date = toDate(timestamp);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  }
  static roundToDayEnd(timestamp) {
    const date = toDate(timestamp);
    date.setHours(23, 59, 59, 999);
    return date.getTime();
  }
  static withTimeStamp(timestamp) {
    return new _DateTime(timestamp);
  }
  static toTimestamp(date) {
    const dateInstance = typeof date === "string" || typeof date === "number" ? new Date(date) : date;
    return Number(dateInstance);
  }
  static timestampSecondsToDate(timestampSeconds, toUTC = false) {
    if (toUTC) {
      const unixStartDate = new Date(Date.UTC(1970, 0, 1));
      unixStartDate.setUTCSeconds(timestampSeconds);
      return unixStartDate;
    }
    const date = new Date(1970, 0, 1);
    date.setSeconds(timestampSeconds);
    return date;
  }
  static now() {
    return new _DateTime(Date.now());
  }
  getTimestamp(date, format) {
    const { from, to } = this.dateNormalization[format];
    return new Date(date.replace(from, to)).getTime();
  }
  toISOString() {
    return new Date(this.timestamp).toISOString();
  }
  toTimestamp() {
    return this.timestamp;
  }
  toTime() {
    return _DateTime.formatTimeStamp(
      this.timestamp,
      "hh:mm"
      /* hoursMinutes */
    );
  }
  toDate() {
    return _DateTime.formatTimeStamp(
      this.timestamp,
      "dd.MM"
      /* dayMonth */
    );
  }
  toFullDate() {
    return _DateTime.formatTimeStamp(
      this.timestamp,
      "dd.MM.yyyy"
      /* dayMonthYear */
    );
  }
  toDateTime() {
    return _DateTime.formatTimeStamp(
      this.timestamp,
      "dd.MM.yyyy hh:mm"
      /* dayMonthYearHoursMinutes */
    );
  }
  toShortDateTime() {
    return _DateTime.formatTimeStamp(
      this.timestamp,
      "dd.MM hh:mm"
      /* dayMonthHoursMinutes */
    );
  }
  toInputDate() {
    return _DateTime.formatTimeStamp(
      this.timestamp,
      "yyyy-MM-dd"
      /* inputDate */
    );
  }
  toInputDateTimeLocal() {
    return _DateTime.formatTimeStamp(
      this.timestamp,
      "yyyy-MM-ddThh:mm"
      /* inputDateTimeLocal */
    );
  }
};
_DateTime.getHoursAndMinutes = (date) => {
  const hh = date.getHours();
  const mm = date.getMinutes();
  return `${addLeadingZero(hh, 2)}:${addLeadingZero(mm, 2)}`;
};
_DateTime.getHoursMinutesSeconds = (date) => {
  const hh = date.getHours();
  const mm = date.getMinutes();
  const ss = date.getSeconds();
  return `${addLeadingZero(hh, 2)}:${addLeadingZero(mm, 2)}:${addLeadingZero(ss, 2)}`;
};
let DateTime = _DateTime;
class DateTimeInterval {
  static getNow() {
    return Date.now();
  }
  static fromNowPlusHours(count = 1) {
    const now = DateTimeInterval.getNow();
    const oneHour = 60 * 60 * 1e3;
    return new DateTimeInterval(now, now + oneHour * count);
  }
  static fromNowUntilTheEndOfTheDay() {
    const now = DateTimeInterval.getNow();
    const from = new Date(now);
    const end = new Date(now);
    end.setHours(23, 59, 59, 999);
    return new DateTimeInterval(from.getTime(), end.getTime());
  }
  static today() {
    const now = DateTimeInterval.getNow();
    const from = new Date(now);
    const end = new Date(now);
    from.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);
    return new DateTimeInterval(from.getTime(), end.getTime());
  }
  static tomorrow() {
    const todayInterval = DateTimeInterval.today();
    const oneDay = 60 * 60 * 24 * 1e3;
    return new DateTimeInterval(
      todayInterval.from.toTimestamp() + oneDay,
      todayInterval.to.toTimestamp() + oneDay
    );
  }
  static afterTomorrow() {
    const todayInterval = DateTimeInterval.today();
    const oneDay = 60 * 60 * 24 * 1e3;
    return new DateTimeInterval(
      todayInterval.from.toTimestamp() + oneDay * 2,
      todayInterval.to.toTimestamp() + oneDay * 2
    );
  }
  constructor(from, to) {
    this.from = typeof from === "number" || typeof from === "string" ? DateTime.withTimeStamp(from) : from;
    this.to = typeof to === "number" || typeof to === "string" ? DateTime.withTimeStamp(to) : to;
  }
  toObject() {
    return {
      from: this.from.toTimestamp(),
      to: this.to.toTimestamp()
    };
  }
}
function normalizeDate(date) {
  return typeof date === "number" ? new Date(date) : date;
}
function replacePlaceholders({
  date,
  pattern,
  getMonth
}) {
  const d = normalizeDate(date);
  const dDate = d.getDate();
  const dMonth = d.getMonth();
  const dFullYear = String(d.getFullYear());
  return pattern.replace(/(^|[^d])(d{2})($|[^d])/g, `$1${String(dDate)}$3`).replace(/(^|[^D])(D{2})($|[^D])/g, `$1${addLeadingZero(dDate, 2)}$3`).replace(/(^|[^M])(M{4})($|[^M])/g, `$1${getMonth({ id: dMonth, format: "long" })}$3`).replace(/(^|[^M])(M{2})($|[^M])/g, `$1${getMonth({ id: dMonth, format: "short" })}$3`).replace(/(^|[^y])(y{4})($|[^y])/g, `$1${dFullYear}$3`).replace(/(^|[^y])(y{2})($|[^y])/g, `$1${dFullYear.slice(-2)}$3`);
}
function createDateLocalizer(options2, formatPattern) {
  return (date, pattern) => replacePlaceholders({
    ...options2,
    pattern: formatPattern(pattern ?? options2.pattern),
    date
  });
}
const getDefaultDatePattern = (pattern) => pattern;
function getMediumDatePattern(pattern) {
  return pattern.replace(/(^|[^y])(y+)($|[^y])/g, "");
}
function getShortDatePattern(fullDatePattern) {
  return getMediumDatePattern(fullDatePattern).replace(/(^|[^M])(M{4})($|[^M])/g, "$1MM$3");
}
function createDateLocalizers(options2) {
  const toFullLocaleDate = createDateLocalizer(options2, getDefaultDatePattern);
  const toMediumLocaleDate = createDateLocalizer(options2, getMediumDatePattern);
  const toShortLocaleDate = createDateLocalizer(options2, getShortDatePattern);
  return {
    toFullLocaleDate,
    toMediumLocaleDate,
    toShortLocaleDate
  };
}
function randomIntInclusive(from, to) {
  const min = Math.ceil(from);
  const max = Math.floor(to);
  return Math.floor(Math.random() * (max - min + 1) + min);
}
function roundToDivisible(value, dividedBy) {
  return Math.round(Math.round(value * 10) / (dividedBy * 10)) * dividedBy;
}
function getCustomerTimezoneOffset(timeShift, timezoneOffset) {
  const offset = -timezoneOffset / 60;
  const shift = timeShift / 60 / 60 / 1e3;
  const roundedShift = roundToDivisible(shift, 0.5);
  const oneDayShift = Math.abs(roundedShift % 24);
  const todayShift = oneDayShift > 12 ? 24 - oneDayShift : oneDayShift;
  const shiftSign = oneDayShift > 12 ? Math.sign(roundedShift) : -Math.sign(roundedShift);
  const result = shiftSign * todayShift + offset;
  return Math.abs(result) > 12 ? -12 * Math.sign(result) + result % 12 : result;
}
function isFile(data) {
  if (process.env.VUE_APP_PLATFORM === "cordova") {
    return isObject(data) && data.name !== void 0 && data.size !== void 0 && data.type !== void 0 && data.lastModified !== void 0 && typeof data.slice === "function";
  }
  const Constructor = typeof File === "undefined" ? false : File;
  return Constructor && data instanceof Constructor;
}
function isArrayOfFiles(items) {
  return isArray(items) && items.every((item) => isFile(item));
}
function isPrimitive(value) {
  const type = typeof value;
  return value === null || type !== "object" && type !== "function";
}
const undefinedResult = /* @__PURE__ */ Symbol("undefined");
class Node {
  constructor() {
    this.weakMap = /* @__PURE__ */ new WeakMap();
    this.map = /* @__PURE__ */ new Map();
    this.value = void 0;
  }
  /**
   * @param {MemoizeArgument} key
   * @param {Node} node
   * @param {object} options
   * @param {boolean} options.allowMutation allow mutate key from outside.
   * @param {boolean} options.serialize allow to serialize arguments.
   */
  set(key, node, options2 = {}) {
    if (isPrimitive(key)) {
      this.map.set(key, node);
    } else if (options2.serialize) {
      this.map.set(JSON.stringify(key), node);
    } else {
      this.weakMap.set(key, node);
    }
  }
  get(key, options2) {
    if (isPrimitive(key)) {
      return this.map.get(key);
    }
    if (options2.serialize) {
      return this.map.get(JSON.stringify(key));
    }
    return this.weakMap.get(key);
  }
  has(key, options2) {
    if (isPrimitive(key)) {
      return this.map.has(key);
    }
    if (options2.serialize) {
      return this.map.has(JSON.stringify(key));
    }
    return this.weakMap.has(key);
  }
}
function memoize(callback, options2 = {}) {
  const root = new Node();
  const { exceptWhen, shared = false, serialize = false } = options2;
  const memoized = function memoized2(...rest) {
    let localRoot = root;
    const setterOptions = serialize ? { serialize } : { allowMutation: true };
    const getterOptions = { serialize };
    if (!shared) {
      const hasInstanceRoot = root.has(this, getterOptions);
      localRoot = hasInstanceRoot ? root.get(this, getterOptions) : new Node();
      if (!hasInstanceRoot) {
        root.set(this, localRoot, setterOptions);
      }
    }
    let node = localRoot;
    const restLength = rest.length;
    for (let index = 0; index < restLength; index += 1) {
      const argument = rest[index];
      if (exceptWhen == null ? void 0 : exceptWhen(argument, index)) {
        return Reflect.apply(callback, this, rest);
      }
      node = node.get(argument, getterOptions);
      if (typeof node === "undefined") break;
    }
    if (!node || typeof node.value === "undefined") {
      const rawValue = Reflect.apply(callback, this, rest);
      const value = rawValue === void 0 ? undefinedResult : rawValue;
      const restCopy = [...rest];
      node = localRoot;
      while (restCopy.length > 0) {
        const argument = restCopy.shift();
        if (node.has(argument, getterOptions)) {
          node = node.get(argument, getterOptions);
        } else {
          const newNode = new Node();
          node.set(argument, newNode, setterOptions);
          node = newNode;
        }
      }
      node.value = value;
    }
    return node.value === undefinedResult ? void 0 : node.value;
  };
  Object.defineProperty(memoized, "name", {
    writable: false,
    enumerable: false,
    configurable: true,
    value: `${callback.name}_memoized`
  });
  return memoized;
}
function safeCall(cb) {
  try {
    cb();
  } catch (err) {
    console.error(err);
  }
}
function voidify(callback) {
  const name = `${callback.name} wrapped`;
  const box = {
    [name](...rest) {
      void callback.apply(this, rest);
    }
  };
  return box[name];
}
function getUuid() {
  return v4();
}
var x64Add = function(m, n) {
  m = [m[0] >>> 16, m[0] & 65535, m[1] >>> 16, m[1] & 65535];
  n = [n[0] >>> 16, n[0] & 65535, n[1] >>> 16, n[1] & 65535];
  var o = [0, 0, 0, 0];
  o[3] += m[3] + n[3];
  o[2] += o[3] >>> 16;
  o[3] &= 65535;
  o[2] += m[2] + n[2];
  o[1] += o[2] >>> 16;
  o[2] &= 65535;
  o[1] += m[1] + n[1];
  o[0] += o[1] >>> 16;
  o[1] &= 65535;
  o[0] += m[0] + n[0];
  o[0] &= 65535;
  return [o[0] << 16 | o[1], o[2] << 16 | o[3]];
};
var x64Multiply = function(m, n) {
  m = [m[0] >>> 16, m[0] & 65535, m[1] >>> 16, m[1] & 65535];
  n = [n[0] >>> 16, n[0] & 65535, n[1] >>> 16, n[1] & 65535];
  var o = [0, 0, 0, 0];
  o[3] += m[3] * n[3];
  o[2] += o[3] >>> 16;
  o[3] &= 65535;
  o[2] += m[2] * n[3];
  o[1] += o[2] >>> 16;
  o[2] &= 65535;
  o[2] += m[3] * n[2];
  o[1] += o[2] >>> 16;
  o[2] &= 65535;
  o[1] += m[1] * n[3];
  o[0] += o[1] >>> 16;
  o[1] &= 65535;
  o[1] += m[2] * n[2];
  o[0] += o[1] >>> 16;
  o[1] &= 65535;
  o[1] += m[3] * n[1];
  o[0] += o[1] >>> 16;
  o[1] &= 65535;
  o[0] += m[0] * n[3] + m[1] * n[2] + m[2] * n[1] + m[3] * n[0];
  o[0] &= 65535;
  return [o[0] << 16 | o[1], o[2] << 16 | o[3]];
};
var x64Rotl = function(m, n) {
  n %= 64;
  if (n === 32) {
    return [m[1], m[0]];
  } else if (n < 32) {
    return [m[0] << n | m[1] >>> 32 - n, m[1] << n | m[0] >>> 32 - n];
  } else {
    n -= 32;
    return [m[1] << n | m[0] >>> 32 - n, m[0] << n | m[1] >>> 32 - n];
  }
};
var x64LeftShift = function(m, n) {
  n %= 64;
  if (n === 0) {
    return m;
  } else if (n < 32) {
    return [m[0] << n | m[1] >>> 32 - n, m[1] << n];
  } else {
    return [m[1] << n - 32, 0];
  }
};
var x64Xor = function(m, n) {
  return [m[0] ^ n[0], m[1] ^ n[1]];
};
var x64Fmix = function(h) {
  h = x64Xor(h, [0, h[0] >>> 1]);
  h = x64Multiply(h, [4283543511, 3981806797]);
  h = x64Xor(h, [0, h[0] >>> 1]);
  h = x64Multiply(h, [3301882366, 444984403]);
  h = x64Xor(h, [0, h[0] >>> 1]);
  return h;
};
const x64hash128 = function(key, seed) {
  key = String(key || "");
  seed = seed || 0;
  var remainder = key.length % 16;
  var bytes = key.length - remainder;
  var h1 = [0, seed];
  var h2 = [0, seed];
  var k1 = [0, 0];
  var k2 = [0, 0];
  var c1 = [2277735313, 289559509];
  var c2 = [1291169091, 658871167];
  for (var i = 0; i < bytes; i = i + 16) {
    k1 = [key.charCodeAt(i + 4) & 255 | (key.charCodeAt(i + 5) & 255) << 8 | (key.charCodeAt(i + 6) & 255) << 16 | (key.charCodeAt(i + 7) & 255) << 24, key.charCodeAt(i) & 255 | (key.charCodeAt(i + 1) & 255) << 8 | (key.charCodeAt(i + 2) & 255) << 16 | (key.charCodeAt(i + 3) & 255) << 24];
    k2 = [key.charCodeAt(i + 12) & 255 | (key.charCodeAt(i + 13) & 255) << 8 | (key.charCodeAt(i + 14) & 255) << 16 | (key.charCodeAt(i + 15) & 255) << 24, key.charCodeAt(i + 8) & 255 | (key.charCodeAt(i + 9) & 255) << 8 | (key.charCodeAt(i + 10) & 255) << 16 | (key.charCodeAt(i + 11) & 255) << 24];
    k1 = x64Multiply(k1, c1);
    k1 = x64Rotl(k1, 31);
    k1 = x64Multiply(k1, c2);
    h1 = x64Xor(h1, k1);
    h1 = x64Rotl(h1, 27);
    h1 = x64Add(h1, h2);
    h1 = x64Add(x64Multiply(h1, [0, 5]), [0, 1390208809]);
    k2 = x64Multiply(k2, c2);
    k2 = x64Rotl(k2, 33);
    k2 = x64Multiply(k2, c1);
    h2 = x64Xor(h2, k2);
    h2 = x64Rotl(h2, 31);
    h2 = x64Add(h2, h1);
    h2 = x64Add(x64Multiply(h2, [0, 5]), [0, 944331445]);
  }
  k1 = [0, 0];
  k2 = [0, 0];
  switch (remainder) {
    case 15:
      k2 = x64Xor(k2, x64LeftShift([0, key.charCodeAt(i + 14)], 48));
    // fallthrough
    case 14:
      k2 = x64Xor(k2, x64LeftShift([0, key.charCodeAt(i + 13)], 40));
    // fallthrough
    case 13:
      k2 = x64Xor(k2, x64LeftShift([0, key.charCodeAt(i + 12)], 32));
    // fallthrough
    case 12:
      k2 = x64Xor(k2, x64LeftShift([0, key.charCodeAt(i + 11)], 24));
    // fallthrough
    case 11:
      k2 = x64Xor(k2, x64LeftShift([0, key.charCodeAt(i + 10)], 16));
    // fallthrough
    case 10:
      k2 = x64Xor(k2, x64LeftShift([0, key.charCodeAt(i + 9)], 8));
    // fallthrough
    case 9:
      k2 = x64Xor(k2, [0, key.charCodeAt(i + 8)]);
      k2 = x64Multiply(k2, c2);
      k2 = x64Rotl(k2, 33);
      k2 = x64Multiply(k2, c1);
      h2 = x64Xor(h2, k2);
    // fallthrough
    case 8:
      k1 = x64Xor(k1, x64LeftShift([0, key.charCodeAt(i + 7)], 56));
    // fallthrough
    case 7:
      k1 = x64Xor(k1, x64LeftShift([0, key.charCodeAt(i + 6)], 48));
    // fallthrough
    case 6:
      k1 = x64Xor(k1, x64LeftShift([0, key.charCodeAt(i + 5)], 40));
    // fallthrough
    case 5:
      k1 = x64Xor(k1, x64LeftShift([0, key.charCodeAt(i + 4)], 32));
    // fallthrough
    case 4:
      k1 = x64Xor(k1, x64LeftShift([0, key.charCodeAt(i + 3)], 24));
    // fallthrough
    case 3:
      k1 = x64Xor(k1, x64LeftShift([0, key.charCodeAt(i + 2)], 16));
    // fallthrough
    case 2:
      k1 = x64Xor(k1, x64LeftShift([0, key.charCodeAt(i + 1)], 8));
    // fallthrough
    case 1:
      k1 = x64Xor(k1, [0, key.charCodeAt(i)]);
      k1 = x64Multiply(k1, c1);
      k1 = x64Rotl(k1, 31);
      k1 = x64Multiply(k1, c2);
      h1 = x64Xor(h1, k1);
  }
  h1 = x64Xor(h1, [0, key.length]);
  h2 = x64Xor(h2, [0, key.length]);
  h1 = x64Add(h1, h2);
  h2 = x64Add(h2, h1);
  h1 = x64Fmix(h1);
  h2 = x64Fmix(h2);
  h1 = x64Add(h1, h2);
  h2 = x64Add(h2, h1);
  return ("00000000" + (h1[0] >>> 0).toString(16)).slice(-8) + ("00000000" + (h1[1] >>> 0).toString(16)).slice(-8) + ("00000000" + (h2[0] >>> 0).toString(16)).slice(-8) + ("00000000" + (h2[1] >>> 0).toString(16)).slice(-8);
};
const hashString = x64hash128;
function setInputCursorAfterValue(inputElement) {
  if (inputElement.value !== "") {
    const position = inputElement.value.length;
    inputElement.setSelectionRange(position, position);
  }
}
const _Json = class _Json {
  static configure(options2) {
    _Json.silent = isUndefined(options2.silent) ? _Json.silent : options2.silent;
  }
  static parse(json, options2) {
    var _a;
    try {
      const result = JSON.parse(json);
      if (((_a = options2 == null ? void 0 : options2.validator) == null ? void 0 : _a.call(options2, result)) === false) {
        console.warn("Unexpected parsed value");
        return null;
      }
      return result;
    } catch (error) {
      if (!_Json.silent) {
        console.warn("Error parsing JSON:", error);
      }
      return (options2 == null ? void 0 : options2.defaultValue) ?? null;
    }
  }
  static stringify(json, options2) {
    try {
      return (options2 == null ? void 0 : options2.circular) ? _Json.stringifyCircular(json, options2) : JSON.stringify(json, options2 == null ? void 0 : options2.replacer);
    } catch (error) {
      if (!_Json.silent) {
        console.warn("Error stringify JSON:", error);
      }
      return (options2 == null ? void 0 : options2.defaultValue) ?? null;
    }
  }
  static stringifyCircular(json, options2) {
    let cacheObject = [];
    const result = JSON.stringify(json, (key, value) => {
      if (isObject(value) && value !== null) {
        assert(cacheObject, "cache object should not be empty here");
        if (cacheObject.includes(value)) {
          return options2.circularPlaceholder || null;
        }
        cacheObject.push(value);
      }
      return value;
    });
    cacheObject = null;
    return result;
  }
};
_Json.silent = false;
let Json = _Json;
function xor(a, b) {
  return !!(a && !b || !a && b);
}
function resolveModuleString(argument) {
  if (isObject(argument) && isString(argument.default)) {
    return argument.default;
  }
  if (!isString(argument)) {
    throw new Error("Not a string module");
  }
  return argument;
}
function serializeHeaders(headerEntries) {
  return headerEntries.reduce((accumulator, [key, value]) => ({ ...accumulator, [key]: value }), {});
}
function groupItemValuesByItemKeys(accumulator, item) {
  return Object.keys(item).reduce((valuesByKey, key) => {
    const group = valuesByKey[key] || [];
    const value = item[key];
    return {
      ...valuesByKey,
      [key]: group.includes(value) ? group : group.concat(value)
    };
  }, accumulator);
}
function groupItems(list) {
  return list.reduce((accumulator, item) => groupItemValuesByItemKeys(accumulator, item), {});
}
function getConflictingKeys(list) {
  if (list.length < 2) {
    return [];
  }
  const valuesByKey = groupItems(list);
  return Object.keys(valuesByKey).filter((key) => valuesByKey[key].length > 1);
}
function has(target, key) {
  return Object.prototype.hasOwnProperty.call(target, key) && target[key] !== void 0;
}
function keys(target) {
  return Object.keys(target);
}
function swapKeyValue(data) {
  return Object.entries(data).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});
}
const createNoopResolver = () => function deferredResolver() {
  return void 0;
};
const createNoopRejecter = () => function deferredRejecter() {
  return void 0;
};
class Deferred {
  constructor() {
    this.state = {
      resolved: false,
      rejected: false,
      resolver: createNoopResolver(),
      rejecter: createNoopRejecter()
    };
    this.promise = new Promise((resolve, reject) => {
      this.state.resolver = resolve;
      this.state.rejecter = reject;
    });
    this.resolve = this.resolve.bind(this);
    this.reject = this.reject.bind(this);
  }
  get resolved() {
    return this.state.resolved;
  }
  get rejected() {
    return this.state.rejected;
  }
  get finished() {
    return this.state.resolved || this.state.rejected;
  }
  resolve(value) {
    const { state } = this;
    if (state.resolved || state.rejected) {
      return;
    }
    state.resolved = true;
    state.rejected = false;
    state.result = value;
    state.resolver(value);
  }
  reject(error) {
    const { state } = this;
    if (state.resolved || state.rejected) {
      return;
    }
    this.state.resolved = false;
    this.state.rejected = true;
    this.state.rejecter(error);
  }
}
function getResolvedDeferred(value) {
  const deferred = new Deferred();
  deferred.resolve(value);
  return deferred;
}
function promiseTimeout({
  promise,
  timeout,
  error,
  onTimeout
}) {
  if (timeout === Infinity) {
    return promise;
  }
  assert(Number.isFinite(timeout) && timeout >= 0, "Expected ms argument to be a positive number");
  let timeoutID;
  let pending = true;
  const rejectingPromise = new Promise((resolve, reject) => {
    timeoutID = setTimeout(() => {
      if (pending) {
        const rejection = typeof error === "string" || !error ? new Error(error || `Timeout of ${timeout} exceeded`) : error();
        reject(rejection);
        onTimeout == null ? void 0 : onTimeout();
      }
    }, timeout);
  });
  const tearDown = () => {
    pending = false;
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
  };
  return Promise.race([
    promise.then((result) => {
      tearDown();
      return result;
    }, (reason) => {
      tearDown();
      return Promise.reject(typeof error === "function" ? error(reason) : reason);
    }),
    rejectingPromise
  ]);
}
function sleep(delay) {
  assert(Number.isFinite(delay) && !isFloat(delay) && delay >= 0, "Expected delay to be a finite, integer, greater or equal to zero value.");
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
function getRandomString(length = 10) {
  return Math.random().toString(36).slice(2, 2 + length);
}
function escape(text) {
  return text.replace(/[\s#$()*+,-.?[\\\]^{|}]/g, "\\$&");
}
function wrapPlaceholder(value) {
  return `\\\${${String(value)}}`;
}
function unwrapPlaceholder(value) {
  return value.slice(2, -1);
}
const createReplacer = (map) => (match) => String(map[unwrapPlaceholder(match)]);
function replace(content, replacements) {
  const rule = Object.keys(replacements).filter((key) => typeof replacements[key] !== "undefined").map(escape).map(wrapPlaceholder).join("|");
  if (rule) {
    const regExp = new RegExp(rule, "g");
    return content.replace(regExp, createReplacer(replacements));
  }
  return content;
}
function safeParseInt(value, radix = 10) {
  try {
    return parseInt(value, radix);
  } catch {
    return null;
  }
}
const stringIsInteger = (value) => value === parseInt(value, 10).toString();
function toBoolean(value) {
  if (!value) {
    return false;
  }
  if (value.toLowerCase() === "true") {
    return true;
  }
  if (value.toLowerCase() === "false") {
    return false;
  }
  return value;
}
function toLowerCase(message) {
  return message.toLowerCase();
}
const defaultOptions = { left: true, right: true };
function trim(input, char, options2 = defaultOptions) {
  assert(char.length === 1, `Unexpected char length: ${char.length}`);
  const { left, right } = options2;
  let start = 0;
  let end = input.length;
  if (left && right && char === " ") return input.trim();
  if (left) {
    while (start < end && input[start] === char) {
      ++start;
    }
  }
  if (right) {
    while (end > start && input[end - 1] === char) {
      --end;
    }
  }
  return start > 0 || end < input.length ? input.substring(start, end) : input;
}
const options$1 = { left: true, right: false };
const trimLeft = (input, char) => trim(input, char, options$1);
const options = { left: false, right: true };
const trimRight = (input, char) => trim(input, char, options);
function createUrlSlug(input) {
  return input.toLowerCase().trim().replace(/[^a-z0-9\s-]/g, "").replace(/\s+/g, "-").replace(/-+/g, "-");
}
function isScalar(argument) {
  if (typeof argument === "string") {
    return true;
  }
  if (typeof argument === "boolean") {
    return true;
  }
  if (typeof argument === "number" && Number.isFinite(argument)) {
    return true;
  }
  if (argument === null) {
    return true;
  }
  return false;
}
function stringifyQuery(parameters) {
  const input = parameters;
  return Object.keys(parameters).reduce((query, key) => {
    const value = input[key];
    if (!key || !isScalar(value)) {
      return query;
    }
    const pair = value === "" ? key : `${key}=${value}`;
    if (!query) {
      return pair;
    }
    return `${query}&${pair}`;
  }, "");
}
function getQueryUrlPart(parameters) {
  if (!parameters || !Object.values(parameters).length) {
    return "";
  }
  return `?${stringifyQuery(parameters)}`;
}
function removeQueryParameters(url) {
  return url.replace(/\?.*$/, "");
}
function cleanQuery(query) {
  return query.replace(/^[&?]+|[&=]+$/g, "");
}
function mergeQueries(query1, query2) {
  let fullQuery = "";
  if (typeof query1 === "string") {
    if (query1.length > 0) {
      fullQuery += cleanQuery(query1);
    }
  } else if (Object.keys(query1).length > 0) {
    fullQuery += stringifyQuery(query1);
  }
  if (typeof query2 === "string") {
    if (query2.length > 0) {
      fullQuery += fullQuery ? `&${cleanQuery(query2)}` : cleanQuery(query2);
    }
  } else if (Object.keys(query2).length > 0) {
    fullQuery += fullQuery ? `&${stringifyQuery(query2)}` : stringifyQuery(query2);
  }
  return fullQuery.length > 0 ? `?${fullQuery}` : fullQuery;
}
function hasSlot(slots, slotName) {
  const slot = slots[slotName];
  if (slot) {
    return slot().some((node) => node.type !== Comment);
  }
  return false;
}
function getIsPortraitOrientation() {
  return "matchMedia" in window && window.matchMedia("(orientation: portrait)").matches;
}
export {
  DateTime,
  DateTimeInterval,
  DecodeSymbol,
  DecodeValue,
  Deferred,
  IdleTimeQueueManager,
  InputDateFormats,
  Json,
  ScriptElement,
  StyleElement,
  TimeFormats,
  Timer,
  addLeadingZero,
  createDateLocalizers,
  createUrlSlug,
  sleep as delay,
  getClientDimension,
  getConflictingKeys,
  getCustomerTimezoneOffset,
  getIsPortraitOrientation,
  getPixelRatio,
  getQueryUrlPart,
  getRandomString,
  getRandomValues,
  getResolvedDeferred,
  getUuid,
  getViewportDimension,
  has,
  hasSlot,
  hashString,
  isArrayOfFiles,
  isFile,
  isToday,
  isVisibleInDOM,
  keys,
  memoize,
  mergeQueries,
  x64hash128 as murmurHash,
  promiseTimeout,
  randomIntInclusive,
  removeQueryParameters,
  replace,
  requestIdleCallback$1 as requestIdleCallback,
  resolveModuleString,
  roundToDivisible,
  ruPatternLocaleDateTime,
  safeCall,
  safeParseInt,
  serializeHeaders,
  setInputCursorAfterValue,
  sleep,
  stringIsInteger,
  stringifyQuery,
  swapKeyValue,
  toBoolean,
  toLowerCase,
  trim,
  trimLeft,
  trimRight,
  voidify,
  xor
};
